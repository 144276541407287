import { Component, Input, TemplateRef, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from '@app/services/common.service';
import { SitesService } from '../../../../../../../app/services/sites.service'
import { EventViewerService } from '@app/services/event-viewer.service';

import ims from '../../../../../imports'
import { Helper } from '../../../../../../../4services/2helper'

@Component({
  selector: 'log_box_audio',
  templateUrl: './audio.component.pug',
  styleUrls: ['../../../../common.scss','../../c_card_event_viewer_box.component.scss'],
})
export class log_box_audio_component {
  @Input() log;
  @Input() timezone;
  @Input() toolbar: TemplateRef<any>;
  @ViewChild('audioControl') audioControl: ElementRef;
  @ViewChild('audioProgressBar') audioProgressBar: ElementRef;

  dealerId: number 
  site: any
  isBuildReportMode = false;

  //TTS Audio
  TTS: any = {};
  isAudioPlay = false;
  isAudioLoading = false;

  constructor(
    private helper: Helper,
    public commonService: CommonService,
    private sitesService: SitesService,
    private eventViewerService: EventViewerService,
    private cdr: ChangeDetectorRef
  ) {}

  private isClearSelectedLogs$w: Subscription;
  private isBuildReportMode$w: Subscription;
  watch() {
    this.isClearSelectedLogs$w = this.eventViewerService.isClearSelectedLogs$w.subscribe((v) => this.unselectLog(v));
    this.isBuildReportMode$w = this.eventViewerService.isBuildReportMode$w.subscribe((v) => this.isBuildReportMode = v);
  }
  unwatch() {
    this.isClearSelectedLogs$w?.unsubscribe();
    this.isBuildReportMode$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.site = this.sitesService.selSite;
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  unselectLog(value){
    if(value === null) return
    if(!value) return
    this.log.isSelected = false
    this.cdr.detectChanges();  // 변경감지 수행
  }

  onClickLog(e){
    e.stopPropagation();
    this.isBuildReportMode ? this.selectLog() : this.openLogDetail()
  }

  selectLog() {
    this.log.isSelected = !this.log.isSelected
    this.log.isSelected
      ? this.eventViewerService.addSelectedLogs(this.log)
      : this.eventViewerService.deleteSelectedLogs(this.log)
  }

  openLogDetail(){
    this.eventViewerService.openDetailModal({status: true, log: this.log})
  }

  // -------------------------------------------------------
  // COMMON

  showToolbar = ims._.throttle(this.showToolbarThrottle.bind(this), 1000);
  onHoverLog(e, id){
    e.stopPropagation();
    this.showToolbar(id)
  }
  showToolbarThrottle(id){
    const toolbox = document.getElementById('log-box-toolbox'+id)
    if(toolbox) toolbox.style.visibility = 'visible';
  }

  hiddenToolbar = ims._.throttle(this.hiddenToolbarThrottle.bind(this), 1000);
  onMouseLeaveLog(e, log){
    e.stopPropagation();
    this.hiddenToolbar(log)
  }
  hiddenToolbarThrottle(log){
    log.isShowMenu = false
    const toolbox = document.getElementById('log-box-toolbox'+log.id)
    if(toolbox) toolbox.style.visibility = 'hidden';
  }
  

  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  // -----------------------------------------
  // Playing TTS
  async getTtsUrl(){
    this.log.isAudioLog = true;

    try {
      const siteId = this.site.site_id;
      const activityLogId = this.log.id;
      const res = await this.helper.activity_log.load_site_tts_url(siteId, activityLogId)
      this.TTS.signedUrl = res.pre_signed_url;
      this.log.signedUrl = res.pre_signed_url
    } catch(err) {
      console.debug('getTtsUrl :>',err)
    }
  }

  //AUDIO play
  async toggleAudioPlay(e) {
    e.stopPropagation();
    this.isAudioLoading = true
    if(!this.log.signedUrl) await this.getTtsUrl()

    if (this.TTS.isAudioLoad) {
      this.stopAudio();
    } else {
      this.playAudio();
    }
  }

  initAudioPlay() {
    this.TTS.isAudioLoad = false;
    this.isAudioPlay = false;
  }

  playAudio() {
    this.initAudioPlay();
    this.TTS.isAudioLoad = true;
    this.isAudioPlay = true;
    this.audioControl.nativeElement.src = this.TTS.signedUrl;
    this.isAudioLoading = false
    this.audioControl.nativeElement.play();
  }

  stopAudio() {
    console.debug('stop audio')
    this.isAudioLoading = false
    if (this.audioControl) {
      const audio = this.audioControl.nativeElement;
      audio.pause();
    }
    this.TTS.isAudioLoad = false;
    this.isAudioPlay = false;
    this.audioProgressBar.nativeElement.style.transform = 'scaleX(0)'
  }

  loadMetadata(){
    const duration = this.audioControl.nativeElement.duration;
    console.log(`Audio duration: ${duration} seconds`);
  }

  updateProgress(){
    const currentTime = this.audioControl.nativeElement.currentTime;
    const duration = this.audioControl.nativeElement.duration;
    const scaleX = currentTime / duration;
    this.audioProgressBar.nativeElement.style.transform = `scaleX(${scaleX})`;
  }
}
