import { 
  Component, 
  Input, 
  Output, 
  EventEmitter, 
  ViewChild, 
  ElementRef, 
  Renderer2, 
  ChangeDetectionStrategy, 
  ChangeDetectorRef 
} from '@angular/core';
import { SitesService } from '@app/services/sites.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { Subscription } from 'rxjs';

import ims from '../../../imports'

@Component({
  selector: 'c_card_event_viewer_box',
  templateUrl: './c_card_event_viewer_box.component.pug',
  styleUrls: ['../../common.scss','./c_card_event_viewer_box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class c_card_event_viewer_box_component {
  @Input() isToday: boolean;
  @Output() recallLogs: EventEmitter<any> = new EventEmitter();
  @Output() onClickEventViewer: EventEmitter<any> = new EventEmitter();
  @Output() onClickLogModal: EventEmitter<any> = new EventEmitter();
  @Output() onClickVerificationModal: EventEmitter<any> = new EventEmitter();
  @Output() onClickSharedLinkModal: EventEmitter<any> = new EventEmitter();
  @Output() onClickDownloadLinkModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('timeListEl') timeListEl: ElementRef;

  timezone: string = ''
  filteredLogs:any[] = []
  isAsc:boolean = false

  isLoading = false; 
  addLogLoading = false;
  processedLogs:any[] = [];

  selectedTimeIndex: any;
  midLogIndex: any;
  timeList: any[] = []; // 시간 리스트
  lastHighlightedTime: string | null = null;

  // download
  blobUrlSnapshots = [];
  snapshots = []
  mp4Video = null; // download available
  mp4Url = '';

  hasDownloadPermission: boolean = false
  hasShareLinkPerm: boolean = false

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private sitesService: SitesService,
    private eventViewerService: EventViewerService, 
  ) {
  }

  private filteredLogs$w: Subscription;
  private sortOrder$w: Subscription;
  private isReadyFilteredLogs$w: Subscription;
  private selCategoryList$w: Subscription;
  watch() {
    this.filteredLogs$w = this.eventViewerService.filteredLogs$w.subscribe((v) => this.checkChangeFilteredLogs(v));
    this.sortOrder$w = this.eventViewerService.sortOrder$w.subscribe((v) => this.checkSortOrder(v));
    this.selCategoryList$w = this.eventViewerService.selCategoryList$w.subscribe((v) => {
      this.setDefaultLabel()
    });
  }
  unwatch() {
    this.filteredLogs$w?.unsubscribe();
    this.sortOrder$w?.unsubscribe();
    this.isReadyFilteredLogs$w?.unsubscribe();
    this.selCategoryList$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoading = true
    this.initData()
    this.setTimeList()
    this.watch()
  }

  ngAfterViewInit() {
    this.setDefaultLabel()
  }

  // ----------------------------------------
  initData(){
    this.timezone = this.sitesService.selSite.timezone
  }
  checkChangeFilteredLogs(value){
    this.isReadyFilteredLogs$w = this.eventViewerService.isReadyFilteredLogs$w.subscribe(isReadyFilteredLogs => {
      if (!isReadyFilteredLogs) {
        if (value.length === 0) {
          this.isLoading = false;
          this.processedLogs = [];
        }
        return; // continue loading
      }

      this.addLogLoading = false;
      this.filteredLogs = value;
      this.processedLogs = this.makeProcessLogs(value);
    });
  }

  async checkSortOrder(value){
    if(!this.timeList || !this.timeList.length) return 

    this.isAsc = value === 'Ascending' ? true : false
    this.timeList.sort((a, b) => this.compare(a.twentyFourTime, b.twentyFourTime, this.isAsc))

    await ims.tool.sleep(700)
    const firstLabel = this.timeList.find(v => !v.isDisabled)?.label ?? ''
    const hour = this.convertTo24HourFormat(firstLabel)
    this.highlightTimeInList(hour);
    this.moveToSelectedTime(firstLabel)
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  // -----------------------------------------
  // Time List
  setTimeList (){
    const currentHour = this.isToday ? ims.moment().tz(this.timezone).hour() : null;

    // PM 시간 생성
    for (let i = 11; i >= 0; i--) {
      const time = i === 0 ? 12 : i;
      const twentyFourTime = time === 12 ? time : time + 12;
      this.createTime(time, 'pm', twentyFourTime, currentHour);
    }
    // AM 시간 생성
    for (let i = 11; i >= 0; i--) {
      const twentyFourTime = i;
      this.createTime(i, 'am', twentyFourTime, currentHour);
    }
  }
  createTime (hour, meridiem, twentyFourTime, currentHour) {
    const time = `${hour} ${meridiem}`;
    const convertTo24HourFormatTime = this.convertTo24HourFormat(time)

    // 현재 시간보다 이전이면 추가하지 않음
    if (currentHour !== null && convertTo24HourFormatTime > currentHour) {
      return;
    }
    this.timeList.push({ label: time, isDisabled: true, twentyFourTime });
  };
  addIconsToMidnightNoon(label){
    if(label === '12 pm') return '☀️'
    if(label === '0 am') return '🌙'
    return false
  }
  setTimeListDisabled(label){
    const hourMarkLabel = this.convertTo24HourFormat(label)
    const timeListValue= this.timeList?.find(time => hourMarkLabel === this.convertTo24HourFormat(time.label))
    if(timeListValue) {
      timeListValue.isDisabled = false
      this.cdr.markForCheck()
    }
  }

  convertTimeListId(timeStr): string { // time : 12 pm
    const twentyForHourFormat = this.convertTo24HourFormat(timeStr)
    return `time-${twentyForHourFormat}` // time-20
  }

  // HOUR MARK
  convertHourMarkLabelId(timeStr): string { // time : 8 pm
    const twentyForHourFormat = this.convertTo24HourFormat(timeStr)
    return `hour-${twentyForHourFormat}` // hour-20
  }

  makeProcessLogs(logs): any[] {
    if (logs.length === 0) {
      this.isLoading = false;
      return [];
    }

    let processedLogs = [];
    this.timeList.forEach(v => v.isDisabled = true)
    const isAsc = this.eventViewerService.getIsSortOrder() // 여기서는 이것으로 적용해야 안바뀜
    isAsc 
      ? this.makeProcessLogsByAsc(logs, processedLogs)
      : this.makeProcessLogsByDes(logs, processedLogs)

    this.isLoading = false
    return processedLogs;
  }

  makeProcessLogsByDes(logs, processedLogs){
    for (let i = 0; i < logs.length; i++) {
      const currentLog = logs[i];
      currentLog.isVisible = true
      const currentLogDate = new Date(currentLog.event_time);

      // 현재 로그 추가
      processedLogs.push(currentLog);

      // 마지막 로그가 아닐 경우, 다음 로그와의 정각 마크를 확인
      if (i < logs.length - 1) {
        const nextLog = logs[i + 1];
        const nextLogDate = new Date(nextLog.event_time);
        
        const currentLogHour = parseInt(ims.moment(currentLogDate).tz(this.timezone).format('H'))
        const nextLogHour = parseInt(ims.moment(nextLogDate).tz(this.timezone).format('H'))
        
        if((currentLogHour) === nextLogHour) continue // 같다면 패스
        
        let hour = currentLogHour
        while(hour > nextLogHour){ // 20, , , 17
          // 전제 : currentLogHour > nextLogHour
          let label = ims.moment(currentLogDate).tz(this.timezone).format('h:00 a')
          if(label === '12:00 am') label = '0:00 am'
          if(processedLogs.find(v => v.label === label)) break
          const id = ims.moment(currentLogDate).tz(this.timezone).format('H')
          processedLogs.push({ id, specialRow: true, isShowMore: false, label, isVisible: true });
          this.setTimeListDisabled(label)
          hour--
        }
      }

      // 마지막 로그일 경우, 마지막 로그의 시간을 넣기
      if(i == logs.length - 1) {
        let label = ims.moment(currentLogDate).tz(this.timezone).format('h:00 a')
        if(label === '12:00 am') label = '0:00 am'

        const id = ims.moment(currentLogDate).tz(this.timezone).format('H')
        processedLogs.push({ id, specialRow: true, label, isVisible: true });

        const isEnoughInit = this.eventViewerService.getIsEnoughInit()
        if(label != '0:00 am' && !isEnoughInit) {
          processedLogs.push({ id, specialRow: false, isShowMore: true, label, isVisible: true });
        }
        this.setTimeListDisabled(label)
      }
    }
  }
  makeProcessLogsByAsc(logs, processedLogs){
    for (let i = 0; i < logs.length; i++) {
      const currentLog = logs[i];
      currentLog.isVisible = true
      const currentLogDate = new Date(currentLog.event_time);

      // 첫번째 로그일 경우, 00am 시간을 넣기
      if(i === 0) {
        let label = ims.moment(currentLogDate).tz(this.timezone).format('h:00 a')
        if(label === '12:00 am') label = '0:00 am'
        const id = parseInt(ims.moment(currentLogDate).tz(this.timezone).format('H'))
        processedLogs.unshift({ id, specialRow: true, label, isVisible: true });
        processedLogs.push(currentLog);
        this.setTimeListDisabled(label)
      }
  
      if (i < logs.length - 1 && i > 0) {
        let label = ims.moment(currentLogDate).tz(this.timezone).format('h:00 a');
        if (label === '12:00 am') label = '0:00 am';

        if(processedLogs.find(v => v.label === label)) {
          processedLogs.push(currentLog);
        } else {
          const id = parseInt(ims.moment(currentLogDate).tz(this.timezone).format('H'))
          processedLogs.push({ id, specialRow: true, isShowMore: false, label, isVisible: true });
          processedLogs.push(currentLog);
          this.setTimeListDisabled(label);
        }
      }

      // 마지막 로그일 경우, Show more만 추가하기
      if(i == logs.length - 1) {
        processedLogs.push(currentLog);
        const currentLogHour = parseInt(ims.moment(currentLogDate).tz(this.timezone).format('H'))
        const isEnoughInit = this.eventViewerService.getIsEnoughInit()

        if(currentLogHour != 23 && !isEnoughInit) {
          const id = currentLogHour
          processedLogs.push({ id, specialRow: false, isShowMore: true, label: null, isVisible: true });
        }
      }
    }
  }

  // tool
  convertTo24HourFormat(time: string): number{
    let hour = time.split(' ')[0]?.split(':')[0]
    let medium = time.split(' ')[1]
    let mediumOffset = 0
    if(hour != '12' && medium === 'pm') mediumOffset = 12
    const twentyForHourFormat = parseInt(hour) + mediumOffset
    return twentyForHourFormat
  }

  // SCROLL EVENT
  setDefaultLabel(){
    const firstLabel = this.timeList.find(v => !v.isDisabled)?.label ?? ''
    const hour = this.convertTo24HourFormat(firstLabel)
    this.highlightTimeInList(hour);
  }


  async moveToSelectedTime(time: string) { // time : 8 pm
    if(!this.processedLogs.length) return
    const labelHour = this.convertTo24HourFormat(time)
    const visibleLogIdx = this.processedLogs.findIndex(v => v.id == labelHour)
    this.selectedTimeIndex = visibleLogIdx

    if(visibleLogIdx > -1) {
      const minIndex = visibleLogIdx - 20 < 0 ? 0 : visibleLogIdx - 20
      const maxIndex = visibleLogIdx + 20 > this.processedLogs.length - 1 ? this.processedLogs.length - 1 : visibleLogIdx + 20

      for(let i = minIndex; i < maxIndex; i++) {
        this.processedLogs[i].isVisible = true
      }
    } else {
      for(let i = 0; i < 20; i++) {
        this.processedLogs[i].isVisible = true
      }
    }

    await ims.tool.sleep(100)
    const element = document.getElementById(`hour-${labelHour}`);
    const block = this.isAsc ? 'start' : 'end'
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: block });
    }
  }

  highlightTimeInList(hour: string | number): void {
    this.removePreviousHighlights();

    const timeListItem = this.el.nativeElement.querySelector(`#time-${hour}`);
    if (timeListItem) {
      this.renderer.addClass(timeListItem, 'highlighted');
    }
  }
  removePreviousHighlights(): void {
    // 모든 타임 리스트 아이템의 하이라이트를 제거합니다.
    const timeListItems = this.el.nativeElement.querySelectorAll('[id^="time-"]');
    timeListItems.forEach(item => {
      this.renderer.removeClass(item, 'highlighted');
    });
  }

  getCurrentHourBasedOnScroll(){
    if(!this.midLogIndex) return

    const log = this.processedLogs[this.midLogIndex]
    const label = log?.label
    if(label) return this.convertTo24HourFormat(label)
    if(log) {
      const currentLogDate = new Date(log.event_time);
      const hour = ims.moment(currentLogDate).tz(this.timezone).format('H')
      return hour
    }
    return null
  }

  // ------------------------------------
  // VIRTUAL SCROLL 
  debounceHandleVisibility = ims._.debounce(this.setVisibility.bind(this), 200)
  handleVisibility(element){
    this.debounceHandleVisibility(element)
  }
  setVisibility(element){
    let visibleLogIdx = element.dataset?.logIndex
    if(!visibleLogIdx) return

    if(this.selectedTimeIndex) visibleLogIdx = this.selectedTimeIndex
    this.midLogIndex = visibleLogIdx
    this.onScroll()

    if(visibleLogIdx < -1) {
      this.processedLogs.forEach((log, i) => {
        if(i < 50) log.isVisible = true
        else log.isVisible = false
      })
    } else {
      this.processedLogs.forEach((log, i) => {
        if(i > visibleLogIdx - 50 && i < visibleLogIdx + 50) log.isVisible = true
        else log.isVisible = false
      })
    }
    this.selectedTimeIndex = null; // init
  }

  onScroll(){
    const hour = this.getCurrentHourBasedOnScroll();
    this.highlightTimeInList(hour);
  }

  // -------------------------------------
  // INFINITY SCROLL
  debounceRecallByAsc = ims._.debounce(this.recallLogsCallbackByAsc.bind(this), 1000)
  debounceRecallByDesc = ims._.debounce(this.recallLogsCallbackByDesc.bind(this), 1000)
  closeToLastList(event: string | null) {
    const hour:string | null = event

    if(!hour) return
    if(this.eventViewerService.getIsEnoughInit()) return // 이미 충분함.
    if(this.addLogLoading) return

    const lastLog = this.filteredLogs[this.filteredLogs.length - 1]
    this.isAsc ? this.recallLogsCallbackByAsc(lastLog) : this.debounceRecallByDesc(lastLog)
  }
  recallLogsCallbackByAsc(lastLog){
    // const lastLogDate = ims.moment(lastLog.event_time).add(1, 'second').tz(this.timezone)
    const lastLogDate = ims.moment(lastLog.event_time).tz(this.timezone)
    const startDate = lastLogDate.clone().utc().format('YYYY-MM-DD HH:mm:ss'); 
    const endDate = lastLogDate.clone().endOf('day').utc().format('YYYY-MM-DD HH:mm:59');

    console.debug('recall by asc!!', startDate, endDate)
    this.addLogLoading = true
    this.recallLogs.emit({startDate, endDate});
  }
  recallLogsCallbackByDesc(lastLog){
    // const lastLogDate = ims.moment(lastLog.event_time).subtract(1, 'second').tz(this.timezone)
    const lastLogDate = ims.moment(lastLog.event_time).tz(this.timezone)
    const startDate = lastLogDate.clone().startOf('day').utc().format('YYYY-MM-DD HH:mm:00'); 
    const endDate = lastLogDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');

    console.debug('recall by desc!!', startDate, endDate)
    this.addLogLoading = true
    this.recallLogs.emit({startDate, endDate});
  }

  isShowAddLogLoading(item){
    return this.addLogLoading && item == this.processedLogs[this.processedLogs.length -1]
  }

  // ----------------------------------
  // APPLY SNAPSHOT
  async showSnapshotOrVideo(element) {
    const visibleLogIdx = element.dataset?.logIndex
    if(!visibleLogIdx) return

    const visibleLog = this.processedLogs[visibleLogIdx]
    if(!visibleLog?.id || visibleLog?.imageUrl) return
    visibleLog.isLoading = true
    await this.eventViewerService.setLogSnapshots([visibleLog])
    this.cdr.detectChanges();
  }


  // ----------------------------------
  // TOOL BAR
  onClickEventViewerInToolbar(e){
    this.onClickEventViewer.emit(e)
  }  
  onClickLogModalInToolbar(e){
    this.onClickLogModal.emit(e)
  }
  onClickVerificationModalToolbar(e){
    this.onClickVerificationModal.emit(e)
  }
  onClickSharedLinkModalToolbar(e){
    this.onClickSharedLinkModal.emit(e)
  }
  onClickDownloadLinkModalToolbar(e){
    this.onClickDownloadLinkModal.emit(e)
  }
}
