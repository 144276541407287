
import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import { ApiService } from '@app/services/api.service'; 
import ims from '../../imports'

declare var google: any;

@Component({
  selector: 'c_map',
  template: `
    <div *ngIf="!isDynamicView">
      <img [src]="staticMapUrl" alt="Static Google Map" style="width: 100%; height: 100%;" />
    </div>
    <div #googleMap *ngIf="isDynamicView" style="width: 100%;height: 100%; min-height: 300px"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class c_map_component {
  @Input() lat: number;
  @Input() lng: number;
  @Input() width: number = 600;
  @Input() height: number = 300;
  @Input() isDynamicView: boolean = false;
  @Output() changePosition : EventEmitter<any> = new EventEmitter();
  @ViewChild('googleMap') googleMapElement: any;

  map: any;
  marker: any;
  staticMapUrl: string;

  constructor(private apiService: ApiService) { }

  async ngAfterViewInit() {
    await ims.tool.sleep(100)
    if (this.isDynamicView) {
      this.updateMap();
    } else {
      this.loadStaticMap();
    }
  }

  ngOnChanges(changes) {
    if (this.isDynamicView) {
      this.updateMap();
    } else {
      this.loadStaticMap();
    }
  }

  loadStaticMap() {
    const width = Math.floor(this.width)
    const height = Math.floor(this.height)
    this.staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${this.lat},${this.lng}&zoom=16&size=${width}x${height}&scale=2&markers=color:red|${this.lat},${this.lng}&key=${this.apiService.googleApiKey}`;
  }

  async initializeDynamicMap() {
    const location = new google.maps.LatLng(this.lat, this.lng);
    const mapProp = {
      center: location,
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true
    };

    await ims.tool.sleep(100)
    if(!this.googleMapElement?.nativeElement) return
    this.map = new google.maps.Map(this.googleMapElement.nativeElement, mapProp);
    this.map.setTilt(45);

    this.marker = new google.maps.Marker({
      position: location,
      draggable: true,
      map: this.map
    });

    this.marker.addListener('dragend', (event) => {
      this.handleEvent(event);
    });
  }

  async updateMap() {
    if(!this.map) await this.initializeDynamicMap()
    const location = new google.maps.LatLng(this.lat, this.lng);
    this.map.setCenter(location);
    this.marker.setPosition(location);
  }

  handleEvent(event) {
    let lat = event.latLng.lat();
    let lng = event.latLng.lng();
    this.changePosition.emit({lat: lat, lng: lng});
  }

}

