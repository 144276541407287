import { NgModule } from "@angular/core";
import { subscribers_component } from "./subscribers_component";
import { c_subscriber_api_errors_module } from "./api_errors/c_subscriber_api_errors_module";
import { c_subscriber_state_changes_module } from "./state_changes/c_subscriber_state_changes_module";

@NgModule({
  imports: [c_subscriber_api_errors_module, c_subscriber_state_changes_module],
  declarations: [subscribers_component],
  exports: [subscribers_component],
})
export class subscribers_module {}
