import { Component, Inject } from "@angular/core";
import { SitesService } from "@app/services/sites.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import * as _ from 'lodash';
import { Helper } from "src/4services/2helper";

@Component({
  templateUrl: './c_dialog_arming_mode_after_schedule_component.pug',
  styleUrls: ['../common.scss', './c_dialog_arming_mode_after_schedule_component.scss']
})
export class c_dialog_arming_mode_after_schedule_component {
  constructor(
    public dialogRef: MatDialogRef<c_dialog_arming_mode_after_schedule_component>,
    private sitesService: SitesService,
    private helper: Helper
  ) { }

  dealerId: any;
  site: any;
  isLoading = false;

  armingStatus = [
    { value: 1, name: "Armed" },
    { value: 2, name: "Disarmed" },
  ];
  selArmingStatus = this.armingStatus[1]; // default : Disarmed

  async ngOnInit() {
    await this.get_me();
    this.site = this.sitesService.selSite;
  }

  //---------------------------------
  async get_me(){
    const me = await this.helper.me.get_me()
    this.dealerId = me.dealer_id;
  }

  async onClickApply(){
    const dealerId = this.dealerId;
    const siteId = this.site.site_id;
    const armingMode = this.selArmingStatus.value;
    try {
      await this.sitesService.putSiteArming(dealerId, siteId, armingMode).toPromise();
      this.dialogRef.close(true);
    } catch(err) {
      console.debug(err);
    }
  }

  close_dialog(result = false){
    this.dialogRef.close(result);
  }
}
