import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Permission } from '../permission'
import { BehaviorSubject, Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class note_directions {

  constructor(
    private ims: Imports, 
    private permission: Permission,
  ) {}

  public dealer_site_notes$w(): Observable<any> {
    return this.ims.noteDirectionsService.dealerSiteNotes$w;
  }
  public site_notes$w(): Observable<any> {
    return this.ims.noteDirectionsService.siteNotes$w;
  }
  public site_note$w(): Observable<any> {
    return this.ims.noteDirectionsService.siteNote$w;
  }
  public dealer_monitoring_directions$w(): Observable<any> {
    return this.ims.noteDirectionsService.dealerMonitoringDirections$w;
  }
  public monitoring_directions$w(): Observable<any> {
    return this.ims.noteDirectionsService.monitoringDirections$w;
  }
  public monitoring_direction$w(): Observable<any> {
    return this.ims.noteDirectionsService.monitoringDirection$w;
  }
  public procedureList$w(): Observable<any> {
    return this.ims.noteDirectionsService.procedureList$w;
  }

  public async get_dealer_site_notes(): Promise<Array<any>> | undefined {
    await this.load_dealer_site_notes();
    return this.ims.noteDirectionsService.dealerSiteNotes$s.getValue();
  }

  public async get_site_notes(site_id): Promise<Array<any>> | undefined {
    await this.load_site_notes(site_id);
    return this.ims.noteDirectionsService.siteNotes$s.getValue();
  }

  public async get_site_note(site_id, note_id): Promise<any> | undefined {
    await this.load_site_note(site_id, note_id);
    return this.ims.noteDirectionsService.siteNote$s.getValue();
  }

  public async get_dealer_monitoring_directions(): Promise<Array<any>> | undefined {
    await this.load_dealer_monitoring_directions();
    return this.ims.noteDirectionsService.dealerMonitoringDirections$s.getValue();
  }

  public async get_monitoring_directions(site_id): Promise<Array<any>> | undefined {
    await this.load_monitoring_directions(site_id);
    return this.ims.noteDirectionsService.monitoringDirections$s.getValue();
  }

  public async get_monitoring_direction(site_id, note_id): Promise<any> | undefined {
    await this.load_monitoring_direction(site_id, note_id);
    return this.ims.noteDirectionsService.monitoringDirection$s.getValue();
  }

  public async get_all_procedures(site_id, mp_direction_id): Promise<any> | undefined {
    await this.load_all_procedures(site_id, mp_direction_id);
    return this.ims.noteDirectionsService.procedureList$s.getValue();
  }

  // --------------------------------------------
  // ----------------- Site Note -----------------
  public async load_dealer_site_notes(): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteReadPermission = this.permission.has("site_note_read")
      if(!hasSiteNoteReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.getDealerSiteNotes(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Site Note List" });
      return false;
    }
  }

  public async load_site_notes(site_id: number | string): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteReadPermission = this.permission.has("site_note_read")
      if(!hasSiteNoteReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.getSiteNotes(dealer_id, site_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Site Note List" });
      return false;
    }
  }

  public async load_site_note(site_id: number | string, note_id): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteReadPermission = this.permission.has("site_note_read")
      if(!hasSiteNoteReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.getSiteNote(dealer_id, site_id, note_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Site Note" });
      return false;
    }
  }

  public async create_site_note(site_id, data: any): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteCreatePermission = this.permission.has("site_note_create")
      if(!hasSiteNoteCreatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.noteDirectionsService.createSiteNote(dealer_id, site_id, data).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Site Note` });
      return false;
    }
  }

  public async delete_site_note(site_id, note_id): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteDeletePermission = this.permission.has("site_note_delete")
      if(!hasSiteNoteDeletePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.deleteSiteNote(dealer_id, site_id, note_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Site Note` });
      return false;
    }
  }

  public async update_site_note(site_id, note_id, data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteUpdatePermission = this.permission.has("site_note_update")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.updateSiteNote(dealer_id, site_id, note_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Site Note` });
      return false;
    }
  }


  // ----------------- Monitoring Directions -----------------
  public async load_dealer_monitoring_directions(): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteReadPermission = this.permission.has("mp_direction_read")
      if(!hasSiteNoteReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.getDealerMonitoringDirections(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Monitoring Directions List" });
      return false;
    }
  }

  public async load_monitoring_directions(site_id: number | string): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteReadPermission = this.permission.has("mp_direction_read")
      if(!hasSiteNoteReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.getSiteMonitoringDirections(dealer_id, site_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Monitoring Directions List" });
      return false;
    }
  }

  public async load_monitoring_direction(site_id: number | string, note_id): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteReadPermission = this.permission.has("mp_direction_read")
      if(!hasSiteNoteReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.getSiteMonitoringDirection(dealer_id, site_id, note_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Monitoring Direction" });
      return false;
    }
  }

  public async create_monitoring_direction(site_id, data: any): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteCreatePermission = this.permission.has("mp_direction_create")
      if(!hasSiteNoteCreatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.noteDirectionsService.createSiteMonitoringDirection(dealer_id, site_id, data).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Monitoring Directions` });
      return false;
    }
  }

  public async delete_monitoring_direction(site_id, note_id): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteDeletePermission = this.permission.has("mp_direction_delete")
      if(!hasSiteNoteDeletePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.deleteSiteMonitoringDirection(dealer_id, site_id, note_id).toPromise();
      await this.ims.sitesService.getAllSiteProcedures(dealer_id, site_id).toPromise(); // update site procedure list
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Monitoring Directions` });
      return false;
    }
  }

  public async update_monitoring_direction(site_id, note_id, data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteUpdatePermission = this.permission.has("mp_direction_update")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.updateSiteMonitoringDirection(dealer_id, site_id, note_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Monitoring Directions` });
      return false;
    }
  }

  public async update_monitoring_direction_status(site_id, note_id, data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteUpdatePermission = this.permission.has("mp_direction_approve")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.updateSiteMonitoringDirectionStatus(dealer_id, site_id, note_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Monitoring Directions` });
      return false;
    }
  }

  public async copy_monitoring_direction_status(site_id, note_id, data: any): Promise<number> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteUpdatePermission = this.permission.has("mp_direction_update")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const { newCopyMpDirectionId } = await this.ims.noteDirectionsService.copySiteMonitoringDirection(dealer_id, site_id, note_id, data).toPromise();
      return newCopyMpDirectionId;
    } catch (err) {
      console.debug('Copy Monitoring Directions Internal') // internal이라서 toast를 표시하지 않음
      return null;
    }
  }

  // ----------------- Procedure -----------------
  public async load_all_procedures(site_id, mp_direction_id): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteReadPermission = this.permission.has("mp_direction_read")
      if(!hasSiteNoteReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.getAllProcedures(dealer_id, site_id, mp_direction_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Procedure List" });
      throw err;
    }
  }

  public async create_procedure(site_id, mp_direction_id, data: any): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteCreatePermission = this.permission.has("mp_direction_update")
      if(!hasSiteNoteCreatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.noteDirectionsService.createProcedure(dealer_id, site_id, mp_direction_id, data).toPromise();
      await this.ims.sitesService.getAllSiteProcedures(dealer_id, site_id).toPromise(); // update site procedure list
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Procedure` });
      throw err;
    }
  }

  public async update_procedure(site_id, procedure_id, data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteUpdatePermission = this.permission.has("mp_direction_update")
      if(!hasSiteNoteUpdatePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.updateProcedure(dealer_id, site_id, procedure_id, data).toPromise();
      await this.ims.sitesService.getAllSiteProcedures(dealer_id, site_id).toPromise(); // update site procedure list
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Procedure` });
      throw err;
    }
  }

  public async delete_procedure(site_id, procedure_id): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSiteNoteDeletePermission = this.permission.has("mp_direction_update")
      if(!hasSiteNoteDeletePermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.noteDirectionsService.deleteProcedure(dealer_id, site_id, procedure_id).toPromise();
      await this.ims.sitesService.getAllSiteProcedures(dealer_id, site_id).toPromise(); // update site procedure list
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Procedure` });
      return false;
    }
  }


  // ----------------------------------------
  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
