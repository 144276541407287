import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeBackground } from '../../../../app/pages/animation';
import { UsersService } from '@app/services/users.service';
import { CommonService } from '@app/services/common.service';
import { DealerPlanService } from '@app/services/service-plan.service';
import { Helper } from '../../../../4services/2helper';
import { c_components } from '../..';

@Component({
  selector: 'c_card_site_plan_v2_component',
  templateUrl: './c_card_site_plan_v2.component.pug',
  styleUrls: ['../common.scss','./c_card_site_plan_v2.component.scss'],
  animations: [animeBackground]
})
export class c_card_site_plan_v2_component implements OnInit {
  @Input('planType') planType;
  @Input('site') site;
  @Input('thisMonthSitePlan') thisMonthSitePlan;
  @Input('nextMonthSitePlan') nextMonthSitePlan;
  @Output('refreshSitePlan') refreshSitePlan = new EventEmitter();

  me: any;
  isSubDealer = false;
  tab_list = [
    { label: 'Basic', value: 1, isReady: true, isCurrentPlan: false, isNextPlan: false},
    { label: 'Video Vault', value: 2, isReady: true, isCurrentPlan: false, isNextPlan: false},
    { label: 'Video Vault Plus', value: 3,isReady: true, isCurrentPlan: false, isNextPlan: false},
    { label: 'Video Vault AI', value: 4, isReady: false, isCurrentPlan: false, isNextPlan: false}, 
  ];
  tab = this.tab_list[0];

  recommendItem = {
    sdCardStorage: null, // 1: unsupported, 2: supported
    videoEventFreeAmount: null, // 1: current, 2: current * 2, 3: unlimited
    storageDuration: null, // 1: current, 2: 1 year, 3: unlimited
    supportEventType: null, // 1: disarmed event, 2: timelapse
    aiAnalysis: null, // 1: supported, 2: unlimited
  }
  
  servicePricing = {
    sitePlanFee: 0,
    videoVaultPlanFee: 0,
    videoVaultPlusPlanFee: 0,
    videoEvent: {
      free_package_amount: {
        event: 0,
        twiceEvent: 0,
      },
      paid_package_amount: {
        price: 0,
        event: 0
      },
    }, 
    sum: 0,
  }

  isLoading = false;
  currentDealerPlan: number
  isStandardDealer: boolean
  constructor(
    public usersService: UsersService,
    public commonService: CommonService,
    private dealerPlanService: DealerPlanService,
    private helper: Helper,
    private c_components: c_components
  ) { }

  async ngOnInit() {
    this.initData();
    await this.fetchData();
  }

  //////////////////////////////

  /////////////////////////////
  // Plan Case
  isVideoEventChargePlan(planId){
    planId = parseInt(planId)
    return planId === 9 ? true : false
  }
  isAlarmBasedSitePlan(planId){
    planId = parseInt(planId)
    return planId === 15 ? true : false
  }
  isVideoVaultPlan(planId){
    planId = parseInt(planId)
    return planId === 16 ? true : false
  }
  isVideoVaultPlusPlan(planId){
    planId = parseInt(planId)
    return planId === 17 ? true : false
  }
  //////////////////////////////

  initData(){
    this.tab_list = [
      { label: 'Basic', value: 1, isReady: true, isCurrentPlan: false, isNextPlan: false },
      { label: 'Video Vault', value: 2, isReady: true, isCurrentPlan: false, isNextPlan: false },
      { label: 'Video Vault Plus', value: 3,isReady: true, isCurrentPlan: false, isNextPlan: false },
      { label: 'Video Vault AI', value: 4, isReady: false, isCurrentPlan: false, isNextPlan: false }, 
    ];
  }

  async fetchData() {
    this.isLoading = true;
    this.me = await this.helper.me.get_me();
    this.isSubDealer = this.me.type === 3 ? true : false

    await this.fetchDealerPlan()
    await this.getDealerPlanPricing()
    this.setCurrentSitePlanTab()
    this.isLoading = false
  }

  setCurrentSitePlanTab(){
    // site tab에서만 실행하기
    if(!this.site || !this.site?.site_id) return

    const currentSitePlan = this.tab_list.find(tab => tab.value === this.thisMonthSitePlan)
    currentSitePlan.isCurrentPlan = true
    this.tab = currentSitePlan
    this.moveToTab(currentSitePlan)
    if(this.thisMonthSitePlan != this.nextMonthSitePlan) {
      this.tab_list.find(tab => tab.value === this.nextMonthSitePlan).isNextPlan = true
    }
  }

  async fetchDealerPlan(){
    try {
      const dealerId = this.me.dealer_id
      const res = await this.dealerPlanService.getDealerPlan(dealerId).toPromise()
      const currentDealerPlan = res.current_month_service_plan_type
      this.currentDealerPlan = currentDealerPlan
      this.isStandardDealer = currentDealerPlan === 1 ? true : false

      if(this.isStandardDealer)  {
        this.thisMonthSitePlan = 1
        this.nextMonthSitePlan = 1
      }
    } catch(err) {
      this.isLoading = false
      console.debug('fetchDealerPlan:> ',err)
    }
  }

  async getDealerPlanPricing(){
    const dealerId = this.me.dealer_id
    const dealerType = this.me.type
    try {
      await this.dealerPlanService.getDealerServicePlanPricing(dealerId, dealerType).toPromise()

      const dealerPlanPricingListForStandard = this.dealerPlanService.dealerPlanPricingListForStandard
      const dealerPlanPricingListForProfessional = this.dealerPlanService.dealerPlanPricingListForProfessional
      const dealerPlanPricingListForPremium = this.dealerPlanService.dealerPlanPricingListForPremium
      const mainDealerPlanPricingListForStandard = this.dealerPlanService.mainDealerPlanPricingListForStandard
      const mainDealerPlanPricingListForReseller = this.dealerPlanService.mainDealerPlanPricingListForReseller
      let target = null
      if(this.me.type === 2) {
        this.currentDealerPlan === 0
          ? target = mainDealerPlanPricingListForStandard
          : target = mainDealerPlanPricingListForReseller
      } else {
        if(this.currentDealerPlan === 0) target = dealerPlanPricingListForStandard
        if(this.currentDealerPlan === 1) target = dealerPlanPricingListForProfessional
        if(this.currentDealerPlan === 2) target = dealerPlanPricingListForPremium
      }
      this.parseServicePricing(target)
    } catch(err) {
      this.isLoading = false;
      console.debug(err)
      let title = 'Request Dealer Plan Info';
      this.commonService.showErrorToast(err, title);
    }
  }

  parseServicePricing(pricingList){
    for(const planId in pricingList) {
      const plan = pricingList[planId]
      if(this.isAlarmBasedSitePlan(planId)) {
        this.assignPlanPriceByPlan('sitePlanFee', plan)
      }
      if(this.isVideoVaultPlan(planId)) {
        this.assignPlanPriceByPlan('videoVaultPlanFee', plan)
      }
      if(this.isVideoVaultPlusPlan(planId)) {
        this.assignPlanPriceByPlan('videoVaultPlusPlanFee', plan)
      }
      if(this.isVideoEventChargePlan(planId)) {
        this.assignPlanPriceByPlan('videoEvent', plan)
      }
    }
  }


  assignPlanPriceByPlan(planName, data){
    if(!planName || !data) return

    const targetServicePricing = this.servicePricing

    const conditionalPriceData = data?.conditional_price
    if(conditionalPriceData instanceof Object) {
      targetServicePricing[planName] = {
        free_package_amount: {
          event: this.formatNumbersWithComma(conditionalPriceData?.free_package_amount),
          twiceEvent: this.formatNumbersWithComma(conditionalPriceData?.free_package_amount * 2)
        },
        paid_package_amount: {
          price: this.formatNumbersWithComma(conditionalPriceData?.fee_per_paid_package_amount ),
          event: this.formatNumbersWithComma(conditionalPriceData?.paid_package_amount)
        }
      }
    } else {
      targetServicePricing[planName] = this.formatNumbersWithComma(data?.price)

      const price = data?.price ?? 0
      this.checkUnlimitedOrIncludedPlan('price', price, targetServicePricing, planName)
    }
  }

  ///////////////////////////////////
  // TOOL
  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }
  checkUnlimitedOrIncludedPlan(pricingType, pricingData, targetData, planName){
    if(pricingType === 'conditional') {
      const paidPackageDataIsZero = !pricingData?.fee_per_paid_package_amount && !pricingData?.paid_package_amount
      if(!paidPackageDataIsZero) return

      if(this.isUnlimitedInAllDealerPlan('conditional', planName)) {
        targetData[planName].priceLabel = 'Included'
      } else {
        targetData[planName].priceLabel = 'Unlimited'
      }
    }
    if(pricingType === 'price') {
      if(!pricingData?.price) return

      if(this.isUnlimitedInAllDealerPlan('price', planName)) {
        targetData[planName] = {
          priceLabel : 'Included',
          price : pricingData?.price
        }
      } else {
        targetData[planName] = {
          priceLabel : 'Unlimited',
          price : pricingData?.price
        }
      }
    }
  }

  isUnlimitedInAllDealerPlan(pricingType, planName){
    const standardServicePricing = this.servicePricing

    if(pricingType === 'conditional') {
      const standardPaidPackageDataIsZero = !standardServicePricing?.[planName]?.fee_per_paid_package_amount && !standardServicePricing?.[planName]?.paid_package_amount
      return standardPaidPackageDataIsZero ? true : false
    }
    if(pricingType === 'price'){
      const standardPaidPackageDataIsZero = !standardServicePricing?.[planName]?.price && !standardServicePricing?.[planName]?.price
      return standardPaidPackageDataIsZero ? true : false
    }
  }

  parseCurrentStorageDuration(){
    if(this.currentDealerPlan === 1) return '30 Days'
    if(this.currentDealerPlan === 2) return '90 Days'
    if(this.currentDealerPlan === 3) return '1 year'
    if(this.currentDealerPlan === 4) return '1 year'
  }

  // ---------------------------------------
  moveToTab(tab){
    this.tab = tab;

    if(tab.value === 1) {
      this.recommendItem.sdCardStorage = 1
      this.recommendItem.videoEventFreeAmount = 1
      this.recommendItem.storageDuration = 1
      this.recommendItem.supportEventType = null
      this.currentDealerPlan === 1
        ? this.recommendItem.aiAnalysis = null
        : this.recommendItem.aiAnalysis = 1
    }
    if(tab.value === 2) {
      this.recommendItem.sdCardStorage = 2
      this.recommendItem.videoEventFreeAmount = 2
      this.recommendItem.supportEventType = 1
      this.recommendItem.storageDuration = 2
      this.recommendItem.aiAnalysis = 1
    }
    if(tab.value === 3) {
      this.recommendItem.sdCardStorage = 2
      this.recommendItem.videoEventFreeAmount = 3
      this.recommendItem.supportEventType = 2
      this.recommendItem.storageDuration = 3
      this.recommendItem.aiAnalysis = 1
    }
    if(tab.value === 4) {
      this.recommendItem.sdCardStorage = 2
      this.recommendItem.videoEventFreeAmount = 3
      this.recommendItem.supportEventType = 2
      this.recommendItem.storageDuration = 3
      this.recommendItem.aiAnalysis = 2
    }
  }

  setRecommendItem(type, value){
    const target = this.recommendItem[type]
    if(target === value) {
      this.recommendItem[type] = null   // reset
    } else {
      this.recommendItem[type] = value
    }

    this.getRecommendedTab()
  }
  getRecommendedTab(){
    const recommendItem = this.recommendItem

    // 조건을 만족하는 탭 인덱스를 추적
    let tabIndex = 0;

    // 우선 순위 조건에 따라 인덱스 설정
    if (recommendItem.aiAnalysis === 2) {
      this.tab = this.tab_list[3]; // 무조건 Video Vault AI로 이동
      return;
    }

    if (recommendItem.supportEventType === 2) {
      this.tab = this.tab_list[2]; // 무조건 Video Vault Plus로 이동
      return;
    }

    // 다른 조건들을 확인하며 탭 인덱스 업데이트
    if (
      recommendItem.storageDuration === 2 || 
      recommendItem.videoEventFreeAmount === 2 || 
      recommendItem.sdCardStorage === 2 ||
      recommendItem.supportEventType === 1
    ) {
      tabIndex = Math.max(tabIndex, 1); // Video Vault
    }
    
    if (
      recommendItem.storageDuration === 3 || 
      recommendItem.videoEventFreeAmount === 3
    ) {
      tabIndex = Math.max(tabIndex, 2); // Video Vault Plus
    }

    // 기본 조건 적용 (storageDuration, videoEventFreeAmount, sdCardStorage가 1인 경우)
    if (
      recommendItem.storageDuration === 1 || 
      recommendItem.videoEventFreeAmount === 1 || 
      recommendItem.sdCardStorage === 1
    ) {
      tabIndex = Math.max(tabIndex, 0); // Basic
    }

    // 최종 탭 반환
    this.tab = this.tab_list[tabIndex];
  }

  // ---------------------------------------
  // ACTION
  async onClickSitePlanSave(planId){
    const isDowngrade = this.thisMonthSitePlan > planId
    this.c_components.dialog.open("warning", {
      header: `Change Site Plan`,
      contents: `
        <p> ${isDowngrade ? 'For downgrades, the changes will take effect from next month.': 'The changes will take effect immediately.'} </p>
      `,
      submit_btn: "Confirm",
      submit_class: ["button-primary"],
      icon: 'warning',
      color: 'orange',
      submit_func: async () => await this.updateSitePlan(planId),
    })
  }

  async updateSitePlan(planId){
    try {
      await this.helper.sites.update_site_plan(this.site.site_id, { site_plan_id: planId });
      this.openWarningDialog('Success', 'Site Plan has been updated successfully.', 'done', 'green');
      this.refreshSitePlan.emit();
    } catch(err) {
      console.debug('onClickSitePlanSave :>',err)
      this.openWarningDialog('Error', 'Failed to update Site Plan.', 'error', 'red');
    }
  }
  
  openWarningDialog(header, msg, icon, color) {
    this.c_components.dialog.open("warning", {
      header: `${header}`,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {},
    });
  }
}
