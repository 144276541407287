import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { UsersService } from '@app/services/users.service';
import { Helper } from 'src/4services/2helper';

@Component({
  selector: 'log_box_timelapse',
  templateUrl: './timelapse.component.pug',
  styleUrls: ['../../../common.scss','../../c_card_event_viewer_time_line.component.scss'],
})
export class log_box_timelapse_component {
  @Input() log;
  
  snapshotPermission = false;
  isBrokenImage = false
  IPuserName = ''

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService,
    private usersService: UsersService,
    private sanitizer: DomSanitizer,
    private helper: Helper,
  ) {}

  private snapshotPermission$w: Subscription;
  watch() {
    this.snapshotPermission$w = this.eventViewerService.snapshotPermission$w.subscribe((v) => this.snapshotPermission = v);
  }
  unwatch() {
    this.snapshotPermission$w?.unsubscribe();
  }

  async ngOnInit() {
    this.watch()
    if(this.log.email && this.log.ip) await this.getIPUserName()
    if(this.log.imageUrl) this.safeBlobUrl()  
  }

ngOnDestroy(): void {
  this.unwatch()
}

  // -------------------------------------------------------
  // COMMON

  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  isAutomationRule(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule
  }

  // --------------------------------
  // Viewed
  async getIPUserName(){
    try {
      if(!this.log.email) return 
      if(!this.log.user_id) return 
      if(this.log.email != this.log.ip) return // 이건 일반 유저가 본 것
      
      const dealerId = await this.helper.me.get_my_dealer_id()
      const userId = this.log.user_id
      const user = await this.usersService.getIpMember(dealerId, userId).toPromise()
      this.IPuserName = user[0]?.name ?? 'No Name'
    } catch(err) {
      console.debug('getIPUserName:>',err)
    }
  }
  // --------------------------------
  safeBlobUrl() {
    const url = this.log.imageUrl?.changingThisBreaksApplicationSecurity
    if(!url) return
    this.log.imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
  }
  
  computedBackground(){
    if(this.log.isLoading) return 'loading-background'
    if(!this.snapshotPermission) return 'no-permission'

    if(this.canIShowImage() === 'need to upgrade plan') return 'no-permission'
    if(this.canIShowImage() === 'expired') return 'no-permission'
    if(this.canIShowImage() === 'privacy mode') return 'no-permission'
    if(this.canIShowImage() === 'broken image') return 'no-permission'
    if(this.canIShowImage() === 'image') return 'full-height'
    if(this.canIShowImage() === 'default') return 'timelapse'
  }

  canIShowImage(){
    const highestPlan = this.helper.dealer_plan.isPremiumPlan() || this.helper.dealer_plan.isResellerPlan()
    if(!this.snapshotPermission) return 'no permission'
    if(highestPlan && this.log.is_expired) return 'expired'
    if(!highestPlan && this.log.is_expired) return 'need to upgrade plan'
    if(this.log.isPrivacy) return 'privacy mode'
    if(this.isBrokenImage) return 'broken image'
    if(this.log.imageUrl) return 'image'
    return 'default'
  } 
}
