import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MpSettingsService {
  mpSettings$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealerDefaultMpSettings$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  mpSettings$w = this.mpSettings$s.asObservable(); 
  dealerDefaultMpSettings$w = this.dealerDefaultMpSettings$s.asObservable();

  constructor(private api: ApiService) { }

  getSiteMpSettings(dealer_id, site_id){
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/mp_settings`).map(res => {
      this.mpSettings$s.next(res)
      return res
    })
  }

  updateSiteMpSettings(dealer_id, site_id, data){
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/mp_settings`, data)
  }

  getDealerDefaultMpSettings(dealer_id){
    return this.api.get(`/dealers/${dealer_id}/default_settings`).map(res => {
      this.dealerDefaultMpSettings$s.next(res)
      return res
    })
  }

  updateDealerDefaultMpSettings(dealer_id, data){
    return this.api.put(`/dealers/${dealer_id}/default_settings`, data)
  }

  updateDealerMpSettingsSync(dealer_id, data){
    return this.api.put(`/dealers/${dealer_id}/default_settings/monitoring_portal/sync`, data)
  }
}
