import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class VirtualGuardTourService {
  virtualGuardTour$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  virtualGuardTour$w = this.virtualGuardTour$s.asObservable();

  constructor(private api: ApiService) { }

  getVirtualGuardTourSchedules(dealer_id, site_id){
    return this.api.get(`/dealers/${dealer_id}/sites/${site_id}/guard_tour_schedules`).map(res => {
      this.virtualGuardTour$s.next(res)
      return res
    })
  }

  createVirtualGuardTourSchedules(dealer_id, site_id, data){
    return this.api.post(`/dealers/${dealer_id}/sites/${site_id}/guard_tour_schedules`, data)
  }

  updateVirtualGuardTourSchedules(dealer_id, site_id, data){
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}/guard_tour_schedules`, data)
  }

  deleteVirtualGuardTourSchedules(dealer_id, site_id){
    return this.api.delete(`/dealers/${dealer_id}/sites/${site_id}/guard_tour_schedules`)
  }
}
