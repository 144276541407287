import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Permission } from '../permission'
import { dealer_plan } from '../dealer_plan'
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class event_storages {
  constructor(private ims: Imports, private dealer_plan: dealer_plan, private permission: Permission) {}

  public event_storages$w(): Observable<any> {
    return this.ims.eventStoragesService.eventStorages$w;
  }

  public async get_event_storages(): Promise<Array<any>> | undefined {
    await this.load_event_storages();
    return this.ims.eventStoragesService.eventStorages$s.getValue();
  }

  public async load_event_storages(): Promise<boolean> {
    try {
      const amIAdmin = await this.permission.i_have_admin_permission()
      if(!amIAdmin) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.eventStoragesService.getAllEventStorages(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Event Storage List" });
      return false;
    }
  }

  public async update_dealer_event_storages(event_storage_id: number): Promise<boolean> {
    try {
      const amIAdmin = await this.permission.i_have_admin_permission()
      if(!amIAdmin) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.eventStoragesService.updateDealerEventStorages(dealer_id, event_storage_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Dealer Event Storage" });
      return false;
    }
  }

  public async update_dealer_all_existing_sites_event_storages(event_storage_id: number): Promise<boolean> {
    try {
      const amIAdmin = await this.permission.i_have_admin_permission()
      if(!amIAdmin) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.eventStoragesService.updateDealerAllExistingSitesEventStorages(dealer_id, event_storage_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Dealer All Sites Event Storage" });
      return false;
    }
  }

  public async update_site_event_storages(site_id: number, event_storage_id: number): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.eventStoragesService.updateSiteEventStorages(dealer_id, site_id, event_storage_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Site Event Storage" });
      return false;
    }
  }

  // --------------------------------
  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
