import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  templateUrl: "./c_dialog_warning_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_warning_component.scss"],
})
export class c_dialog_warning_component implements OnInit {
  public inputText = "";
  public width = 600;
  public isErr = false;
  public left_btns = [];
  public right_btns = [];

  constructor(
    public dialogRef: MatDialogRef<c_dialog_warning_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: c_dialog_warning_inputs_custom
  ) {}

  ngOnInit() {
    this.init_width();
    this.init_btns();
  }

  init_width(): void {
    this.width = 600;
    if (isNaN(this.data?.width)) return;
    this.width = this.data.width;
  }

  init_btns(): void {
    this.left_btns = [];
    this.right_btns = [];
    if (!Array.isArray(this.data?.btns)) return;
    this.left_btns = this.data.btns.filter((btn) => btn?.left);
    this.right_btns = this.data.btns.filter((btn) => !btn?.left);
  }

  cancel() {
    this.call_cancel_callback_if_any_provided();
    this.close_dialog(false);
  }

  submit(event?: string, doOpen = false): void {
    this.check_inputText_and_update_isErr();

    if (!this.ready_to_submit()) return;

    this.call_submit_callback_if_any_provided(event);

    this.close_dialog_if_not_prevented_by_caller(true, doOpen);
  }

  ready_to_submit() {
    return this.is_inputText_valid();
  }

  is_inputText_valid() {
    if (!this.data.isInput) return true;
    if (this.data.confirmInput === this.inputText) return true;
    else return false;
  }

  check_inputText_and_update_isErr() {
    if (this.is_inputText_valid()) this.isErr = false;
    else this.isErr = true;
  }

  call_submit_callback_if_any_provided(event?: string) {
    if (!this.data.submit_func) return;
    this.data.submit_func(event);
  }

  close_dialog_if_not_prevented_by_caller(result: boolean, doOpen = false) {
    if (doOpen) return;
    this.close_dialog(true);
  }

  call_cancel_callback_if_any_provided() {
    if (!this.data.cancel_func) return;
    this.data.cancel_func();
  }

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }
}

interface c_dialog_warning_inputs_custom extends c_dialog_warning_inputs_double_check {
  btns?: Array<c_dialog_warning_inputs_btns>;
  betweenBtn?: boolean;
}

interface c_dialog_warning_inputs_double_check extends c_dialog_warning_inputs_simple {
  isInput?: string;
  confirmInput?: string;
}

interface c_dialog_warning_inputs_simple {
  width?: number;
  header?: string;
  contents?: string;
  target?: string;
  warningMessage?: string;
  icon?: string;
  submit_btn?: string;
  submit_class?: Array<string>;
  isConfirm?: boolean;
  color?: string;
  submit_func?: Function;
  cancel_func?: Function;
}

interface c_dialog_warning_inputs_btns {
  text?: string;
  submit?: string;
  btnColor?: string;
  doOpen?: boolean;
  left?: boolean;
  right?: boolean;
}
