
import {of as observableOf,  BehaviorSubject,Subject ,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { UsersService } from './users.service';




@Injectable()
export class PartnerService {
    node:Subject<Node> = new BehaviorSubject<any>(null);
    node$ = this.node.asObservable();
    selRetailDealer = new Object();
    partners: any[];
    partners$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    partners$w = this.partners$s.asObservable();

    constructor(private api: ApiService, private usersService: UsersService) { }

//   search(dealerId, query, filter= 'device,site,user'): Observable<any> {
//     const encodedQuery = encodeURIComponent(query);
//     return this.api.get(`/dealers/${dealerId}/search?query=${encodedQuery}&filter=${filter}`)
//       .map(res => {
//         return res;
//       });
//   }
    changeParnter(data:Node) {
        this.node.next(data);
    }

    /*** GET ***/
    // sub dealer의 사이트인 경우 external_dealer_id와 함께 company_name를 표시하기 위한 함수
    parseExternalDealerIdWithCompanyName(dealer){
        const me = this.usersService.me;

        dealer.external_dealer_id_with_company_name = dealer.company_name
        if(dealer.dealer_id == me.dealer_id) return dealer.external_dealer_id_with_company_name = dealer.company_name
        if(dealer.type === 3) {
        dealer?.external_dealer_id
            ? dealer.external_dealer_id_with_company_name = `${dealer.external_dealer_id} - ${dealer.company_name}`
            : dealer.external_dealer_id_with_company_name = dealer.company_name
        return
        }
    }

    fetch(dealerId: number): Observable<any> {
        return this.api.get(`/dealers/${dealerId}/partners?sort=company_name&dir=asc`).pipe(
          map(res => {
            res.forEach(partner => this.parseExternalDealerIdWithCompanyName(partner));
            this.partners = res;
            this.partners$s.next(res)
            return res;
          }));
    }

    getAllPartners(dealerId: number, refresh?): Observable<any> {
        if (this.partners && !refresh) {
            return observableOf(this.partners);
        }
        return this.fetch(dealerId);
    }

    getPartner(dealerId, partnerId): Observable<any> {
        return this.api.get(`/dealers/${dealerId}/partners/${partnerId}`).map(res => {
            res.forEach(partner => this.parseExternalDealerIdWithCompanyName(partner))
            return res;
        });
    }

    inviteDealer(dealerId, partnerId): Observable<any> {
        return this.api.get(`/dealers/${dealerId}/partners/${partnerId}/invite`);
    }

    getRetailDealerSites(dealerId, partnerId): Observable<any> {
        return this.api.get(`/dealers/${dealerId}/retail_dealers/${partnerId}/sites`);
    }

    getRetailDealerSite(dealerId, partnerId, siteId): Observable<any> {
        return this.api.get(`/dealers/${dealerId}/retail_dealers/${partnerId}/sites/${siteId}`);
    }

    searchPartners(dealerId, query, limit = 10, offset = 0): Observable<any> {
        let encodedQuery = encodeURIComponent(query);
        return this.api.get(`/dealers/${dealerId}/partner_search?query=${encodedQuery}&limit=${limit}&offset=${offset}`).pipe(
        map(res => {
            return res;
        }));
    }
    
    /*** partner status */
    getPartnersStatus(dealerId): Observable<any> {
        return this.api.get(`/dealers/${dealerId}/partner_status`)
    }

    getPartnerStatus(dealerId, partnerId): Observable<any> {
        return this.api.get(`/dealers/${dealerId}/partner_status/${partnerId}`)
    }

    /*** PUT ***/
    updatePartnerActive(dealerId, partnerId, activeState): Observable<any> {
        const data = {state : activeState};
        return this.api.put(`/dealers/${dealerId}/partners/${partnerId}/active`, data);
    }
    updatePartnerSigninActive(dealerId, partnerId, activeState): Observable<any> {
        const data = {state : activeState};
        return this.api.put(`/dealers/${dealerId}/partners/${partnerId}/signin_active`, data);
    }
    updatePartner(dealerId, partnerId, data): Observable<any> {
        return this.api.put(`/dealers/${dealerId}/partners/${partnerId}`, data);
    }

    /*** POST ***/
    postPartner(dealerId, data): Observable<any> {
        return this.api.post(`/dealers/${dealerId}/partners`, data);
    }

    /*** DELETE ***/
    deletePartner(dealerId, partnerId): Observable<any> {
        return this.api.delete(`/dealers/${dealerId}/partners/${partnerId}`);
    }

    //logo
    uploadLogo(dealerId, partnerId, data, content_type): Observable<any> {
        let result = new BehaviorSubject<any>(null);
        let ext = content_type.split('/')[1];
        this.api.put(`/dealers/${dealerId}/partners/${partnerId}/logo`, {format: ext}).subscribe(
        res => {
            let uploadUrl = res['url'];
            let header = new HttpHeaders({
            'content-type': content_type
            });
            let options = { headers: header, responseType: 'text' };
            this.api.put_custom(uploadUrl, data, options).subscribe(
            res => {
                result.next({res: true, url: uploadUrl});
            }, err => {
                console.log(err);
                result.next({res: false});
            }
            );
        }, err => {
            result.next(false);
        }
        );

        return result
        }
}
