
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse,
         HttpErrorResponse } from '@angular/common/http';



 // don't forget the imports
import { LogsService } from './logs.service';
import * as _ from 'lodash';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
        catchError((err: HttpErrorResponse, source) => {
            let error:any = _.cloneDeep(err);
            // const logsService = this.injector.get(LogsService);
            const location = this.injector.get(LocationStrategy);
            const url = location instanceof PathLocationStrategy ? location.path() : '';
            error['url'] = url;
            if (error.status !== 0 && error.status !== 401 && error.status !== 403 &&
                error.status !== 409 && error.status !== 412) {
                // logsService.err(error['_body'], error, request);
            }
            if (err.error instanceof Error) {
                // A client-side or network error occurred. Handle it accordingly.
                // console.error('An error occurred:', err.error.message);
            } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                // console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
            }

            // ...optionally return a default fallback value so app can continue (pick one)
            // which could be a default value (which has to be a HttpResponse here)
            // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
            // or simply an empty observable
            // return Observable.empty<HttpEvent<any>>();
            return observableThrowError(err);
            // return Observable.empty<HttpEvent<any>>();
        }));
    }
}
