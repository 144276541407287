import { Component, Input, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { Subscription } from 'rxjs';

import ims from '../../../../../imports'

@Component({
  selector: 'log_box_arming',
  templateUrl: './arming.component.pug',
  styleUrls: ['../../../../common.scss','../../c_card_event_viewer_box.component.scss'],
})
export class log_box_arming_component {
  @Input() log;
  @Input() timezone;
  @Input() toolbar: TemplateRef<any>;

  logIcon = { type: '', name: ''}
  isBuildReportMode = false;
  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService, 
    private cdr: ChangeDetectorRef
  ) {
  }

  private isClearSelectedLogs$w: Subscription;
  private isBuildReportMode$w: Subscription;
  watch() {
    this.isClearSelectedLogs$w = this.eventViewerService.isClearSelectedLogs$w.subscribe((v) => this.unselectLog(v));
    this.isBuildReportMode$w = this.eventViewerService.isBuildReportMode$w.subscribe((v) => this.isBuildReportMode = v);
  }
  unwatch() {
    this.isClearSelectedLogs$w?.unsubscribe();
    this.isBuildReportMode$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.checkLogType();
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  unselectLog(value){
    if(value === null) return
    if(!value) return
    this.log.isSelected = false
    this.cdr.detectChanges();  // 변경감지 수행
  }

  onClickLog(e){
    e.stopPropagation();
    this.isBuildReportMode ? this.selectLog() : this.openLogDetail()
  }

  selectLog() {
    this.log.isSelected = !this.log.isSelected
    this.log.isSelected
      ? this.eventViewerService.addSelectedLogs(this.log)
      : this.eventViewerService.deleteSelectedLogs(this.log)
  }

  openLogDetail(){
    this.eventViewerService.openDetailModal({status: true, log: this.log})
  }

  // -------------------------------------------------------
  // COMMON
  computedActionName(action: string){
    const parsedAction = action.split(' ')[0]
    return parsedAction.toLowerCase()
  }

  armingIconColor(action: string){
    const parsedAction = action.split(' ')[0]
    const lowerCaseAction = parsedAction.toLocaleLowerCase()

    if(lowerCaseAction === 'armed') return '#FF7F16'
    if(lowerCaseAction === 'armed away') return '#FF7F16'
    if(lowerCaseAction === 'armed stay') return '#FF7F16'
    if(lowerCaseAction.includes('armed stay')) return '#FF7F16'
    return '#1EA675' // 그 외는 disarmed
  }


  showToolbar = ims._.throttle(this.showToolbarThrottle.bind(this), 200);
  onHoverLog(e, id){
    e.stopPropagation();
    this.showToolbar(id)
  }
  showToolbarThrottle(id){
    const toolbox = document.getElementById('log-box-toolbox'+id)
    if(toolbox) toolbox.style.visibility = 'visible';
  }

  hiddenToolbar = ims._.throttle(this.hiddenToolbarThrottle.bind(this), 200);
  onMouseLeaveLog(e, log){
    e.stopPropagation();
    this.hiddenToolbar(log)
  }
  hiddenToolbarThrottle(log){
    log.isShowMenu = false
    const toolbox = document.getElementById('log-box-toolbox'+log.id)
    if(toolbox) toolbox.style.visibility = 'hidden';
  }

  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  capitalizeFirstLetter(string: string) {
    if(!string || typeof string != 'string') return
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  isAutomationRule(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule
  }
  // -------------------------------------------------------
  // 타입별로 분류해보자
  // - arming 상태를 표시하는 항목 : armed / disarmed (arming status)
  // - arming 상태를 '결정하는 시스템을 변경하는' 항목 : arming settings
  // - entry / exit delay 관련으로 arming 상태를 지연시키는 항목 (arming delay)

  checkLogType(){
    if(this.checkArmingStatus()) return
    if(this.checkArmingDelay()) return 
    // other : arming settings - voltage arming / cloud arming
    this.logIcon = { type: 'material', name: 'settings'}
  }
  checkArmingStatus(){
    const status = this.log.status_text.toLowerCase()
    if(status === 'armed') return true
    if(status === 'disarmed') return true
    if(status === 'armed away') return true
    if(status === 'armed stay') return true
    if(status.includes('armed stay')) return true
    return false
  }
  checkArmingDelay(){
    // entry / exit delay
    const status = this.log.status_text.toLocaleLowerCase()
    if(status.includes('end')) {
      this.logIcon = { type: 'material', name: 'sync_lock'}
      return true
    }
    if(status.includes('start')) {
      this.logIcon = { type: 'material', name: 'sync_lock'}
      return true
    }
    return false
  }
}
