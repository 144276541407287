import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class VideoAIService {
  constructor(
    private api: ApiService,
  ) { }

  cloudAIEnabled$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  cloudAIEnabled$w = this.cloudAIEnabled$s.asObservable()
  cloudAIProviders$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  cloudAIProviders$w = this.cloudAIProviders$s.asObservable()

  /////////////////////////////
  // DEALER LEVEL
  getAllFilter(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/videoai/filters`).map(res =>{
      const person = res.filter(v => v.filter.includes('person') && v.enabled)
      const vehicle = res.filter(v => v.filter.includes('vehicle') && v.enabled)
      const result = { person, vehicle }
      return result
    })
  }
  getAllProvider(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/videoai/providers`).map(res =>{
      res.forEach(v => {
        switch(v.id){
          case(1) : v.icon_url = '/assets/logo/calipsa-logo.png'
          break
          case(2) : v.icon_url = '/assets/logo/actuate-logo.png'
          break
          case(4) : v.icon_url = '/assets/logo/vca-logo.png'
          break
        }
      })
      this.cloudAIProviders$s.next(res)
      return res
    })
  }

  getSubDealerCloudAIConfig(dealerId, subDealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/partner_videoai/config?partner_id=${subDealerId}`)
  }
  getDealerCloudAIConfig(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/videoai/config`).map(res =>{
      this.cloudAIEnabled$s.next(res)
      return res
    })
  }

  // dealer config
  updateSubDealerCloudAIConfig(dealerId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/partner_videoai/config`, data)
  }
  updateDealerCloudAIConfig(dealerId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/videoai/config`, data)
  }

  // dealer config filter
  createDealerCloudAIFilter(dealerId, data){
    return this.api.post(`/dealers/${dealerId}/videoai/filters`, data)
  }

  creatSubDealerCloudAIFilter(dealerId, data){
    return this.api.post(`/dealers/${dealerId}/videoai/filters`, data)
  }

  deleteDealerCloudAIFilter(dealerId, filterId){
    return this.api.delete(`/dealers/${dealerId}/videoai/filters/${filterId}`)
  }

  deleteSubDealerCloudAIFilter(dealerId, filterId){
    return this.api.delete(`/dealers/${dealerId}/videoai/filters${filterId}`)
  }

  // Update my dealer provider config
  updateDealerCloudAIProviderConfig(dealerId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/videoai/config`, data)
  }

  /////////////////////////////
  // SITE LEVEL
  getSiteCloudAIConfig(dealerId, siteId, include?): Observable<any> {
    let url = `/dealers/${dealerId}/sites/${siteId}/videoai/config`
    if(include) url += `?include=${include}`
    return this.api.get(url)
  }
  updateSiteCloudAIConfig(dealerId, siteId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/videoai/config`, data)
  }
  updateMulityDevicesConfigInSite(dealer_id, site_id, data): Observable<any> {
    // 최대 60개까지만 가능함. 
    // -> FE측에서 60개씩 끊어서 호출해야함.
    return this.api.patch(`/dealers/${dealer_id}/sites/${site_id}/devices/videoai`, data)
  }

  /////////////////////////////
  // DEVICE LEVEL - filter
  getDeviceCloudAIFilter(dealerId, device){
    return this.api.get(`/dealers/${dealerId}/sites/${device.site_id}/devices/${device.device_id}/videoai/filters`)
  }

  updateDeviceCloudAIFilter(dealerId, device, data){
    return this.api.put(`/dealers/${dealerId}/sites/${device.site_id}/devices/${device.device_id}/videoai/filters`, data)
  }


  //////////////////////////////////////////// V3 
  // 1. Rank
  // time, log_type, provider, ai_filter_matched, cs_dealer_id(모니터링 해주는 곳), main_dealer_id(서브는 다름), dealer_id(사이트 소유주), site_id, device_id, rank_time, rank_tag, rank_tags, limit
  // log_type : null, success, failure / default - Success + failure (failure를 원할 때만 수정하기)
  // rank_time : last_24h, day, month, year
  // rank_tag : provider, ai_filter_matched, cs_dealer_id, main_dealer_id, dealer_id, site_id, device_id
  getDealerStatisticByRank(dealerId, isSubDealer=false, time, provider?, ai_filter_matched?, rank_time="month", rank_tag?, rank_tags?, limit?){
    let url = `/statistics/rank`
    isSubDealer
      ? url += `?dealer_id=${dealerId}` // sub는 이것만 들어가야 함. 둘다 들어가면 빈 배열
      : url += `?main_dealer_id=${dealerId}` // cs / si, main은 이것만 들어가야 함. 둘다 들어가면 main의 경우, sub 것이 포함되지 않음
    if(time) url += `&time=${encodeURIComponent(time)}`
    if(provider) url += `&provider=${provider}`
    if(ai_filter_matched) url += `&ai_filter_matched=${ai_filter_matched}`
    if(rank_time) url += `&rank_time=${rank_time}`
    if(rank_tag) url += `&rank_tag=${rank_tag}`
    if(rank_tags) url += `&rank_tags=${rank_tags}`
    if(limit) url += `&limit=${limit}`
    return this.api.get(url, 'json', 'v2').map(res =>{
      return res
    })
  }

  getSiteStatisticByRank(siteId, time, provider?, ai_filter_matched?, rank_time="month", rank_tag?, rank_tags?, limit?){
    let url = `/statistics/rank?site_id=${siteId}`
    if(time) url += `&time=${encodeURIComponent(time)}`
    if(provider) url += `&provider=${provider}`
    if(ai_filter_matched) url += `&ai_filter_matched=${ai_filter_matched}`
    if(rank_time) url += `&rank_time=${rank_time}`
    if(rank_tag) url += `&rank_tag=${rank_tag}`
    if(rank_tags) url += `&rank_tags=${rank_tags}`
    if(limit) url += `&limit=${limit}`
    return this.api.get(url, 'json', 'v2').map(res =>{
      return res
    })
  }

  // for analytics tab
  dealerCSDealersCloudAIEvents$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealerCSDealersCloudAIEvents$w = this.dealerCSDealersCloudAIEvents$s.asObservable()
  getDealerCSDealersCloudAIEventStat(dealerId, time){
    let url = `/statistics/rank`
    url += `?main_dealer_id=${dealerId}` // 어차피 여긴 main만 씀
    if(time) url += `&time=${encodeURIComponent(time)}`
    url += `&rank_time=month`
    url += `&rank_tags=ai_filter_matched&rank_tags=dealer_id`
    return this.api.get(url, 'json', 'v2').map(res =>{
      this.dealerCSDealersCloudAIEvents$s.next(res)
      return res
    })
  }

  dealerSitesCloudAIEvents$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealerSitesCloudAIEvents$w = this.dealerSitesCloudAIEvents$s.asObservable()
  getDealerSitesCloudAIEventStat(dealerId, isSubDealer=false, time){
    let url = `/statistics/rank`
    isSubDealer
      ? url += `?dealer_id=${dealerId}` // sub는 이것만 들어가야 함. 둘다 들어가면 빈 배열
      : url += `?main_dealer_id=${dealerId}` // cs / si, main은 이것만 들어가야 함. 둘다 들어가면 main의 경우, sub 것이 포함되지 않음
    if(time) url += `&time=${encodeURIComponent(time)}`
    url += `&rank_time=month`
    url += `&rank_tags=ai_filter_matched&rank_tags=dealer_id&rank_tags=site_id`
    return this.api.get(url, 'json', 'v2').map(res =>{
      this.dealerSitesCloudAIEvents$s.next(res)
      return res
    })
  }

  dealerDevicesCloudAIEvents$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealerDevicesCloudAIEvents$w = this.dealerDevicesCloudAIEvents$s.asObservable()
  getDealerDevicesCloudAIEventStat(dealerId, isSubDealer=false, time){
    let url = `/statistics/rank`
    isSubDealer
      ? url += `?dealer_id=${dealerId}` // sub는 이것만 들어가야 함. 둘다 들어가면 빈 배열
      : url += `?main_dealer_id=${dealerId}` // cs / si, main은 이것만 들어가야 함. 둘다 들어가면 main의 경우, sub 것이 포함되지 않음
    if(time) url += `&time=${encodeURIComponent(time)}`
    url += `&rank_time=month`
    url += `&rank_tags=ai_filter_matched&rank_tags=dealer_id&rank_tags=device_id`
    return this.api.get(url, 'json', 'v2').map(res =>{
      this.dealerDevicesCloudAIEvents$s.next(res)
      return res
    })
  }

  // 2. time_stream
  // stime, etime, provider, ai_filter_matched, partner_id, dealer_id, site_id, device_id, time_block_interval, limit
  // time_block_interval: 3600, 7200, day, month, year
  getDealerStatisticByTimeStream(dealerId, stime, etime, provider?, ai_filter_matched?, time_block_interval?, limit?){
    let url = `/statistics/time_series?dealer_id=${dealerId}`
    if(stime) url += `&stime=${encodeURIComponent(stime)}`
    if(etime) url += `&etime=${encodeURIComponent(etime)}`
    if(provider) url += `&provider=${provider}`
    if(ai_filter_matched) url += `&ai_filter_matched=${ai_filter_matched}`
    if(time_block_interval) url += `&time_block_interval=${time_block_interval}`
    if(limit) url += `&limit=${limit}`
    return this.api.get(url, 'json', 'v2').map(res =>{
      return res
    })
  }

  getSiteStatisticByTimeStream(siteId, stime, etime, provider?, ai_filter_matched?, time_block_interval?, limit?){
    let url = `/statistics/time_series?site_id=${siteId}`
    if(stime) url += `&stime=${encodeURIComponent(stime)}`
    if(etime) url += `&etime=${encodeURIComponent(etime)}`
    if(provider) url += `&provider=${provider}`
    if(ai_filter_matched) url += `&ai_filter_matched=${ai_filter_matched}`
    if(time_block_interval) url += `&time_block_interval=${time_block_interval}`
    if(limit) url += `&limit=${limit}`
    return this.api.get(url, 'json', 'v2').map(res =>{
      return res
    })
  }

}
