import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningModule } from '../../warning/warning.module'
import { SharedModule } from '../../../directives/shared.module'
import { PlanPrecautionsComponent } from './plan-precautions.component'
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    MatCheckboxModule
  ],
  declarations: [
    PlanPrecautionsComponent
  ],
  exports: [
    PlanPrecautionsComponent
  ]
})
export class PlanPrecautionsModule { }
