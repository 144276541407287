
import {map} from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { Observable } from 'rxjs';

@Injectable()
export class AccessesService {

  accesses: any;
  accesses$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  accesses$w = this.accesses$s.asObservable();

  constructor(private api: ApiService) { }

  public fetch(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/accesss`).pipe(
      map(res => {
        this.accesses = res;
        this.accesses$s.next(res)
        return this.accesses
      }));

  }

  public getAccesses(dealer_id: number): Observable<any> {
    return this.fetch(dealer_id);
  }

  public delete(dealer_id: number, access_id): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/accesss/${access_id}`);
  }

  public create(dealer_id: number, accessData): Observable<any> {
    let data = {
      name: accessData.name,
      is_whitelist: accessData.is_whitelist,
      ips: accessData.ip
    };

    return this.api.post(`/dealers/${dealer_id}/accesss`, data);
  }

  public update(dealer_id: number, access_id: number, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/accesss/${access_id}`, data);
  }
}
