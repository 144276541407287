import { Component, OnInit } from '@angular/core';
import { UsersService } from './services/users.service';
import { DealerPlanService } from './services/service-plan.service';
import { DealerService } from './services/dealer.service';
import { CommonService } from './services/common.service';
import { ApiService } from './services/api.service';
import { TosService } from './services/tos.service';
import { PermissionsService } from './services/permissions.service';

import { Modal } from './model/modal';

import tool from "../0lib/0tool"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading_status = 'Loading...';
  is_loading = true;
  opacity = 1;

  m_notice: Modal = new Modal();
  tos: any;
  me: any;
  ownerEmail: string;

  selectedPlan = null
  m_dealer_plan_modal: Modal = new Modal();
  m_dealer_plan_modal_v2: Modal = new Modal();

  hasAccessPermission = false;
  isBrowserCheck = true;
  isSubDealerEnable = true;
  isIpBlocked = false;

  params = <any>{};

  accessPerm$i = null;

  constructor(
    private usersService: UsersService,
    private dealerPlanService: DealerPlanService,
    private dealerService: DealerService,
    private commonService: CommonService,
    private apiService: ApiService,
    private tosService: TosService,
    private permissionsService: PermissionsService,
  ) {
    // console.log = logsService.notice;
    // console.error = logsService.err;
  }

  ngOnInit() {
    const url = window.location.href;
    const params = this.commonService.getQueryParams(url.split('?')[1]);
    this.params = params
    let dealer_id = null;
    if (params['dealer_id']) {
      dealer_id = params['dealer_id'];
    }
    let isChrome = (/Chrome/.test(navigator.userAgent) || /iPhone/.test(navigator.userAgent)) && /Google Inc/.test(navigator.vendor);
    let isFirefox = (/Firefox/.test(navigator.userAgent) || /iPhone/.test(navigator.userAgent)) && /Gecko/.test(navigator.userAgent);
    if(!isChrome && !isFirefox) {
      this.isBrowserCheck = false;
    }
    if (!this.isBrowserCheck) {
      return;
    }
    this.usersService.getMe(dealer_id).subscribe(res => {
      this.opacity = 0;
      this.me = res;
      this.isIpBlocked = this.me.is_ip_blocked;
      if (this.me.type === 3 && !this.me.is_signin_activated) {
        this.isSubDealerEnable = false;
      }
      this.checkBlockStatus()
      if ( res.type === 3) this.fetchWCS()
      setTimeout(_ => {
        this.is_loading = false;
      }, 400);
      if (!this.me.is_accepted_tos && this.me.permissions.has_admin_permission) {
        this.fetchTos();
      }
      this.dealerService.getDealerInfo(this.me.dealer_id).subscribe(dealerInfo => {
        const dealer = dealerInfo[0]
        this.ownerEmail = dealer?.email ?? dealer?.company_email
        this.dealerService.getCurrencyList(this.me.dealer_id, 'json', 'v1.2').subscribe(res=> {
          const currency = res.filter(currency => currency.currency_id === dealer.currency_id)[0]
          this.usersService.me.currency = currency.currency
          this.usersService.me.currency_id = currency.currency_id
          this.usersService.me.currency_symbol = currency.symbol
        })
      })
      this.permissionsService.getPermissions(this.me.dealer_id).subscribe();
      this.fetchDealerPlan()
    }, async err => {
      this.loading_status = 'Locating dealer information...';
      await tool.sleep(1000*5)
      location.reload();
    });
  }


  fetchTos() {
    if (!this.params['dealer_id']) {
      this.tosService.get(this.me.dealer_id).subscribe(res => {
        this.m_notice['tos'] = res[0];
        this.tos = res[0];
        this.openNotice();
      });
    }
  }

  fetchDealerPlan(){
    this.dealerPlanService.getDealerPlan(this.me.dealer_id).subscribe(res => {
      if(res?.current_month_service_plan_type === 1) {
        const localKey = 'CHeKTUser_standard_dealer_notification';
        let notice = JSON.parse(localStorage.getItem(localKey));
        if(!notice) {
          return this.m_dealer_plan_modal_v2.open()
          // this.m_dealer_plan_modal.data['type'] = 'plan-description'
          // return this.m_dealer_plan_modal.open()
        }
      }
    }, err => {
        console.debug('Failed Get dealer plan', err)
        // this.commonService.showErrorToast(err, 'Failed Get dealer plan')
      })
  }

  fetchWCS() {
    this.dealerService.getPartners(this.me.dealer_id).subscribe(res => {
      if (res.length !== 0) {
        this.dealerService.wcs = res[0];
      }
    });
  }

  async fetchAccessPerm(){
    try {
      const res = await this.usersService.fetchMe().toPromise()
      this.opacity = 0;
      this.me = res;
      this.isIpBlocked = this.me.is_ip_blocked;
      if (this.me.type === 3 && !this.me.is_signin_activated) {
        this.isSubDealerEnable = false;
      }
      this.checkBlockStatus()
    } catch(err){
      console.debug('fetchAccessPerm:>',err)
    }
  }

  checkBlockStatus(){
    // if(!this.me.is_activated) {
    //   this.hasAccessPermission = false
    //   return  // hard block : not now
    // }

    if (this.me.app_access_permissions.has_access_dealer_app) {
      this.hasAccessPermission = true
      return  // common logic
    }
    if (!this.me.app_access_permissions.has_access_dealer_app &&
      (this.me.app_access_permissions.has_access_payment_page && this.me.permissions.has_payment_permission)) {
      this.hasAccessPermission = true;
      return  // soft block
      // true : payment 권한 있어야 payment 페이지 보여줄 수 있음
      // false : 권한 없으면 기존대로 볼 수 없도록 하기
    }
    this.hasAccessPermission = false
    return
  }

  openNotice() {
    this.m_notice.data['title'] = 'Terms and Agreement';
    this.m_notice.open();
  }

  onCloseDealerPlanModal(event){
    this.dealerPlanService.getDealerPlan(this.me.dealer_id).subscribe()
    this.saveLocalStorage(event)
  }

  showPlanDescriptionModal(event){
    this.m_dealer_plan_modal.data['type'] = 'plan-description'
    this.m_dealer_plan_modal.open()
    this.saveLocalStorage(event)
  }

  saveLocalStorage(isHiding){
    if(!isHiding) return 
    const localKey = 'CHeKTUser_standard_dealer_notification';
    localStorage.setItem(localKey, JSON.stringify(true))
  }
}
