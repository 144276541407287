import { Component, Input, Output, EventEmitter, HostListener, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { CommonService } from '../../../../../../app/services/common.service'
import { Helper } from '../../../../../../4services/2helper'
import ims from '../../../imports'

@Component({
  selector: 'c_dtable_cloud_ai_stat_device',
  templateUrl: './c_dtable_cloud_ai_stat_device.component.pug',
  styleUrls: ['../../../common.scss', '../video-ai-stat.scss']
})
export class c_dtable_cloud_ai_stat_device_component {
  @Input() tab;
  @Input() amIStandardPlan;
  @Input() amIMainDealer;
  @Input() pagedItemIsReady;
  @Input() prevSelectedMonth;
  @Input() selectedMonth;
  @Input() selSearchSite;
  @Input() pagedItems;
  @Input() searchText;
  @Output() setSiteFilterForDeviceTab: EventEmitter<any> = new EventEmitter();
  @Output() doFilterDevice: EventEmitter<any> = new EventEmitter();
  @Output() sumCloudAIEvents: EventEmitter<any> = new EventEmitter();
  @Output() doneCallingAllData: EventEmitter<any> = new EventEmitter();
  @ViewChildren('deviceActionMenus') deviceActionMenus: QueryList<any>
  //-----------------------------------------------------------------------------

  isLoading = false;
  dealerId = null;
  meDealerInfo = null;
  dealers = []

  sites = []
  providerList = []
  filteredDevices = []
  originDevices = []

  deviceCloudAIDataList = []
  deviceCloudAISkippedDataList = []
  deviceVideoEventsDataList = []

  // sort by header
  sortedColumn: string | null = null;
  isAscending = true;

  deviceTableHeader = [
    { name: 'CH#', value: 'zone_number', width: "7%", tooltip: false },
    { name: 'DEALER NAME', value: 'dealer_name', width: "13%", tooltip: false },
    { name: 'SITE NAME', value: 'site_name', width: "13%", tooltip: false },
    { name: 'DEVICE NAME', value: 'name', width: "13%", tooltip: false },
    { name: 'NETWORK', value: 'is_online', width: "8%", tooltip: false },
    { name: 'AI PROVIDER', value: 'provider_name', width: "8%", tooltip: false },
    // { name: 'AI SKIPPED', value: 'ai_skipped_alarm', width: '7%', tooltip: true },
    { name: 'VIDEO EVENTS', value: 'video_events', width: '7%', tooltip: false },
    { name: 'AI TRUE', value: 'ai_true_alarm', width: "7%", tooltip: false },
    { name: 'AI FALSE', value: 'ai_false_alarm', width: "7%", tooltip: false },
    { name: 'AI EVENTS', value: 'ai_request', width: "10%", tooltip: false },
    { name: '', value: null, width: "5%", tooltip: false },
  ]

  constructor(
    public commonService: CommonService,
    private helper: Helper  
  ) { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.deviceActionMenus) {
      const deviceActionMenus = this.deviceActionMenus.toArray();
      deviceActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(device=> {
          if (device.device_id === parseInt(actionMenu.nativeElement.id)) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              device.isShowMenu = false;
            }
          }
        })
      })
    }
  }

  async ngOnInit(){
    this.dealerId = await this.helper.me.get_my_dealer_id()
    await this.load_device_tab_data()
  }

  ngOnChanges(changes): void {
    if(!changes.prevSelectedMonth) return
    if(!changes.selectedMonth) return
    if(!changes.prevSelectedMonth.currentValue) return
    if(changes.prevSelectedMonth.currentValue === changes.selectedMonth.currentValue) return
    this.load_dealer_devices_cloudAI_data()
  }

  // -----------------------------------------------------------------------
  async load_device_tab_data(){
    try {
      this.isLoading = true
      await this.setDealerInfo()
      await this.load_partners()
      await this.set_sites_loaded()
      await this.load_get_all_provider()
      await this.load_devices()
    } catch(err) {
      this.endForLoadData()
    }
  }

  async setDealerInfo(){
    const res = await this.helper.dealer.get_dealer_info()
    this.meDealerInfo = res[0]
    this.amIStandardPlan = this.helper.dealer_plan.isStandardPlan()
  }
  async load_partners(){
    try {
      if(!this.amIMainDealer) return
      const value = await this.helper.partner.get_partners()
      value.unshift(this.meDealerInfo)
      this.dealers = value
    } catch(err){
      console.debug(err)
      this.endForLoadData()
    }
  }

  async set_sites_loaded(){
    try{
      const res = await this.helper.sites.sites_loaded()
      const sites_loaded = res?.filter(v => v.dealer_id == this.dealerId || v.dealer_type == 3)
      sites_loaded && sites_loaded?.length  
        ? this.parseSiteList(sites_loaded)
        : await this.load_sites()
    } catch(err){
      console.debug('set_sites_loaded:>',err)
    }
  }
  async load_sites() {
    try {
      this.isLoading = true
      const fields = 'site_id&fields=name&fields=dealer_id&fields=dealer_type'
      const res = await this.helper.sites.get_sites(null, fields)
      this.parseSiteList(res)
    } catch(err) {
      console.debug('load_sites', err)
      this.endForLoadData()
    }
  }
  async parseSiteList(value){
    value.forEach(site => {
      if (!site.name) site.name = 'No Name'
    });
    value = value.filter(v => v.dealer_id == this.dealerId || v.dealer_type == 3)
    this.sites = value
    this.setSiteFilterForDeviceTab.emit({sites: value, selSearchSite: this.selSearchSite})
  }

  async load_get_all_provider(){
    try {
      this.providerList = await this.helper.dealer_cloud_ai.get_cloud_ai_providers() ?? []
      this.providerList.forEach(v => v.name = this.capitalizeFirstLetter(v.name))
    } catch(err){
      console.debug('load_get_all_provider',err)
      this.endForLoadData()
    }
  }

  async load_devices() {
    try {
      let filter = 'sort=site.name&dir=asc&filter=device.type,12,eq&filter=device.is_registered,1,eq&filter=site.is_activated,1,eq';
      filter += '&fields=device.device_id,device.zone_number,device.name,device.is_online,';
      filter += 'device.videoai_enabled,device.videoai_area_filter_meta,device.videoai_provider_id,device.videoai_area_filter_meta,';
      filter += 'site.site_id';
      const res = await this.helper.devices.get_devices(filter, true)
      await this.parseDeviceDataAndCallStatsData(res)
    } catch(err){
      console.debug('load_devices :>',err)
      this.endForLoadData()
    }
  }

  async parseDeviceDataAndCallStatsData(value){
    if(!Array.isArray(value) || !value?.length) return this.endForLoadData()
    value.forEach(device => {
      const site = this.sites.find(site => site.site_id == device.site_id)
      if(!site) return // Shared site는 걸러내기

      if(this.amIMainDealer) {
        device['dealer_name'] = site?.external_dealer_id_with_company_name ?? 'No Name'
      } else {
        device['dealer_name'] = this.meDealerInfo?.company_name ?? 'No Name'
      }

      device.name ? device.name = device.name : device.name = 'No Name'
      device['site_name'] = site?.name ?? 'No Site Name'
      device['videoai_enabled'] = (!!site?.videoai_enabled && !!device?.videoai_enabled) ?? false
      device['provider_name'] = device?.videoai_enabled ? this.providerList.find(v => v.id == device.videoai_provider_id)?.name ?? 'None' : 'None'

      device['ai_request'] = '0'
      device['ai_true_alarm'] = '0'
      device['ai_false_alarm'] = '0'
      device['ai_skipped_alarm'] = '0'
      device['video_events'] = '0'
    })
    const result = value.filter(v => Object.keys(v).includes('site_name'))

    this.originDevices = [...result];
    this.filteredDevices = [...result];
    this.doFilterDevice.emit({filteredDevices: this.filteredDevices, originDevices: this.originDevices})

    await this.checkLoadingPagedItemAndDelay()
  }

  async checkLoadingPagedItemAndDelay(){
    if (!this.pagedItemIsReady) {
      setTimeout(() => this.checkLoadingPagedItemAndDelay(), 500);
    } else {
      await this.load_dealer_devices_cloudAI_data()
    }
  }
  async load_dealer_devices_cloudAI_data() {
    this.isLoading = true
    this.initDeviceStats()
    await this.load_dealer_stats_by_devices_every_100_units()
    await this.load_dealer_devices_cloudAI_events()
  }

  initDeviceStats(){
    this.filteredDevices.forEach(device =>{
      device['ai_request'] = '0'
      device['ai_true_alarm'] = '0'
      device['ai_false_alarm'] = '0'
      device['ai_skipped_alarm'] = '0'
      device['video_events'] = '0'
    })
  }

  async load_dealer_devices_cloudAI_events() {
    try {
      const month = this.selectedMonth.value 
      if(this.deviceCloudAIDataList?.length && !month) return this.endForLoadData()
      if(this.amIStandardPlan) return this.endForLoadData()
      const time = this.calcCurrentMonth(month)
      const res = await this.helper.dealer_cloud_ai.get_dealer_devices_cloudAI_events(time)
      const result = res?.data ?? []
      this.deviceCloudAIDataList = result
  
      this.parseDeviceCloudAIdata()
    } catch(err) {
      console.debug('load_dealer_devices_cloudAI_events :>',err)
      this.endForLoadData()
    }
  }

  async load_dealer_stats_by_devices_every_100_units(){
    try {
      const devices_ids = this.filteredDevices.map(v => v.device_id)
      let cutArrayForEvery100DevicesIds = []
      for(let sliceIdx = 100; sliceIdx <= devices_ids.length + 100; sliceIdx += 100){
        let tempArray = devices_ids.slice(sliceIdx - 100, sliceIdx)
        cutArrayForEvery100DevicesIds.push(tempArray)
      }

      for(let i = 0; i < cutArrayForEvery100DevicesIds.length; i++){
        const devices_ids = cutArrayForEvery100DevicesIds[i]
        await Promise.all([
          // this.load_dealer_devices_skipped_stats(devices_ids),
          this.load_dealer_devices_video_events_stats(devices_ids)
        ])
      }
    } catch(err) {
      this.endForLoadData()
      console.debug('load_dealer_stats_by_devices_every_100_units :>>',err)
    }
  }

  async load_dealer_devices_skipped_stats(devices_ids){
    try {
      if(!devices_ids?.length) return this.endForLoadData()
      const selectedMonth = this.selectedMonth.value
      const stime = this.utcDate(selectedMonth).startOf('day').unix()
      let etime 
      if(this.isCurrentMonth()) {
        etime = this.utcDate().unix()
      } else {
        etime = this.utcDate(selectedMonth).endOf('month').endOf('day').unix()
      }

      const res = await this.helper.dealer.get_dealer_stats_by_devices(stime, etime, devices_ids)
      this.deviceCloudAISkippedDataList = res
      this.parseDeviceCloudAISkippedData()
    } catch(err){
      console.debug('load_dealer_devices_skipped_stats:>',err)
      this.endForLoadData()
    }
  }
  async load_dealer_devices_video_events_stats(devices_ids){
    try {
      if(!devices_ids?.length) return this.endForLoadData()
      const selectedMonth = this.selectedMonth.value
      const stime = this.utcDate(selectedMonth).startOf('day').unix()
      let etime 
      if(this.isCurrentMonth()) {
        etime = this.utcDate().unix()
      } else {
        etime = this.utcDate(selectedMonth).endOf('month').endOf('day').unix()
      }

      const res = await this.helper.dealer.get_dealer_stats_by_devices(stime, etime, devices_ids, 2, 11)
      this.deviceVideoEventsDataList = res
      this.parseDeviceVideoEventsData()
    } catch(err){
      console.debug('load_dealer_devices_video_events_stats:>',err)
      this.endForLoadData()
    }
  }

  // ------------------------------------------------------------------------
  parseDeviceCloudAISkippedData(){
    if(!this.filteredDevices?.length || !this.filteredDevices) return
    for(let i = 0 ; i < this.filteredDevices.length; i++){
      const device = this.filteredDevices[i]
      const deviceId = device?.device_id ?? ""
      if(!deviceId) continue
      const skippedData = this.deviceCloudAISkippedDataList[deviceId]?.videoai_skipped_count
      
      if(!skippedData) continue
      const skippedDataNumber = parseInt(skippedData)
      device['ai_skipped_alarm'] = this.formatNumbersWithComma(skippedDataNumber)
    }
  }
  parseDeviceVideoEventsData(){
    if(!this.filteredDevices?.length || !this.filteredDevices) return
    for(let i = 0 ; i < this.filteredDevices.length; i++){
      const device = this.filteredDevices[i]
      const deviceId = device?.device_id ?? ""
      if(!deviceId) continue
      const videoWEventsData = this.deviceVideoEventsDataList[deviceId]?.video_event_count
      if(!videoWEventsData) continue
      const videoWEventsDataNumber = parseInt(videoWEventsData)
      device['video_events'] = this.formatNumbersWithComma(videoWEventsDataNumber)
    }
  }

  parseDeviceCloudAIdata(){
    if(!this.filteredDevices?.length || !this.filteredDevices) return
    for(let i = 0; i < this.filteredDevices.length ; i++){
      const device = this.filteredDevices[i]
      const cloudAIData = this.deviceCloudAIDataList.filter(v => v.device_id == device.device_id)
      if(!cloudAIData.length) continue

      const request = cloudAIData.reduce((acc, curr) => acc += curr?.count, 0) ?? 0
      const truAlarm = cloudAIData.find(v => v?.ai_filter_matched)?.count ?? 0
      const falseAlarm = cloudAIData.find(v => !v?.ai_filter_matched)?.count ?? 0

      device['ai_request'] = this.formatNumbersWithComma(request)
      device['ai_true_alarm'] = this.formatNumbersWithComma(truAlarm)
      device['ai_false_alarm'] = this.formatNumbersWithComma(falseAlarm)
    }

    this.endForLoadData()
  }

  endForLoadData(){
    this.doFilterDevice.emit({filteredDevices: this.filteredDevices})
    this.sumCloudAIEvents.emit()
    this.doneCallingAllData.emit('devices')
    this.isLoading = false
  }

  // ------------------------------------------------------------------------
  sortTableByColumn(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.filteredDevices;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'name': 
          return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'videoai_enabled': 
          return this.compare(a.videoai_enabled, b.videoai_enabled, isAsc);
        case 'video_events': 
          return this.compare(this.formatNumberWithCommaToNumber(a.video_events), this.formatNumberWithCommaToNumber(b.video_events), isAsc);
        case 'ai_true_alarm': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_true_alarm), this.formatNumberWithCommaToNumber(b.ai_true_alarm), isAsc);
        case 'ai_false_alarm': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_false_alarm), this.formatNumberWithCommaToNumber(b.ai_false_alarm), isAsc);
        case 'ai_skipped_alarm': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_skipped_alarm), this.formatNumberWithCommaToNumber(b.ai_skipped_alarm), isAsc);
        case 'ai_request': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_request), this.formatNumberWithCommaToNumber(b.ai_request), isAsc);
        // device
        case 'zone_number': 
          return this.compare(a.zone_number.toLowerCase(), b.zone_number.toLowerCase(), isAsc);
        case 'dealer_name': 
          return this.compare(a.dealer_name.toLowerCase(), b.dealer_name.toLowerCase(), isAsc);
        case 'site_name': 
          return this.compare(a.site_name.toLowerCase(), b.site_name.toLowerCase(), isAsc);
        case 'is_online': 
          return this.compare(a.is_online, b.is_online, isAsc);
        case 'provider_name': 
          return this.compare(a.provider_name.toLowerCase(), b.provider_name.toLowerCase(), isAsc);
        default: return 0;
      }
    });

    this.filteredDevices = tmpData;
    this.doFilterDevice.emit({filteredDevices: tmpData});
  }

  async goToCamera(camera) {
    console.debug('click')
    try {
      console.debug('click')
      const device = await this.helper.devices.get_device(camera.site_id, camera.device_id)
      console.debug('device', device)
      let targetCamera = device[0];
      camera = {...targetCamera};
      console.debug('???')
      this.helper.router.navigate_to('/customers', { id: camera.site_id, device_id: camera.device_id });
      this.commonService.emitSiteTab([{name: 'Devices', link: 'devices', targetDevice:camera, targetCategory: 'general'}, 'device-list'])
      this.pagedItems.forEach(v => v.isShowMenu = false)
    } catch(err){
      console.debug('goToCamera L>',err)
    }
  }

  // ------------------------------------------------------------------------

  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById(id);
    let more = document.getElementById('more-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right - 10;
    const posY = rect.bottom + 7;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(device){
    const targetMenu = device.isShowMenu;
    device.isShowMenu = !targetMenu;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }

  formatNumberWithCommaToNumber(value){
    return parseInt(value.replace(/,/g, ""))
  }

  capitalizeFirstLetter(input){
    let firstChar = input.charAt(0);
    let others = input.slice(1);
    return firstChar.toUpperCase() + others;
  }

  // month
  utcDate(date?){
    const target = date ? new Date(date) : new Date()
    return ims.moment(target).utc()
  }
  calcCurrentMonth(month?){
    const time = this.utcDate(month).startOf('month').format() 
    return time
  }
  isCurrentMonth(){
    const selectedMonth = this.selectedMonth.value
    const stime = this.utcDate(selectedMonth).startOf('day').month()
    const currentMonth = this.utcDate().month()
    return stime === currentMonth 
  }
}
