import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SEPAAccountComponent } from './sepa-account.component';
import { SharedModule } from '../../../../../directives/shared.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [SEPAAccountComponent],
  exports: [ SEPAAccountComponent ]
})
export class SEPAAccountModule { }
