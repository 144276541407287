import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.pug',
  styleUrls: ['./bank-account.component.scss']
})

export class BankAccountComponent implements OnInit {
  @Input() bank: any;
  @Input() defaultPaymentId: string;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  ngOnInit(){
    // console.log(this.bank, this.defaultPaymentId)
  }
  onClickList(bank){
    this.onUpdate.next(bank)
  }
}