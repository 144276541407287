import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-account',
  templateUrl: './card-account.component.pug',
  styleUrls: ['./card-account.component.scss']
})

export class CardAccountComponent implements OnInit {
  @Input() card: any;
  @Input() defaultPaymentId: string;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  ngOnInit(){
    // console.log(this.card, this.defaultPaymentId)
  }
  onClickList(card){
    this.onUpdate.next(card)
  }
}
