import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Modal } from '../../model/modal';

@Component({
  selector: 'dealer-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['../common.scss', './warning.component.scss']
})
export class WarningComponent implements OnInit {

  @Input() public modal: Modal;
  @Input() public active:boolean;
  @Input() public doCancel: boolean;
  @Output() result: EventEmitter<boolean> = new EventEmitter();
  
  inputText = '';
  cancelText = 'Cancel';
  width = 600;
  isErr = false;
  leftBtns = [];
  rightBtns = [];

  constructor() { }

  ngOnInit() {
    this.modal.data = new Object();
  }

  ngOnChanges(changes) {
    this.cancelText = 'Cancel';
    this.width = 600;
    this.leftBtns = [];
    this.rightBtns = [];
    if (this.modal.data) {
      if (this.modal.data.cancel_btn) {
        this.cancelText = this.modal.data.cancel_btn;
      }
      if (this.modal.data.width) {
        this.width = this.modal.data.width;
      }
      if (this.modal.data.btns) {
        this.leftBtns = this.modal.data.btns.filter(btn=>btn.left);
        this.rightBtns = this.modal.data.btns.filter(btn=>btn.right || !btn.left);
      }
    }
    if (this.doCancel) {
      this.cancel();
    }
  }

  submit(event?, doOpen = false) {
    if (!this.inputCheck()) return;
    if(this.modal.data['submit_func']) {
      this.modal.data['submit_func'](event);
    }
      
    if(this.result)
      this.result.next(true);
    this.inputText = '';
    if (!doOpen) {
      this.modal.close();
    }
  }

  inputCheck() {
    let res = true;
    this.isErr = false;
    if (this.modal.data['isInput']) {
      if (this.modal.data['confirmInput'] !== this.inputText) {
        res = false;
        this.isErr = true;
      }
    }
    return res;
  }

  cancel() {
    this.inputText = '';
    if(this.modal.data['cancel_func'])
      this.modal.data['cancel_func']();

    if(this.result)
      this.result.next(false);

    this.modal.close();
  }
}