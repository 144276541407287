import { Devices } from '../../app/model/devices';
export default {
  firmware_version_string_to_array: function (version: string) {
    return version.replace(/[^\d|\.]/gi, '').split('.').map(v => Number(v));
  },
  parseFirmwareVersion: function(bridge) {
    let firmVersion = '';
    if (bridge?.firmware_version) {
      try {
        firmVersion = JSON.parse(bridge?.firmware_version)?.bridge?.replace('V', '')?.split('.')?.slice(0, 4)?.join('.');
      } catch {
        firmVersion = bridge?.firmware_version?.replace('V', '')?.split('.')?.slice(0, 4)?.join('.');
      }
    } 
    if (bridge?.parsedFwVersion) {
      firmVersion = bridge?.parsedFwVersion
    }
    if (typeof bridge === 'string') {
      firmVersion = bridge?.replace('V', '')?.split('.')?.slice(0, 4)?.join('.');
    }
    return firmVersion;
  },
  firmware_version_greater_than_or_equal_to: function (bridge: Devices | string | JSON, target: string) {
    let firmVersion = this.parseFirmwareVersion(bridge)
    if (firmVersion === target) return true // equal
    if (typeof firmVersion != 'string' || typeof target != 'string') return

    const sourceVersion = this.firmware_version_string_to_array(firmVersion)
    const targetVersion = this.firmware_version_string_to_array(target)

    const maxLength = sourceVersion.length > targetVersion.length ? sourceVersion.length : targetVersion.length
    for (let i = 0; i < maxLength; i++) {
      const versionS = sourceVersion[i] || 0
      const versionT = targetVersion[i] || 0

      if (versionS > versionT) return true
      if (versionS < versionT) return false
    }
    return true // equal
  },
};



