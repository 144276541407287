import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class StatisticsService {

  me: any;
  apiKEys: any;
  permissions: any;

  selRoles = <any>{};

  constructor(private api: ApiService) { }

  public getMonitoringOpenInfo(dealerId, stime, etime) {
      const url = `/dealers/${dealerId}/statistics/monitoring_open_time?stime=${stime}&etime=${etime}`
      return this.api.get(url);
  }

  /*
  1: bridge offline count
  3: camera offline count
  5: monitoring portal open count
  6: monitoring portal open time
  8: verification dispatch count
  9: verification cancel count
  10: verification no response count
  11: video event count
  12: alarm signal count
  13: zone bypass count
  periodType= 0: hour, 1: day
  rank= default 10,
  jsonFormat 0: category > time, 1: time > category
  subDealerId: null or subDealerId
  */
  getStatisticsDealer(dealerId, stime, etime, types='1,3,5,6,8,9,10,11,12,13', periodType=1, rank=10, jsonFormat=0, subDealerId=null) {
    let url = `/dealers/${dealerId}/statistics/events?stime=${stime}&etime=${etime}`;
    if (types !== null) url += `&types=${types}`;
    if (periodType !== null) url += `&period_type=${periodType}`;
    if (rank !== null)  url += `&rank=${rank}`;
    if (jsonFormat !== null) url += `&json_format=${jsonFormat}`;
    if (subDealerId !== null) url += `&sub_dealer_id=${subDealerId}`;
    return this.api.get(url);
  }

  getRegistrationStatisticsDealer(dealerId, stime, etime, subDealerId=null, version=null) {
    let url = `/dealers/${dealerId}/statistics/registrations?stime=${stime}&etime=${etime}`;
    if (subDealerId !== null) url += `&sub_dealer_id=${subDealerId}`;
    if (version !== null) url += `&version=${version}`;
    return this.api.get(url);
  }

  getSiteInfo(dealerId, subDealerId=null, siteIds=null, type='connection_status') {
    let url = `/dealers/${dealerId}/sites_info?type=${type}`;
    if (siteIds) url += `&site_ids=${siteIds}`;
    if (subDealerId) url += `&sub_dealer_id=${subDealerId}`;
    return this.api.get(url);
  }
}