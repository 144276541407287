import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { EventViewerService } from '@app/services/event-viewer.service';

@Component({
  selector: 'c_card_event_viewer_time_line_input_area_component',
  templateUrl: './c_card_event_viewer_time_line_input_area.component.pug',
  styleUrls: ['./c_card_event_viewer_time_line_input_area.component.scss'],
})
export class c_card_event_viewer_time_line_input_area_component {
  @ViewChild('memoTextArea') memoTextArea: ElementRef<HTMLTextAreaElement>;
  @Input() log: any
  @Input() isMode: string; // 'print' | 'create' | 'view'
  @Input() isHasImage: any;

  maxRows = 3; // 최대 허용 행 수
  maxLength = 160; // 최대 글자 수 - 450
  noteLength = 0;
  noteList = [];
  isShowErr = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private eventViewerService: EventViewerService,
  ) { }

  ngAfterViewInit(): void {
    this.adjustTextArea()
    this.cdr.detectChanges(); // 수동으로 변경 감지 실행
  }

  // -------------------------------------------
  // TEXTAREA
  adjustTextArea(): void {
    if(!this.memoTextArea?.nativeElement) return

    if (this.isHasImage) {
      const contentHeight = 250;
      const adjustedHeight = contentHeight - 20;
      this.memoTextArea.nativeElement.style.height = `${adjustedHeight}px`;

      const lineHeight = 1.3; // line-height in rem
      const fontSize = 16; // Assuming the base font size is 16px for 1rem
      const rows = Math.floor(adjustedHeight / (lineHeight * fontSize));
      this.maxRows = rows;
      this.maxLength = 450; 
      console.log('adjustedHeight', adjustedHeight, 'rows', rows);
      return 
    } 

    this.memoTextArea.nativeElement.style.height = '80px';
    this.maxRows = 3;
    this.maxLength = 160; 
  }

  checkTextarea(inputEvent: Event) {
    const textarea = inputEvent.target as HTMLTextAreaElement;
    const lines = textarea.value.split('\n');
    this.noteLength = textarea.value.length;
    const height = this.isHasImage ? 230 : 80
    this.isShowErr = false;
    this.eventViewerService.isShowErrInTextArea = false;

    if (this.memoTextArea.nativeElement.scrollHeight > height) {
      textarea.value = textarea.value.substring(0, textarea.value.length - 1);
      this.isShowErr = true;
      this.eventViewerService.isShowErrInTextArea = true;
    }

    if (lines.length > this.maxRows) {
      textarea.value = lines.slice(0, this.maxRows).join('\n'); // 초과된 내용을 제거
    }
    console.log(textarea.value, textarea.value.length, lines.length, this.maxRows)
  }
}
