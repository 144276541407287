import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/directives/shared.module';

import { c_side_modal_component } from './c_side_modal.component';
import { c_side_modal_site_note_component } from './site-note/c_side_modal_site_note.component';
import { c_side_modal_approval_process_component } from './monitoring-direction/approval-process/c_side_modal_approval_process.component';
import { c_side_modal_direction_define_component } from './monitoring-direction/define/c_side_modal_direction_define.component';
import { c_side_modal_direction_features_component } from './monitoring-direction/directions-features/c_side_modal_direction_features.component';
import { c_side_modal_direction_procedures_component } from './monitoring-direction/stored-procedures/c_side_modal_direction_procedures.component';
import { c_side_modal_direction_common_component } from './direction_common/c_side_modal_direction_common.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    SharedModule
  ],
  declarations: [
    c_side_modal_component, 
    c_side_modal_site_note_component, 
    c_side_modal_approval_process_component,
    c_side_modal_direction_define_component,
    c_side_modal_direction_features_component,
    c_side_modal_direction_procedures_component,
    c_side_modal_direction_common_component
  ],
  exports: [c_side_modal_component],
})
export class c_side_modal_module { }
