import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { c_dialog_share_incident_report_log_component } from './c_dialog_share_incident_report_log_component';
import { WarningModule } from '../../../../app/modals/warning/warning.module';
import { SharedModule } from '../../../../app/directives/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule
  ],
  declarations: [c_dialog_share_incident_report_log_component],
})
export class c_dialog_share_incident_report_log_module { }
