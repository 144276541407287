import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { SortableTableDirective } from './sortable/sortable';
import { SortableColumnComponent } from '../components/sortable/sortable.component'
import { IconsComponent } from '../components/icons/icons.component'
import { DndDirective } from './drag-and-drop/drag-and-drop';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SortableTableDirective,
    SortableColumnComponent,
    IconsComponent,
    DndDirective
  ],
  exports: [
    SortableTableDirective,
    SortableColumnComponent,
    IconsComponent,
    DndDirective
  ]
})

export class SharedModule { }