import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SharedModule } from '../../../../../app/directives/shared.module'
import { MatCheckboxModule } from '@angular/material/checkbox';

import { c_dialog_dealer_division_add_sites_component } from "./c_dialog_dealer_division_add_sites_component";

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, MatCheckboxModule],
  declarations: [c_dialog_dealer_division_add_sites_component],
})
export class c_dialog_dealer_division_add_sites_module {}
