import { Component, HostListener, Input, Output, ViewChild, ViewChildren, ElementRef, QueryList, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PagerService } from '@app/services/pager.service';
import { CommonService } from '@app/services/common.service';
import { DevicesService } from '@app/services/devices.service';
import { Helper } from '../../../../../4services/2helper'
import { c_components } from 'src/3ui/2components';
import ims from '../../imports'

@Component({
  selector: 'c_dtable_cloud_vms_manager_for_device',
  templateUrl: './c_dtable_cloud_vms_manager_for_device.component.pug',
  styleUrls: ['../../common.scss', '../cloud_vms_manager.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class c_dtable_cloud_vms_manager_for_device_component {
  @Input() deviceList: any;
  @Input() isLoading: boolean;
  @Output() refresh = new EventEmitter<boolean>();
  @ViewChild('selectDropdown') selectDropdown: ElementRef;
  @ViewChildren('deviceActionMenus') deviceActionMenus: QueryList<any>
//-----------------------------------------------------------------------------

tableHeader = [
    { name: '', value: null, width: "5%", isCheckbox: true },
    { name: 'SITE NAME', value: 'site_name', width: "20%", isCheckbox: false },
    { name: 'DEVICE NAME', value: 'name', width: "20%", isCheckbox: false },
    { name: 'ONLINE', value: 'is_online', width: "10%", isCheckbox: false },
    { name: 'ZONE NUMBER', value: 'zone_number', width: "10%", isCheckbox: false },
    { name: 'VIDEO EVENTS', value: 'video_events', width: "10%", isCheckbox: false },
    { name: 'PLAN', value: 'device_plan', width: "20%", isCheckbox: false },
    { name: '', value: null, width: "5%", isCheckbox: false },
  ]

  filteredDeviceList = [];
  originalDeviceList = [];
  pagedItems = [];
  
  isAllSelected = false;
  selectedDeviceList = [];

  isFocusSearch = false;
  searchText = '';

  basicPlanCount = 0
  thirtyDaysPlanCount = 0
  ninetyDaysPlanCount = 0

  // DEVICE PLAN
  isUnfoldPlanDropdown = false;
  devicePlanList = [
    { label: 'Basic', value: null },
    { label: '30 Day Storages Plan', value: 1 },
    { label: '90 Day Storages Plan', value: 2 },
  ]
  selectedDevicePlan = this.devicePlanList[0];

  // Pager
  pager: any = {};
  curPage = 1;
  totalItem = 0;
  pageOffset = 50;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;

  constructor(
    public pagerService : PagerService,
    public commonService: CommonService,
    private devicesService: DevicesService,
    public c_components: c_components,
    private cdr: ChangeDetectorRef,
    public helper: Helper,
  ) { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.deviceActionMenus) {
      const deviceActionMenus = this.deviceActionMenus.toArray();
      deviceActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(device=> {
          if (`device-${device.device_id}` === actionMenu.nativeElement.id) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              device.isShowMenu = false;
            }
          }
        })
      })
    }
    if (this.selectDropdown) {
      if (!this.selectDropdown.nativeElement.contains(event.target)) {
        event.stopPropagation()
        this.isUnfoldPlanDropdown = false;
      }
    }
  }

  ngOnInit() {
    this.originalDeviceList = ims._.cloneDeep(this.deviceList);
    this.filteredDeviceList = ims._.cloneDeep(this.deviceList);
    this.selectedDevicePlan = this.devicePlanList[0];
    this.setPage(1)
  }

  ngOnChanges(changes): void {
    if(changes.siteList){
      this.originalDeviceList = ims._.cloneDeep(this.deviceList);
      this.filteredDeviceList = ims._.cloneDeep(this.deviceList);
      this.setPage(1)
    }
  }

  // --------------------------------------------------------------------------------
  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById('device-'+id);
    let more = document.getElementById('more-device-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect?.right - 10;
    const posY = rect?.bottom - 45;

    if(!more) return
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(device){
    const targetMenu = device.isShowMenu;
    device.isShowMenu = !targetMenu;
  }

  toggleAllCheckbox(event: Event) {
    // 최대 체크된 수 제한 (10개)
    const maxChecked = 10;
    const currentCheckedCount = this.deviceList.length;
    const target = event.target as HTMLInputElement;

    if (this.isAllSelected || currentCheckedCount < maxChecked) {
      this.isAllSelected = !this.isAllSelected
      this.deviceList.forEach(device => device.isSelected = this.isAllSelected)
      this.filteredDeviceList.forEach(device => device.isSelected = this.isAllSelected)
      this.isAllSelected
        ? this.selectedDeviceList = ims._.cloneDeep(this.deviceList)
        : this.selectedDeviceList = []
    } else {
      // 상태 변경을 취소
      event.preventDefault();
      target.checked = this.isAllSelected;
      this.openWarningDialog('Warning', 'You can select up to 10 devices.', 'warning', 'orange');
    }
  }

  toggleCheckbox(device, event: Event) {
    const maxChecked = 10;
    const currentCheckedCount = this.selectedDeviceList.length;
    const eventTarget = event.target as HTMLInputElement;
    
    const target = this.deviceList.find(item => item?.device_id === device?.device_id);
    if (device.isSelected || currentCheckedCount < maxChecked) {
      device.isSelected = !device.isSelected;
      target.isSelected = !target.isSelected;
    } else {
      event.preventDefault();
      eventTarget.checked = device.isSelected;
      this.openWarningDialog('Warning', 'You can select up to 10 devices.', 'warning', 'orange');
    }
    this.selectedDeviceList = this.deviceList.filter(device => device.isSelected);
    this.isAllSelected = this.deviceList.every(device => device.isSelected);
  }

  toggleSelectDropdown(e){
    e?.stopPropagation()
    this.isUnfoldPlanDropdown = !this.isUnfoldPlanDropdown
  }

  selectDevicePlan(event, plan){
    event?.stopPropagation();
    this.selectedDevicePlan = plan;
  }

  // STYLES
  checkTotalCount(target){
    this.basicPlanCount = 0
    this.thirtyDaysPlanCount = 0
    this.ninetyDaysPlanCount = 0
  
    target.forEach(device => {
      if(device?.device_plan_id === 1 || device?.is_activated_device_plan === 0) this.basicPlanCount++
      if(device?.device_plan_id === 2 && device?.is_activated_device_plan === 1) this.thirtyDaysPlanCount++
      if(device?.device_plan_id === 3 && device?.is_activated_device_plan === 1) this.ninetyDaysPlanCount++
      if(!device?.device_plan_id || !device?.is_activated_device_plan) this.basicPlanCount++
    })
  }

  determineElementToShow(){
    if(this.isLoading) return false
    if(!this.deviceList?.length) return 'no-data'
    return 'normal'
  } 

  computedStyleForOnline(device){
    return device.is_online ? 'online' : 'offline';
  }

  computedPlanBadgeStyle(isActivatedDevicePlan, plan){
    if(!isActivatedDevicePlan) return 'basic-badge'
    switch(plan){
      case 1: return 'first-plan-badge'
      case 2: return 'second-plan-badge'
      default: return 'basic-badge'
    }
  }

  parseDevicePlan(isActivatedDevicePlan, plan){
    if(isActivatedDevicePlan === 0) return 'Basic';
    if(plan === 1) return '30 Days Storage Plan';
    if(plan === 2) return '90 Days Storage Plan';
    return 'Basic'; // null
  }

  goToDetail(event, device){
    event.stopPropagation();
    this.helper.router.navigate_to(`/customers/setting/video-vault`, {id: device.site_id});
  }

  // -----------------------------------------------------------------------------
  setSearchFocus() {
    this.isFocusSearch = true;
  }
  
  search(){
    if (this.searchText.length == 0 || this.searchText.length < 3) { 
      this.filteredDeviceList = this.originalDeviceList
      this.setPage(1)
      return
    }
    this.filteredDeviceList = this.searchDevice()
    this.setPage(1)
  }
  searchDevice(){
    let search_text = this.searchText.toLowerCase();
    return this.originalDeviceList.filter(device => {
      let text = device.name +
                device.site_name.toLowerCase() 

      if(search_text) return text.search(search_text) > -1;
      else return false;
    });
  }

  // -----------------------------------------------------------------------------
  // ACTION
  async onClickBulkUpdatePlan(){
    this.isLoading = true;
    const selectedDevices = this.selectedDeviceList.filter(device => device.isSelected);
    
    let result = 0;
    const promises = selectedDevices.map(item =>
      this.updateDevicePlan(item)
        .then(() => result++)
        .catch(err => {
          console.debug('updateDevicePlan :>', err)
          selectedDevices.forEach(device => device.isSelected = false);
          this.commonService.showErrorToast(err, `Failed to update Device Plan - ${item.device_id}`);
        })
    );
    await Promise.all(promises);
    if(result === selectedDevices?.length) { // Success
      console.log('success..?')
      this.refresh.emit();

      // init
      this.selectedDeviceList = [];
      this.deviceList.forEach(device => device.isSelected = false)
      this.setPage(1)
      this.openWarningDialog('Success', 'Device Plan has been updated successfully.', 'done', 'green');
    }
    this.isLoading = false;
  }

  async updateDevicePlan(device){
    const selectedPlanId = this.selectedDevicePlan.value;
    const isActivatedDevicePlan = selectedPlanId ? 1 : 0;
    let data = {}
    if(this.selectedDevicePlan.value) {
      data = {
        device_plan_id: selectedPlanId,
        is_activated_device_plan: isActivatedDevicePlan
      }
    } else {
      // device plan을 끄기 위해서는 is_activated_device_plan을 0으로 설정해야함
      data = { is_activated_device_plan: isActivatedDevicePlan }
    }
    await this.devicesService.updateDevice(device, data).toPromise();
  }

  // -----------------------------------------------------------------------------
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  formatNumberWithCommaToNumber(value){
    return parseInt(value?.replace(/,/g, ""))
  }

  sortTableByColumn(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.filteredDeviceList;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'site_name': 
          return this.compare(a.site_name?.toLowerCase(), b.site_name?.toLowerCase(), isAsc);
        case 'name': 
          return this.compare(a.name?.toLowerCase(), b.name?.toLowerCase(), isAsc);
        case 'is_online': 
          return this.compare(a.is_online, b.is_online, isAsc);
        case 'zone_number': 
          return this.compare(a.zone_number, b.zone_number, isAsc);
        case 'video_events': 
          return this.compare(this.formatNumberWithCommaToNumber(a.video_events), this.formatNumberWithCommaToNumber(b.video_events), isAsc);
        case 'device_plan': 
          return this.compare(a.device_plan_id, b.device_plan_id, isAsc);
        default: return 0;
      }
    });
    
    this.pagedItems = tmpData;
    this.setPage(1)
  }

  setPage(page: number) {
    this.pagedItems = []

    const target = this.filteredDeviceList
    this.checkTotalCount(target)
    if(!target){
      console.debug('⚠️ list is lost', target)
      return this.isLoading = false
    }
    
    // get pager object from service
    this.pager = this.pagerService.getPager(target?.length, page, this.pageOffset);
    this.totalItem = target?.length;
    
    // get current page of items
    this.pagedItems = target.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalItem) {
      this.pageOffsetEnd =  this.totalItem;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
  }

  openWarningDialog(header, msg, icon, color) {
    this.c_components.dialog.open("warning", {
      header: `${header}`,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {},
    });
  }
}