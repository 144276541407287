import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from "@angular/material/dialog";

import { c_dialog_basic_component } from "./c_dialog_basic_component";

@NgModule({
  imports: [CommonModule, FormsModule, MatDialogModule],
  declarations: [c_dialog_basic_component],
})
export class c_dialog_basic_module {}
