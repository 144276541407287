
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';





@Injectable()
export class PermissionsService {

  me: any;
  roles: any;
  permissions: any;
  
  allPermissions : any;

  constructor(private api: ApiService) { }

  public getPermissions(dealer_id: number, refresh = false): Observable<any> {
    if (this.permissions && !refresh) {
      // return this.permissions;
      return observableOf(this.permissions);
    }

    return this.api.get(`/dealers/${dealer_id}/members/me/permissions`).pipe(map(res => {
      this.permissions = res;
      return res;
    }));
  }

  public getAllPermissions(dealer_id: number, isGroup?): Observable<any> {
    if (this.allPermissions) {
      // return this.permissions;
      return observableOf(this.allPermissions);
    }
    let url = `/dealers/${dealer_id}/permissions`;
    if (isGroup) {
      url += `?group=${isGroup}`;
    }
    return this.api.get(url).pipe(map(res => {
      this.allPermissions = res;
      return res;
    }));
  }
}