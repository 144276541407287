import { Component } from '@angular/core';
import { Helper } from 'src/4services/2helper';

@Component({
  selector: 'c_side_modal_direction_common_component',
  templateUrl: './c_side_modal_direction_common.component.pug',
  styleUrls: ['../common.scss','../c_side_modal.component.scss'],
})
export class c_side_modal_direction_common_component {

  constructor(private helper: Helper) { }

  goToReport(){
    this.helper.router.navigate_to(`/reports/site-note-manager`);
  }
}
