import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { UsersService } from '../../app/services/users.service';
import { DealerService } from '../../app/services/dealer.service';
import { PartnerService } from '../../app/services/partner.service';
import { SitesService } from '../../app/services/sites.service';
import { DevicesService } from '../../app/services/devices.service';
import { RolesService } from '../../app/services/roles.service';
import { AccessesService } from '../../app/services/accesses.service';
import { PermissionsService } from "../../app/services/permissions.service";
import { ThirdPartyService } from "../../app/services/thirdparty.service";
import { DealerPlanService } from '../../app/services/service-plan.service'
import { DivisionService } from '../../app/services/divisions.service'
import { PaymentService } from '../../app/services/payment.service'
import { EventStoragesService } from '../../app/services/event-storages.service'
import { LogsService } from '../../app/services/logs.service'
import { VirtualGuardTourService } from '../../app/services/virtual-guard-tour.service'
import { VideoAIService } from '../../app/services/cloud-ai.service';
import { IncidentReportsService } from '@app/services/incident-reports.service';
import { NoteDirectionsService } from '@app/services/note-directions.service';
import { MpSettingsService } from '@app/services/mp_settings.service';

@Injectable()
export class Imports {
  constructor(
    public router: Router,
    public usersService: UsersService,
    public partnerService: PartnerService,
    public sitesService:SitesService,
    public devicesService:DevicesService,
    public dealerService: DealerService,
    public rolesService: RolesService,
    public accessesService: AccessesService,
    public permissionsService: PermissionsService,
    public thirdPartyService: ThirdPartyService,
    public dealerPlanService: DealerPlanService,
    public divisionService: DivisionService,
    public paymentService: PaymentService,
    public eventStoragesService: EventStoragesService,
    public logsService: LogsService,
    public virtualGuardTourService: VirtualGuardTourService,
    public videoAIService: VideoAIService,
    public incidentReportsService: IncidentReportsService,
    public noteDirectionsService: NoteDirectionsService,
    public mpSettingsService: MpSettingsService,
  ) {}
}

