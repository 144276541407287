import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { c_components } from "../../../index";
import { Helper } from "../../../../../4services/2helper";
import { validateIBAN } from 'ibantools';

@Component({
  templateUrl: "./c_dialog_dealer_payment_sepa_component.pug",
  styleUrls: ["../../common.scss", "./c_dialog_dealer_payment_sepa_component.scss"],
})
export class c_dialog_dealer_payment_sepa_component {
  // ------------------------------------------------------------------------

  dealerId;
  name: string;
  email: string;
  iban: string;

  isValidEmail: boolean = true;
  isValidIBAN: boolean;

  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<c_dialog_dealer_payment_sepa_component>,
    @Inject(MAT_DIALOG_DATA)
    private c_components: c_components,
    private helper: Helper
  ) {}

  ngOnInit() {
    this.get_my_dealer_id()
  }

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  async get_my_dealer_id(){
    this.dealerId = await this.helper.me.get_my_dealer_id()
  }
  async load_sepa(){
    await this.helper.dealer_payment.load_sepa()
  }

  async submit() {
    this.isLoading = true;
    this.trim_spaces_data();
    await this.createSEPA();
    this.isLoading = false;
  }

  async createSEPA(){
    if(!this.isValidate()) {
      return this.openConfirmDialog('Invalid Data', '', 'warning', 'orange')
    }
    try {
      const data = {
        "iban": this.iban,
        "account_holder_name": this.name,
        "account_holder_email": this.email,
      }
      await this.helper.dealer_payment.create_sepa(data)
      await this.load_sepa()
      this.close_dialog(true)
    } catch(err) {
      console.debug(err)
      this.isLoading = false
    }
  }

  // ------------------------------------------------------------------------

  trim_spaces_data() {
    this.name = this.name?.trim();
    this.email = this.email?.trim();
    this.iban = this.iban.replace(/ /g, '')
  }

  isValidate(){
    if((!this.name || !this.email) || !this.iban) return false
    if(!this.isValidEmail) return false
    if(!this.isValidIBAN) return false
    return true
  }

  validateIBAN(){
    if(!this.iban) return 

    this.formatingIBAN(this.iban)
    let tempIBAN = this.iban?.replace(/ /g, '')
    this.isValidIBAN = validateIBAN(tempIBAN).valid
  }
  formatingIBAN(value, keyCode?){
    if(!value) return
    if(keyCode === 8) return // backspace

    let trimSpaceIBAN = value?.replace(/ /g, '')
    const splitedIBANArr = trimSpaceIBAN.split('')
    
    let result = ''
    for(let i = 0; i < splitedIBANArr.length; i++){
      result += splitedIBANArr[i]
      if(!((i+1) % 4)) result += ' '
    }

    this.iban = result
  }

  validateEmail() {
    if(!this.email) return  this.isValidEmail = false

    this.isValidEmail = true
    // eslint-disable-next-line
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    this.isValidEmail = reg.test(this.email)
    if (this.email.length < 3 && this.email.length !== 0) {
      this.isValidEmail = false
    }
  }

  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }
}
