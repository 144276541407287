import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { c_dtable_service_note_component } from './c_dtable_service_note.component';
import { SharedModule } from '../../../../../../app/directives/shared.module'

import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatCheckboxModule
  ],
  declarations: [c_dtable_service_note_component],
  exports: [c_dtable_service_note_component],
})
export class c_dtable_service_note_module { }
