import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningModule } from '../../warning/warning.module'
import { SharedModule } from '../../../directives/shared.module'
import { PaymentInformationModule } from './payment-information/payment-information.module';
import { PlanPaymentComponent } from './plan-payment.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    PaymentInformationModule
  ],
  declarations: [
    PlanPaymentComponent
  ],
  exports: [
    PlanPaymentComponent
  ]
})
export class PlanPaymentModule { }
