import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../../../app/directives/shared.module';
import { c_dialog_event_storage_region_component } from './c_dialog_event_storage_region_component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [c_dialog_event_storage_region_component],
  exports: [
    c_dialog_event_storage_region_component
  ]
})
export class c_dialog_event_storage_region_module { }
