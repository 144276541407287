import { Component, Inject } from "@angular/core";
import { SitesService } from "@app/services/sites.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import ims from "../../imports";
import { Helper } from "../../../../4services/2helper";

@Component({
  templateUrl: "./c_dialog_share_incident_report_log_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_share_incident_report_log_component.scss"],
})
export class c_dialog_share_incident_report_log_component {
  site: any;
  reportId: any = null;
  isLoading = false;

  sharedHistory: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<c_dialog_share_incident_report_log_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private sitesService: SitesService,
    private helper: Helper,
  ) {}

  ngOnInit() {
    this.reportId = this.data?.reportId
    this.initData()
  }

  close_dialog(): void {
    this.dialogRef.close();
  }

  async initData() {
    this.isLoading = true;
    this.site = this.sitesService.selSite;
    this.sharedHistory = []
    console.log('init', this.reportId, this.site.site_id)
    await this.fetchSharedLog();
    
    this.isLoading = false;
  }

  async fetchSharedLog() {
    try {
      const siteId = this.site.site_id;
      if(!this.site || !siteId) return this.isLoading = false;
      if(!this.reportId) return this.isLoading = false;

      const logs = await this.helper.incident_reports.get_shared_log(siteId, this.reportId);
      logs?.forEach(log => log.created_at = ims.moment(log.created_at).tz(this.site.timezone).format('MM/DD/YYYY hh:mm a'));
      this.sharedHistory = logs || [];  
    } catch(err) {
      console.debug('fetchSharedLog:>',err)
      this.isLoading = false
    }
  }
}