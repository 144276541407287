import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaymentInformationComponent } from './payment-information.component';
import { WarningModule } from '../../../warning/warning.module';
import { SharedModule } from '../../../../directives/shared.module';
import { CardAccountModule } from './card-account/card-account.module';
import { BankAccountModule } from './bank-account/bank-account.module';
import { SEPAAccountModule } from './sepa-account/sepa-account.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    MatCheckboxModule,
    CardAccountModule,
    BankAccountModule,
    SEPAAccountModule
  ],
  declarations: [PaymentInformationComponent],
  exports: [ PaymentInformationComponent ]
})
export class PaymentInformationModule { }
