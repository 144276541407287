import { NgModule } from "@angular/core";

import { c_filter_date_range_module } from "./date-picker/c_filter_date_range_module";

@NgModule({
  exports: [
    c_filter_date_range_module
  ],
})
export class c_filter_module {}
