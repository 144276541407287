import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { ApiService } from './api.service';





@Injectable()
export class TosService {
  constructor(private api: ApiService) { }

  get(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/toss/last/text`);
  }

  accept(dealer_id: number, tosId, data?): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/toss/${tosId}/accept`, data);
  }
}
