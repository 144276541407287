import { Component, HostListener, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import ims from '../../../imports'
import { Helper } from '../../../../../../4services/2helper'
import { CommonService } from '../../../../../../app/services/common.service'

@Component({
  selector: 'c_dtable_cloud_ai_stat_dealer',
  templateUrl: './c_dtable_cloud_ai_stat_dealer.component.pug',
  styleUrls: ['../../../common.scss', '../video-ai-stat.scss']
})
export class c_dtable_cloud_ai_stat_dealer_component {
  @Input() tab;
  @Input() amIStandardPlan;
  @Input() pagedItemIsReady;
  @Input() prevSelectedMonth;
  @Input() selectedMonth;
  @Input() pagedItems;
  @Input() searchText;
  @Output() doFilterDealer: EventEmitter<any> = new EventEmitter();
  @Output() sumCloudAIEvents: EventEmitter<any> = new EventEmitter();
  @Output() moveToDealerDetail: EventEmitter<any> = new EventEmitter();
  @Output() doneCallingAllData: EventEmitter<any> = new EventEmitter();
  @ViewChildren('dealerActionMenus') dealerActionMenus: QueryList<any>
  //-----------------------------------------------------------------------------

  isLoading = false;
  meDealerInfo = null
  originDealers = []
  filteredDealers = []
  CSDealerCloudAIDataList = []
  CSDealerCloudAISkippedDataList = []
  CSDealerVideoEventDataList = []

  // sort by header
  sortedColumn: string | null = null;
  isAscending = true;

  dealerTableHeader = [
    { name: 'DEALER NAME', value: 'company_name', width: '30%', tooltip: false },
    { name: 'PLAN', value: 'current_month_service_plan_type', width: '10%', tooltip: false },
    { name: 'SITES', value: 'site_count', width: '5%', tooltip: false },
    { name: 'AI ENABLED', value: 'is_videoai_enabled', width: '10%', tooltip: false },
    // { name: 'AI SKIPPED', value: 'ai_skipped_alarm', width: '10%', tooltip: true },
    { name: 'VIDEO EVENTS', value: 'video_events', width: '10%', tooltip: false },
    { name: 'AI TRUE', value: 'ai_true_alarm', width: '10%', tooltip: false },
    { name: 'AI FALSE', value: 'ai_false_alarm', width: '10%', tooltip: false },
    { name: 'AI EVENTS', value: 'ai_request', width: '10%', tooltip: false },
    { name: '', value: null, width: '5%', tooltip: false },
  ]

  constructor(
    private helper: Helper,
    public commonService: CommonService,  
  ) { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.dealerActionMenus) {
      const dealerActionMenus = this.dealerActionMenus.toArray();
      dealerActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(dealer=> {
          if (dealer.dealer_id === parseInt(actionMenu.nativeElement.id)) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              dealer.isShowMenu = false;
            }
          }
        })
      })
    }
  }

  async ngOnInit() {
    this.originDealers = []
    this.filteredDealers = []
    this.load_dealer_tab_data()
  }

  ngOnChanges(changes): void {
    if(!changes.prevSelectedMonth) return
    if(!changes.selectedMonth) return
    if(!changes.prevSelectedMonth.currentValue) return
    if(changes.prevSelectedMonth.currentValue === changes.selectedMonth.currentValue) return
    this.load_dealer_dealers_cloudAI_data()
  }

  // -----------------------------------------------------------------------

  async load_dealer_tab_data(){
    try {
      this.isLoading = true
      await this.setDealerInfo()
      await this.load_partners()
    } catch(err) {
      this.endForLoadData()
    }
  }

  async setDealerInfo(){
    const res = await this.helper.dealer.get_dealer_info()
    const videoAIEnabled = await this.helper.dealer_cloud_ai.get_cloud_ai_enabled()
    const dealerPlanService = await this.helper.dealer_plan.get_dealer_plan()
    const currentDealerPlan = dealerPlanService?.current_month_service_plan_type ?? 1

    this.meDealerInfo = res[0]
    this.meDealerInfo['is_videoai_enabled'] = videoAIEnabled
    this.meDealerInfo['current_month_service_plan_type'] = currentDealerPlan
    this.amIStandardPlan = this.helper.dealer_plan.isStandardPlan()
  }
  async load_partners(){
    try {
      const value = await this.helper.partner.get_partners(true) ??[]
      
      const mainDealer = value.find(v => v.dealer_id == this.meDealerInfo.dealer_id)
      if(!mainDealer) value.unshift(this.meDealerInfo)
      
      value.forEach(dealer => {
        dealer['dealer_name'] = dealer?.external_dealer_id_with_company_name ?? 'No Name'
        dealer['ai_request'] = '0'
        dealer['ai_true_alarm'] = '0'
        dealer['ai_false_alarm'] = '0'
        dealer['ai_skipped_alarm'] = '0'
        dealer['video_events'] = '0'
      })
      this.originDealers = [...value];
      this.filteredDealers = [...value];
      
      this.doFilterDealer.emit({filteredDealers: this.filteredDealers, originDealers: this.originDealers})
      await this.checkLoadingPagedItemAndDelay() 
    } catch(err) {
      console.debug('load_partners :>',err)
      this.endForLoadData()
    }
  }

  async checkLoadingPagedItemAndDelay(){
    if (!this.pagedItemIsReady) {
      setTimeout(() => this.checkLoadingPagedItemAndDelay(), 500);
    } else {
      await this.load_dealer_dealers_cloudAI_data()
    }
  }
  async load_dealer_dealers_cloudAI_data(){
    this.isLoading = true
    this.initDealerStats()
    await this.load_dealer_stats_by_dealers_every_100_units()
    await this.load_dealer_CSDealers_cloudAI_events()
  }

  initDealerStats(){
    this.filteredDealers.forEach(dealer =>{
      dealer['ai_request'] = '0'
      dealer['ai_true_alarm'] = '0'
      dealer['ai_false_alarm'] = '0'
      dealer['ai_skipped_alarm'] = '0'
      dealer['video_events'] = '0'
    })
  }

  async load_dealer_stats_by_dealers_every_100_units(){
    try {
      const sub_dealer_ids = this.filteredDealers.map(v => v.dealer_id)
      let cutArrayForEvery100SubDealerIds = []
      for(let sliceIdx = 100; sliceIdx <= sub_dealer_ids.length + 100; sliceIdx += 100){
        let tempArray = sub_dealer_ids.slice(sliceIdx - 100, sliceIdx)
        cutArrayForEvery100SubDealerIds.push(tempArray)
      }

      for(let i = 0; i < cutArrayForEvery100SubDealerIds.length; i++){
        const sub_dealer_ids = cutArrayForEvery100SubDealerIds[i]
        await Promise.all([
          // this.load_dealer_skipped_stats(sub_dealer_ids),
          this.load_dealer_video_events_stats(sub_dealer_ids)
        ])
      }
    } catch(err) {
      this.endForLoadData()
      console.debug('load_dealer_stats_by_dealers_every_100_units :>>',err)
    }
  }
  async load_dealer_skipped_stats(sub_dealer_ids){
    if(!sub_dealer_ids?.length) return
    try {
      const selectedMonth = this.selectedMonth.value
      const stime = this.utcDate(selectedMonth).startOf('day').unix()
      let etime 
      if(this.isCurrentMonth()) {
        etime = this.utcDate().unix()
      } else {
        etime = this.utcDate(selectedMonth).endOf('month').endOf('day').unix()
      }

      const res = await this.helper.dealer.get_dealer_stats_by_dealers(stime, etime, sub_dealer_ids)
      this.CSDealerCloudAISkippedDataList = res
      this.parseCSDealerCloudAISkippedData()
    } catch(err) {
      console.debug('load_dealer_skipped_stats :>',err)
      this.endForLoadData()
    }
  }
  async load_dealer_video_events_stats(sub_dealer_ids){
    if(!sub_dealer_ids?.length) return
    try {
      const selectedMonth = this.selectedMonth.value
      const stime = this.utcDate(selectedMonth).startOf('day').unix()
      let etime 
      if(this.isCurrentMonth()) {
        etime = this.utcDate().unix()
      } else {
        etime = this.utcDate(selectedMonth).endOf('month').endOf('day').unix()
      }

      sub_dealer_ids?.length === 1
        ? await this.load_dealer_video_events_stats_only_one_dealer(stime, etime, sub_dealer_ids)
        : await this.load_dealer_video_events_stats_for_dealers(stime, etime, sub_dealer_ids)
    } catch(err) {
      console.debug('load_dealer_skipped_stats :>',err)
      this.endForLoadData()
    }
  }

  async load_dealer_video_events_stats_only_one_dealer(stime, etime, sub_dealer_id){
    const res = await this.helper.dealer.get_dealer_stats_by_dealer(stime, etime, sub_dealer_id, 2, 11)
    this.CSDealerVideoEventDataList = res
    this.parseCSDealerVideoEventsData()
  }
  async load_dealer_video_events_stats_for_dealers(stime, etime, sub_dealer_ids){
    const res = await this.helper.dealer.get_dealer_stats_by_dealers(stime, etime, sub_dealer_ids, 2, 11)
    this.CSDealerVideoEventDataList = res
    this.parseCSDealerVideoEventsData()
  }

  async load_dealer_CSDealers_cloudAI_events(){
    const month = this.selectedMonth.value 
    if(this.CSDealerCloudAIDataList?.length && !month) return this.endForLoadData()
    if(this.amIStandardPlan) return this.endForLoadData()
    try {
      const time = this.calcCurrentMonth(month)
      const res = await this.helper.dealer_cloud_ai.get_dealer_CSDealers_cloudAI_events(time)
  
      const result = res?.data ?? []
      this.CSDealerCloudAIDataList = result
      this.parseCSDealerCloudAIData()
    } catch(err){
      console.debug('load_dealer_CSDealers_cloudAI_events :>',err)
      this.endForLoadData()
    }
  }

  // ------------------------------------------------------------------------
  parseCSDealerCloudAISkippedData(){
    if(!this.filteredDealers?.length || !this.filteredDealers) return
    for(let i = 0 ; i < this.filteredDealers.length; i++){
      const dealer = this.filteredDealers[i]
      const dealerId = dealer?.dealer_id ?? ""
      if(!dealerId) continue
      const skippedData = this.CSDealerCloudAISkippedDataList[dealerId]?.videoai_skipped_count
      if(!skippedData) continue
      const skippedDataNumber = parseInt(skippedData)
      dealer['ai_skipped_alarm'] = this.formatNumbersWithComma(skippedDataNumber)
    }
  }
  parseCSDealerVideoEventsData(){
    if(!this.filteredDealers?.length || !this.filteredDealers) return
    for(let i = 0 ; i < this.filteredDealers.length; i++){
      const dealer = this.filteredDealers[i]
      const dealerId = dealer?.dealer_id ?? ""
      if(!dealerId) continue
      if(!this.CSDealerVideoEventDataList) return
      const videoEventsData = this.CSDealerVideoEventDataList[dealerId]?.video_event_count
      if(!videoEventsData) continue
      const videoEventsDataNumber = parseInt(videoEventsData)
      dealer['video_events'] = this.formatNumbersWithComma(videoEventsDataNumber)
    }
  }
  
  parseCSDealerCloudAIData(){
    if(!this.filteredDealers?.length || !this.filteredDealers) return
    for(let i = 0 ; i < this.filteredDealers.length; i++){
      const dealer = this.filteredDealers[i]
      const cloudAIData = this.CSDealerCloudAIDataList.filter(v => v.dealer_id == dealer.dealer_id)
      if(!cloudAIData.length) continue

      const request = cloudAIData.reduce((acc, curr) => acc += curr?.count, 0) ?? 0
      const truAlarm = cloudAIData.find(v => v?.ai_filter_matched)?.count ?? 0
      const falseAlarm = cloudAIData.find(v => !v?.ai_filter_matched)?.count ?? 0

      dealer['ai_request'] = this.formatNumbersWithComma(request)
      dealer['ai_true_alarm'] = this.formatNumbersWithComma(truAlarm)
      dealer['ai_false_alarm'] = this.formatNumbersWithComma(falseAlarm)
    }

    this.endForLoadData()
  }

  async endForLoadData(){
    this.doFilterDealer.emit({filteredDealers: this.filteredDealers, originDealers: this.originDealers})
    this.sumCloudAIEvents.emit()
    this.doneCallingAllData.emit('dealers')
    this.isLoading = false
  }
  
  // ------------------------------------------------------------------------

  sortTableByColumn(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.filteredDealers;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'company_name': 
          return this.compare(a.dealer_name.toLowerCase(), b.dealer_name.toLowerCase(), isAsc);
        case 'current_month_service_plan_type': 
          return this.compare(a.current_month_service_plan_type, b.current_month_service_plan_type, isAsc);
        case 'site_count': 
          return this.compare(a.site_count, b.site_count, isAsc);
        case 'is_videoai_enabled': 
          return this.compare(a.is_videoai_enabled, b.is_videoai_enabled, isAsc);
        case 'video_events': 
          return this.compare(this.formatNumberWithCommaToNumber(a.video_events), this.formatNumberWithCommaToNumber(b.video_events), isAsc);
        case 'ai_true_alarm': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_true_alarm), this.formatNumberWithCommaToNumber(b.ai_true_alarm), isAsc);
        case 'ai_false_alarm': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_false_alarm), this.formatNumberWithCommaToNumber(b.ai_false_alarm), isAsc);
        case 'ai_skipped_alarm': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_skipped_alarm), this.formatNumberWithCommaToNumber(b.ai_skipped_alarm), isAsc);
        case 'ai_request': 
          return this.compare(this.formatNumberWithCommaToNumber(a.ai_request), this.formatNumberWithCommaToNumber(b.ai_request), isAsc);
        default: return 0;
      }
    });
    this.filteredDealers = tmpData;
    this.doFilterDealer.emit({filteredDealers: tmpData});
  }

  parseServicePlanTypeToPlanName(type){
    switch(type){
      case(1) : return 'Standard'
      case(2) : return 'Professional'
      case(3) : return 'Premium'
      case(4) : return 'Reseller'
      default : return 'Standard'
    }
  }

  goToDealer(dealerId){
    this.helper.router.navigate_to(`/wcs-sub-dealers/info`, {retailDealerId: dealerId});
  }

  moveToDetail(dealer){
    this.moveToDealerDetail.emit(dealer)
    this.pagedItems.forEach(v => v.isShowMenu = false)
  }

  // ------------------------------------------------------------------------

  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById(id);
    let more = document.getElementById('more-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right - 10;
    const posY = rect.bottom + 7;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(site){
    const targetMenu = site.isShowMenu;
    site.isShowMenu = !targetMenu;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }

  formatNumberWithCommaToNumber(value){
    return parseInt(value.replace(/,/g, ""))
  }

  // month
  utcDate(date?){
    const target = date ? new Date(date) : new Date()
    return ims.moment(target).utc()
  }
  calcCurrentMonth(month?){
    const time = this.utcDate(month).startOf('month').format() 
    return time
  }
  isCurrentMonth(){
    const selectedMonth = this.selectedMonth.value
    const stime = this.utcDate(selectedMonth).startOf('day').month()
    const currentMonth = this.utcDate().month()
    return stime === currentMonth 
  }
}