import { Component, Inject, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Router } from '@angular/router';
import { FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SitesService } from "@app/services/sites.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { c_components } from "../../index";
import { Helper } from "../../../../4services/2helper";

@Component({
  templateUrl: "./c_dialog_share_incident_report_email_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_share_incident_report_email_component.scss"],
})
export class c_dialog_share_incident_report_email_component {
  @ViewChild('emailInput') emailInputRef: ElementRef;
  @ViewChild('contactSelect') contactSelect: ElementRef;
  // ------------------------------------------------------------------------
  emailControl = new FormControl('', [Validators.required, Validators.email, this.validateEmail()]);
  currentRoute: string;

  site: any;
  reportId: any = null;
  isLoading = false;

  contacts: any[] = [];
  filteredContacts: any[] = [];

  emailList:string[] = [];
  inputEmailErr = '';
  downloadEmails = [];
  isValidate = false;

  emailSubject = '[NOTICE] Incident Report'
  emailContents = ''

  constructor(
    public dialogRef: MatDialogRef<c_dialog_share_incident_report_email_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private c_components: c_components,
    private sitesService: SitesService,
    private helper: Helper,
    private router: Router, 
  ) {}

  ngOnInit() {
    this.reportId = this.data?.reportId
    this.initData()
    this.currentRoute = this.router.url;
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.contactSelect) {
      if (!this.contactSelect.nativeElement.contains(event.target)) {
        this.filteredContacts = [];
      }
    }
  }

  close_dialog(result: boolean): void {
    if(this.currentRoute.includes('event-viewer')) this.goToViewReportList()
    this.dialogRef.close(result);
  }

  goToViewReportList(){
    const site = this.site;
    let path = `/customers/report/incident-report`;
    this.helper.router.navigate_to(path, {id : site.site_id});
  }

  async initData() {
    this.isLoading = true;
    this.site = this.sitesService.selSite;
    this.emailList = [];
    this.inputEmailErr = '';
    this.downloadEmails = [];
    this.isValidate = false;
    await this.fetchSiteContact();
    
    this.isLoading = false;
  }

  async fetchSiteContact() {
    const dealerId = await this.helper.me.get_my_dealer_id()
    const siteId = this.site.site_id;

    const contacts = await this.sitesService.getSiteContacts(dealerId, siteId).toPromise();
    this.contacts = contacts.filter(v => v.email);
  }

  // ------------------------------------------------------------------------

  addEmail(): void {
    const isIncludes = this.emailList.find(v => v === this.emailControl.value)
    if(isIncludes) return

    if (this.emailControl.valid) {
      this.emailList.push(this.emailControl.value);
      this.emailControl.reset();
      this.emailControl.setValue('');
      this.filteredContacts = [];
    }
  }

  removeEmail(index: number): void {
    this.emailList.splice(index, 1);
  }

  onEmailInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if(!input.value || !input.value.trim()) {
      this.filteredContacts = []
      return
    }

    this.filteredContacts = this.contacts?.filter(contact => {
      const isIncludes = this.emailList.find(v => v === contact.email)
      if(isIncludes) return false;
      if(contact.name && typeof contact.name === 'string' ) return contact.name.includes(input.value)
      if(contact.email && typeof contact.email === 'string' ) return contact.email.includes(input.value)
      return false
    }) // 이메일만 필터링

    if(this.emailControl.value && this.emailControl.valid) {
      const obj = { email: input.value, isManual: true }
      this.filteredContacts.unshift(obj)
    }
  }

  selectContact(contact) {
    const isIncludes = this.emailList.find(v => v === contact.email)
    if(isIncludes) return

    this.emailList.push(contact.email);
    this.emailInputRef.nativeElement.focus();
    this.filteredContacts = [];
  }
  
  // ------------------------------------------------------------------------
  async shareReportByEmail() {
    this.isValidate = true
    if (!this.emailList.length) {
      this.openConfirmDialog("Email is required", "Please enter an email address.", "warning", "orange");
      return;
    }
    this.isLoading = true;

    const siteId = this.site.site_id;
    const data = {
      emails: this.emailList,
      subject: this.emailSubject,
      body: this.emailContents,
    };
    try {
      await this.helper.incident_reports.shared_incident_report(siteId, this.reportId, data);
      this.openConfirmDialog("Success", "Incident report shared successfully.", "done", "green");
    } catch (err) {
      this.openErrorDialog(err, "Share Incident Report");
    } finally {
      this.isLoading = false;
    }
  }

  // ------------------------------------------------------------------------

  validateEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;
      if(email?.length < 3 && email?.length !== 0) return { 'customEmailInvalid': true };  // 유효하지 않을 경우

      // eslint-disable-next-line
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      let result = reg.test(email)
      if (result) return null;  // 유효할 경우
      return { 'customEmailInvalid': true };  // 유효하지 않을 경우
    };
  }

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}