import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningModule } from '../warning/warning.module'
import { SharedModule } from '../../directives/shared.module'
import { PlanNotificationModule } from './plan-notification/plan-notification.module';
import { PlanPaymentModule } from './plan-payment/plan-payment.module';
import { PlanPrecautionsModule } from './plan-precautions/plan-precautions.module';
import { DealerPlanModalComponent } from './dealer-plan-modal.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    PlanNotificationModule,
    PlanPaymentModule,
    PlanPrecautionsModule
  ],
  declarations: [
    DealerPlanModalComponent
  ],
  exports: [
    DealerPlanModalComponent
  ]
})
export class DealerPlanModalModule { }
