import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {of as observableOf ,  Observable, BehaviorSubject } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class PaymentService {

  supportedCountryList = [];
  sepaAccount$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sepaAccount$w = this.sepaAccount$s.asObservable();

  constructor(
    private api: ApiService
  ) { }

  getStripe(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/payments/apps/stripe/customers`);
  }

  createStripe(dealer_id: number, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/payments/apps/stripe/customers`, data);
  }

  updateStripeInfo(dealerId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/payments/apps/stripe/customers`, data);
  }

  addStripe(dealer_id: number, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/payments/apps/stripe/customers/card`, data);
  }

  deleteStripeCard(dealer_id: number, cardToken): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/payments/apps/stripe/customers/cards/token/${cardToken}`);
  }

  paymentStripe(dealer_id: number, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/payments/stripe`, data, 'v1.2');
  }

  createPaymentByStripe(dealer_id: number, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/payments/stripe/web`, data, 'v1.2');
  }

  createPaymentByStripeV3(dealer_id: number, invoice_id: number): Observable<any>{
    return this.api.post(`/dealers/${dealer_id}/invoices/${invoice_id}/actions/pay`, null, 'v3')
  }

  updatePaymentByStripe(dealerId, data): Observable<any> {
    //  https://api.chektdev.com/invoice/v3/dealers/20771/payments/thirdparties/stripe/default-payment-method
    return this.api.put(`/dealers/${dealerId}/payments/thirdparties/stripe/default-payment-method`, data, 60 * 1000, 'v3');
  }

  getPaymentSources(dealer_id: number, data): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/payments/apps/stripe/customers/payment_sources`);
  }

  getStripeSupportedCountryList(dealer_id: number, refresh?): Observable<any> {
    const apiPath = `/dealers/${dealer_id}/payments/stripe/supported_country_specs`
    if (this.supportedCountryList.length !== 0 && !refresh) {
      return observableOf(this.supportedCountryList);
    }
    return this.api.get(apiPath).pipe(
      map(res => {
        this.supportedCountryList = res;
        return res;
      }));
  }

  /* ACH */
  createACH(dealer_id: number, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/payments/apps/stripe/customers/ach`, data);
  }

  verifyACH(dealer_id: number,bankAccountToken, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/payments/apps/stripe/customers/ach/${bankAccountToken}/verify`, data);
  }

  deleteACH(dealer_id: number,bankAccountToken): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/payments/apps/stripe/customers/ach/token/${bankAccountToken}`);
  }

  /* SEPA */
  getSEPA(dealer_id: number): Observable<any> {
    /**
     [
        {
          "payment_method_id": "pm_1NhosxBmMoF8Upk08FdcPqKx",
          "email": "testemail@test.com",
          "name": "testAccountHolder",
          "last_4": "3201",
          "status": "active"
        }
      ]
     */
    return this.api.get(`/dealers/${dealer_id}/payments/thirdparties/stripe/payment-methods/sepa-debits`, null, 'v3').map(res =>{
      res.forEach(v => v['last4'] = v?.last_4)
      this.sepaAccount$s.next(res)
      return res
    })
  }

  createSEPA(dealer_id: number, data): Observable<any> {
    /** body
      {
        "iban": "DE89370400440532013000",
        "account_holder_name": "Chas Park" 
        "account_holder_email": "chasp@chekt.com",
      }
     */
    return this.api.post(`/dealers/${dealer_id}/payments/thirdparties/stripe/payment-methods/sepa-debits`, data, 'v3');
  }

  deleteSEPA(dealer_id: number,sepaAccountToken): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/payments/thirdparties/stripe/payment-methods/${sepaAccountToken}`, 'v3');
  }
}
