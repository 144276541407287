import { Buffer } from 'buffer';

export default {
  decodeJWTToken: function (token: string) {
    if (typeof token !== 'string') throw new Error('invalid token');
    const m = token.split('.');
    if (m.length !== 3) throw new Error('misformed token');
    const decoded = JSON.parse(Buffer.from(m[1], 'base64').toString('utf-8'));
    if (!(decoded instanceof Object)) throw new Error('failed parsing');
    return decoded;
  },
};
