import { Component, Inject, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import * as _ from 'lodash';
import { c_components } from "../../index";
import { Helper } from "../../../../4services/2helper";

import { CommonService } from "../../../../app/services/common.service";
import isIpPrivate from 'private-ip';

@Component({
  templateUrl: './c_dialog_dealer_member_add_user_component.pug',
  styleUrls: ['../common.scss', './c_dialog_dealer_member_add_user_component.scss']
})
export class c_dialog_dealer_member_add_user_component {
  @Output() onCloseDialogRecallData: EventEmitter<any> = new EventEmitter();

  am_i_division_user = false;
  roles: any = [];
  divisionRoles: any = [];
  accesses: any = [];
  divisions: any = []
  hasPremiumOrResellerPlan = false;

  data = {
    email: '',
    err_email: '',
    role_id: null,
    access_id: null, 
    division_id: null,
    is_division_user: null,

    ip: '',
    err_ip: '',
    username: '',
    err_username: '',
    msg: '',
    err: '',
    err_invite: '',
  }

  userType = 'user'
  publicIp: String;

  constructor(
    public commonService: CommonService,
    public dialogRef: MatDialogRef<c_dialog_dealer_member_add_user_component>,
    @Inject(MAT_DIALOG_DATA)
    private c_components: c_components,
    private helper: Helper
  ) { }

  private dealer_role$w: Subscription;
  private dealer_access$w: Subscription;
  private my_divisions$w: Subscription;
  private dealer_plan$w: Subscription;
  watch() {
    this.dealer_role$w = this.helper.dealer_role.roles$w().subscribe((v) => this.on_dealer_role_change(v));
    this.dealer_access$w = this.helper.dealer_access.accesses$w().subscribe((v) => this.on_dealer_access_change(v));
    this.my_divisions$w = this.helper.dealer_division.my_divisions$w().subscribe((v) => this.on_dealer_my_division_change(v));
    this.dealer_plan$w = this.helper.dealer_plan.dealer_plan$w().subscribe((v) => this.on_dealer_plan_change(v));
  }
  unwatch() {
    this.dealer_role$w?.unsubscribe();
    this.dealer_access$w?.unsubscribe();
    this.my_divisions$w?.unsubscribe();
    this.dealer_plan$w?.unsubscribe();
  }

  async ngOnInit() {
    console.log('init')
    await this.initData();

    await Promise.all([
      this.get_me(),
      this.load_accesses(),
      this.load_roles(),
      this.load_division()
    ])
    this.watch();
  }

  async ngOnChanges(changes) {
    console.log('change')
    this.initData();
  }

  ngOnDestroy() {
    this.unwatch();
  }

  // -----------------------------------------------------------------

  async get_me(){
    const me = await this.helper.me.get_me()
    this.am_i_division_user = me?.is_division_user ?? false
  }

  async load_accesses() {
    await this.helper.dealer_access.load_accesses()
  }
  async load_roles() {
    await this.helper.dealer_role.load_roles()
  }
  async load_division() {
    await this.helper.dealer_division.load_my_divisions()
  }

  on_dealer_role_change(value: any){
    if(!value) return
    this.roles = value.filter(role => role.role_type != 1 && !role.is_division_role);
    this.divisionRoles = value.filter(role => role.role_type != 1 && role.is_division_role);
    if (this.roles.length > 0) this.data['role_id'] = this.roles[0].role_id;
    if (this.am_i_division_user && this.divisionRoles.length > 0) this.data['role_id'] = this.divisionRoles[0].role_id;
  }
  on_dealer_access_change(value : any){
    if(!value) return
    this.accesses = value;
    if(this.accesses.length > 0) this.data['access_id'] = this.accesses[0].access_id;
  }
  on_dealer_my_division_change(value : any){
    if(!value) return
    this.divisions = value;
    if(this.divisions.length > 0) this.data['division_id'] = this.divisions[0].id;
  }

  on_dealer_plan_change(value: any){
    if(!value) return
    this.hasPremiumOrResellerPlan = value?.current_month_service_plan_type > 2
  }

  // ------------------------------------------------------------------

  async initData() {
    this.userType = 'user';
    this.publicIp = '';
    this.data = {
      email: '',
      err_email: '',
      role_id: null,
      access_id: null, 
      division_id: null,
      is_division_user: null,
  
      ip: '',
      err_ip: '',
      username: '',
      err_username: '',
      msg: '',
      err: '',
      err_invite: '',
    }
    const res = await this.helper.dealer_member.getMyIp()
    this.publicIp = res?.ip;
  }

  close_dialog(): void {
    if (this.roles.length > 0) this.data['role_id'] = this.roles[0].role_id;
    if (this.accesses.length > 0) this.data['access_id'] = this.accesses[0].access_id;
    if (this.divisions.length > 0) this.data['division_id'] = this.divisions[0].id;
    this.dialogRef.close();
  }

  // ------------------------------------------------------------------
  
  create() {
    if (this.userType === 'user') return this.createUser();
    if (this.userType === 'ip_user') return this.createIpUser();
  }

  async createUser() {
    this.data['err_email'] = '';
    this.data['err_invite'] = '';
    this.data['err'] = '';
    if (!this.commonService.checkEmail(this.data['email'])) {
      this.data['err_email'] = 'Email is invalid';
      return;
    }
    if(Object.keys(this.data).includes('is_division_user')) {
      this.data['is_division_user'] = this.data['is_division_user'] ? 1 : 0
      if(this.am_i_division_user) this.data['is_division_user'] = 1 // division 유저는 division 유저만 생성
    }

    try {
      await this.createMember()
      await this.inviteMember()
      this.commonService.showSuccessToast(`Create User - ${this.data['email']}`, 'Success')
      this.onCloseDialogRecallData.emit()
      this.close_dialog();
    } catch(err) {
      if (err.status == 409) {
        this.data['err'] = 'This user is already invited';
        return;
      }
      err.code == 'ER_DUP_ENTRY' 
        ? this.data['err'] = 'This user is already invited by other dealers'
        : this.data['err'] = 'Unexpected Error';
      this.openErrorDialog(err,'Create User')
    }
  }

  async createIpUser() {
    this.data['err_ip'] = '';
    this.data['err_username'] = '';

    if (!this.data['ip']) {
      this.data['err_ip'] = 'Invalid IP address';
      return
    }

    if (isIpPrivate(this.data['ip'])) {
      this.data['err_ip'] = 'This IP address is private IP address';
      return;
    }

    if (!this.data['username']) {
      this.data['err_username'] = 'Please input username';
      return;
    } 
    if (this.data['username'].length < 3) {
      this.data['err_username'] = 'user name must be at least 3 characters.';
      return;
    }

    try {
      await this.createIPMember()
      this.commonService.showSuccessToast('Create IP User', 'Success')
      this.openConfirmDialog('Create IP User', 'Create IP user - success.');
      this.onCloseDialogRecallData.emit()
    } catch(err) {
      this.openErrorDialog(err,'Create IP User');
    }
  }

  async createMember(){
    const res = await this.helper.dealer_member.create_member(this.data)
    this.data['msg'] = `Sending invite mail to ${this.data['email']} ...`;
    this.data['user_id'] = res.user_id;
    if(this.data['is_division_user']) this.assignDivision(res.user_id)
  }

  async createIPMember(){
    const res = await this.helper.dealer_member.create_ip_member(this.data)
    this.data['user_id'] = res.user_id;
  }

  async inviteMember(){
    const userId = this.data['user_id']
    await this.helper.dealer_member.invite_member(userId)
  }

  async assignDivision(userId){
    console.debug(this.data?.division_id, userId)
    const divisionId = this.data?.division_id
    if(!divisionId) return

    const user = [userId]
    if(!user) return
    await this.helper.dealer_division.add_users_to_division(divisionId, user)
  }

  async reinvite() {
    this.data['msg'] = `Resending invite mail to ${this.data['email']}`;
    try {
      await this.helper.dealer_member.invite_member(this.data['user_id'])
      this.close_dialog();
    } catch(err){
      this.data['msg'] = '';
      this.data['err_invite'] = 'Sending invite mail is failed';
      this.openErrorDialog(err, 'Resend User Invite');
    }
  }

  checkDivisionPermissionGroupRequired(){
    if(
      this.hasPremiumOrResellerPlan && 
      (this.am_i_division_user || this.data?.is_division_user)
    ) return true
    return false
  }

  setIp(ip){
    this.data['ip'] = ip;
  }

  onChangeUserType(e) {
    this.data['err_email'] = '';
    this.data['err_invite'] = '';
    this.data['err'] = '';
  }

  onChangeIsDivisionUser(e){
    const isCheckedDivision = e.checked
    if (isCheckedDivision) {
      this.data['role_id'] = this.divisionRoles[0].role_id;
      return 
    }
    if (!isCheckedDivision) {
      this.data['role_id'] = this.roles[0].role_id;
      return 
    }
  }

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: 'OK',
      submit_class: ['button-primary'],
      icon : icon,
      isConfirm : true,
      color: color
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}
