import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import { Permission } from '../permission'
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer_access {
  constructor(private ims: Imports, private permission: Permission) {}

  public accesses$w(): Observable<any> {
    return this.ims.accessesService.accesses$w;
  }

  public async get_accesses(): Promise<Array<any>> | undefined {
    await this.load_accesses();
    return this.ims.accessesService.accesses$s.getValue();
  }

  public async load_accesses(): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasAccessReadPermission = this.permission.has("dealer_ipfilter_read")
      if(!hasAccessReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.accessesService.getAccesses(dealer_id).toPromise();
      return res[0];
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get Access List` });
    }
  }

  public async create_access(data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.accessesService.create(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Access (${data.name})` });
      return false;
    }
  }

  public async update_access(access_id: number, data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.accessesService.update(dealer_id, access_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Access(Id: ${access_id})` });
      return false;
    }
  }

  public async delete_role(access_id: number): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.accessesService.delete(dealer_id, access_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Access(Id: ${access_id})` });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
