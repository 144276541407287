import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {  Observable, Subject } from 'rxjs';
// import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class VerificationService {
  constructor(private api: ApiService) { }

  updateVerificationService(dealerId, data, content_type?): Observable<any>{
    return this.api.put(`/dealers/${dealerId}/verification`, data);
  }
}
