import { Injectable } from "@angular/core";
import { Imports } from "../imports";

@Injectable()
export class Permission {
  constructor(private ims: Imports) {}

  public has(resource: string): boolean {
    if (this.i_have_admin_permission()) return true;
    return !!this.get_permission(resource);
  }

  public has_payment_permission(): boolean {
    if (this.ims.usersService.isTemporaryLogin) return false;
    if (this.i_have_admin_permission()) return true;
    if (!this.has("dealer_read")) return false;
    if (!this.has("dealer_site_read")) return false;
    if (!this.has("dealer_payment_read")) return false;
    if (!this.has("dealer_invoice_read")) return false;
    if (!this.has("dealer_invoice_plan_read")) return false;
    return true;
  }

  public has_integration_permission(): boolean {
    if (this.i_have_admin_permission()) return true;
    if (!this.has("dealer_station_create")) return false;
    if (!this.has("dealer_integration_create")) return false;
    return true;
  }

  public i_have_admin_permission(): boolean {
    return !!this.get_permission("all");
  }

  public async load_my_permissions_only_if_not_loaded(): Promise<Array<permissionObject>> | undefined {
    if (this.my_permissions_already_loaded()) return;

    await this.load_my_dealer_only_if_not_loaded();
    const dealer_id = this.ims.usersService.me.dealer_id;
    if (!dealer_id) return;

    const res = await this.ims.permissionsService.getPermissions(dealer_id).toPromise();
    return res;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private get_permission(resource: string): permissionObject | undefined {
    return this.my_permissions().find((v: permissionObject) => v?.resource === resource);
  }

  private my_permissions(): Array<permissionObject> {
    return this.ims.permissionsService.permissions || [];
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }

  private my_permissions_already_loaded(): boolean {
    return !!this.ims.permissionsService.permissions;
  }
}

interface permissionObject {
  group_id: string;
  name: string;
  resource: string;
  type: number;
}
