import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';

@Component({
  selector: 'log_box_default_template',
  templateUrl: './default_template.component.pug',
  styleUrls: ['../../../common.scss','../../c_card_event_viewer_time_line.component.scss'],
})
export class log_box_default_template_component {
  @Input() log;
  
  snapshotPermission = false;
  logIcon = { type: '', name: ''}
  isBrokenImage = false

  procedureName = ''

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService,
  ) {}

  private snapshotPermission$w: Subscription;
  watch() {
    this.snapshotPermission$w = this.eventViewerService.snapshotPermission$w.subscribe((v) => this.snapshotPermission = v);
  }
  unwatch() {
    this.snapshotPermission$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.setIconName()
    this.setProcedureName()
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  // -------------------------------------------------------
  // STYLE
  capitalizeFirstLetters(str) {
    if(!str) return ''
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  isAutomationRule(){
    const description = this.log.description.toLowerCase()
    const isAutomationRule = description.includes('automation') || this.log.isAutomation
    return isAutomationRule
  }
  
  computedIconColor(log){
    if(log?.isAutomation) return '#FFA07A'
    return '#8B8B8B'
  }
  computedIconSize(){
    if(this.logIcon.name === 'metabolism') return '40'
    if(this.logIcon.name === 'http') return '40'
    return '30'
  }

  setIconName(){
    this.setSensorIcon()
    if(!this.logIcon.name) this.setBridgeIcon()
    if(!this.logIcon.name) this.setOtherCategory()
  }
  setOtherCategory(){
    const category = this.log.category.toLowerCase()
    switch(category){
      case('alarm'): return this.logIcon = { type: 'material', name: 'notifications_active'}
      // case('end user live view'): return this.logIcon = { type: 'material', name: 'remember_me'}
      case('guard tour'): return this.logIcon = { type: 'comp', name: 'metabolism'}
      case('live video'): return this.logIcon = { type: 'material', name: 'smart_display'}
      case('monitoring portal'): return this.logIcon = { type: 'material', name: 'live_tv'}
      case('boot'): return this.logIcon = { type: 'material', name: 'power_settings_new'}
      // case('periodic test'): return this.logIcon = { type: 'material', name: 'manage_history'}
      // case('playback event'): return this.logIcon = { type: 'material', name: 'replay'}
      case('privacy mode access'): return this.logIcon = { type: 'material', name: 'passkey'}
      case('share download link'): return this.logIcon = { type: 'material', name: 'download'}
      case('share response link'): return this.logIcon = { type: 'material', name: 'reply'}
      case('site procedure action'): return this.logIcon = { type: 'material', name: 'interests'}
      case('site procedure http request'): return this.logIcon = { type: 'material', name: 'http'}
      case('trouble'): return this.logIcon = { type: 'material', name: 'warning'}
      // case('timelapse event'): return this.logIcon = { type: 'material', name: 'timelapse'}
      // case('walk test'): return this.logIcon = { type: 'material', name: 'directions_walk'}
      default : return this.logIcon = { type: '', name: ''}
    }
  }

  setSensorIcon(){
    const category = this.log.category.toLowerCase()
    const trigger = this.log.trigger.toLowerCase()

    if(category != 'sensor') return { type: '', name: ''}
    if(trigger.includes('di')) return this.logIcon = { type: 'material', name: 'settings_input_component'}
    if(trigger.includes('oe')) return this.logIcon = { type: 'material', name: 'psychology'}
    if(trigger.includes('le')) return this.logIcon = { type: 'comp', name: 'bridge'}
    if(trigger.includes('local')) return this.logIcon = { type: 'comp', name: 'bridge'}
    if(trigger.includes('camera')) return this.logIcon = { type: 'comp', name: 'bypass'}
  }
  setBridgeIcon(){
    const category = this.log.category.toLowerCase()
    const description = this.log.description.toLowerCase()

    if(category != 'bridge') return { type: '', name: ''}
    if(description.includes('automation')) return this.logIcon = { type: 'comp', name: 'autopay'}
    return this.logIcon = { type: 'comp', name: 'bridge'}
  }

  setProcedureName(){
    if(this.log.trigger.toLowerCase() != 'procedure') return
    const meta = JSON.parse(this.log.meta)
    const additionalInfo = meta?.additionalInfo
    
    additionalInfo
      ? this.procedureName = additionalInfo?.procedure_name
      : this.procedureName = meta?.info?.procedure_name
  }v
}
