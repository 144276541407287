
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';





@Injectable()
export class IntegrationService {

  me: any;

  cidCodes = [];
  eventTypes = [];

  constructor(private api: ApiService) { }

  public getAll(dealer_id: number, partnerId?): Observable<any> {
    if ( partnerId) {
      return this.api.get(`/dealers/${dealer_id}/event_adapters?partner=${partnerId}`);
    } else {
      return this.api.get(`/dealers/${dealer_id}/event_adapters`);
    }
  }

  public getSharedReceivers(dealer_id: number, partnerId?): Observable<any> {
    if ( partnerId) {
      return this.api.get(`/dealers/${dealer_id}/shared_receivers?partner=${partnerId}`);
    } else {
      return this.api.get(`/dealers/${dealer_id}/shared_receivers`);
    }
  }

  public getCids(dealerId: number): Observable<any> {
    if (this.cidCodes.length !== 0) {
      return observableOf(this.cidCodes);
    }
    return this.api.get(`/dealers/${dealerId}/event_codes/cid`).pipe(map(res => {
      this.cidCodes = res;
      localStorage.setItem('cidCodes', JSON.stringify(this.cidCodes));
      return this.cidCodes;
    }));
  }

  //microkey
  public getMicroKey(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}`);
  }

  public getMicroKeyLogs(dealer_id, adapterId, offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}/logs?sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  public getDealerMicroKeyLogs(dealer_id,filter='', offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/microkeys/all/logs?${filter}&sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  public getMicroKeySites(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}/sites`);
  }

  public createMicroKey(dealer_id, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/microkeys`, data);
  }

  public updateMicroKey(dealer_id, adapterId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}`, data);
  }

  public deleteMicroKey(dealer_id, adapterId): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}`);
  }

  public testMicroKey(dealer_id, microKeyId, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/microkeys/${microKeyId}/test`, data);
  }

  public statusMicrokey(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}/status`);
  }

  public getMicrokeyCode(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}/codes`);
  }

  public updateMicrokeyCode(dealer_id, adapterId, codeId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/microkeys/${adapterId}/codes/${codeId}`, data);
  }


  //bold
  public getBold(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}`);
  }

  public getBoldLogs(dealer_id, adapterId, offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}/logs?sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  public getDealerBoldLogs(dealer_id, filter='', offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/bolds/all/logs?${filter}&sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  public getBoldSites(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}/sites`);
  }

  public createBold(dealer_id, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/bolds`, data);
  }

  public updateBold(dealer_id, adapterId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}`, data);
  }

  public deleteBold(dealer_id, adapterId): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}`);
  }

  public testBold(dealer_id, boldId, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/bolds/${boldId}/test`, data);
  }

  public statusBold(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}/status`);
  }

  public getBoldCode(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}/codes`);
  }

  public updateBoldCode(dealer_id, adapterId, codeId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/bolds/${adapterId}/codes/${codeId}`, data);
  }

  //stages
  getStages(dealerId, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/event_adapters/stages/${adapterId}`);
  }

  getStagesLogs(dealer_id, adapterId, offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/stages/${adapterId}/logs?sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  getDealerStagesLogs(dealer_id, filter='', offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/stages/all/logs?${filter}&sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  createStages(dealer_id, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/stages`, data);
  }

  updateStages(dealer_id, adapterId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/stages/${adapterId}`, data);
  }

  deleteStages(dealer_id, adapterId): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_adapters/stages/${adapterId}`);
  }

  testStages(dealer_id, boldId, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/stages/${boldId}/test`, data);
  }

  statusStages(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/stages/${adapterId}/status`);
  }

  getStagesCode(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/stages/${adapterId}/codes`);
  }

  updateStagesCode(dealer_id, adapterId, codeId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/stages/${adapterId}/codes/${codeId}`, data);
  }

  //Dice
  public getDice(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}`);
  }

  public getDiceLogs(dealer_id, adapterId, offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}/logs?sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  public getDealerDiceLogs(dealer_id, filter='', offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/dices/all/logs?${filter}&sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  public getDiceSites(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}/sites`);
  }

  public createDice(dealer_id, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/dices`, data);
  }

  public updateDice(dealer_id, adapterId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}`, data);
  }

  public deleteDice(dealer_id, adapterId): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}`);
  }

  public testDice(dealer_id, boldId, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/dices/${boldId}/test`, data);
  }

  public statusDice(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}/status`);
  }

  public getDiceCode(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}/codes`);
  }

  public updateDiceCode(dealer_id, adapterId, codeId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/dices/${adapterId}/codes/${codeId}`, data);
  }

  //eventformat
  createEventFormat(dealer_id, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_formats`, data);
  }

  public getEventFormats(dealerId: number, partnerId?): Observable<any> {
    if (partnerId) {
      return this.api.get(`/dealers/${dealerId}/event_formats?partner=${partnerId}`);
    } else {
      return this.api.get(`/dealers/${dealerId}/event_formats`);
    }
  }

  getEventFormat(dealerId: number, formatId?): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/event_formats/${formatId}`);
  }

  getEventFormatSites(dealerId: number, eventFormatId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/event_formats/${eventFormatId}/sites`);
  }

  updateEventFormat(dealer_id, eventFormatId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_formats/${eventFormatId}`, data);
  }

  deleteEventFormat(dealer_id, eventFormatId): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_formats/${eventFormatId}`);
  }

  //event format code
  getEventFormatCode(dealer_id, eventFormatId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_formats/${eventFormatId}/codes`);
  }

  updateEventFormatCode(dealer_id, eventFormatId, codeId, data): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_formats/${eventFormatId}/codes/${codeId}`, data);
  }

  syncEventFormatCode(dealerId, eventFormatId) {
    return this.api.put(`/dealers/${dealerId}/event_formats/${eventFormatId}/syncsite`, null);
  }

  //monitoring station
  getMonitoringStations(dealerId, partnerId?): Observable<any> {
    if (partnerId) {
      return this.api.get(`/dealers/${dealerId}/stations?partner=${partnerId}`);
    } else {
      return this.api.get(`/dealers/${dealerId}/stations`);
    }
  }
  getMonitoringStation(dealerId, msId, partnerId?): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/stations/${msId}`);
  }
  createMonitoringStation(dealerId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/stations`, data);
  }
  updateMonitoringStation(dealerId, stationId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/stations/${stationId}`, data);
  }
  deleteMonitoringStation(dealerId, stationId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/stations/${stationId}`);
  }

  getStationSites(dealerId,stationId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/stations/${stationId}/sites`);
  }

  //site alrm library
  getDefaultEventCodes(dealerId): Observable<any> {
    if (this.eventTypes.length !== 0) {
      return observableOf(this.eventTypes);
    }
    return this.api.get(`/dealers/${dealerId}/default_event_codes`).pipe(map(res => {
      this.eventTypes = res;
      
      // Intercom Self Zone은 지금은 사용하지 않음
      const intercomSelf = this.eventTypes.findIndex(zoneType => zoneType.zone_type === 20)
      if(intercomSelf >= 0) this.eventTypes.splice(intercomSelf, 1) 

      /**
       * 
        [0] --> Entry/Exit Delay Zone
        [1] --> Follower Zone
        [2] --> Instant Zone
        [3] --> 24-Hour Zone
        [4] --> Exterior Zone (Instant)
        [5] --> Exterior Zone (Entry/Exit)
        [21] --> intercom_armed zone: Monitored only when armed (by CS) zone (not supported now)
        [22] --> intercom_24h zone: Monitored all day (by CS) including armed, disarmed (not supported now)
        [100] --> live_video_only zone: it doesn't create events at all. Just to view live. It is useful when camera's OE is mal-functioning.
       */

      this.eventTypes.forEach(type => {
        type.description = '';
        if(type.zone_type === 0) type.description = `This zone has an Entry/Exit Delay on the trigger. When Exiting, the Bridge will ignore any triggers on this zone for the "Exit Timer" value from the time of arming. When Entering, the Bridge will ignore any triggers on this zone for the "Entry Timer" value.  If the Bridge is not disarmed within the time period, the initial alarm signal and event video will be uploaded.`
        if(type.zone_type === 1) type.description = `A follower zone will behave like an Entry/Exit zone if an Entry/Exit zone has been triggered first.  If an Entry/Exit zone is not triggered first, this zone behaves like an Instant zone.`
        if(type.zone_type === 2) type.description = `When the Bridge is armed, triggers on this zone create an alarm and video event.`
        if(type.zone_type === 3) type.description = 'This zone creates an alarm and video event when the Bridge is Armed and Disarmed.'
        if(type.zone_type === 4) type.description = 'Same Behavior as the Instant Zone described above, but with an independent alarm code for specifying custom automation response for outdoor detectors (E136)'
        if(type.zone_type === 5) type.description = 'Same Behavior as the Entry/Exit zone described above, but with an independent alarm code for specifying custom automation response for outdoor detectors (E134)'

        if(type.zone_type === 21) type.description = 'When selected, any events from this zone type will send a remote intercom notification to the customer and, if enabled, a signal to the monitoring station. As an Armed Zone, events are only sent when the system is armed. If the central station signal is enabled, the signal is sent concurrently with the notification to the customer.'
        if(type.zone_type === 22) type.description = 'When selected, any events from this zone type will send a remote intercom notification to the customer and, if enabled, a signal to the monitoring station. As a 24-hour zone, events are sent when the system is armed and disarmed. If the central station signal is enabled, the signal is sent concurrently with the notification to the customer.'
        if(type.zone_type === 100) type.description = "This zone type is used for cameras that should not create any events. When selected, all alarm triggers from sensors, analytics, and APIs alarms will be ignored."
      })
      localStorage.setItem('eventTypes', JSON.stringify(this.eventTypes));
      return this.eventTypes;
    }));
  }

  //receivers
  createReceiver(dealerId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/receivers`, data);
  }

  getAllReceiver(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers`);
  }

  getReceiver(dealerId, receiverId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers/${receiverId}`);
  }

  updateReceiver(dealerId, receiverId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/receivers/${receiverId}`, data);
  }

  deleteReceiver(dealerId, receiverId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/receivers/${receiverId}`);
  }

  getReceiverStations(dealerId,receiverId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers/${receiverId}/types`);
  }

  getReceiverState(dealerId,receiverId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers/${receiverId}/state`);
  }

  testReceiver(dealerId,receiverId,data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/receivers/${receiverId}/test`, data);
  }

  getAllReceiverType(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers/types`);
  }

  getReceiverLogs(dealerId, receiverId, offset = 0, limit = 100): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers/${receiverId}/logs?sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }

  getAllReceiverLogs(dealerId, filter='', id = null, limit = 100): Observable<any> {
    if (id) {
      return this.api.get(`/dealers/${dealerId}/receivers/all/logs?${filter}&filter=receiver_log.id,${id},lt&sort=receiver_log.id&dir=desc&offset=0&limit=${limit}`);
    } else {
      return this.api.get(`/dealers/${dealerId}/receivers/all/logs?${filter}&sort=receiver_log.id&dir=desc&offset=0&limit=${limit}`);
    }
  }

  getAllHeartbeatReceiverLogs(dealerId, filter='', id=null, limit=100): Observable<any> {
    if (id) {
      return this.api.get(`/dealers/${dealerId}/receivers/all/heartbeats?${filter}&filter=receiver_heartbeat_log.id,${id},lt&sort=receiver_heartbeat_log.id&dir=desc&offset=0&limit=${limit}`);
    } else {
      return this.api.get(`/dealers/${dealerId}/receivers/all/heartbeats?${filter}&sort=receiver_heartbeat_log.id&dir=desc&offset=0&limit=${limit}`);
    }
  }

  getReceiverLog(dealerId, id=null): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers/all/logs?filter=receiver_log.id,${id},eq`); 
  }

  getTypes(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/receiver/types?sort=name&dir=asc`);
  }

  //timezone  /api/v1.1/dealers/:dealer_id/receiver/timezones
  getTimeZones(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/receiver/timezones`);
  }

  //servers
  createReceiverServer(dealerId, receiverId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/receivers/${receiverId}/servers`, data);
  }
  getReceiverServers(dealerId, receiverId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/receivers/${receiverId}/servers?sync=1`);
  }
  updateReceiverServer(dealerId, receiverId, serverId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/receivers/${receiverId}/servers/${serverId}`, data);
  }
  deleteReceiverServer(dealerId, receiverId, serverId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/receivers/${receiverId}/servers/${serverId}`);
  }
}
