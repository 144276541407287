import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

import { Observable } from "rxjs";

@Injectable()
export class RolesService {
  roles: any;
  roles$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  roles$w = this.roles$s.asObservable();
  permissions: any;

  selRoles = <any>{};

  constructor(private api: ApiService) {}

  public getRoles(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/roles`).pipe(
      map((res) => {
        this.roles = res;
        this.roles$s.next(res);
        return this.roles;
      })
    );
  }

  public getRole(dealer_id: number, RoleId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/roles/${RoleId}`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getRolePermissions(dealer_id: number, role_id: number, isGroup?): Observable<any> {
    let url = `/dealers/${dealer_id}/roles/${role_id}/permissions`;
    if (isGroup) {
      url += `?group=${isGroup}`;
    }
    return this.api.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getPermissions(dealer_id: number): Observable<any> {
    if (this.permissions) return this.permissions;

    return this.api.get(`/dealers/${dealer_id}/permissions`).pipe(
      map((res) => {
        this.permissions = res;
        return res;
      })
    );
  }

  public createRole(dealerId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/roles/`, data);
  }

  public editRole(dealerId, roleId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/roles/${roleId}`, data);
  }

  public deleteRole(dealerId, roleId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/roles/${roleId}`);
  }

  public createRolePermissions(dealerId, roleId, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/roles/${roleId}/permissions`, data);
  }

  public deleteRolePermissions(dealerId, roleId, permissionId): Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/roles/${roleId}/permissions/${permissionId}`);
  }

  public setRolePermissions(dealerId, roleId, data): Observable<any> {
    return this.api.put(`/dealers/${dealerId}/roles/${roleId}/permissions/set`, data);
  }
}
