import { Component, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '@app/services/common.service';

@Component({
  selector: 'c_side_modal_component',
  templateUrl: './c_side_modal.component.pug',
  styleUrls: ['./common.scss','./c_side_modal.component.scss'],
})
export class c_side_modal_component {
  @ViewChild('imageRef') imageRef: ElementRef<HTMLImageElement>;

  private resizeObserver: ResizeObserver;
  
  isOpen = false;
  page = 'list';
  selectedContent = 'site-note';

  imgSrc = ''
  imgWidth = 730;
  imgHeight = 386;
  imgTop = 0;
  imgLeft = 0;
  isShowPosition = false;

  // 나중에는 여기를 props로 받아서 재사용할 수도 있겠지
  pageTitle = 'F&A Section'
  pageList = [
    { name: 'What is site notes?', pageName: 'site-note', isFold: true, subPages: [] },
    { name: 'Is there a way to manage Notes all at once?', pageName: 'direction-common', isFold: true, subPages: [] },
  ]

  ngAfterViewInit(): void {
    const backgroundEl = document.querySelector('.side-modal-background') as HTMLElement;
    if(backgroundEl) {
      backgroundEl.style.top = '40px';
      backgroundEl.style.height = 'calc(100% - 41px)';
    }

    const sideModalEl = document.querySelector('.side-modal') as HTMLElement;
    if(sideModalEl) {
      sideModalEl.style.top = '40px';
      sideModalEl.style.height = 'calc(100% - 41px)';
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  constructor(public commonService: CommonService) { }
  
  init(){
    this.page = 'list';
    this.selectedContent = 'site-note';
    this.pageTitle = 'F&A Section'
  }

  toggleModal() {
    this.isOpen = !this.isOpen;
    if(!this.isOpen) this.init();
  }

  onClickPage(e, page){
    page.pageName
      ? this.goToContent(e, page.pageName)
      : page.isFold = !page.isFold;
  }

  goToContent(e, content: string) {
    e && e.preventDefault();
    if(!content) return;
    this.page = 'contents'
    this.selectedContent = content;
    this.setPageTitle()
    this.setImgSrc()
  }
  
  changePage(page: string = 'list') {
    this.page = page;
  }
  
  setPageTitle() {
    switch(this.selectedContent) {
      case('site-note'): return this.pageTitle = 'Site Notes';
      case('direction-define'): return this.pageTitle = 'Monitoring Portal Directions & Procedures';
      case('direction-features'): return this.pageTitle = 'Monitoring Portal Directions & Procedures';
      case('direction-procedures'): return this.pageTitle = 'Monitoring Portal Directions & Procedures';
      case('approval-process'): return this.pageTitle = 'Monitoring Portal Directions & Procedures';
      case('direction-common'): return this.pageTitle = 'Common';
    }
  }

  // SET IMAGE
  setImgSrc(){
    switch(this.selectedContent) {
      case('site-note'): return this.imgSrc = `/assets/imgs/side-modal/site_note_preview.png`;
      case('direction-define'): return this.imgSrc = ``;
      case('direction-features'): return this.imgSrc = `/assets/imgs/side-modal/direction_example.png`;
      case('direction-procedures'): return this.imgSrc = `/assets/imgs/side-modal/procedure_example.png`;
      case('approval-process'): return this.imgSrc = ``;
      case('direction-common'): return this.imgSrc = ``;
      default: return this.imgSrc = ``;
    }
  }

  setLoadEvent(){
    this.resizeObserver = new ResizeObserver(entries => this.onImageResize(entries));
    this.resizeObserver.observe(this.imageRef.nativeElement);
  }

  showPosition(type){
    this.isShowPosition = true;

    if(type === 'direction-tags') {
      const { x, y, h, w } = this.setDirectionTagAreaPosition(this.imgWidth, this.imgHeight);
      this.updateOverlay(x, y, w, h);
      return
    }
    if(type === 'direction') {
      const { x, y, h, w } = this.setDirectionAreaPosition(this.imgWidth, this.imgHeight);
      this.updateOverlay(x, y, w, h);
      return
    }
    if(type === 'direction-preview') {
      const { x, y, h, w } = this.setDirectionPreviewAreaPosition(this.imgWidth, this.imgHeight);
      this.updateOverlay(x, y, w, h);
      return
    }
    if(type === 'direction-paste') {
      const { x, y, h, w } = this.setDirectionPasteAreaPosition(this.imgWidth, this.imgHeight);
      this.updateOverlay(x, y, w, h);
      return
    }
    if(type === 'procedure-level') {
      const { x, y, h, w } = this.setProcedureLevelAreaPosition(this.imgWidth, this.imgHeight);
      this.updateOverlay(x, y, w, h);
      return
    }
  }

  hidePosition(){
    const overlay = document.querySelector('.overlay') as HTMLElement;
    overlay.style.clipPath = 'polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%)';
    this.isShowPosition = false;
  }

  onImageResize(entries: ResizeObserverEntry[]): void {
    // imge resize event
    // load 될때도 실행됨
    for (let entry of entries) {
      const img = entry.target as HTMLImageElement;
      const width = img.clientWidth;
      const height = img.clientHeight;
      const ratio = width / height;
      console.log(`Image resized: width=${width}, height=${height}, ratio=${ratio}`);
      
      this.imgWidth = width;
      this.imgHeight = height;
      this.imgTop = img.offsetTop;
      this.imgLeft = img.offsetLeft;

      if(!this.isShowPosition) return
      const { x, y, h, w } = this.setDirectionTagAreaPosition(width, height);
      this.updateOverlay(x, y, w, h);
    }
  }

  updateOverlay(x: number, y: number, width: number, height: number): void {
    const overlay = document.querySelector('.overlay') as HTMLElement;

    overlay.style.width = this.imgWidth + 'px';
    overlay.style.height = this.imgHeight + 'px';
    overlay.style.top = this.imgTop + 'px';
    overlay.style.left = this.imgLeft + 'px';
    overlay.style.clipPath = `
      polygon(
        0% 0%, 0% 100%, 
        ${x}px 100%, 
        ${x}px ${y}px, 
        ${x + width}px ${y}px, 
        ${x + width}px ${y + height}px, 
        ${x}px ${y + height}px, 
        ${x}px 100%, 
        100% 100%, 100% 0% 
      )
    `;
    console.log(`Overlay updated: x=${x}, y=${y}, width=${width}, height=${height}`);
  }

  // POSITION TOOLS
  setDirectionTagAreaPosition(imgWidth, imgHeight){
    // 원본 이미지 비율 : 860 x 478
    // 원본 이미지 기준 좌표 : x: 4, y: 162, h: 45, w: 845
    const x = (4 * imgWidth) / 860;
    const y = (162 * imgHeight) / 478;
    const h = (45 * imgHeight) / 478;
    const w = (845 * imgWidth) / 860;

    return { x, y, h, w }
  }

  setDirectionAreaPosition(imgWidth, imgHeight){
    // 원본 이미지 비율 : 860 x 478
    // 원본 이미지 기준 좌표 : x: 10, y: 234, h: 125, w: 825
    const x = (10 * imgWidth) / 860;
    const y = (234 * imgHeight) / 478;
    const h = (125 * imgHeight) / 478;
    const w = (825 * imgWidth) / 860;

    return { x, y, h, w }
  }

  setDirectionPreviewAreaPosition(imgWidth, imgHeight){
    // 원본 이미지 비율 : 860 x 478
    // 원본 이미지 기준 좌표 : x: 3, y: 208, h: 160, w: 850
    const x = (3 * imgWidth) / 860;
    const y = (208 * imgHeight) / 478;
    const h = (160 * imgHeight) / 478;
    const w = (850 * imgWidth) / 860;

    return { x, y, h, w }
  }

  setDirectionPasteAreaPosition(imgWidth, imgHeight){
    // 원본 이미지 비율 : 860 x 478
    // 원본 이미지 기준 좌표 : x: 715, y: 140, h: 20, w: 40
    const x = (715 * imgWidth) / 860;
    const y = (140 * imgHeight) / 478;
    const h = (20 * imgHeight) / 478;
    const w = (40 * imgWidth) / 860;

    return { x, y, h, w }
  }

  setProcedureLevelAreaPosition(imgWidth, imgHeight){
    // 원본 이미지 비율 : 740 x 565
    // 원본 이미지 기준 좌표 : x: 215, y: 200, h: 115, w: 460
    const x = (215 * imgWidth) / 740;
    const y = (200 * imgHeight) / 565;
    const h = (115 * imgHeight) / 565;
    const w = (460 * imgWidth) / 740;

    return { x, y, h, w }
  }
}
