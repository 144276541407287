import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sepa-account',
  templateUrl: './sepa-account.component.pug',
  styleUrls: ['./sepa-account.component.scss']
})

export class SEPAAccountComponent implements OnInit {
  @Input() sepa: any;
  @Input() defaultPaymentId: string;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  ngOnInit(){
    // console.log(this.sepa, this.defaultPaymentId)
  }
  onClickList(sepa){
    this.onUpdate.next(sepa)
  }
}