import { Component, Input,  ElementRef, ViewChildren, QueryList, HostListener } from "@angular/core";
import { Subscription } from "rxjs";

import { c_components } from "../../..";
import { Helper } from "../../../../../4services/2helper";
import { isString } from "lodash";

@Component({
  selector: "c_dtable_division_detail_component",
  templateUrl: "./c_dtable_division_detail_component.pug",
  styleUrls: ["../../common.scss", "./c_dtable_division_detail_component.scss"],
})
export class c_dtable_division_detail_component {
  // ------------------------------------------------------------------------
  @ViewChildren('actionMenus', {read: ElementRef}) actionMenus: QueryList<any>
  @Input() public search_text: string;
  @Input() public tab: string;
  @Input() public divisionId: number;

  ngOnChanges(changes: any) {
    if (changes.search_text && changes.search_text.currentValue !== changes.search_text.previousValue) {
      this.on_search_text_change();
    }
    if (changes.tab && changes.tab.currentValue !== changes.tab.previousValue) {
      this.on_search_text_change();
    }
    if (changes.divisionId && changes.divisionId.currentValue !== changes.divisionId.previousValue) {
      this.load_division_members();
      this.load_division_sites()
    }
  }

  isLoading: boolean = false;

  // users
  users: any[] = [];
  filteredUsers = [];

  // sites
  sites: any[] = [];
  filteredSites = [];

  // permission
  am_i_division_user = false;
  hasReadDivisionMemberPermission = false
  hasDeleteDivisionMemberPermission = false

  constructor(private helper: Helper, private c_components: c_components) {}

  private divisions$w: Subscription;
  private divisions_members$w: Subscription;
  private divisions_sites$w: Subscription;
  watch() {
    this.divisions$w = this.helper.dealer_division.divisions$w().subscribe();
    this.divisions_members$w = this.helper.dealer_division.divisions_members$w().subscribe((v) => this.on_division_members_change(v));
    this.divisions_sites$w = this.helper.sites.division_sites$w().subscribe((v) => this.on_division_sites_change(v));
  }
  unwatch() {
    this.divisions$w?.unsubscribe();
    this.divisions_members$w?.unsubscribe();
    this.divisions_sites$w?.unsubscribe();
  }

  // ------------------------------------------------------------------------

  async ngOnInit() {
    this.watch();
    await Promise.all([
      this.get_me(),
      this.load_division_members(),
      this.load_division_sites()
    ])
    this.checkPermission()
  }
  ngOnDestroy() {
    this.unwatch();
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.actionMenus) {
      const actionMenus = this.actionMenus.toArray();
      actionMenus.forEach(actionMenu => {
        this.filteredUsers?.forEach(user=> {
          if (('target-'+user?.user_id) === (actionMenu.nativeElement.id+'')) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              user['isShowMenu'] = false;
            }
          }
        })
        this.filteredSites?.forEach(site=> {
          if (('target-'+site?.site_id) === (actionMenu.nativeElement.id+'')) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              site['isShowMenu'] = false;
            }
          }
        })
      })
    }
  }

  // ------------------------------------------------------------------------

  on_search_text_change() {
    this.update_filteredTableData();
  }

  on_tab_change() {
    this.update_filteredTableData();
  }

  on_division_members_change(value: any){
    this.isLoading = true
    this.users = value;
    this.update_filteredTableData();
    this.isLoading = false
  }

  on_division_sites_change(value: any){
    this.isLoading = true
    this.sites = value;
    this.update_filteredTableData();
    this.isLoading = false
  }

  async get_me(){
    const me = await this.helper.me.get_me()
    this.am_i_division_user = me?.is_division_user ?? false
  }

  async load_my_divisions() {
    this.isLoading = true
    await this.helper.dealer_division.load_my_divisions();
    this.isLoading = false
  }
  
  async load_division_members() {
    await this.helper.dealer_division.load_division_members(this.divisionId);
  }

  async load_division_sites() {
    await this.helper.sites.load_division_sites(this.divisionId)
  }

  checkPermission(){
    this.hasReadDivisionMemberPermission = this.helper.permission.has("dealer_division_member_read")
    this.hasDeleteDivisionMemberPermission = this.helper.permission.has("dealer_division_member_delete")
  }

  // ------------------------------------------------------------------------

  update_filteredTableData() {
    if(this.tab === 'site') return this.update_filteredSite()
    if(this.tab === 'user') return this.update_filteredUser()
  }

  update_filteredSite() {
    if (!isString(this.search_text)) return this.filteredSites = this.sites;

    const search_text = this.search_text.toLowerCase();
    if (search_text.length < 2) return (this.filteredSites = this.sites);

    this.filteredSites = this.sites.filter((site) =>
      [site.name, site.address1, site.address2].join("").toLowerCase().includes(search_text)
    );
  }

  update_filteredUser() {
    if (!isString(this.search_text)) return this.filteredUsers = this.users;

    const search_text = this.search_text.toLowerCase();
    if (search_text.length < 2) return (this.filteredUsers = this.users);

    this.filteredUsers = this.users.filter((user) =>
    {
      const divisionList = user?.divisions.map(v => v?.name)
      return [user.firstname, user.lastname, user.email, ...divisionList].join("").toLowerCase().includes(search_text)
    }
    );
  }

  // ------------------------------------------------------------------------

  goToDetailDivision(divisionId){
    this.helper.router.navigate_to(`/settings/divisions/${divisionId}`);
    this.load_my_divisions()
  }

  goToDetailUser(userId){
    this.helper.router.navigate_to(`/settings/users`, {user: userId});
  }

  goToDetailSite(siteId){
    this.helper.router.navigate_to(`/customers`, {id: siteId});
  }

  // ------------------------------------------------------------------------

  setMorePosition(target) {
    const docElem = document.documentElement
    let elem = document.getElementById('target-'+target);
    let more = document.getElementById('target-more-'+target);
    let rect = elem.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right + 5
    const posY = rect.top - 22;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(division){
    const targetMenu = division.isShowMenu;
    division.isShowMenu = !targetMenu;
  }

  // ------------------------------------------------------------------------

  hasPermissionRemoveSiteFormDivision(){
    return this.hasDeleteDivisionMemberPermission && !this.am_i_division_user;
  }

  // ------------------------------------------------------------------------

  openDialogRemoveDataToDivision(target){
    this.tab === 'site'
      ? this.dialogRemoveSitesToDivision(target)
      : this.dialogRemoveUserToDivision(target)
  }

  dialogRemoveSitesToDivision(target){
    this.c_components.dialog.open("warning", {
      header: `Are you sure you want to exclude this site?`,
      contents: `
        <p>Name : ${target?.name}</b></p>
      `,
      submit_btn: "Exclude",
      submit_class: ["button-danger"],
      icon: 'warning',
      isConfirm: false,
      color: 'orange',
      submit_func: () => {
        this.removeSiteToDivision(target)
      }
    });
  }

  dialogRemoveUserToDivision(target){
    this.c_components.dialog.open("warning", {
      header: `Are you sure you want to exclude this user?`,
      contents: `
        <p>Name : ${target?.firstname} ${target?.lastname ? target?.lastname : ''}</b></p>
      `,
      submit_btn: "Exclude",
      submit_class: ["button-danger"],
      icon: 'warning',
      isConfirm: false,
      color: 'orange',
      submit_func: () => {
        this.removeUserToDivision(target)
      },
    });
  }

  async removeUserToDivision(user){
    await this.helper.dealer_division.delete_user_to_division(this.divisionId, user.user_id)
    await this.load_division_members()
  }

  async convertToGlobalUser(userId){
    const data = { is_division_user : 0 }
    await this.helper.dealer_member.update_member(userId, data)
  }

  async removeSiteToDivision(site){
    await this.helper.dealer_division.delete_site_to_division(site.site_id)
    await this.load_division_sites()
  }


  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green", doAction = false) {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (doAction) {
          this.load_division_members();
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}
