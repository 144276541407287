import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningModule } from '../../warning/warning.module'
import { SharedModule } from '../../../directives/shared.module'
import { NewFeatureNotificationModule } from '../../../components/new-feature/new-feature-notification.module'
import { PlanNotificationComponent } from './plan-notification.component'
import { MatCheckboxModule } from '@angular/material/checkbox';

import { DealerPlanDescriptionModule } from '../../../components/dealer-plan-description/dealer-plan/dealer-plan-description.module'
import { ResellerPlanDescriptionModule } from '../../../components/dealer-plan-description/reseller-plan/reseller-plan-description.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    MatCheckboxModule,
    NewFeatureNotificationModule,
    DealerPlanDescriptionModule,
    ResellerPlanDescriptionModule
  ],
  declarations: [
    PlanNotificationComponent
  ],
  exports: [
    PlanNotificationComponent
  ]
})
export class PlanNotificationModule { }
