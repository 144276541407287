import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BankAccountComponent } from './bank-account.component';
import { SharedModule } from '../../../../../directives/shared.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [BankAccountComponent],
  exports: [ BankAccountComponent ]
})
export class BankAccountModule { }
