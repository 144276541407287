import { Component, Input, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { Helper } from 'src/4services/2helper';
import ims from '../../../../imports'

@Component({
  selector: 'log_box_verification',
  templateUrl: './verification.component.pug',
  styleUrls: ['../../../common.scss','../../c_card_event_viewer_time_line.component.scss'],
})
export class log_box_verification_component {
  @Input() log;
  @Input() timezone;
  @Input() toolbar: TemplateRef<any>;

  isSelected = false
  isBuildReportMode = false;

  snapshotPermission = false
  isBrokenImage = false

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService,
    private helper: Helper,
  ) {}

  private snapshotPermission$w: Subscription;
  watch() {
    this.snapshotPermission$w = this.eventViewerService.snapshotPermission$w.subscribe((v) => this.snapshotPermission = v);
  }
  unwatch() {
    this.snapshotPermission$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  // -------------------------------------------------------
  // COMMON
  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  isAutomationRule(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule
  }

  computedBackground(){
    if(this.log.isLoading) return 'loading-background'
    if(!this.snapshotPermission) return 'no-permission'

    if(this.canIShowImage() === 'need to upgrade plan') return 'no-permission'
    if(this.canIShowImage() === 'expired') return 'no-permission'
    if(this.canIShowImage() === 'privacy mode') return 'no-permission'
    if(this.canIShowImage() === 'broken image') return 'no-permission'
    if(this.canIShowImage() === 'image') return 'full-height'
    if(this.canIShowImage() === 'broken video') return 'no-permission'
    if(this.canIShowImage() === 'video') return 'full-height'
    if(this.canIShowImage() === 'default') return 'verification'
  }
  
  canIShowImage(){
    const highestPlan = this.helper.dealer_plan.isPremiumPlan() || this.helper.dealer_plan.isResellerPlan()
    if(!this.snapshotPermission) return 'no permission'
    if(highestPlan && this.log.is_expired) return 'expired'
    if(!highestPlan && this.log.is_expired) return 'need to upgrade plan'
    if(this.log.isPrivacy) return 'privacy mode'
    if(this.isBrokenImage) return 'broken image'
    if(this.log.imageUrl) return 'image'
    if(this.log.videoUrl && !this.log.safetyVideoUrl) return 'broken video'
    if(this.log.safetyVideoUrl) return 'video'
    return 'default'
  } 
}
