import * as Sentry from "@sentry/angular-ivy";

const sentry_loader = function(){
  // Sentry.init({
  //   dsn: "https://c436b39444d04041b463157bc9c158e1@o4505039503687680.ingest.sentry.io/4505092183556096",
  //   integrations: [
  //       new Sentry.BrowserTracing({
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //       }),
  //       new Sentry.Replay({
  //         networkDetailAllowUrls: [
  //           window.location.origin,
  //           /^https:\/\/api\.chekt\.com/,
  //         ],
  //         // networkRequestHeaders: [window.location.origin],
  //       }),
  //   ],

  //   // performance monitoring.
  //   tracesSampleRate: 0.1,

  //   replaysSessionSampleRate: 0,
  //   replaysOnErrorSampleRate: 0,
  //   ignoreErrors: [
  //     'Non-Error exception captured'
  //   ]
  // })
  console.debug('✅ 2loaders.auth0_auth loaded');
}

export default sentry_loader