import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from "../../animations";
import { Modal } from '../../../model/modal';

import { UsersService } from '../../../services/users.service';
import { RolesService } from '../../../services/roles.service';
import { DealerPlanService } from '../../../services/service-plan.service';

import { Helper } from '../../../../4services/2helper';

@Component({
  selector: 'app-dealer-plan-notification',
  templateUrl: './plan-notification.component.pug',
  styleUrls: ['../../common.scss', './plan-notification.component.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})

export class PlanNotificationComponent implements OnInit {
  @Input() active: boolean;
  @Input() modal: Modal;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  @Output() event: EventEmitter<any> = new EventEmitter();

  me: any;
  m_warning = new Modal();
  isLoading = false;

  relatedFeature = ''
  showingNewFeatureWithPlan = false;
  showingOnlyNewFeature = false;
  showingOnlyPlan = false;

  currentIdx = 1;
  isHiding = false;
  isNext = false
  currentDealerPlan = 1
  hasPlanUpgradePermission = false

  constructor(
    private usersService: UsersService,
    private rolesService: RolesService,
    public dealerPlanService: DealerPlanService,
    private helper: Helper,
  ) {}

  ngOnInit() {
    this.isLoading = true
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.checkPlanUpgradePermission()
      this.fetchData()
    }, err => {
      this.isLoading = false
    });
  }

  fetchData(){
    const dealerId = this.me.dealer_id
    const dealerType = this.me.type
    this.dealerPlanService.getDealerPlan(this.me.dealer_id).subscribe(plan => {
      this.currentDealerPlan = plan?.current_month_service_plan_type
      this.checkWhatShouldShow()
    })
    this.dealerPlanService.getDealerServicePlanPricing(dealerId, dealerType).subscribe()
  }

  closeModal(res = false, plan = null) {
    this.onClose.next([res, plan, this.isHiding]);
    this.modal.is_active = false
    this.active = false
    this.modal.close();
  }

  onCancelOrBack() {
    const feature = this.modal.data['feature']
    if(feature === 'root') {
      this.currentIdx -= 1
      if(this.currentIdx === 3) this.closeModal()
      if(this.currentIdx === 2) this.isNext = false
      if(this.currentIdx === 1) this.relatedFeature = 'cloud-ai'
      if(this.currentIdx === 0)this.closeModal()
      return
    }
    this.isNext ? this.isNext = false : this.closeModal()
  }

  nextModal(){
    const feature = this.modal.data['feature']
    if(feature === 'root') {
      this.currentIdx += 1
      if(this.currentIdx === 2) this.relatedFeature = 'automation-rules'
      if(this.currentIdx >= 3) !this.isNext ? this.isNext = true : this.closeModal()
      return
    }
    !this.isNext ? this.isNext = true : this.closeModal()
  }

  //////////////////////////////////////
  checkWhatShouldShow(){
    const feature = this.modal.data['feature']
    const minDealerPlanService = this.modal.data['minDealerPlanService']
    this.relatedFeature = ''

    // CASE 1. show only plan desciption
    if(!this.modal.data || !Object.keys(this.modal.data).length || !this.modal.data['feature']) {
      this.showingOnlyPlan = true
      this.isLoading = false
      return
    }

    if(feature === 'root') {
      this.relatedFeature = 'cloud-ai'
      this.showingNewFeatureWithPlan = true
      this.isLoading = false
      return
    }

    this.relatedFeature = feature
    this.isLoading = false
    if(this.modal.data['feature']) {
      // CASE 2. show feature + plan desciption
      if(this.currentDealerPlan < minDealerPlanService) {
        this.showingNewFeatureWithPlan = true
        return
      }

      // CASE 3. show only feature
      if(this.currentDealerPlan >= minDealerPlanService) {
        this.showingOnlyNewFeature = true
        return
      }
    }
  }

  checkPlanUpgradePermission(){
    this.rolesService.getRoles(this.me.dealer_id).subscribe(res => {
      const adminRoleId = res.find(v => v.is_admin_role === 1)?.role_id

      this.me.role_id === adminRoleId
        ? this.hasPlanUpgradePermission = true
        : this.hasPlanUpgradePermission = false
    })
  }

  onClickShowChangingPlanModal(plan){
    if(!this.hasPlanUpgradePermission) {
      const header ='Please ask the administrator'
      const msg = `<br/>Plan upgrades are only available for administrators.<br/>Ask your administrator for an upgrade.`
      return this.confirmDialog(header, msg, 'warning', 'orange', true)
    }

    this.closeModal(true, plan)
  }

  goToPricingPlanDescription(){
    this.helper.router.navigate_to_service_plan();
    this.closeModal()
  }

  /////////////////////////////
  confirmDialog(header= '', msg= '', icon= 'warning',color='orange', isConfirm=false) {
    this.m_warning.data = {
      header: header,
      contents: msg,
      submit_btn: 'OK',
      submit_class: ['button-primary'],
      icon : icon,
      isConfirm,
      color: color

    };

    this.m_warning.data['submit_func'] = () => {}
    this.m_warning.open();
  }
}
