import { Component, OnInit, OnChanges, Inject } from '@angular/core';

import { CommonService } from '../../../../app/services/common.service';
import { EventsService } from '../../../../app/services/events.service';

import { DomSanitizer } from '@angular/platform-browser';

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  templateUrl: './c_dialog_score_event_viewer_component.html',
  styleUrls: ['../common.scss', './c_dialog_score_event_viewer_component.scss'],
})
export class c_dialog_score_event_viewer_component implements OnInit, OnChanges {
  me: any;

  constructor(
    private commonService: CommonService,
    private eventsService: EventsService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<c_dialog_score_event_viewer_component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  isLoading = false;
  isPlaySnapshot = false
  isReadySnapshot = false
  isFirstEvent = false
  isLastEvent = false
  records = []
  snapshotUrls = []
  safeSnapshots = []
  blobUrlSnapshots = []
  totalSnapshot = null
  snapshots = []
  readySnapshotCount = 0
  curSnapshotIdx = 0
  curSnapshotPercentage = 0
  currentEventIndex = 0
  eventIndexOffset = 0
  initEventIndex = 0
  snapshot$i = null

  dealerId = null
  siteId = null
  deviceId = null
  eventIds = []
  timeData = []
  scoreData = []
  currentTime = ''
  currentScore = ''

  areas = []
  showAreaFilter = true

  ngOnInit() {
    this.init()
  }

  ngOnChanges(changes) {
    this.resetData();
  }

  async init() {
    this.initData();
    await this.getEventRecords();
    await this.getEventAnnotation();
    this.isLoading = false;
  }


  initData() {
    this.initEventIndex = this.data.index
    this.dealerId = this.data.dealerId
    this.siteId = this.data.siteId
    this.deviceId = this.data.deviceId
    this.eventIds = this.data.eventIds
    this.timeData = this.data.timeData
    this.scoreData = this.data.scoreData
  }

  async getEventRecords() {
    try {
      this.currentEventIndex = this.initEventIndex + this.eventIndexOffset
      const eventId = this.eventIds[this.currentEventIndex]
      this.currentTime = this.timeData[this.currentEventIndex]
      this.currentScore = this.scoreData[this.currentEventIndex]

      const records = await this.eventsService.getSiteDeviceEventRecords(this.dealerId, this.siteId, this.deviceId, eventId).toPromise();
      this.records = records;
      this.getSnapshots(records); 
    }
    catch (error) {
      console.debug('getEventRecords:>',error)
    }
  }

  async getEventAnnotation() {
    try {
      const eventId = this.eventIds[this.currentEventIndex];
      const annotations = await this.eventsService.getSiteDeviceEventAnnotation(this.dealerId, this.siteId, eventId).toPromise();

      if (!(annotations instanceof Array)) return
      const annotation = annotations[0];
  
      if (!annotation?.area_filters) return
      this.areas = JSON.parse(annotation?.area_filters);
    }
    catch (error) {
      this.showAreaFilter = false
      this.areas = []
    }
  }

  getSnapshots(records) {
    this.isLoading = true;
    this.snapshotUrls = records.filter(record => record.format === 'jpg');
    this.safeSnapshots = [];
    this.totalSnapshot = this.snapshotUrls.length;
    this.snapshotUrls.forEach((rec, idx) => {
      this.getSnapshot(rec.url, idx)
    })
  }
  async getSnapshot(url, idx) {
    if (!url) return

    try {
      const urlCreator = window.URL;
      const snapshot = await this.eventsService.getSnapshotFromUrl(url).toPromise()
      this.blobUrlSnapshots[idx] = urlCreator.createObjectURL(snapshot);
      this.safeSnapshots[idx] = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(snapshot));
      this.snapshots[idx] = snapshot;
      this.readySnapshotCount += 1;
  
      if (this.readySnapshotCount !== this.totalSnapshot) return
      this.isReadySnapshot = true;
      this.startSnapshotPlay(); 
    }
    catch (error) {
      console.debug('getSnapshot:>',error)
    }

    // reset
    this.isLoading = false;
    this.readySnapshotCount = 0
    this.curSnapshotPercentage = 0
    this.curSnapshotIdx = 0
}

  startSnapshotPlay() {
    if (this.snapshot$i && this.isPlaySnapshot) return
    this.stopSnapshotPlay();
    this.isPlaySnapshot = true;
    this.snapshot$i = setInterval(() => {
      if (this.curSnapshotIdx >= this.totalSnapshot-1) {
        this.curSnapshotIdx = 0;
      } else {
        this.curSnapshotIdx += 1;
      }
      this.curSnapshotPercentage = (this.curSnapshotIdx/(this.totalSnapshot-1)) * 100
    }, 500)
  }

  stopSnapshotPlay() {
    this.isPlaySnapshot = false;
    clearInterval(this.snapshot$i);
    this.snapshot$i = null;
  }

  resetData() {
    this.stopSnapshotPlay()
    this.isLoading = false;
    this.isPlaySnapshot = false
    this.isReadySnapshot = false
    this.isFirstEvent = false
    this.isLastEvent = false
    this.records = []
    this.snapshotUrls = []
    this.safeSnapshots = []
    this.blobUrlSnapshots = []
    this.totalSnapshot = null
    this.snapshots = []
    this.readySnapshotCount = 0
    this.curSnapshotIdx = 0
    this.curSnapshotPercentage = 0
    this.currentEventIndex = 0
    this.eventIndexOffset = 0
    this.initEventIndex = 0
    this.snapshot$i = null

    this.dealerId = null
    this.siteId = null
    this.deviceId = null
    this.eventIds = []
    this.timeData = []
    this.scoreData = []
    this.currentTime = ''
    this.currentScore = ''
  }

  async onClickPreviousBtn() {
    if (0 >= this.currentEventIndex-1) this.isFirstEvent = true
    if (0 >= this.currentEventIndex) return
    this.eventIndexOffset--
    this.currentEventIndex = this.data.index + this.eventIndexOffset
    await this.getEventRecords();
    await this.getEventAnnotation();
  }
  async onClickNextBtn() {    
    if (this.data.eventIds.length-1 <= this.currentEventIndex+1) this.isLastEvent = true
    if (this.data.eventIds.length-1 <= this.currentEventIndex) return
    this.eventIndexOffset++
    this.currentEventIndex = this.data.index + this.eventIndexOffset
    await this.getEventRecords();
    await this.getEventAnnotation();
  }

  getPoints(area) {
    if (!area) return
    let points = '';
    area.points.forEach(p => {
      points += (p[0] * 16)+ ',' + (p[1] * 9) + ',';
    });
    points = points.slice(0, points.length-1);
    return points;
  }

  close(result: boolean): void {
    this.dialogRef.close(result);
  }
}
