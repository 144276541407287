import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';

import { SortService } from '../../services/sort.service';

@Component({
	selector: '[sortable-column]',
	templateUrl: './sortable.component.html'
})
export class SortableColumnComponent implements OnInit, OnDestroy {

	constructor(private sortService: SortService) { }

	@Input('sortable-column')
	columnName: string;

	@Input('sort-direction')
	sortDirection: string = '';

	@Input('sort-icon')
	iconPosition: string = 'right';

	private columnSortedSubscription: Subscription;

	@HostListener('click')
	sort() {
		this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
		this.sortService.columnSorted({ sortColumn: this.columnName, sortDirection: this.sortDirection });
	}

	ngOnInit() {
		// subscribe to sort changes so we can react when other columns are sorted
		this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
			// reset this column's sort direction to hide the sort icons
			if (this.columnName != event.sortColumn) {
				this.sortDirection = '';
			}
		});
	}

	ngOnDestroy() {
		this.columnSortedSubscription.unsubscribe();
	}
}