import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import { Permission } from '../permission'
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer_role {
  constructor(private ims: Imports, private permission: Permission) {}

  public roles$w(): Observable<any> {
    return this.ims.rolesService.roles$w;
  }

  public async get_roles(): Promise<Array<any>> | undefined {
    await this.load_roles();
    return this.ims.rolesService.roles$s.getValue();
  }

  public async get_role(role_id: string): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasRoleReadPermission = this.permission.has("dealer_role_read")
      if(!hasRoleReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.rolesService.getRole(dealer_id, role_id).toPromise();
      return res[0];
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get Member Role(${role_id})` });
    }
  }

  public async create_role(data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.rolesService.createRole(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Member Role` });
      return false;
    }
  }

  public async update_role(role_id: string, data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.rolesService.editRole(dealer_id, role_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Member Role(${role_id})` });
      return false;
    }
  }

  public async load_roles(): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasRoleReadPermission = this.permission.has("dealer_role_read")
      if(!hasRoleReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.rolesService.getRoles(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Member Roles" });
      return false;
    }
  }

  public async delete_role(role_id: string): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.rolesService.deleteRole(dealer_id, role_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Member Role(${role_id})` });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
