import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../directives/shared.module'
import { DealerPlanDescriptionComponent } from './dealer-plan-description.component'
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatCheckboxModule
  ],
  declarations: [
    DealerPlanDescriptionComponent
  ],
  exports: [
    DealerPlanDescriptionComponent
  ]
})
export class DealerPlanDescriptionModule { }
