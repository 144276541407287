import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'c_side_modal_site_note_component',
  templateUrl: './c_side_modal_site_note.component.pug',
  styleUrls: ['../common.scss','../c_side_modal.component.scss'],
})
export class c_side_modal_site_note_component {

  constructor() { }

}
