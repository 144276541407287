import { Component, Input, ChangeDetectorRef } from '@angular/core';

import { CommonService } from '@app/services/common.service';

@Component({
  selector: 'log_box_relay',
  templateUrl: './relay.component.pug',
  styleUrls: ['../../../common.scss','../../c_card_event_viewer_time_line.component.scss']
})
export class log_box_relay_component {
  @Input() log;
  @Input() timezone;

  constructor(
    public commonService: CommonService,
  ) {}

  // -------------------------------------------------------
  // COMMON
  isAutomationRule(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule || this.log.isAutomation
  }

  // --------------------------------------------------------------
  // STYLE
  relayIcon(meta){
    if(!meta || typeof meta != 'string') return 'relay'
    const metaData = JSON.parse(meta)
    const iconName = metaData?.info?.relay?.icon ?? 'relay'
    return iconName
  }
  capitalizeFirstLetter(string: string) {
    if(!string || typeof string != 'string') return
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
