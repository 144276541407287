
export class Modal {

  is_active = false;
  title = '';
  public data = <any>{}

  constructor(
  ) {
  }

  open() {
    this.is_active = true;
  }

  close() {
    this.is_active = false;
    setTimeout(() => {
      this.data = <any>{
      }
    }, 200);
  }
}
