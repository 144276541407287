import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
	selector: 'comp-icon',
	styleUrls: ['./icons.component.scss'],
	templateUrl: './icons.component.html'
})
export class IconsComponent implements OnInit, OnDestroy {
	
	@Input('icon') icon: string;
	@Input('color') color: string = 'hsla(0,0%,0%,0.6)';
	@Input('size') size: string = '24';
	@Input('rotate') rotate: number = 0;
	@Input('iconId') iconId: string ="12333";
    constructor() {}

	ngOnInit() {
		// subscribe to sort changes so we can react when other columns are sorted
	}

	ngOnDestroy() {
	}
}