import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { Observable } from 'rxjs';





@Injectable()
export class LogsService {
  service = 'dealer';

  constructor(public api: ApiService) {
    }

    private putLog(data): Observable<any> {
        return this.api.put_url(`https://j88ohvpvtc.execute-api.ap-northeast-2.amazonaws.com/dev/logger`, data);
    }

    checkPlatform() {
        let platform = 'web';
        const useragent = navigator.userAgent;
        if (useragent.match(/Android/i)) {
            platform = 'android';
        } else if (useragent.match(/BlackBerry/i)) {
            platform = 'blackBerry';
        } else if (useragent.match(/iPhone|iPad|iPod/i)) {
            platform = 'ios';
        } else if (useragent.match(/Opera Mini/i)) {
            platform = 'opera web';
        } else if (useragent.match(/IEMobile/i)) {
            platform = 'ie web';
        }
        return platform;
    }

    log(lv, msg, option?, reqeust?) {
        const log = this.makeLog(lv, msg, option);
        this.putLog(log).subscribe(res => {
        });
    }

    notice(msg, option?, reqeust?) {
        const log = this.makeLog('notice', msg, option, reqeust);
        this.putLog(log).subscribe(res => {
        });
    }

    err(msg, option?, request?) {
        const log = this.makeLog('err', msg, option, request);
        this.putLog(log).subscribe(res => {
        });
    }

    fatal(msg, option?, request?) {
        const log = this.makeLog('fatal', msg, option, request);
        this.putLog(log).subscribe(res => {
        });
    }

    test() {
        const e = new Error();
    }

    makeLog(lv, msg, data, request?) {
        const log = {};
        let stack = '';

        let fields = {};
        this.setFields(fields);
        if (data) {
            if (data['stack']) {
                stack = data['stack'];
                delete data['stack'];
            }
            fields = Object.assign(fields, data);
        }
        if (request) {
            fields['request'] = request;
        }

        const now = new Date();
        log['service'] = this.service;
        log['level'] = lv;
        log['path'] =  window.location.href;
        log['message'] = msg;
        log['fields'] = fields;
        log['timestamp'] = now.toISOString();
        log['stack'] = stack;
        

        return log;
    }

    setFields(fields) {
        const userAgent = navigator.userAgent;
        fields['platform'] = 'web';
        fields['useragent'] = userAgent;
        fields['stage'] =  window.location.hostname.indexOf('chektdev') !== -1 ? 'prod' : 'dev';
        fields['platform'] = this.checkPlatform();
    }

    //Activity Log
    getAllActivityLog(dealerId, filter?): Observable<any> {
        let URL = `/dealers/${dealerId}/activitylogs?sort=id&dir=desc&`;
        if (filter) {
          URL += `${filter}`
        }
        return this.api.get(URL);
    }

    getAllSiteActivityLog(dealerId, siteId, filter?): Observable<any> {
        let URL = `/dealers/${dealerId}/sites/${siteId}/activitylogs?sort=id&dir=desc&`;
        if (filter) {
          URL += `${filter}`
        }
        return this.api.get(URL);
    }

    getAllSiteActivityLogByTimeOrder(dealerId, siteId, isAsc, filter?): Observable<any> {
        const dir = isAsc ? 'asc' : 'desc'
        let URL = `/dealers/${dealerId}/sites/${siteId}/activitylogs?sort=created&dir=${dir}&`;
        if (filter) {
          URL += `${filter}`
        }
        return this.api.get(URL);
    }

    getActivityLogCategorys(dealerId, filter?): Observable<any> {
        let URL = `/dealers/${dealerId}/activitylog/categorys`;
        if (filter) {
          URL += `${filter}`
        }
        return this.api.get(URL);
    }
    
    getActivityLogDetail(dealerId, eventId): Observable<any> {
        let URL = `/dealers/${dealerId}/activitylogs/${eventId}/detail`;
        return this.api.get(URL);
    }

    //Audit Log
    getAllAuditLog(dealerId, filter?): Observable<any> {
        let URL = `/dealers/${dealerId}/auditlogs?sort=id&dir=desc&`;
        if (filter) {
            URL += `${filter}`
        }
        return this.api.get(URL);
    }

    getAllSiteAuditLog(dealerId, siteId, filter?): Observable<any> {
        let URL = `/dealers/${dealerId}/sites/${siteId}/auditlogs?sort=id&dir=desc&`;
        if (filter) {
            URL += `${filter}`
        }
        return this.api.get(URL);
    }

    getAuditLogCategorys(dealerId, filter?): Observable<any> {
        let URL = `/dealers/${dealerId}/auditlog/categorys`;
        if (filter) {
          URL += `${filter}`
        }
        return this.api.get(URL);
    }

    //verification
    getVerificationHistorys(dealerId, siteId, verificationId, filter?): Observable<any> {
        let URL = `/dealers/${dealerId}/sites/${siteId}/verifications/${verificationId}/history`;
        if (filter) {
          URL += `${filter}`
        }
        return this.api.get(URL);
    }

    // site tts Url
    getSiteTtsUrl(dealerId, siteId, activityLogId): Observable<any> {
        const url = `/dealers/${dealerId}/sites/${siteId}/tts_url`;
        const data = {
            activity_log_id: activityLogId
        };
        return this.api.post(url, data);
    }
}
