import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { Observable } from 'rxjs';





@Injectable()
export class MastermindsService {

  me: any;

  constructor(private api: ApiService) { }

  public getAllEventAdapters(dealer_id): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters`);
  }

  public get(dealer_id: number, mastermind_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}`);
  }

  public getAll(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/masterminds`);
  }

  public create(dealer_id: number, data: any): Observable<any> {

    return this.api.post(`/dealers/${dealer_id}/event_adapters/masterminds`, data);
  }

  public update(dealer_id: number, mastermind_id: number, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}`, data);
  }

  public delete(dealer_id: number, mastermind_id: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}`);
  }

  public getKeys(dealer_id: number, mastermind_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}/keys`);
  }

  public getCodes(dealer_id: number, mastermind_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}/codes`);
  }

  public getSites(dealer_id: number, mastermind_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}/sites`);
  }

  public status(dealer_id, adapterId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/masterminds/${adapterId}/status`);
  }

  public test(dealer_id, adapterId, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/masterminds/${adapterId}/test`, data);
  }

  public getLogs(dealer_id, adapterId, offset= 0, limit= 100): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/masterminds/${adapterId}/logs?sort=id&dir=desc&offset=${offset}&limit=${limit}`);
  }


  public createKey(dealer_id: number, mastermind_id: number, data: any): Observable<any> {
    let post_data = {
      pair_key: data['pair_key'],
      pair_value: data['pair_value'],
      is_static: data['is_static'] ? 1:0
    };
    return this.api.post(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}/keys`, data);
  }

  public deleteKey(dealer_id: number, mastermind_id: number, key_id: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_adapters/masterminds/${mastermind_id}/keys/${key_id}`);
  }
}