import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from '../../modals/animations';
import { Modal } from '../../model/modal';

import { UsersService } from '../../services/users.service';
import { DealerService } from '../../services/dealer.service';

import { Helper } from '../../../4services/2helper';

@Component({
  selector: 'new-feature-notification',
  templateUrl: './new-feature-notification.component.html',
  styleUrls: ['../../modals/common.scss', './new-feature-notification.component.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})

export class NewFeatureNotification implements OnInit, OnChanges {
  @Input() feature: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  me: any;
  m_warning = new Modal();
  isLoading = false;
  isImgLoading = false;
  isLoadingVideo = false;

  descriptionBox = []
  seletctedDescription = null;

  snsLogoList = [
    {
      name : 'Youtube',
      img : '/assets/logo/youtube.png',
      src : 'https://www.youtube.com/@VisualAlarmMonitoring/featured',
    },
    {
      name : 'Facebook',
      img : '/assets/logo/facebook.png',
      src : 'https://www.facebook.com/chektsolutions',
    },
    {
      name : 'Instagram',
      img : '/assets/logo/instagram.png',
      src : 'https://www.instagram.com/chektsolutions/',
    },
    {
      name : 'Twitter',
      img : '/assets/logo/twitter.png',
      src : 'https://twitter.com/chektsolutions',
    },
    {
      name : 'Linkedin',
      img : '/assets/logo/linkedin.png',
      src : 'https://www.linkedin.com/company/chekt/',
    },
  ]

  cloudAIDescriptionVideoRegion = null
  cloudAIDescriptionVideo = {}

  constructor(
    private usersService: UsersService,
    private dealerService: DealerService,
    private helper: Helper,
  ) {}

  ngOnInit() {
    this.isLoading = true
    this.isImgLoading = true
    this.isLoadingVideo = true
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.checkRegionAndSetDescriptionVideo()
    });
  }
  ngOnChanges(changes){
    if(changes['feature'] && this.me){
      this.checkRegionAndSetDescriptionVideo()
    }
  }

  /////////////////////////////////////////
  checkRegionAndSetDescriptionVideo(){
    this.dealerService.getDealerInfo(this.me.dealer_id).subscribe(dealer => {
      const region = dealer[0]?.region_id
      switch(region){
        case(4):
          this.cloudAIDescriptionVideoRegion = 'british'
          this.automationRuleDescriptionVideoRegion = 'british'
          break
        case(8):
          this.cloudAIDescriptionVideoRegion = 'french'
          this.automationRuleDescriptionVideoRegion = 'french'
          break
        default:
          this.cloudAIDescriptionVideoRegion = 'english'
          this.automationRuleDescriptionVideoRegion = 'english'
          break
      }
      if(this.feature === 'automation-rules') this.fetchDescriptionVideoForAutomationRule()
      if(this.feature === 'cloud-ai') this.fetchDescriptionVideoForCloudAI()
      this.isLoading = false
      this.isLoadingVideo = false
    })
  }

  ///////////////////////////////////
  // for automation rule
  automationRuleDescriptionVideo = {}
  automationRuleDescriptionVideoRegion = null
  fetchDescriptionVideoForAutomationRule(){
    // for passing 'exposes your application to XSS security risks'
    // ref: https://blog.stories.pe.kr/218
    this.automationRuleDescriptionVideo = {
      english: 'https://www.youtube.com/embed/sDqK_6JXjrQ?autoplay=1&rel=0',
      french: 'https://www.youtube.com/embed/ul9zHD_V8os?autoplay=1&rel=0',
      british: 'https://www.youtube.com/embed/HyZd5F-pk5o?autoplay=1&rel=0'
    }
    this.descriptionBox = [
      {
        className: 'description-box selected',
        titleIcon: 'autopay',
        iconType: 'component',
        title: 'What are Automation Rules?',
        contents: 'Automation Rules automatically initiate audio messages, relays, or video event creation when a defined condition occurs.',
        src: this.automationRuleDescriptionVideo[this.automationRuleDescriptionVideoRegion],
      },
      {
        className:'description-box',
        titleIcon: 'zone_trigger',
        iconType: 'component',
        title: 'Automation Rules for Security',
        contents: 'Without operator action, play audible warnings and turn on lights, when analytics or alarm occurs.',
        src: `https://www.youtube.com/embed/mYR4aWQV5l8?autoplay=1&rel=0`
      },
      {
        className: 'description-box',
        titleIcon: 'handshake',
        iconType: 'material',
        title: 'Supporting Services for Customers',
        contents: 'From welcome messages to warning messages, Automation Rules never sleep.',
        src: `https://www.youtube.com/embed/WXF7g2t7sr8?autoplay=1&rel=0`
      }
    ]
    this.seletctedDescription = this.descriptionBox[0]
  }

  onClickSwitchVideo(target){
    this.isLoadingVideo = true

    // init
    this.descriptionBox.forEach(v => v.className = 'description-box')

    // switch box
    switch(target){
      case (0) :
      case (1) :
      case (2) :
        this.descriptionBox[target].className += ' selected'
        this.seletctedDescription = this.descriptionBox[target]
        break
      default:
        console.log('invalid target')
    }
  }

  // for Cloud AI description
  fetchDescriptionVideoForCloudAI(){
    this.cloudAIDescriptionVideo = {
      english: 'https://www.youtube.com/embed/T6hsk8ypido?autoplay=1&rel=0',
      french: 'https://www.youtube.com/embed/biMtAGHXaYY?autoplay=1&rel=0',
      british: 'https://www.youtube.com/embed/Og0DOCd-D-E?autoplay=1&rel=0'
    }
  }

  // CHECKING for loading asset
  videoUploadDone(){
    this.isLoadingVideo = false
  }

  imageUploadDone(){
    this.isImgLoading = false
  }

  //////////////////
  goToCloudApi(){
    this.helper.router.navigate_to('/settings/api-keys');
    this.onClose.next()
  }

  setTooltipPosition(target) {
    let tooltipBox = document.querySelector('#'+target +'-box');
    let tooltip = document.getElementById(target);
    let rect = tooltipBox.getBoundingClientRect();
    let tooltipRect = tooltip.getBoundingClientRect();
    const posX = rect.left + (rect.right - rect.left) - tooltipRect.width/2;
    const posY = rect.top + tooltipRect.height + 5;
    tooltip.style.left = posX + 'px';
    tooltip.style.top = posY + 'px';
  }
}
