import { Component, HostListener, Input, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { PagerService } from '@app/services/pager.service';
import { CommonService } from '@app/services/common.service';

import ims from '../../../imports'
import { Helper } from '../../../../../../4services/2helper'

@Component({
  selector: 'c_dtable_site_list',
  templateUrl: './c_dtable_site_list.component.pug',
  styleUrls: ['../../../common.scss', '../site-note-manager.scss']
})
export class c_dtable_site_list_component {
  @Input() pagedItemIsReady: boolean;
  @Input() dealerId: any;
  @Input() searchText: string;
  @Input() siteListPagedItems: any[] = [];
  @Input() dealerList: any;
  @Input() serviceNotePagedItems: any[] = [];
  @Input() mpDirectionList: any[] = [];
  @Output() moveToSiteDetail = new EventEmitter<any>();
  @ViewChildren('siteActionMenus') siteActionMenus: QueryList<any>
  //-----------------------------------------------------------------------------
  isLoading = true;
  hasReadSiteDetailPermission = false;

  siteTableHeader = [
    { name: 'SITE ID', value: 'site_id', width: "7%" },
    { name: 'SITE NAME', value: 'site_name', width: "15%" },
    { name: 'DEALER NAME', value: 'dealer_name', width: "15%" },
    { name: 'SERVICE NOTES', value: 'service_note_length', width: "25%" },
    { name: 'MP DIRECTIONS', value: 'mp_direction_length', width: "25%" },
    { name: '', value: null, width: "5%" },
  ]

  pagedItems = [];
  originalSiteListPagedItems = [];

  // Pager
  pager: any = {};
  curPage = 1;
  totalItem = 0;
  pageOffset = 50;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;


  constructor(
    public pagerService : PagerService,
    public commonService: CommonService,
    public helper: Helper  
  ) { }

  ngOnChanges(changes): void {
    this.applyItemList(changes)
    this.applySearch(changes)
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.siteActionMenus) {
      const siteActionMenus = this.siteActionMenus.toArray();
      siteActionMenus.forEach(actionMenu => {
        this.siteListPagedItems?.forEach(site=> {
          if (site.site_id === parseInt(actionMenu.nativeElement.id)) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              site.isShowMenu = false;
            }
          }
        })
      })
    }
  }

  applyItemList(changes){
    if(!changes?.pagedItemIsReady) return
    if(changes?.pagedItemIsReady?.currentValue === changes?.pagedItemIsReady?.previousValue) return
    if(!changes?.pagedItemIsReady?.currentValue) return

    this.isLoading = true
    this.checkPermission()
    this.parseSiteList()
    this.setPage(1)
    this.isLoading = false
  }

  checkPermission(){
    // 여긴 이미 준비되어 있음. change에서 실행되니까
    this.hasReadSiteDetailPermission = this.helper.permission.has("dealer_site_read")
  }

  applySearch(changes) {
    if(!changes?.searchText) return
    if(changes?.searchText?.currentValue === changes?.searchText?.previousValue) return

    this.search()
    this.setPage(1)
  }

  //-----------------------------------------------------------------------------
  // 1. Site List
  parseSiteList(){
    this.siteListPagedItems?.forEach((site) => {
      site.dealer_name = this.setDealerName(site)
      site.service_notes = this.siteServiceNotes(site)
      site.mp_directions = this.siteMPDirections(site)
      site.isShowMenu = false;
    })
    this.originalSiteListPagedItems = ims._.cloneDeep(this.siteListPagedItems)
  }
  setDealerName(site){
    if(!site?.dealer_id) return 'No Dealer Name'
    if(!this.dealerList?.length) return 'No Dealer Name'
    return this.dealerList.find(dealer => dealer?.dealer_id === site?.dealer_id)?.external_dealer_id_with_company_name ?? 'No Dealer Name'
  }
  siteServiceNotes(site){
    // [{type: 'General': count: 2}, {type: 'Installation': count: 1}, ..]
    let result = []
    this.serviceNotePagedItems?.forEach(note => {
      // 이미 BE에서 내 사이트에 대해서만 전달하고 있음
      if(note?.site_id === site?.site_id) {
        const type = note?.type_label
        if(!type) return
        const index = result.findIndex(r => r?.type === type)
        if(index === -1) result.push({type, count: 1})
        else result[index].count++
      }
    })
    return result
  }
  siteMPDirections(site){
    // [{type: 'Draft': count: 2}, {type: 'Approved': count: 1}, {type: 'Approval Pending': count: 1}, ...]
    let result = []
    this.mpDirectionList?.forEach(direction => {
      if(direction?.site_id === site?.site_id) {
        const type = direction?.statusLabel
        const index = result.findIndex(r => r?.type === type)
        if(index === -1) result.push({type, count: 1, status: direction.status})
        else result[index].count++
      }
    })
    return result
  }
  // -----------------------------------------------------------------------------
  search(){
    if (this.searchText.length == 0 || this.searchText.length < 3) { 
      this.siteListPagedItems = this.originalSiteListPagedItems
      this.setPage(1)
      return
    }
    this.siteListPagedItems = this.searchSite()
    this.setPage(1)
  }
  searchSite(){
    let search_text = this.searchText.toLowerCase();
    return this.originalSiteListPagedItems.filter(site => {
      let text = site.site_id +
                site.name.toLowerCase() +
                site.dealer_name.toLowerCase() 

      if(search_text) return text.search(search_text) > -1;
      else return false;
    });
  }

  //-----------------------------------------------------------------------------
  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById(id);
    let more = document.getElementById('more-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right - 10;
    const posY = rect.bottom + 7;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(device){
    const targetMenu = device.isShowMenu;
    device.isShowMenu = !targetMenu;
  }

  computedStatusLabelColor(direction): string{
    if(!direction) return ''
    if(this.isNoContents(direction)) return 'no-contents'
    if(direction.status === 0) return 'not-approved-label'
    if(direction.status === 1) return 'pending-label'
    if(direction.status === 2) return 'finalized-label'
    if(direction.status === 3) return 'pending-label'
    if(direction.status === 4) return 'delete-requested-label'
  }

  computedStatusLabelIcon(direction): string {
    if(!direction) return ''
    if(this.isNoContents(direction)) return ''
    if(direction.status === 0) return 'edit'
    if(direction.status === 1) return 'schedule'
    if(direction.status === 2) return 'check_circle'
    if(direction.status === 3) return 'schedule' // 값은 invalid이지만 표시는 pending으로
    if(direction.status === 4) return 'do_not_disturb_on'
  }

  isShowGoToServiceNoteButton(site){
    return site.dealer_id == this.dealerId
  }

  isNoContents(direction){
    return direction.status === null
  }

  isNeedToCheck(value){
    return value === 'Need to Check'
  }

  determineElementToShowInSiteListTable(){
    if(this.isLoading) return false
    if(!this.hasReadSiteDetailPermission) return 'no-permission'
    if(!this.pagedItems?.length) return 'no-data'
    return 'normal'
  }

  // -----------------------------------------------------------------------------
  moveToServiceNote(event, site){
    event.stopPropagation();
    this.moveToSiteDetail.emit({type: 'service notes', site})
  }

  moveToMpDirection(event, site){
    event.stopPropagation();
    this.moveToSiteDetail.emit({type: 'monitoring portal directions', site})
  }

  goToSite(event, siteId){
    event?.stopPropagation();
    this.helper.router.navigate_to(`/customers/devices/note`, {id: siteId});
  }

  // -----------------------------------------------------------------------------
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortTableByColumnInSiteList(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.siteListPagedItems;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'site_id': 
          return this.compare(a.site_id, b.site_id, isAsc);
        case 'site_name': 
          return this.compare(a.name?.toLowerCase(), b.name?.toLowerCase(), isAsc);
        case 'dealer_name': 
          return this.compare(a.dealer_name?.toLowerCase(), b.dealer_name?.toLowerCase(), isAsc);
        case 'service_note_length': 
          return this.compare(a.service_notes?.length, b.service_notes?.length, isAsc);
        case 'mp_direction_length': 
          return this.compare(a.mp_directions?.length, b.mp_directions?.length, isAsc);
        default: return 0;
      }
    });
    
    this.siteListPagedItems = tmpData;
    this.setPage(1)
  }

  setPage(page: number) {
    this.pagedItems = []

    const target = this.siteListPagedItems
    if(!target){
      console.debug('⚠️ list is lost', target)
      return this.isLoading = false
    }
    
    // get pager object from service
    this.pager = this.pagerService.getPager(target?.length, page, this.pageOffset);
    this.totalItem = target?.length;
    
    // get current page of items
    this.pagedItems = target.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalItem) {
      this.pageOffsetEnd =  this.totalItem;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
  }
}