import { Component, Inject, HostListener, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from '@angular/router'
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { SitesService } from "@app/services/sites.service";
import { CommonService } from "@app/services/common.service";
import { PagerService } from "@app/services/pager.service";
import { c_components } from "../../index";
import { Helper } from "../../../../4services/2helper";
import ims from "../../imports"

@Component({
  templateUrl: "./c_dialog_mp_direction_copy_paste_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_mp_direction_copy_paste_component.scss"],
})
export class c_dialog_mp_direction_copy_paste_component {
  public dealerId: number;
  public divisionId: number;
  public isLoading: boolean = false;

  site: any
  isOwnSite = false;
  isSiteOwnerDealer = false;
  isSiteMonitoringDealer = false;
  mpDirectionList = [];
  selectedDirection = null;
  
  cameraList = []

  params: any;
  pager: any = {};
  curPage = 1;
  totalDirection = 0;
  pageOffset = 10;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;

  // paged items
  pagedItems: any[];

  constructor(
    private sitesService: SitesService,
    public commonService: CommonService,
    public pagerService : PagerService,
    private route: ActivatedRoute,

    public dialogRef: MatDialogRef<c_dialog_mp_direction_copy_paste_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private c_components: c_components,
    private helper: Helper
  ) {}

  private monitoringDirections$w: Subscription;
  watch() {
    this.monitoringDirections$w = this.helper.note_directions.monitoring_directions$w().subscribe((v) => {
      if(!v) return
      v.forEach(direction => this.parseMonitoringNote(direction))
      this.mpDirectionList = this.mpDirectionList.filter(v => v.id && this.data.mpDirectionId != v.id)
      this.mpDirectionList = this.sortMPDirection(this.mpDirectionList)
      this.setPage(1)
      this.isLoading = false
    });
  }
  unwatch() {
    this.monitoringDirections$w?.unsubscribe();
  }

  async ngOnInit() {
    this.isLoading = true
    this.site = this.sitesService.selSite;
    this.mpDirectionList = []
    await this.get_my_dealer_id()
    await this.fetchDeviceList()
    await this.fetchMPDirectionList()
    this.watch();
  }

  ngOnDestroy() {
    this.unwatch();
  }

  // -----------------------------------------------------------------------
  checkParams(){
    this.route.paramMap.subscribe((params) => {
      this.params = params.get('id')
    })
  }

  async get_my_dealer_id(){
    const data = await this.helper.me.get_my_dealer_id()
    this.dealerId = data
    this.isOwnSite = (this.site?.dealer_id === this.dealerId) && (this.site.partner_id === this.dealerId)
    this.isSiteOwnerDealer = (this.site?.dealer_id === this.dealerId) && (this.site.partner_id != this.dealerId)
    this.isSiteMonitoringDealer = (this.site?.dealer_id != this.dealerId) && (this.site.partner_id === this.dealerId)
  }
  async fetchDeviceList(){
    try {
      const deviceList = await this.sitesService.getSiteDevices(this.dealerId, this.site.site_id).toPromise()
      const cameraList = deviceList.filter(v => v.type === 12)
      this.makeMPDirectionContent(cameraList)
      this.cameraList = cameraList
    } catch(err) {
      console.debug('fetchDeviceList :>',err)
      this.isLoading = false;
    }
  }
  async fetchMPDirectionList() {
    try {
      await this.helper.note_directions.load_monitoring_directions(this.site.site_id);
    } catch(err) {
      console.debug('fetchMPDirectionList :>',err)
      this.isLoading = false;
    }
  }
  
  makeMPDirectionContent(deviceList){
    const obj: mpDirectionEl = {
      id: null,
      site_id: this.site.site_id,
      device_id: null,
      name: this.site?.name ? this.site.name : 'No Site Name',
      zoneNumber: null,

      status: null,
      statusLabel: null,
      type: 'Site',

      finalized: false,
      finalized_by: null,
      direction: null,
      unfinalized_direction: null,

      monitoring_operator_approved_by: null,
      site_owned_dealer_approved_by: null,

      updated_at: null,
      updated_by: null,
      isShowMenu: false
    }
    this.mpDirectionList.push(obj)

    deviceList.forEach(device => {
      const obj: mpDirectionEl = {
        id: null,
        site_id: device.site_id,
        device_id: device.device_id,
        name: device?.name || 'No Device Name',
        zoneNumber: device?.zone_number || '-',

        status: null,
        statusLabel: 'No Content',
        type: 'Camera',

        finalized: null,
        finalized_by: null,
        direction: null,
        unfinalized_direction: null,

        monitoring_operator_approved_by: null,
        site_owned_dealer_approved_by: null,

        updated_at: null,
        updated_by: null,
        isShowMenu: false
      }
      this.mpDirectionList.push(obj)
    })
  }
  parseMonitoringNote(direction){
    this.mpDirectionList.forEach(list => {
      if(list.device_id === direction.device_id) {
        list.id = direction.id
        list.status = direction.status
        list.statusLabel = this.parseMPDirectionStatus(direction)

        list.finalized = direction.status === 2
        list.finalized_by = direction.finalized_by
        list.direction = direction.direction
        list.unfinalized_direction = direction.unfinalized_direction

        list.monitoring_operator_approved_by = this.parseMonitoringOperatorApprovedBy(direction)
        list.site_owned_dealer_approved_by = this.parseSiteOwnedDealerApprovedBy(direction)
        
        list.updated_at = ims.moment(direction.updated_at).format('YYYY/MM/DD')
        list.updated_by = direction.updated_by 
        // list.isShowMenu = false
      }
    })
  }
  
  parseMPDirectionStatus(direction): string{
    // 0: draft, 1: Approval Pending , 2: finalized, 3: Approval Pending(switch MP), 4: delete requested
    const status = direction.status
    switch(status){
      case 0: return 'Draft'
      case 1: 
        if(!direction.site_owned_dealer_approved_by && !direction.monitoring_operator_approved_by) return 'Invalid Data'
        return 'Approval Pending'
      case 2: return 'Finalized'
      case 3: return 'Approval Pending'
      case 4: return 'Delete requested'
      default: return 'No Content'
    }
  }
  parseSiteOwnedDealerApprovedBy(value){
    if(value.site_owned_dealer_approved_by) return value.site_owned_dealer_approved_by

    const siteDealer = this.site?.dealer_id
    if(value?.status === 3) return 'Waiting for approval'
    if(value.monitoring_operator_approved_by) return 'Skip'
    if(siteDealer === this.dealerId) return 'Need to Check'
    return '-'
  }
  parseMonitoringOperatorApprovedBy(value){
    if(value.monitoring_operator_approved_by) return value.monitoring_operator_approved_by

    const siteDealer = this.site?.dealer_id
    if(value?.status === 1) return 'Waiting for approval'
    if(siteDealer != this.dealerId) return 'Need to Check'
    return '-'
  }
  sortMPDirection(mpDirections){
    return mpDirections.sort((a, b) => {
      // 우선 type에 따라 'site'가 먼저 오도록 정렬
      if (a.type?.toLowerCase() === 'site' && b.type?.toLowerCase() !== 'site') {
        return -1;
      } else if (a.type?.toLowerCase() !== 'site' && b.type?.toLowerCase() === 'site') {
        return 1;
      }
      
      // type이 둘 다 'device'인 경우 finalized를 기준으로 정렬
      if (a.type?.toLowerCase() === 'device' && b.type?.toLowerCase() === 'device') {
        // finalized가 true인 경우 더 먼저 오도록 설정
        return (a.finalized === b.finalized) ? 0 : a.finalized ? -1 : 1;
      }

      // 그 후에도 남은 리스트들은 zoneNumber 기준으로 정렬
      return (a.zoneNumber < b.zoneNumber) ? -1 : 1;
    })
  }

  // ----------------------------------------------------
  computedStatusLabelColor(list): string{
    if(!list) return ''
    if(this.isNoContents(list)) return 'no-contents'
    if(list.status === 0) return 'not-approved-label'
    if(list.status === 1) return 'pending-label'
    if(list.status === 2) return 'finalized-label'
    if(list.status === 3) return 'pending-label'
    if(list.status === 4) return 'delete-requested-label'
  }

  computedStatusLabelIcon(direction): string {
    if(!direction) return ''
    if(this.isNoContents(direction)) return ''
    if(direction.status === 0) return 'edit'
    if(direction.status === 1) return 'schedule'
    if(direction.status === 2) return 'check_circle'
    if(direction.status === 3) return 'schedule' // 값은 invalid이지만 표시는 pending으로
    if(direction.status === 4) return 'do_not_disturb_on'
  }

  computedMPDirectionStatusLabelColor(value){
    if(value === 'Skip') return true
    if(value === 'Waiting for approval') return true
    return false
  }
  
  isNoContents(list){
    return list.status === null
  }

  isNeedToCheck(value){
    return value === 'Need to Check'
  }
  // ----------------------------------------------------
  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  isSelectedDirection(direction){
    if(!this.selectedDirection) return false
    if(!direction) return
    return this.selectedDirection?.id == direction?.id
  }
  onChangedSelectedDirection(direction){
    this.selectedDirection = direction
  }  

  disabledButton(){
    return this.selectedDirection?.id ? false : true
  }

  async submit_changes() {
    this.c_components.dialog.open("warning", {
      header: `Would you like to "Paste" the content of the selected direction?`,
      contents: `
      <p>
        All information of the selected Direction (Description, Tags, Procedure) will be copied.<br/>
        The existing information remains intact even after clicking the "Paste" button and pasting the copied information, so it can be restored at any time.
      </p>`,
      submit_btn: "Paste",
      submit_class: ["button-primary"],
      icon: 'warning',
      isConfirm: false,
      color: 'orange',
      submit_func: () => {
        this.pasteDirection()
      },
    });
  }

  pasteDirection(){
    this.close_dialog(this.selectedDirection)
  }

  // ------------------------------------------------------------------------

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.mpDirectionList?.length, page, this.pageOffset);
    this.totalDirection = this.mpDirectionList?.length;

    // get current page of items
    this.pagedItems = this.mpDirectionList?.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    let lastIdx = this.pager.totalPages;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalDirection) {
      this.pageOffsetEnd =  this.totalDirection;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
    for (let i = 0; i < lastIdx; i ++) {
      let isExist = this.mpDirectionList?.slice(i*this.pageOffset, i*this.pageOffset + this.pageOffset).filter(site => site.site_id == this.params);
      if (isExist?.length != 0) {
        if (this.pager.currentPage !== i+1) {
          this.setPage(i+1);
        }
      }
    }
  }


  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}
interface mpDirectionEl {
  id: number
  site_id: number
  device_id: number | string
  name: string
  zoneNumber: string

  status: number
  statusLabel: string
  type: string

  finalized: boolean
  finalized_by: string
  direction: any
  unfinalized_direction: any

  monitoring_operator_approved_by: string
  site_owned_dealer_approved_by: string
  
  updated_at: string
  updated_by: string

  isShowMenu: boolean
}