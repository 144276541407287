import { Component, Input, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@app/services/common.service';

@Component({
  selector: 'log_box_arming',
  templateUrl: './arming.component.pug',
  styleUrls: ['../../../common.scss','../../c_card_event_viewer_time_line.component.scss'],
})
export class log_box_arming_component {
  @Input() log;
  @Input() timezone;

  logIcon = { type: '', name: ''}
  constructor(
    public commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
    this.checkLogType();
  }
  ngOnDestroy(){
  }

  // -------------------------------------------------------
  // COMMON
  computedActionName(action: string){
    const parsedAction = action.split(' ')[0]
    return parsedAction.toLowerCase()
  }

  armingIconColor(action: string){
    const parsedAction = action.split(' ')[0]
    const lowerCaseAction = parsedAction.toLocaleLowerCase()

    if(lowerCaseAction === 'armed') return '#FF7F16'
    if(lowerCaseAction === 'armed away') return '#FF7F16'
    if(lowerCaseAction === 'armed stay') return '#FF7F16'
    if(lowerCaseAction.includes('armed stay')) return '#FF7F16'
    return '#1EA675' // 그 외는 disarmed
  }

  capitalizeFirstLetter(string: string) {
    if(!string || typeof string != 'string') return
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  isAutomationRule(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule
  }
  // -------------------------------------------------------
  // 타입별로 분류해보자
  // - arming 상태를 표시하는 항목 : armed / disarmed (arming status)
  // - arming 상태를 '결정하는 시스템을 변경하는' 항목 : arming settings
  // - entry / exit delay 관련으로 arming 상태를 지연시키는 항목 (arming delay)

  checkLogType(){
    if(this.checkArmingStatus()) return
    if(this.checkArmingDelay()) return 
    // other : arming settings - voltage arming / cloud arming
    this.logIcon = { type: 'material', name: 'settings'}
  }
  checkArmingStatus(){
    const status = this.log.status_text.toLowerCase()
    if(status === 'armed') return true
    if(status === 'disarmed') return true
    if(status === 'armed away') return true
    if(status === 'armed stay') return true
    if(status.includes('armed stay')) return true
    return false
  }
  checkArmingDelay(){
    // entry / exit delay
    const status = this.log.status_text.toLocaleLowerCase()
    if(status.includes('end')) {
      this.logIcon = { type: 'material', name: 'sync_lock'}
      return true
    }
    if(status.includes('start')) {
      this.logIcon = { type: 'material', name: 'sync_lock'}
      return true
    }
    return false
  }
}
