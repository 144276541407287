import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventViewerService } from '@app/services/event-viewer.service';
import { EventsService } from '@app/services/events.service';
import { CommonService } from '@app/services/common.service';

import { c_components } from "../../../..";
import { Helper } from '../../../../../../4services/2helper'
import ims from '../../../../imports'

import JSZip from 'jszip';
import * as gifshot from 'gifshot';

@Component({
  selector: 'log_tool_bar',
  templateUrl: './log_tool_bar.component.pug',
  styleUrls: ['../../../common.scss','../c_card_event_viewer_box.component.scss'],
})
export class log_tool_bar {
  @Input() log: any;
  @Output() onClickEventViewerInToolbar: EventEmitter<any> = new EventEmitter();
  @Output() onClickLogModalInToolbar: EventEmitter<any> = new EventEmitter();
  @Output() onClickVerificationModalToolbar: EventEmitter<any> = new EventEmitter();
  @Output() onClickSharedLinkModalToolbar: EventEmitter<any> = new EventEmitter();
  @Output() onClickDownloadLinkModalToolbar: EventEmitter<any> = new EventEmitter();

  isLoading = false;

  // download
  blobUrlSnapshots = [];
  snapshots = []
  mp4Video = null; // download available
  mp4Url = '';

  hasDownloadPermission: boolean = false
  hasShareLinkPerm: boolean = false

  constructor(
    private helper: Helper,
    private c_components: c_components,
    private eventViewerService: EventViewerService, 
    private eventsService: EventsService,
    private commonService: CommonService,
  ) {
  }

  // ----------------------------------
  // TOOL BAR
  showActionMenus(e, log){
    e.stopPropagation();
    log.isShowMenu = true
  }

  // shared link download
  async openDownloadLinkModal(e, log){
    e.stopPropagation();
    const isExpired = log.is_expired
    const isPrivacy = log.isPrivacy
    
    if(isExpired) return this.openWarningDialog('This event has expired or cannot be verified')
    if(isPrivacy) return this.openWarningDialog('This video is in privacy mode.')
    this.onClickDownloadLinkModalToolbar.emit(log) // 이건 detail까진 필요 없음.
  }

  // download
  downloadLogEvent = null
  async download(e, log) {
    e.stopPropagation();
    this.isLoading = true
    await this.getActivityLogDetail(log) // 넣지 않으면 detail이 없어서 다운로드가 안됨
    const isExpired = log.is_expired
    const isPrivacy = log.isPrivacy
    
    if(isExpired) {
      this.isLoading = false
      return this.openWarningDialog('This event has expired or cannot be verified')
    }
    if(isPrivacy) {
      this.isLoading = false
      return this.openWarningDialog('This video is in privacy mode.')
    }

    const isSnapshot = log?.isSnapshotUpload && !log?.isVideoUpload
    this.downloadLogEvent = log
    this.initDownloadData()

    await this.getEventRecords()
    await ims.tool.sleep(1000)
    isSnapshot
      ? this.downloadSnapshotToGif()
      : this.downloadMP4();
  }

  // event viewer
  async openVideoEventViewer(log){
    const logDetail = await this.getActivityLogDetail(log)
    const isExpired = log.is_expired
    
    if(!logDetail) return this.openWarningDialog('This event has expired or cannot be verified')
    if(isExpired) return this.openWarningDialog('This event has expired or cannot be verified')
    this.onClickEventViewerInToolbar.emit(logDetail)
  }

  async openLogDetail(log){
    const category = log.category.toLowerCase()

    if(category === 'verification') return this.onClickVerificationModalToolbar.emit(log)
    if(category === 'share download link' || category === 'share response link') return this.onClickSharedLinkModalToolbar.emit(log)
    this.onClickLogModalInToolbar.emit(log)
  }

  async getActivityLogDetail(log) {
    const eventIdxId = log.videoLogId;
    try {
      const logDetail = await this.helper.activity_log.load_site_activity_log_detail(eventIdxId)
      log.detail = logDetail;
      log.event_type = logDetail.event.type;
      log.isExpired = !logDetail.event.has_video && !logDetail.event.has_snapshots
      if (log.event_code) {
        logDetail.receiver?.code_description?.forEach(codeInfo=> {
          if (log.event_code === codeInfo.event_code || log.event_code === codeInfo.restore_code) {
            log.code_description = codeInfo.name;
          }
        })
      }
      this.checkIsUploadedSnapshotOrVideo(log)
      return log
    } catch(err) {
      console.debug('getActivityLogDetail', err)
    }
  }
  checkIsUploadedSnapshotOrVideo(log) {
    log.is_snapshot_upload = log.isSnapshotUpload
    log.is_video_upload = log.isVideoUpload
    // event viewer에서 먼저 처리하므로 기존의 로직은 적용하지 않아도 괜찮을 것으로 예상
  }

  // -------------------------------------------
  // TODO!
  // Download, Shared Link Permission 설정해야함. 
  // 도책임님께 문의하기

  initDownloadData() {
    this.blobUrlSnapshots = [];
    this.snapshots = []
    this.mp4Video = null; // download available
    this.mp4Url = '';

    this.hasDownloadPermission = false
    this.hasShareLinkPerm = false
  }

  async getEventRecords() {
    try {
      const { dealer_id, site_id, device_id, event_id } = this.downloadLogEvent
      const records = await this.eventsService.getSiteDeviceEventRecords(dealer_id, site_id, device_id, event_id).toPromise()
        this.hasDownloadPermission = !!records[0].has_download_permission;
        this.hasShareLinkPerm = !!records[0].has_share_download_link_permission;
        await this.getSnapshots(records);
        await this.getEventVideo(records);
    } catch(err){
      this.isLoading = false
    }
  }

  async getEventVideo(records) {
    let mp4urls = records.filter(record => record.format==='mp4');
    if (mp4urls.length >0) {
      this.mp4Url = mp4urls[0].url;
      await this.getMP4Video(this.mp4Url);
    }
  }

  async getMP4Video(url) {
    const res = await this.eventsService.getSnapshotFromUrl(url).toPromise()
    this.mp4Video = res;
  }

  async getSnapshots(records) {
    if (!this.downloadLogEvent.is_snapshot_upload) return;
    const snapshotUrls = records.filter(record => record.format==='jpg');
    snapshotUrls.forEach(async (rec, idx) => {
      await this.getSnapshot(rec.url, idx)
    })
  }

  async getSnapshot(url, idx) {
    let urlCreator = window.URL;
    const res = await this.eventsService.getSnapshotFromUrl(url).toPromise()
    this.blobUrlSnapshots[idx] = urlCreator.createObjectURL(res);
    this.snapshots[idx] = res;
  }

  downloadSnapshotToGif() {
    gifshot.createGIF({
      'images': this.blobUrlSnapshots,
      'numFrames': 1,
      'gifWidth': 640,
      'gifHeight': 360,
    }, (obj)=> {
      let urlCreator = window.URL;
      if(!obj.error) {
        let image = obj.image;
        let blob = this.b64toBlob(image,'image/gif',512);
        let fileURL   = urlCreator.createObjectURL(blob);
        let a         = document.createElement('a');
        a.href        = fileURL;
        a.target      = '_blank';
        a.download    = `${this.downloadLogEvent.event_id}.gif`;
        document.body.appendChild(a);
        this.isLoading = false
        a.click();
      } else {
        console.debug(obj.error)
        this.isLoading = false
      }
    });
  }
  downloadSnapshots() {
    let keys = Object.keys( this.snapshots )
    var zip = new JSZip()
    keys.forEach((idx) => {
      let filename = `${this.downloadLogEvent.event_id}_${idx}.jpg`
      const blob = this.snapshots[idx]
      zip.file(filename, blob)
    })
    zip.generateAsync({type:"blob"}).then((content) => {
      var fileURL = URL.createObjectURL(content);
      var a         = document.createElement('a');
      a.href        = fileURL;
      a.target      = '_blank';
      a.download    = `${this.downloadLogEvent.event_id}.zip`;
      document.body.appendChild(a);
      this.isLoading = false
      a.click();
      document.body.removeChild(a);
    }, err=>{
      console.debug(err)
      this.isLoading = false
    })
  }
  downloadMP4() {
    const mp4file = this.mp4Video;
    var fileURL = URL.createObjectURL(mp4file);
    var a         = document.createElement('a');
    a.href        = fileURL;
    a.target      = '_blank';
    a.download    = `${this.downloadLogEvent.event_id}.mp4`;
    document.body.appendChild(a);
    this.isLoading = false
    a.click();
    document.body.removeChild(a);
  }
  b64toBlob(b64Data, contentType, sliceSize) {
    b64Data = b64Data.replace('data:image/gif;base64,','')
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  // -----------------------------------
  // STYLE
  openWarningDialog(msg) {
    this.c_components.dialog.open("warning", {
      header: 'Video Viewer Failed',
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: 'warning',
      isConfirm: true,
      color: 'orange',
      submit_func: () => {},
    });
  }

  setTooltipPosition(e, target) {
    e.stopPropagation();
    let tooltip = document.getElementById(target);
    tooltip.style.top = '36px';
    tooltip.style.left = '-10px';
  }

  isHasEventVideo(log){
    return log?.isSnapshotUpload || log?.isVideoUpload
  }
}
