import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { c_dtable_cloud_vms_manager_for_site_component } from './c_dtable_cloud_vms_manager_for_site.component';
import { SharedModule } from '../../../../../app/directives/shared.module'
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatCheckboxModule
  ],
  declarations: [c_dtable_cloud_vms_manager_for_site_component],
  exports: [c_dtable_cloud_vms_manager_for_site_component],
})
export class c_dtable_cloud_vms_manager_for_site_module { }
