import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningModule } from '../warning/warning.module'
import { SharedModule } from '../../directives/shared.module'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SafePipeModule } from '../../components/safe-pipe/safe.pipe.module'

import { DealerPlanForStandardModalComponent } from './dealer-plan-for-standard-modal.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    MatCheckboxModule,
    SafePipeModule
  ],
  declarations: [
    DealerPlanForStandardModalComponent
  ],
  exports: [
    DealerPlanForStandardModalComponent
  ]
})
export class DealerPlanForStandardModule { }
