import { Component, ChangeDetectorRef, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SitesService } from '@app/services/sites.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

import ims from '../../imports'

@Component({
  selector: 'c_filter_date_range',
  templateUrl: './c_filter_date_range_component.pug',
  styleUrls: ['./c_filter_date_range_component.scss']
})
export class c_filter_date_range_component {
  @Input() label: string = 'Create';
  @Input() tab: string;
  @Output() dateRange: EventEmitter<any> = new EventEmitter();
  @ViewChild('searcherDate') searcherDate: ElementRef;
  @ViewChild(DaterangepickerDirective, { static: false }) picker: DaterangepickerDirective;

  site: any;
  currentUrl: string = '';
  selected = { start: null, end: null };
  isSelectedDate = false;

  timePicker = false
  timePicker24Houre = false
  isFocusSearchDate = false;

  today = ims.moment();
  startDate = ims.moment().utc().startOf('day');
  endDate = ims.moment().utc().endOf('day');

  locale = {
    "format": "MMM/DD/YY",
    "displayFormat": "MMM/DD/YY",
    "separator": " To ",
    "cancelLabel": "Cancel",
    "applyLabel": "Apply"
  }
  ranges: any = {
    'Today': [ims.moment().startOf('day'), ims.moment().endOf('day')],
    'Yesterday': [ims.moment().subtract(1, 'days').startOf('day'), ims.moment().subtract(1, 'days').endOf('day')],
    'Last 7 Days': [ims.moment().subtract(6, 'days').startOf('day'), ims.moment().endOf('day')],
    'Last 30 Days': [ims.moment().subtract(29, 'days').startOf('day'), ims.moment().endOf('day')],
    'This Month': [ims.moment().startOf('month').startOf('day'), ims.moment().endOf('month')],
    'Last Month': [ims.moment().subtract(1, 'month').startOf('month').startOf('day'), ims.moment().subtract(1, 'month').endOf('month')]
  }

  constructor(
    private sitesService: SitesService, 
    private router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.searcherDate) {
      return;
    } else {
      if (!this.searcherDate.nativeElement.contains(event.target)) {
        this.isFocusSearchDate = false;
      }
    }
  }

  ngOnInit(): void {
    this.initData()
  }
  async ngAfterViewInit() {
    await ims.tool.sleep(80)
    this.showOnlySelectedCalendar({rightHide: true, leftHide: false})
    this.setCalenderWidth()
    this.clearDates()
  }
  ngOnChanges(changes): void {
    if(!changes.tab) return
    if(changes.tab.previousValue === changes.tab.currentValue) return
    if(changes.tab.currentValue) {
      this.clearDates()
    }
  }
  
  initData() {
    this.isFocusSearchDate = false;
    this.isSelectedDate = false;

    this.currentUrl = this.router.url;
    if(this.currentUrl.includes('customers')){
      this.site = this.sitesService.selSite;
      this.timePicker = true
      this.timePicker24Houre = true
      this.locale.format = "MM/DD/YY HH:mm"
      this.locale.displayFormat = "MM/DD/YY HH:mm"
    }
  }

  setSearchFocusStartDate(flag) {
    this.isFocusSearchDate = flag;
  }

  setCalenderWidth(){
    const calendars = document.querySelectorAll('.md-drppicker');
    if(!calendars || !calendars?.length) return
    calendars.forEach(calendar => {
      this.renderer.setStyle(calendar, 'width', '390px');
    })
  }

  //----------------- Date Picker -----------------
  clearDates() {
    if (this.picker) {
      this.picker.clear();
      this.isSelectedDate = false;
      this.cdr.detectChanges();
    }
  }

  calandarUpdate(event) {
    // event = {startDate: Dayjs, endDate: Dayjs}
    if(!event) return
    const { startDate, endDate } = event;
    if(startDate) this.isSelectedDate = true;

    const timezone = this.site?.timezone;
    if(timezone) {
      this.selected.start = startDate.tz(this.site.timezone)
      this.selected.end = endDate.tz(this.site.timezone)
    } else {
      this.selected.start = startDate
      this.selected.end = endDate
    }
    const data = {
      label : this.label,
      selected: this.selected
    }
    this.dateRange.emit(data);
  }
  calandarUpdateInRange(event) {
    // event = {label: 'This Month', dates: [Dayjs, Dayjs]}
    if(!event) return
    const { label, dates } = event;
    if(this.selected.start === dates[0] && this.selected.end === dates[1]) return
    
    const timezone = this.site?.timezone;
    if(timezone) {
      this.selected.start = dates[0].tz(this.site.timezone)
      this.selected.end = dates[1].tz(this.site.timezone)
    } else {
      this.selected.start = dates[0]
      this.selected.end = dates[1]
    }

    if(label === 'Today' || label === 'Yesterday' || label === 'Last 7 Days') {
      this.showOnlySelectedCalendar({rightHide: false, leftHide: true})
    } else {
      this.showOnlySelectedCalendar({rightHide: true, leftHide: false})
    }
    
    const data = {
      label : this.label,
      selected: this.selected
    }
    this.dateRange.emit(data);
  }
  showOnlySelectedCalendar(hide) {
    const rightCalendars = document.querySelectorAll('.calendar.right');
    const leftCalendars = document.querySelectorAll('.calendar.left');
    const { rightHide, leftHide } = hide;

    if(rightHide) {
      if(!rightCalendars || !rightCalendars?.length) return
      rightCalendars.forEach(calendar => {
        this.renderer.setStyle(calendar, 'display', 'none');
      })
      leftCalendars.forEach(calendar => {
        this.renderer.setStyle(calendar, 'display', 'block');
      })
    }
    if(leftHide) {
      if(!leftCalendars || !leftCalendars?.length) return
      leftCalendars.forEach(calendar => {
        this.renderer.setStyle(calendar, 'display', 'none');
      })
      rightCalendars.forEach(calendar => {
        this.renderer.setStyle(calendar, 'display', 'block');
      })
    }
  }
}