import { Component, OnInit, Input, Output, NgZone, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import { UsersService } from '../../../../services/users.service';
import { DealerService } from '../../../../services/dealer.service';
import { PaymentService } from '../../../../services/payment.service';
import { CommonService } from '../../../../services/common.service';
import { Modal } from '../../../../model/modal';

import * as _ from 'lodash';
import moment from "moment-timezone";
import csc from 'country-state-city'
import { validateIBAN } from 'ibantools';

@Component({
  selector: 'setting-payment-information',
  templateUrl: './payment-information.component.pug',
  styleUrls: ['../../../common.scss', '../plan-payment.component.scss', './payment-information.component.scss']
})

export class PaymentInformationComponent implements OnInit {
  @Input() modal: Modal;
  @Input() active: boolean;
  @Output() onSelectPaymentMethod: EventEmitter<any> = new EventEmitter();
  @Output() onNoCustomerUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onHasCustomerUpdate: EventEmitter<any> = new EventEmitter();
  @ViewChild('registeredCardAccountSelectDropdown') registeredCardAccountSelectDropdown: ElementRef;
  @ViewChild('registeredBankAccountSelectDropdown') registeredBankAccountSelectDropdown: ElementRef;
  @ViewChild('registeredSEPAAccountSelectDropdown') registeredSEPAAccountSelectDropdown: ElementRef;
  @ViewChild('countrySelectDropdown') countrySelectDropdown: ElementRef;
  @ViewChild('paymentMethodsSelectDropdown') paymentMethodsSelectDropdown: ElementRef;
  @ViewChild('stateSelectDropdown') stateSelectDropdown: ElementRef;
  @ViewChild('bankTypeSelectDropdown') bankTypeSelectDropdown: ElementRef;
  @ViewChild('sepaTypeSelectDropdown') sepaTypeSelectDropdown: ElementRef;
  @ViewChild('currencySelectDropdown') currencySelectDropdown: ElementRef;

  me: any;
  isNewDealer = false;
  m_warning: Modal = new Modal();

  euroCurrencyEnabled = false
  paymetMethodsList = ['Credit or Debit card', 'ACH Bank Accounts'];
  paymentMethod = '';
  defaultPaymentId = null;

  stripPaymentList = []
  cardAccounts = [];
  bankAccounts = [];
  sepaAccounts = []

  isUnfoldPaymetMethodsList = false;

  isValid = false;
  isValidAccountNumber = true;
  isLoading = false;

  ///////////////////////////
  // hasCustomer
  isUnfoldCardAccountList = false;
  isUnfoldBankAccountList = false;
  isUnfoldSEPAAccountList = false;
  selectedForCustomerCardAccount = null;
  selectedForCustomerBankAccount = null;
  selectedForCustomerSEPAAccount = null;

  ///////////////////////////
  // credit card
  countries = [];
  states = [];
  countryFilterTxt = '';
  filteredCountries = [];

  errorString = {
    cardNumber: '',
    expirDate: '',
    cvc: '',
  };
  cardType = 'genric';
  newCardInfo = {
    nickname: '',
    cardnumber: '',
    expMonth: '',
    expYear: '',
    CVC: '',
    exp:'',
    country: {id: "231", sortname: "US", name: "United States", phonecode: "1"},
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    isDefault: 1,
  };
  isShowOption = false;
  hasStripeCustomerAccount = false;

  isCreatingCreditCardAccount = false
  isUnfoldCountryList = false;
  isUnfoldStateList = false;

  ///////////////////////////

  ///////////////////////////
  // ach-create
  accountHolderTypes = ['Individual', 'Company'];
  isUnfoldBankTypeSelectList = false;

  currencys = [];
  selCurrency: any = '';
  isUnfoldCurrencyList = false;

  newBankAccount = {
    account_holder_name: '',
    account_holder_type: 'Individual', // individual, company
    bank_name: '',
    country: 'US',
    currency: 'usd',
    routing_number: '',
    account_number: '',
    confirm_account_number: '',
    isDefault: 1
  };
  bankErrorString = {
    routing_number: '',
    account_number: '',
  };
  //////////////////////////

  //////////////////////////
  // 
  newSEPAAccount = {
    name: '',
    email: '', 
    iban: '',
    isDefault: 1
  }
  isValidIBAN = false
  isValidEmail = false

  constructor(
    private usersService: UsersService,
    private dealerService: DealerService,
    private paymentService: PaymentService,
  ) { }

  ngOnInit() {
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.euroCurrencyEnabled = this.me.currency_id == 4
      this.initData();
      this.fetchData();
    });
  }

  ngOnChanges(changes) {
    if (changes['active'] && this.active) {
      this.initData();
      this.fetchData();
    }
  }

  closeModal(res = false) {
    this.modal.is_active = false
    this.modal.close();
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.paymentMethodsSelectDropdown) {
      if (!this.paymentMethodsSelectDropdown.nativeElement.contains(event.target)){
        this.isUnfoldPaymetMethodsList = false
      }
    }
    if(this.countrySelectDropdown) {
      if (!this.countrySelectDropdown.nativeElement.contains(event.target)) {
        this.isUnfoldCountryList = false;
      }
    }

    // registerd customer
    if(this.registeredCardAccountSelectDropdown) {
      if(!this.registeredCardAccountSelectDropdown.nativeElement.contains(event.target)) {
        this.isUnfoldCardAccountList = false;
      }
    }
    if(this.registeredBankAccountSelectDropdown) {
      if(!this.registeredBankAccountSelectDropdown.nativeElement.contains(event.target)) {
        this.isUnfoldBankAccountList = false;
      }
    }
    if(this.registeredSEPAAccountSelectDropdown) {
      if(!this.registeredSEPAAccountSelectDropdown.nativeElement.contains(event.target)) {
        this.isUnfoldSEPAAccountList = false;
      }
    }

    // credit card
    if (this.stateSelectDropdown) {
      if (!this.stateSelectDropdown.nativeElement.contains(event.target)) {
        this.isUnfoldStateList = false;
      }
    }

    // bank
    if (this.bankTypeSelectDropdown && this.currencySelectDropdown) {
      if (!this.bankTypeSelectDropdown.nativeElement.contains(event.target)) {
        // clicked outside => close dropdown list
        this.isUnfoldBankTypeSelectList = false;
      }
      if (!this.currencySelectDropdown.nativeElement.contains(event.target)) {
        this.isUnfoldCurrencyList = false;
      }
    }
  }

  initData() {
    // credit
    this.newCardInfo = {
      nickname: '',
      cardnumber: '',
      expMonth: '',
      expYear: '',
      CVC: '',
      exp:'',
      country: {id: "231", sortname: "US", name: "United States", phonecode: "1"},
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      isDefault: 1,
    };
    this.states = [];
    this.getCounties();
    this.getStates(this.newCardInfo.country)

    // bank
    this.newBankAccount = {
      account_holder_name: '',
      account_holder_type: 'Individual', // individual, company
      bank_name: '',
      country: 'US',
      currency: 'usd',
      routing_number: '',
      account_number: '',
      confirm_account_number: '',
      isDefault: 1
    };
    this.selCurrency = '';

    // common
    this.isValid = false;
    this.isValidAccountNumber = true;
    this.isLoading = false;
    this.setPaymetMethodsList()
  }

  setPaymetMethodsList(){
    // SEPA
    if(this.euroCurrencyEnabled) this.paymetMethodsList.push('SEPA')

    // Others payment
    const today = moment.utc()
    const created = moment.utc(this.me.created)
    const diff = today.diff(created, 'days')
    if(diff <= 30) {
      this.isNewDealer = true
    } else {
      this.isNewDealer = false
      this.paymetMethodsList.push('Others(Check By Mail, etc.)')
    }
  }

  ///////////////////////////
  async fetchData() {
    this.isLoading = true;
    this.excludeACHBankPaymentForNonUSRegions()
    await Promise.all([
      this.getStripePayment(),
      this.getSEPAPayment()
    ])
    this.setStripePayment(this.stripPaymentList)
    this.isLoading = false;
  }

  excludeACHBankPaymentForNonUSRegions(){
    this.dealerService.getDealerInfo(this.me.dealer_id)
      .subscribe(res => {
        const data = res[0]
        if(data?.region_id != 1) this.paymetMethodsList = this.paymetMethodsList.filter(v => v !== 'ACH Bank Accounts')
      })
  }
  async getStripePayment(){
    try {
      this.stripPaymentList = await this.paymentService.getStripe(this.me.dealer_id).toPromise()
      this.hasStripeCustomerAccount = true
    } catch(err) {
      this.hasStripeCustomerAccount = false;
      this.isLoading = false;
      console.debug('getStripe :>>',err)
    }
  }
  async getSEPAPayment(){
    try {
      this.sepaAccounts = await this.paymentService.getSEPA(this.me.dealer_id).toPromise()
    } catch(err) {
      this.isLoading = false
      console.debug(err)
    }
  }

  setStripePayment(customers){
    if (customers[0]['meta'].deleted) {
      this.isLoading = false
      // 무언가 처리?
      return;
    }

    this.findDefaultPaymentId(customers)
    const paymentMethodList = customers[0].meta.sources.data; // array
    let tmpDefaultPayment = null;
    let tmpStripeCards = [];
    let tmpBankAccounts = [];
    paymentMethodList.forEach(paymentMethod => {
      // CASE 0 : default payment
      if (this.defaultPaymentId === paymentMethod.id) {
        paymentMethod['object'] === 'card'
          ? this.paymentMethod = 'Credit or Debit card'
          : this.paymentMethod = 'ACH Bank Accounts'
        this.onHasCustomerUpdate.next(paymentMethod.id)
      }

      // CASE 1 : card payment
      if (paymentMethod['object'] === 'card') {
        if (paymentMethod['metadata']) {
          paymentMethod['nickname'] = paymentMethod['metadata']['nickname'];
        }
      }

      // CASE 2 : bank payment
      if (paymentMethod['object'] === 'bank_account') {
        if (paymentMethod['account_holder_name']) {
          paymentMethod['nickname'] = paymentMethod['account_holder_name'];
        }
        paymentMethod['accountNumber'] = '************' + paymentMethod['last4'];
      }
    })
    tmpDefaultPayment = paymentMethodList.find(data => data.id === this.defaultPaymentId);
    tmpStripeCards = paymentMethodList.filter(data => data.object === 'card');
    tmpBankAccounts = paymentMethodList.filter(data => data.object === 'bank_account');

    this.cardAccounts = tmpStripeCards;
    if(this.cardAccounts.length) this.cardAccounts.push({type: 'create', msg: 'Create New Card Account'})
    this.bankAccounts = tmpBankAccounts;
    if(!tmpDefaultPayment || (!tmpStripeCards.length && !tmpBankAccounts.length)) {
      this.paymentMethod = 'Credit or Debit card'
    }
    this.selectedForCustomerCardAccount = tmpStripeCards[0]
    this.selectedForCustomerBankAccount = tmpBankAccounts[0]
  }

  findDefaultPaymentId(customers){
    const customer = customers[0]
    const meta = customer.meta;
    const newMeta = customer.meta?.invoice_settings?.default_payment_method
    this.defaultPaymentId = newMeta ? newMeta : meta.default_source
  }

  selectMethod(state) {
    this.isLoading = true;

    // init
    this.paymentMethod = state;
    this.isUnfoldPaymetMethodsList = false;
    this.newBankAccount = {
      account_holder_name: '',
      account_holder_type: 'Individual', // individual, company
      bank_name: '',
      country: 'US',
      currency: 'usd',
      routing_number: '',
      account_number: '',
      confirm_account_number: '',
      isDefault: 1
    }
    this.newCardInfo = {
      nickname: '',
      cardnumber: '',
      expMonth: '',
      expYear: '',
      CVC: '',
      exp:'',
      country: {id: "231", sortname: "US", name: "United States", phonecode: "1"},
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      isDefault: 1,
    };
    this.newSEPAAccount = {
      name: '',
      email: '', 
      iban: '',
      isDefault: 1
    }
    this.isCreatingCreditCardAccount = false

    // set default value
    if(this.cardAccounts.length && !this.cardAccounts[0]?.type){
      this.selectedForCustomerCardAccount = this.cardAccounts[0]
    }
    if(this.bankAccounts.length){
      this.selectedForCustomerBankAccount = this.bankAccounts[0]
    }
    if(this.sepaAccounts.length){
      this.selectedForCustomerSEPAAccount = this.sepaAccounts[0]
    }

    if(
      this.selectedForCustomerCardAccount?.id || 
      this.selectedForCustomerBankAccount?.id ||
      this.selectedForCustomerSEPAAccount?.payment_method_id
    ) {
      let token = ''
      let method = state.toLowerCase()
      if(method.includes('card')) token = this.selectedForCustomerCardAccount?.id ?? null
      if(method.includes('bank')) token = this.selectedForCustomerBankAccount?.id ?? null
      if(method.includes('sepa')) token = this.selectedForCustomerSEPAAccount?.payment_method_id ?? null
      this.onSelectPaymentMethod.next([state, token])
    } else {
      this.onSelectPaymentMethod.next(['Others(Check By Mail, etc.)', null])
    }
    this.isLoading = false;
  }

  selectCardAccountList(card){
    this.selectedForCustomerCardAccount = card
    this.isUnfoldCardAccountList = false;
    if(card?.type) {
      this.isCreatingCreditCardAccount = true
      this.isValidAccountNumber = false
      this.onNoCustomerUpdate.next([{type: 'create'}, false])
    } else {
      this.onHasCustomerUpdate.next(card.id)
    }
  }
  selectBankAccountList(bank){
    this.selectedForCustomerBankAccount = bank
    this.isUnfoldBankAccountList = false;
    this.onHasCustomerUpdate.next(bank.id)
  }
  selectSEPAAccountList(account){
    this.selectedForCustomerSEPAAccount = account
    this.isUnfoldSEPAAccountList = false;
    this.onHasCustomerUpdate.next(account.payment_method_id)
  }

  getCounties() {
    this.countries = csc.getAllCountries();
    this.filteredCountries = _.cloneDeep(this.countries);
  }
  filterCountries() {
    const filterTxt = this.countryFilterTxt.trim();
    if (filterTxt) {
      this.filteredCountries = this.countries.filter(country => {
        return country.name.toLowerCase().includes(filterTxt)
      })
    } else {
      this.filteredCountries = _.cloneDeep(this.countries);
    }
  }

  toggleCountryList() {
    this.isUnfoldCountryList = !this.isUnfoldCountryList;
    this.countryFilterTxt = '';
  }

  selectCountry(country) {
    this.newCardInfo.country = country; // {id: "116", sortname: "KR", name: "Korea South", phonecode: "82"}
    this.getStates(country);
    this.isUnfoldCountryList = false;
    this.countryFilterTxt = '';
    this.newCardInfo.state = '';
    this.onNoCustomerUpdate.next([{ country }, false])
    this.paymentMethod === 'Credit or Debit card' ? this.validateCard() : null
  }

  getStates(country) {
    const countryId = country.id;
    this.states = csc.getStatesOfCountry(countryId);
  }

  selectState(state) {
    this.newCardInfo.state = state.name;
    this.onNoCustomerUpdate.next([{state : state.name}, false])
    this.validateCard()
    this.isUnfoldStateList = false;
  }

  /////////////////////////////
  ///////// INPUT NEW ////////


  // cc number key event
  mask (value, limit, separator){
    var output = [];
    for (let i = 0; i < value.length; i++) {
      if ( i !== 0 && i % limit === 0) {
        output.push(separator);
      }
      output.push(value[i]);
    }
    return output.join("");
  }
  unmask (value) {
    return value.replace(/[^\d]/g, '')
  }
  checkSeparator (position, interval) {
    return Math.floor(position / (interval + 1))
  }

  ccNumberInputOldValue = null
  ccNumberInputOldCursor = null
  onKeydownCCNumberHandler(e) {
    let el = e.target;
    this.ccNumberInputOldValue = el.value;
    this.ccNumberInputOldCursor = el.selectionEnd;
  }
  onInputCCNumberHandler = (e) => {
    let el = e.target,
        newValue = this.unmask(el.value),
        newCursorPosition;

    if ( newValue.match(/^\d{0,16}$/g) ) {
      newValue = this.mask(newValue, 4, " ");

      newCursorPosition =
        this.ccNumberInputOldCursor - this.checkSeparator(this.ccNumberInputOldCursor, 4) +
        this.checkSeparator(this.ccNumberInputOldCursor + (newValue.length - this.ccNumberInputOldValue.length), 4) +
        (this.unmask(newValue).length - this.unmask(this.ccNumberInputOldValue).length);

      el.value = (newValue !== "") ? newValue : "";
    } else {
      el.value = this.ccNumberInputOldValue;
      newCursorPosition = this.ccNumberInputOldCursor;
    }
    el.setSelectionRange(newCursorPosition, newCursorPosition);
    this.highlightCCIcon(el.value);
    this.onNoCustomerUpdate.next([{cardNumber : el.value}, false])
    this.validateCard()
  }

  highlightCCIcon (ccValue) {
    let ccCardTypePatterns = {
        amex: /^3[47]/,
        visa: /^4/,
        mastercard: /^5[1-5]/,
        // disc: /^6/,
        genric: /(^1|^2|^6|^7|^8|^9|^0)/,
      };

    for (const cardType in ccCardTypePatterns) {
      if ( ccCardTypePatterns[cardType].test(ccValue) ) {
        this.cardType = cardType;
        break;
      }
      this.cardType = 'genric';

    }
  }

  // expiry
  ccExpiryInputOldValue = null
  ccExpiryInputOldCursor = null
  onKeyDownExpiryHandler(e) {
    let el = e.target;
    this.ccExpiryInputOldValue = el.value;
    this.ccExpiryInputOldCursor = el.selectionEnd;
  }
  onInputExpiryHandler(e){
    let el = e.target,
        newValue = el.value;

    newValue = this.unmask(newValue);
    if ( newValue.match(/^\d{0,4}$/g) ) {
      newValue = this.mask(newValue, 2, "/");
      el.value = newValue;
    } else {
      el.value = this.ccExpiryInputOldValue;
    }
    this.onNoCustomerUpdate.next([{exp : el.value}, false])
    this.validateCard()
  }

  validate(evt) {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode( key );
    const regex = /[0-9]|\//;
    if (!regex.test(key) ) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
          theEvent.preventDefault();
      }
    }
  }

  // input events
  onInputCVC() {
    this.onNoCustomerUpdate.next([{CVC : this.newCardInfo.CVC}, false])
    this.validateCard()
  }
  onInputNickName(){
    this.onNoCustomerUpdate.next([{nickname : this.newCardInfo.nickname}, false])
    this.validateCard()
  }
  onInputAddress(){
    this.onNoCustomerUpdate.next([{ address1 : this.newCardInfo.address1 }, false])
    this.validateCard()
  }
  onInputAddressDetail(){
    this.onNoCustomerUpdate.next([{ address2 : this.newCardInfo.address2 }, false])
    this.validateCard()
  }
  onInputCity(){
    this.onNoCustomerUpdate.next([{ city : this.newCardInfo.city }, false])
    this.validateCard()
  }
  onInputZipcode(){
    this.onNoCustomerUpdate.next([{ zipcode : this.newCardInfo.zipcode }, false])
    this.validateCard()
  }

  // validate
  validateCard() {
    this.errorString = {
      cardNumber: '',
      expirDate: '',
      cvc: '',
    };
    const exp = this.newCardInfo.exp;
    let expList = [];
    let expMonth = '';
    let expYear = '';
    let expValidate = false;

    if (this.newCardInfo.cardnumber.length < 14) {
      this.errorString.cardNumber = 'Please enter at least 14 card numbers.';
      return
    }

    if (exp.length === 5) {
      let count = (exp.match(/\//g)||[]).length
      if (count === 1 && exp[2] === '/') {
        expList = exp.split('/');
        expMonth = expList[0];
        expYear = expList[1];
        expValidate = true;
      }
    }
    if (!expValidate) {
      this.errorString.expirDate =  'Please enter at expire date.';
      return
    }
    if (parseInt(expMonth, 10) < 0 || parseInt(expMonth, 10) > 12) {
      this.errorString.expirDate =  'Please enter at expire month 1~12';
      return
    }
    if (expYear.length < 2) {
      this.errorString.expirDate =  'Please enter at least 2 expire Year.';
      return
    }
    if (this.newCardInfo.CVC.length < 3) {
      this.errorString.cvc = 'Please enter at least 3 CVC numbers.';
      return
    }
    this.onNoCustomerUpdate.next([{}, true])
  }

  ///////////////////////////

  ///////////////////////////
  // bank
  checkString(e) {
    let k;
    k = e.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  /* Action */
  selectType(selType) {
    this.newBankAccount.account_holder_type = selType;
    this.isUnfoldBankTypeSelectList = false;
    this.onNoCustomerUpdate.next([{account_holder_type: selType}, false])
    this.validateBankAccount()
  }

  diffAccountNumber() {
    let res = false; // different value is 'true'
    if (!this.newBankAccount.account_number || !this.newBankAccount.confirm_account_number) {
      res = true;
    }
    if (this.newBankAccount.account_number !== this.newBankAccount.confirm_account_number) {
      res = true;
    }
    this.isValidAccountNumber = !res;
    return res;
  }

  onInputHolderName(){
    this.onNoCustomerUpdate.next([{ account_holder_name : this.newBankAccount.account_holder_name }, false])
    this.validateBankAccount()
  }
  onInputRoutingNumber(){
    this.onNoCustomerUpdate.next([{ routing_number : this.newBankAccount.routing_number }, false])
    this.validateBankAccount()
  }
  onInputAccountNumber(){
    this.onNoCustomerUpdate.next([{ account_number : this.newBankAccount.account_number }, false])
    this.validateBankAccount()
  }

  validateBankAccount() {
    this.bankErrorString = {
      routing_number: '',
      account_number: '',
    }
    if (!this.newBankAccount.account_holder_type) return
    if (!this.newBankAccount.account_holder_name) return
    if (!this.newBankAccount.routing_number) {
      this.bankErrorString.routing_number =  'Please enter at routing number.';
      return
    }
    if (this.newBankAccount.routing_number.length != 9) {
      this.bankErrorString.routing_number =  'Routing number is 9 digits.';
      return
    }
    if (!this.newBankAccount.account_number || !this.newBankAccount.confirm_account_number) {
      this.bankErrorString.account_number =  'Please enter at account number.';
      return
    }
    if(this.newBankAccount.account_number.length != 12) {
      this.bankErrorString.account_number =  'Account number is 12 digits.';
    }
    if (this.newBankAccount.account_number !== this.newBankAccount.confirm_account_number) return
    this.onNoCustomerUpdate.next([this.newBankAccount, true])
  }

  ///////////////////////////
  // sepa
  onInputSEPAName(){
    this.onNoCustomerUpdate.next([{ account_holder_name : this.newSEPAAccount.name }, false])
  }
  onInputSEPAEmail(){
    this.onNoCustomerUpdate.next([{ account_holder_email : this.newSEPAAccount.email }, false])
    this.validateEmail()
    this.validateSEPA()
  }
  onInputIBAN(){
    this.onNoCustomerUpdate.next([{ iban : this.newSEPAAccount.iban }, false])
    this.validateIBAN()
    this.validateSEPA()
  }

  validateIBAN(){
    if(!this.newSEPAAccount.iban) return 

    this.formatingIBAN(this.newSEPAAccount.iban)
    let tempIBAN = this.newSEPAAccount.iban?.replace(/ /g, '')
    this.isValidIBAN = validateIBAN(tempIBAN).valid
  }
  formatingIBAN(value, keyCode?){
    if(!value) return
    if(keyCode === 8) return // backspace

    let trimSpaceIBAN = value?.replace(/ /g, '')
    const splitedIBANArr = trimSpaceIBAN.split('')
    
    let result = ''
    for(let i = 0; i < splitedIBANArr.length; i++){
      result += splitedIBANArr[i]
      if(!((i+1) % 4)) result += ' '
    }

    this.newSEPAAccount.iban = result
  }

  validateEmail() {
    if(!this.newSEPAAccount.email) return  this.isValidEmail = false

    this.isValidEmail = true
    // eslint-disable-next-line
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    this.isValidEmail = reg.test(this.newSEPAAccount.email)
    if (this.newSEPAAccount.email.length < 3 && this.newSEPAAccount.email.length !== 0) {
      this.isValidEmail = false
    }
  }

  validateSEPA(){
    if((!this.newSEPAAccount.name || !this.newSEPAAccount.email) || !this.newSEPAAccount.iban) return
    if(!this.isValidEmail) return 
    if(!this.isValidIBAN) return
    this.onNoCustomerUpdate.next([{}, true])
  }

  ////////////////////////
  // common
  setDefaultPayment(token) {
    const dealerId = this.me.dealer_id;
    const updateData = { payment_method_id : token };
    this.paymentService.updatePaymentByStripe(dealerId, updateData)
    .subscribe(res => {
      const msg = 'successfully set as the default payment.'
    }, err => {
      this.errorDialog(err, 'Delete Card');
      this.isLoading = false;
    });
  }

  /* Dialog */
  confirmDialog(header= '', msg= '', icon= 'done', color= 'green') {
    this.m_warning.data = {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: 'OK',
      submit_class: ['button-primary'],
      icon : icon,
      isConfirm : true,
      color: color

    };

    this.m_warning.data['submit_func'] = () => {
      if (color === 'green') {
        this.closeModal(true);
      }
    };
    this.m_warning.open();
  }

  errorDialog(err , title) {
    let msg = 'failed.';
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.confirmDialog(title, msg, 'warning', 'orange');
    }, 200);
    this.isLoading = false;
  }
}
