import { Component, Inject, ViewChildren, ElementRef, QueryList, HostListener } from "@angular/core";
import { SitesService } from "@app/services/sites.service";
import { CommonService } from "@app/services/common.service";
import { DevicesService } from "@app/services/devices.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Speaker, Audio, Relay, ResponseContact, DownloadLink, PlaybackCamera, HttpRequest, Procedure, newProcedureList } from '../../../../app/model/procedure';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import ims from "../../imports";
import { c_components } from "../..";
import { Helper } from "../../../../4services/2helper";

@Component({
  templateUrl: "./c_dialog_mp_direction_procedure_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_mp_direction_procedure_component.scss"],
})
export class c_dialog_mp_direction_procedure_component {
  @ViewChildren('actionLevelSelects', {read: ElementRef}) actionLevelSelects: QueryList<any>;
  @ViewChildren('actionTypeSelects', {read: ElementRef}) actionTypeSelects: QueryList<any>;
  @ViewChildren('ttsSelects', {read: ElementRef}) ttsSelects: QueryList<any>;
  @ViewChildren('talkdownActionSpeakerSelects', {read: ElementRef}) talkdownActionSpeakerSelects: QueryList<any>;
  @ViewChildren('relayActionSelects', {read: ElementRef}) relayActionSelects: QueryList<any>;
  @ViewChildren('cameraSelects', {read: ElementRef}) cameraSelects: QueryList<any>;
  @ViewChildren('responseContactSelects', {read: ElementRef}) responseContactSelects: QueryList<any>;
  @ViewChildren('responseTimeSelects', {read: ElementRef}) responseTimeSelects: QueryList<any>;
  @ViewChildren('downloadContactSelects', {read: ElementRef}) downloadContactSelects: QueryList<any>;

  dealerId = null
  site: any;
  deviceId = null;
  mpDirectionId = null;
  currentCamera = null;

  isLoading = false;
  isEditLoading = false;
  isEditMode = false;

  // steps
  steps = [
    {name: 'Information', idx: 0, description: ''},
    {name: 'Action', idx: 1, description: 'What action would you like to take? The followed action is performed at once.'},
    {name: 'Complete', idx: 2, description: ''},
  ]
  curStep = this.steps[0];

  // for copy backup
  mpDirectionStatus = null
  monitoringNoteContents = { memo: [], tags: [] }

  // procedure
  monitoringProcedures = []
  procedureId = null;
  procedureData: Procedure = new Procedure() 

  // new data
  newProcedureList: newProcedureList[] = [] 
  procedureName = '';

  isUnfoldActionLevelList = false;
  procedureActionLevel = null;
  actionLevelList = [
    {
      value : null,
      name: 'Not Set',
      description: ''
    },
    {
      value : 0,
      name: 'Level 0',
      description: 'No security threat seen. Used for preventive alert actions.'
    },
    {
      value : 1,
      name: 'Level 1',
      description: 'Uncertain security threat seen. Used for necessary alert actions.'
    },
    {
      value : 2,
      name: 'Level 2',
      description: "Probable security threat seen. Used for warning alert actions."
    },
    {
      value : 3,
      name: 'Level 3',
      description: 'Clear security threat seen. Used for critical alert actions.'
    }
  ]

  isEventAction = false;

  actions = [
    {type: 'audio', name:'Talkdown', isUsed: false, isDisabled: false},
    {type: 'relays', name:'Relay', isUsed: false, isDisabled: false},
    {type: 'playback_cameras', name:'2 Minutes Recording', isUsed: false, isDisabled: false},
    {type: 'http_requests', name:'Http Action', isUsed: false, isDisabled: false}
  ];
  filteredActions = this.actions;
  isSupportedMultipleSpeaker = false;
  isSupportedHttpRequest = false;
  siteBridges = [];

  applyErr = ''

  // tts
  ttsList = [];
  selectedTts = null;
  isUnfoldTtsList = false;
  
  siteSpeakers: Speaker[] = [];
  selectedSpeakers: any[] = [];
  allSpeakers = { device_name: "All Speakers", device_audio_id: -1 }
  isShowSpeakerMenu = false;

  // relay
  siteRelays = [];

  // playback
  siteCameras = [];
  isUnfoldSiteCameras = false;
  selectedCameras = [];

  // verification
  isSendVerification = false;

  // Send response Link to contacts
  isUnfoldResponseContacts = false;
  // 1) expired time
  isUnfoldResponseTime = false;
  selectedResponseTime = 1;
  originSelectedResponseTime = 1;

  // 2) emergency Send response Link to contacts
  emergencyContacts = [];
  selectedResponseContacts = [];

  // 3) manual response contact
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Canada];
	manualResponseContactForm = new FormGroup({
		contactName: new FormControl(undefined, [Validators.required]),
		title: new FormControl(undefined),
		phone: new FormControl(undefined, [Validators.required]),
		email: new FormControl(undefined, [Validators.required, Validators.email]),
	});

  // Share Download Link to Contacts
  emergencyDownloadContacts = []
  isUnfoldDownloadContacts = false;
  selectedDownloadContacts = [];

  // 3) manual response contact
	manualDownloadContactForm = new FormGroup({
		contactName: new FormControl(undefined, [Validators.required]),
		title: new FormControl(undefined),
		email: new FormControl(undefined, [Validators.required, Validators.email]),
	});
  
  // HTTP Request
  // code mirror
  codeMirrorOptions: any = {
    theme: 'default',
    mode: 'javascript',
    indentWithTabs: true,
    statementIndent: 4,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: false,
    extraKeys: { "Ctrl-Space": "autocomplete" },
    foldGutter: true,
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    placeholder:
    `{
      "Site_ID": "{{siteID}}"
      "bridge_Channel": "{{bridgeChannel}}"
      "zone_Number": "{{zoneNumber}}"
    }`,
  };
  codeMirrorResponseSuccessOptions: any = {
    theme: 'default',
    mode: 'javascript',
    indentWithTabs: true,
    statementIndent: 4,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: false,
    foldGutter: true,
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    readOnly: true,
  };
  codeMirrorResponseFailedOptions: any = {
    theme: 'default',
    mode: 'text',
    indentWithTabs: true,
    statementIndent: 4,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    readOnly: true,
  };
  httpBobyCaption = `Zone number, Site ID and Bridge Channel can be dynamically added and sent with URL query, body and header.
                    You can use mustache parentheses to contain variables.
                    e.g) {{zoneNumber}} {{siteID}} {{bridgeChannel}}`;

  constructor(
    public dialogRef: MatDialogRef<c_dialog_mp_direction_procedure_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private sitesService: SitesService,
    private commonService: CommonService,
    private devicesService: DevicesService,
    private c_components: c_components,
    private helper: Helper,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initData()
  }

  @HostListener('document:click', ['$event'])
  onGlobalClick(){
    if (this.actionLevelSelects)  {
      const actionLevelSelects = this.actionLevelSelects.toArray();
      actionLevelSelects.forEach(actionLevelSelect => {
        if ('action-level' === (actionLevelSelect.nativeElement.id+'')) {
          if (!actionLevelSelect.nativeElement.contains(event.target)) {
            this.isUnfoldActionLevelList = false;
          }
        }
      })
    }
    if (this.actionTypeSelects)  {
      const actionTypeSelects = this.actionTypeSelects.toArray();
      actionTypeSelects.forEach(actionTypeSelect => {
        this.newProcedureList.forEach((action: any, idx)=> {
          if (('action-type-'+idx) === (actionTypeSelect.nativeElement.id+'')) {
            if (!actionTypeSelect.nativeElement.contains(event.target)) {
              action.isUnfoldActionList = false;
            }
          }
        })
      })
    }
    if (this.ttsSelects) {
      const ttsSelects = this.ttsSelects.toArray();
      ttsSelects.forEach(ttsSelect => {
        if (('action-talkdown') === (ttsSelect.nativeElement.id+'')) {
          if (!ttsSelect.nativeElement.contains(event.target)) {
            this.isUnfoldTtsList = false;
          }
        }
      })
    }
    if(this.talkdownActionSpeakerSelects) {
      const talkdownActionSpeakerSelects = this.talkdownActionSpeakerSelects.toArray();
      talkdownActionSpeakerSelects.forEach(talkdownActionSpeakerSelect => {
        if (('action-speaker') === (talkdownActionSpeakerSelect.nativeElement.id+'')) {
          if (!talkdownActionSpeakerSelect.nativeElement.contains(event.target)) {
            this.isShowSpeakerMenu = false;
          }
        }
      })
    }
    if(this.relayActionSelects) {
      const relayActionSelects = this.relayActionSelects.toArray();
      relayActionSelects.forEach(relayActionSelect => {
        this.newProcedureList.forEach((action: any)=> {
          action?.relays?.forEach((relay: { isShowRelaySelect: boolean; }, idx: string)=> {
            if (('action-relay-'+idx) === (relayActionSelect.nativeElement.id+'')) {
              if (!relayActionSelect.nativeElement.contains(event.target)) {
                relay.isShowRelaySelect = false;
              }
            }
          })
        })
      })
    }

    if(this.cameraSelects) {
      const cameraSelects = this.cameraSelects.toArray();
      cameraSelects.forEach(cameraSelects => {
        if ('action-playback' === (cameraSelects.nativeElement.id+'')) {
          if (!cameraSelects.nativeElement.contains(event.target)) {
            this.isUnfoldSiteCameras = false;
          }
        }
      })
    }

    if(this.responseContactSelects) {
      const responseContactSelects = this.responseContactSelects.toArray();
      responseContactSelects.forEach(responseContactSelects => {
        if ('action-response-contact' === (responseContactSelects.nativeElement.id+'')) {
          if (!responseContactSelects.nativeElement.contains(event.target)) {
            this.isUnfoldResponseContacts = false;
          }
        }
      })
    }

    if(this.responseTimeSelects) {
      const responseTimeSelects = this.responseTimeSelects.toArray();
      responseTimeSelects.forEach(responseTimeSelects => {
        if ('action-response-time' === (responseTimeSelects.nativeElement.id+'')) {
          if (!responseTimeSelects.nativeElement.contains(event.target)) {
            this.isUnfoldResponseTime = false;
          }
        }
      })
    }

    if(this.downloadContactSelects) {
      const downloadContactSelects = this.downloadContactSelects.toArray();
      downloadContactSelects.forEach(downloadContactSelects => {
        if ('action-download-contact' === (downloadContactSelects.nativeElement.id+'')) {
          if (!downloadContactSelects.nativeElement.contains(event.target)) {
            this.isUnfoldDownloadContacts = false;
          }
        }
      })
    }
  }

  close_dialog(): void {
    this.dialogRef.close();
  }

  async initData() {
    this.isLoading = true;
    this.site = this.sitesService.selSite;
    const idx = this.data.idx
    this.deviceId = this.data.deviceId;
    this.mpDirectionId = this.data.mpDirectionId;
    this.monitoringProcedures = this.data.monitoringProcedures
    this.mpDirectionStatus = this.data.mpDirectionStatus
    this.monitoringNoteContents = this.data.monitoringNoteContents

    this.dealerId = await this.helper.me.get_my_dealer_id()
    await Promise.all([
      this.fetchSiteDevices(),
      this.fetchTtsList(),
      this.fetchEmergencyContacts(),
    ])
    this.checkBridgeFWSupportMultipleSpeakerAction()
    this.checkBridgeFWSupportHTTPAction()
    this.makeManualResponseContact()
    this.makeManualDownloadContact()
    await ims.tool.sleep(100)

    if(typeof idx === 'number') {
      this.isEditMode = true
      this.parseProcedureData(idx)
      this.parseFilteredActions()
    }
    
    this.isLoading = false;
  }

  checkBridgeFWSupportMultipleSpeakerAction(){
    this.isSupportedMultipleSpeaker = this.siteBridges?.some(bridge => {
      try {
        return ims.tool.firmware_version_greater_than_or_equal_to(bridge, '3.6.0.1')
      } catch(err) {
        return false
      }
    }) 
  }

  checkBridgeFWSupportHTTPAction(){
    this.isSupportedHttpRequest = this.siteBridges?.some(bridge => {
      try {
        return ims.tool.firmware_version_greater_than_or_equal_to(bridge, '4.4.0.12')
      } catch(err) {
        return false
      }
    })
  }

  async fetchSiteDevices(){
    try {
      const deviceList = await this.sitesService.getSiteDevices(this.dealerId, this.site.site_id).toPromise()
      
      // bridge
      this.siteBridges = deviceList.filter((device) => device?.type === 11)

      // cameras
      this.siteCameras = deviceList.filter((device) => device?.type === 12)
      this.siteCameras.forEach(camera => {
        const cameraBridge = this.siteBridges.find(b => b.device_id === camera.group_id);
        const label = `${cameraBridge.name||'No Name'} (${cameraBridge.virtual_zone} - CH${camera.group_channel+1})`
        const shortLabel = `(${cameraBridge.virtual_zone}-CH${camera.group_channel+1})`;
        const bridgeLabel = `${cameraBridge.name} (${cameraBridge.virtual_zone})`
        camera.id = camera.device_id;
        camera.label = label;
        camera.shortLabel = shortLabel;
        camera.bridgeLabel = bridgeLabel;
      })
      if(this.deviceId) this.currentCamera = this.siteCameras.find(camera => camera.device_id === this.deviceId)

      // speakers
      await this.parseSpeakers()

      // relay
      this.parseSiteRelay()
    } catch(err) {
      console.debug('fetchSiteDevices:>',err)
      this.isLoading = false
    }
  }

  async parseSpeakers(){
    this.siteSpeakers = []
    const audioDevices = await this.sitesService.getAllAudioDevices(this.dealerId, this.site.site_id).toPromise()
    audioDevices.forEach((audioDevice) => {
      const obj = {
        device_name : audioDevice.name,
        device_id: null,
        device_audio_id : audioDevice.id
      }
      this.siteSpeakers.push(obj)
    })

    this.siteBridges.forEach(bridge => {
      const bridgeSpeaker = {
        device_audio_id: bridge.device_id, // 내부 사용용. api 호출시에는 삭제
        device_id: bridge.device_id,
        device_name: `${bridge.name} (Bridge Speaker)`
      }
      this.siteSpeakers.unshift(bridgeSpeaker)
    })
  }

  parseSiteRelay(){
    if(!this.siteBridges || !this.siteBridges?.length) return console.debug('siteBridges is empty')
      
    let relays = []
    this.siteBridges.forEach((bridge) => {
      bridge.relays.forEach((relay) => {
        if(!relay.show_monitoring_enabled) return
        const obj = {
          isShowRelaySelect : false,
          device_name : relay.name,
          device_do_id : relay.id,
          device_id : relay.device_id,
          normal_icon : relay.normal_icon,
          latch_enabled : relay.latch_enabled,
          momentary_enabled : relay.momentary_enabled,
          momentary_delay_times: relay.momentary_delay_times,
          mode_duration : relay.momentary_enabled ? relay.momentary_delay_times : 1,
          mode : relay.latch_enabled ? 'latch' : 'momentary',
          energized_icon : relay.energized_icon,
          channel : relay.channel,
          show_monitoring_enabled : relay.show_monitoring_enabled,
        }
        relays.push(obj);
      })
    })
    this.siteRelays = relays
  }

  async fetchTtsList(){
    try {
      if(!this.site) return this.isLoading = false
      const ttsList = await this.sitesService.getTTSList(this.dealerId, this.site.site_id).toPromise()
      this.ttsList = []
      ttsList.forEach(tts => {
        const obj = {
          site_tts_id: tts.id,
          tts_text: tts.text,
          type: tts.type,
          playing_time: tts.playing_time
        }
        this.ttsList.push(obj)
      })
    } catch(err) {
      console.debug(err)
      this.ttsList = []
      this.isLoading = false
    }
  }

  async fetchEmergencyContacts(){
    try {
      if(!this.site) return this.isLoading = false
      const dealerId = this.dealerId
      const siteId = this.site.site_id
      const emergencyContacts = await this.sitesService.getSiteContacts(dealerId, siteId).toPromise()
      emergencyContacts.forEach(v => v.site_contact_id = v.contact_id)
      this.emergencyContacts = emergencyContacts
      this.emergencyDownloadContacts = emergencyContacts.filter(v => v.email)
    } catch(err) {
      console.debug(err)
      this.emergencyContacts = []
      this.isLoading = false
    }
  }

  parseProcedureData(idx: number){
    this.sanitizeProcedureData(idx)
    const list = ims._.cloneDeep(this.procedureData)
    if(!list) return

    this.makeNewProcedureList(list)
  }
  sanitizeProcedureData(idx: string | number){
    // null 값인 내용들은 삭제
    const monitoringProcedures = this.monitoringProcedures
    const tempProcedureData = ims._.cloneDeep(monitoringProcedures[idx])
    for(const key in tempProcedureData) {
      const value = tempProcedureData[key]
      if(value === null) delete tempProcedureData[key]
      if(Array.isArray(value) && !value?.length) delete tempProcedureData[key]
    }
    this.procedureData = tempProcedureData
    this.procedureId = tempProcedureData.id
  }
  makeNewProcedureList(list: Procedure){
    this.newProcedureList = [] // init
    for(const key in list) {
      if(key === 'name' || key ==='level') continue
      if(key === 'isShowMenu' || key === 'id' || key === 'site_mp_direction_id') continue
      if(key === 'created_at' || key === 'updated_at') continue

      const obj = new newProcedureList()
      obj.actionType = key
      obj[key] = list[key]
      if(key === 'relays') obj.relays = this.parseRelaysToSiteRelays(list.relays)
      if(key === 'audio') {
        const isAllSpeakerForProcedure = list.audio.speakers === null && list.audio.bridge_speakers === null
        if(isAllSpeakerForProcedure) {
          this.selectedSpeakers = [this.allSpeakers]
        } else {
          if(!list.audio?.bridge_speakers) list.audio.bridge_speakers = []
          if(!list.audio.speakers) list.audio.speakers = []

          // 형식 맞추기
          list.audio.speakers?.forEach(v => v.device_id = null)
          list.audio.bridge_speakers?.forEach(v => v.device_audio_id = v.device_id)
          this.selectedSpeakers = list.audio.speakers.concat(list.audio?.bridge_speakers)
        }
      }
      if(key === 'playback_cameras') {
        this.selectedCameras = this.parsePlaybackCamerasToSiteCameras(list.playback_cameras)
      }
      if(key === 'verification') {
        this.isSendVerification = list.verification ? true : false
        if(!list.verification) continue // false면 보여지지 않도록 하자
      }
      if(key === 'response_contacts') {
        this.parseSelectedResponseContacts(list.response_contacts)
        this.selectedResponseTime = list.response_contacts[0]?.expire_time || 1
        this.originSelectedResponseTime = list.response_contacts[0]?.expire_time || 1
      }
      if(key === 'download_contacts') {
        this.parseSelectedDownloadContacts(list.download_contacts)
      }
      if(key === 'http_requests') {
        obj.http_requests = []
        this.setHttpRequestForm(list.http_requests, obj.http_requests)
      }
      this.newProcedureList.push(obj)
    }
    this.procedureName = list.name
    this.procedureActionLevel = this.actionLevelList.find(level => level.value === list.level)
    this.selectedTts = list.audio
    
    this.isEventAction = this.isCheckEventAction(this.newProcedureList)
    this.setEventActions()
  }

  // RELAY
  parseRelaysToSiteRelays(relays){
    if(!relays || !Array.isArray(relays) || !relays.length) return []
    relays.forEach(relay => {
      const siteRelay = this.siteRelays.find(v => v?.device_do_id == relay.device_do_id)
      if(!siteRelay) return
      relay.isShowRelaySelect = false
      relay.device_name = siteRelay.device_name
      relay.device_do_id = siteRelay.device_do_id
      relay.device_id = siteRelay.device_id
      relay.normal_icon = siteRelay.normal_icon
      relay.latch_enabled = siteRelay.latch_enabled
      relay.momentary_enabled = siteRelay.momentary_enabled
      relay.mode_duration = relay.mode_duration ? relay.mode_duration : siteRelay.momentary_delay_times
      relay.mode = siteRelay.latch_enabled ? 'latch' : 'momentary'
      relay.energized_icon = siteRelay.energized_icon
      relay.channel = siteRelay.channel
      relay.show_monitoring_enabled = siteRelay.show_monitoring_enabled
    })
    return relays
  }

  // PLAYBACK
  parsePlaybackCamerasToSiteCameras(playbackCameras){
    if(!playbackCameras || !Array.isArray(playbackCameras) || !playbackCameras.length) return []
    playbackCameras.forEach(camera => {
      const siteCamera = this.siteCameras.find(cam => cam?.device_id == camera.device_id)
      if(!siteCamera) return
      const cameraBridge = this.siteBridges.find(b => b.device_id == siteCamera?.group_id);
      const label = `${cameraBridge?.name||'No Name'} (${cameraBridge?.virtual_zone} - CH${siteCamera?.group_channel+1})`
      const shortLabel = `(${cameraBridge?.virtual_zone}-CH${siteCamera?.group_channel+1})`;
      const bridgeLabel = `${cameraBridge?.name} (${cameraBridge?.virtual_zone})`
      // camera.id = siteCamera.device_id;
      camera.name = siteCamera.name;
      camera.label = label;
      camera.shortLabel = shortLabel;
      camera.bridgeLabel = bridgeLabel;
    })
    return playbackCameras
  }

  // RESPONSE CONTACTS
  parseSelectedResponseContacts(contactList){
    if(!contactList || !Array.isArray(contactList) || !contactList?.length) return []
    let manualSelectedResponseContacts = []
    contactList.forEach(contact => {
      const deletedEmergencyContact = Object.keys(contact).includes('site_contact_id')
      const isHave = this.emergencyContacts.find(emergencyContact => emergencyContact.site_contact_id === contact.site_contact_id)
      if(isHave){
        return this.selectedResponseContacts.push(contact)
      } else {
        if(deletedEmergencyContact) return // 삭제된 emergency contact는 제외
        return manualSelectedResponseContacts.push(contact)
      }
    })
    this.setManualContactsForm(manualSelectedResponseContacts);
  }

  makeManualResponseContact(){
    this.manualResponseContactForm = this.fb.group({
      contacts: this.fb.array([])
    });
  }

  get contacts(): FormArray {
    return this.manualResponseContactForm.get('contacts') as FormArray;
  }

  setManualContactsForm(list) {
    list.forEach(contact => {
      this.contacts.push(this.fb.group({
        name: [contact.name, Validators.required],
        title: [contact.title],
        email: [contact.email, [Validators.required, Validators.email]],
        phone: [contact.phone, Validators.required]
      }));
    });
  }

  // Share DOWNLOAD Link to CONTACTS
  parseSelectedDownloadContacts(downloadContactList){
    if(!downloadContactList || !Array.isArray(downloadContactList) || !downloadContactList?.length) return []

    let manualSelectedDownloadContacts = []
    downloadContactList.forEach(contact => {
      const deletedEmergencyContact = Object.keys(contact).includes('site_contact_id')
      const isHave = this.emergencyDownloadContacts.find(emergencyContact => emergencyContact.site_contact_id == contact.site_contact_id)
      if(isHave){
        return this.selectedDownloadContacts.push(contact)
      } else {
        if(deletedEmergencyContact) return // 삭제된 emergency contact는 제외
        return manualSelectedDownloadContacts.push(contact)
      }
    })
    this.setDownloadContactsForm(manualSelectedDownloadContacts);
  }

  makeManualDownloadContact(){
    this.manualDownloadContactForm = this.fb.group({
      downloadContacts: this.fb.array([])
    });
  }

  get downloadContacts(): FormArray {
    return this.manualDownloadContactForm.get('downloadContacts') as FormArray;
  }

  setDownloadContactsForm(list) {
    list.forEach(contact => {
      this.downloadContacts.push(this.fb.group({
        name: [contact.name, Validators.required],
        title: [contact.title],
        email: [contact.email, [Validators.required, Validators.email]],
      }));
    });
  }

  // HTTP REQUEST
  setHttpRequestForm(targets, actions){
    targets.forEach(target=> {
      let contentType = null;
      let contentTypeCustom = ''
      let headersCustom = [];
      let isShowHeaderAuth = false;
      let apiAddTo = null;

      let headers = JSON.parse(target.headers) ?? null;
      // Parse headers
      if(headers instanceof Object) {
        Object.entries(headers)?.forEach(([k, v]:any) => {
          if (k === 'Content-Type') contentType = v
          if (k === target.auth_header_key) { // 이거 반드시 BE와 소통해서 수정해야함
          // if (k === 'Authorization') {
            // Basic
            if (target.auth_type === 1) {
              isShowHeaderAuth = true;
              if (!v) v = ''
              let splitAuth = v.split(' ');
              let encodedToken = splitAuth[1] + '';
              let decodedToken = atob(encodedToken);
              let idPass = decodedToken.split(':') ?? [];
              target.username = idPass[0] ?? '';
              target.password = idPass[1] ?? '';
              target.auth_header_key = k;
              target.auth_header_val = v;
            }
            // API Key
            if (target.auth_type === 3) {
              isShowHeaderAuth = true;
              if (k === target.auth_header_key) apiAddTo = 'header'
              target.apiKey = k;
              target.apiVal = v;
              target.auth_header_key = k;
              target.auth_header_val = v;
            }
            // Bearer Token
            if (target.auth_type === 4) {
              isShowHeaderAuth = true;
              target.authKey = k;
              target.authVal = v;
              target.auth_header_key = k;
              target.auth_header_val = v;
              if (!v) v = ''
              let splitAuth = v.split(' ');
              target.bearerToken = splitAuth[1] ?? '';
            }
          }
  
          // content type custom
          if (k === 'Content-Type') {
            if (v !== 'application/json' && v !== 'text/plain') {
              contentType = 'custom'
              contentTypeCustom = v
            }
          }
  
          // push to custom headers
          if (k !== 'Content-Type' && k !== target.auth_header_key) headersCustom.push({key:k, value:v});
        });
      }

      // set api query
      if (target.auth_type === 3 && !apiAddTo) {
        apiAddTo = 'query'
        isShowHeaderAuth = false;
        if (!target.auth_header_key) target.auth_header_key = ''
        let queryData = target.auth_header_key.split(' ')
        let query = queryData[1] ?? ''
        if (!target.url) target.url = ''
        target.url = target.url.replace(query, '')
        query = query.replace('?', '')
        query = query.replace('&', '')
        let apiKeyValue = query.split('=');
        target.apiKey = apiKeyValue[0] ?? ''
        target.apiVal = apiKeyValue[1] ?? ''
      }

      // Check URL
      // must include http:// or https://
      var httpRegex = /https?:\/\//g
      // Validate URL
      let testResult = httpRegex.test(target.url);
      target.isValidHttpUrl = testResult
      
      // Check Bytes - Body /////
      const bodyMax = 4000 // BE max is 4096
      if (target.body) target.bodyBytes = new Blob([target.body]).size;
      else target.bodyBytes = 0
      
      if (target.bodyBytes >= bodyMax) {
        target.isBodyMaxBytes = true
        }
        else target.isBodyMaxBytes = false
        
        // Check Bytes - Headers /////
      const headerMax = 2000 // BE max is 2048
      
      target.headerBytes = new Blob([target.headers]).size;
      
      if (target.headerBytes >= headerMax) target.isHeaderMaxBytes = true
      else target.isHeaderMaxBytes = false
      
      // data set
      const action = new HttpRequest({
        method: target.method?.toUpperCase() ?? 'GET',
        url: target.url,
        name: target.name,

        headers : headers,
        headersCustom: headersCustom,

        auth_type : target.auth_type ?? 1,
        auth_header_key : target.auth_header_key,
        auth_header_val: target.auth_header_val,
        contentType: contentType ?? 'application/json', // only FE default
        contentTypeCustom: contentTypeCustom,

        body : target.body,
        username : target.username,
        password : target.password,
        timeout : target.timeout,
        bearerToken: target.bearerToken,
        retry : target.retry,
        })
      action.isValidHttpUrl = target.isValidHttpUrl, // only FE default
      action.isHeaderMaxBytes = target.isHeaderMaxBytes
      action.headerBytes = target.headerBytes
      action.isBodyMaxBytes = target.isBodyMaxBytes
      action.bodyBytes = target.bodyBytes
      actions.push(action);
    })
  }

  // ---------------------------------------
  // ACTION LEVEL
  toggleActionLevelSelectDropdown(event){
    event.stopPropagation();
    this.isUnfoldActionLevelList = !this.isUnfoldActionLevelList;
  }

  selectActionLevel(event, actionLevel: number){
    event.stopPropagation();
    this.isUnfoldActionLevelList = false;
    this.procedureActionLevel = this.actionLevelList.find(level => level.value === actionLevel)
  }

  // ---------------------------------------
  // TYPE
  toggleIsEventAction() {
    this.isEventAction = !this.isEventAction;
    this.isEventAction
      ? this.addEventAction()
      : this.removeEventAction()
    this.parseFilteredActions()
  }

  setEventActions(){
    this.isEventAction
      ? this.addEventAction()
      : this.removeEventAction()
  }

  addEventAction(){
    const isPrivacyMode = this.currentCamera?.is_privacy
    this.actions.push({type: 'verification', name:'Verification', isUsed: false, isDisabled: false})
    this.actions.push({type: 'response_contacts', name:'Send Response Link to Contacts', isUsed: false, isDisabled: isPrivacyMode})
    this.actions.push({type: 'download_contacts', name:'Share Download Link to Contacts', isUsed: false, isDisabled: isPrivacyMode})
  }
  removeEventAction(){
    this.actions.forEach(action => {
      if(action.type === 'verification' || action.type === 'response_contacts' || action.type === 'download_contacts') action.isUsed = false
    })
    this.actions = this.actions.filter(action => {
      return action.type !== 'verification' && action.type !== 'response_contacts' && action.type !== 'download_contacts'
    })
    const verificationIdx = this.newProcedureList.findIndex(action => action.actionType === 'verification') 
    if(verificationIdx > -1) {
      this.newProcedureList.splice(verificationIdx, 1)
      this.isSendVerification = false
    }
    const responseContactsIdx = this.newProcedureList.findIndex(action => action.actionType === 'response_contacts') 
    if(responseContactsIdx > -1) this.newProcedureList.splice(responseContactsIdx, 1)
    const downloadContactsIdx = this.newProcedureList.findIndex(action => action.actionType === 'download_contacts')
    if(downloadContactsIdx > -1) this.newProcedureList.splice(downloadContactsIdx, 1)
  }

  isCheckEventAction(newProcedureList) : boolean {
    return newProcedureList.some(action => {
      if(action.actionType === 'verification' && action.verification) return true
      if(action.actionType === 'response_contacts' && action.response_contacts?.length) return true
      if(action.actionType === 'download_contacts' && action.download_contacts?.length) return true
    }) ? true : false
  }

  // ---------------------------------------
  // ACTION TYPE
  toggleActionListSelectDropdown(event, idx: string | number){
    event.stopPropagation();
    this.newProcedureList[idx]['isUnfoldActionList'] = !this.newProcedureList[idx]['isUnfoldActionList'];
  }
  selectedActionTypeName(actionType: string){
    if(!actionType) return
    return this.actions.find(action => action.type === actionType)?.name
  }

  // ---------------------------------------
  // ACTION 1. TTS
  toggleTTSListSelectDropdown(event){
    event.stopPropagation();
    this.isUnfoldTtsList = !this.isUnfoldTtsList;
  }
  selectTTS(event, audioAction: Audio, tts: any){
    event.stopPropagation();
    if(!tts) return;

    this.isUnfoldTtsList = false;
    this.selectedTts = tts;

    audioAction.site_tts_id = tts.id
    audioAction.tts_text = tts.text
    audioAction.type = tts.type
    audioAction.playing_time = tts.playing_time
  }

  computedPlayTime(tts: { playing_time: number; }){
    if(!tts) return
    return Math.ceil((tts?.playing_time/1000))
  }

  toggleSpeakerSelect(event) {
    event.stopPropagation();
    this.isShowSpeakerMenu = !this.isShowSpeakerMenu
  }

  isSelectedSpeaker(speaker): boolean {
    if(!speaker) return false
    if(!this.selectedSpeakers?.length) return false
    const isHave = this.selectedSpeakers.find(selectedSpeaker => {
      return selectedSpeaker.device_audio_id == speaker.device_audio_id &&
             selectedSpeaker.device_id == speaker.device_id
    })
    return isHave ? true : false
  }

  selectSpeaker(speaker) {
    const dayIdx = this.selectedSpeakers.findIndex(v => {
      return v.device_audio_id === speaker.device_audio_id &&
             v.device_id === speaker.device_id
    });
    const allSpeakers = this.allSpeakers;
    if (speaker === allSpeakers) {
      if (dayIdx !== -1) { 
        this.removeSpeaker(speaker)
      } else {
        this.selectedSpeakers = [allSpeakers];
      }
    } else {
      const allDayIdx = this.selectedSpeakers.findIndex(v => v.device_audio_id === -1);
      if (allDayIdx !== -1) {
        this.selectedSpeakers.splice(allDayIdx, 1);
      }
      if (dayIdx !== -1) {
        this.removeSpeaker(dayIdx);
      } else {
        this.selectedSpeakers.push(speaker);
      }
    }
    if (this.selectedSpeakers.length === this.siteSpeakers.length || speaker.device_audio_id === -1) {
      this.selectedSpeakers = [allSpeakers];
    } 
  }
  removeSpeaker(speakerIdx) {
    if (speakerIdx >= 0) {
      this.selectedSpeakers.splice(speakerIdx, 1);
    }
  }

  applyStyleBasedOnSpeakerRemoval(){
    return this.selectedSpeakers.some(v => !v.device_id && !v.device_audio_id)
  } 

  // ---------------------------------------
  // ACTION 2. RELAY
  selectActionRelay(event, actionRelay, relay) {
    event.stopPropagation();

    if(!relay) return
    if(!relay?.show_monitoring_enabled) return
    actionRelay.isShowRelaySelect = false;
    actionRelay.device_name = relay.device_name
    actionRelay.device_do_id = relay.device_do_id
    actionRelay.device_id = relay.device_id
    actionRelay.normal_icon = relay.normal_icon
    actionRelay.latch_enabled = relay.latch_enabled
    actionRelay.momentary_enabled = relay.momentary_enabled
    actionRelay.mode_duration = relay.momentary_enabled ? relay.momentary_delay_times : 1
    actionRelay.mode = relay.latch_enabled ? 'latch' : 'momentary'
    actionRelay.energized_icon = relay.energized_icon
    actionRelay.channel = relay.channel
    actionRelay.show_monitoring_enabled = relay.show_monitoring_enabled
  }

  addRelay(event, idx: string | number){
    event.stopPropagation();
    const newAction = this.newProcedureList[idx]
    newAction.relays.push(new Relay());
  }

  deleteRelay(relays: Relay[], relayIdx) {
    relays?.splice(relayIdx, 1);
    if(relays?.length === 0) {
      relays.push(new Relay());
    }
    relays.forEach(relay => relay.isShowRelaySelect = false)
  }

  toggleRelaySelectDropdown(event, relay){
    event.stopPropagation(); 
    relay.isShowRelaySelect = !relay.isShowRelaySelect
  }
  validateRelays(relayActions: Relay[], selRelay) {
    let result = null;
    let isIncludes = relayActions.find((relayAction) => relayAction?.device_do_id === selRelay?.device_do_id)
    result = isIncludes ? false : true
    return result;
  }
  isMoreEnabledRelay(relayActions: string | any[]) {
    let enabledRelays = this.siteRelays.filter(relay => relay?.device_do_id && relay?.show_monitoring_enabled)
    return relayActions?.length >= enabledRelays?.length ? false : true
  }
  checkNoRelayAvailable(relayActions: any[]){
    const hasAvailable = this.siteRelays.some(relay => this.validateRelays(relayActions, relay))

    return hasAvailable ? false : true
  }
  applyStyleBasedOnRelayRemoval(relay){
    return relay?.device_id && (!relay?.device_do_id || !relay?.show_monitoring_enabled)
  } 

  // ---------------------------------------
  // ACTION 3. PLAYBACK
  toggleCameraSelect(){
  this.isUnfoldSiteCameras = !this.isUnfoldSiteCameras; 
  }
  selectCamera(camera){
    const cameraIdx = this.selectedCameras.findIndex(cam => cam.device_id === camera.device_id);
    if (cameraIdx !== -1) {
      this.removeCamera(camera);
    } else {
      this.selectedCameras.push(camera);
    }
  }
  removeCamera(camera){
    const cameraIdx = this.selectedCameras.findIndex(cam => cam.device_id === camera.device_id);
    if (cameraIdx >= 0) {
      this.selectedCameras.splice(cameraIdx, 1);
    }
  }
  isSelectedCamera(camera) {
    if(!camera) return false
    if(!this.selectedCameras?.length) return false
    const isHave = this.selectedCameras.find(selectedCamera => selectedCamera?.device_id === camera?.device_id)
    return isHave
  }

  applyStyleBasedOnCameraRemoval(){
    return this.selectedCameras.some(v => !v.device_id)
  }

  // ---------------------------------------
  // ACTION 4. VERIFICATION
  // NOTHING

  // ---------------------------------------
  // ACTION 5. Send RESPONSE Link to CONTACTS
  toggleResponseContactSelect(){
    this.isUnfoldResponseContacts = !this.isUnfoldResponseContacts
  }
  selectDownloadContact(downloadContact){
    const contactIdx = this.selectedDownloadContacts.findIndex(contact => {
      if(contact?.site_contact_id === downloadContact?.site_contact_id) return true
      return false
    });
    if (contactIdx !== -1) {
      this.removeDownloadContact(downloadContact);
    } else {
      this.selectedDownloadContacts.push(downloadContact);
    }
  }
  removeDownloadContact(downloadContact){
    const contactIdx = this.selectedDownloadContacts.findIndex(contact => {
      if(contact?.site_contact_id === downloadContact?.site_contact_id) return true
      return false
    });
    if (contactIdx >= 0) {
      this.selectedDownloadContacts.splice(contactIdx, 1);
    }
  }
  isSelectedDownloadContact(downloadContact){
    if(!downloadContact) return false
    if(!this.selectedResponseContacts?.length) return false
    const isHave = this.selectedDownloadContacts.find(selectedDownloadContact => {
      if(selectedDownloadContact?.email === downloadContact?.email) return true
      return false
    })
    return isHave
  }

  addDownloadContact(){
    this.downloadContacts.push(this.fb.group({
      name: ['', Validators.required],
      title: [''],
      email: ['', [Validators.required, Validators.email]],
    }));
  }

  deleteDownloadContact(contactIdx){
    this.downloadContacts.removeAt(contactIdx);
  }

  applyStyleBasedOnDownloadContactRemoval(){
    return this.selectedDownloadContacts.some(v => !v.site_contact_id)
  }

  // ---------------------------------------
  // ACTION 6. Share Download Link to Contacts
  toggleDownloadContactSelect(){
    this.isUnfoldDownloadContacts = !this.isUnfoldDownloadContacts
  }
  selectResponseContact(responseContact){
    const contactIdx = this.selectedResponseContacts.findIndex(contact => {
      if(contact?.phone && contact?.phone === responseContact?.phone) return true
      if(contact?.email && contact?.email === responseContact?.email) return true
      return false
    });
    if (contactIdx !== -1) {
      this.removeResponseContact(responseContact);
    } else {
      this.selectedResponseContacts.push(responseContact);
    }
  }
  removeResponseContact(responseContact){
    const contactIdx = this.selectedResponseContacts.findIndex(contact => {
      if(contact?.phone && contact?.phone === responseContact?.phone) return true
      if(contact?.email && contact?.email === responseContact?.email) return true
      return false
    });
    if (contactIdx >= 0) {
      this.selectedResponseContacts.splice(contactIdx, 1);
    }
  }
  isSelectedResponseContact(responseContact){
    if(!responseContact) return false
    if(!this.selectedResponseContacts?.length) return false
    const isHave = this.selectedResponseContacts.find(selectedContact => {
      if(selectedContact?.phone && selectedContact?.phone === responseContact?.phone) return true
      if(selectedContact?.email && selectedContact?.email === responseContact?.email) return true
      return false
    })
    return isHave
  }

  addResponseContact(){
    this.contacts.push(this.fb.group({
      name: ['', Validators.required],
      title: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required]
    }));
  }

  deleteResponseContact(contactIdx){
    this.contacts.removeAt(contactIdx);
  }

  applyStyleBasedOnResponseContactRemoval(){
    return this.selectedDownloadContacts.some(v => !v.site_contact_id)
  }
  // expire time
  toggleResponseTimeSelect(event){
    event.stopPropagation();
    this.isUnfoldResponseTime = !this.isUnfoldResponseTime
  }
  selectResponseTime(event, time){
    event.stopPropagation();
    this.isUnfoldResponseTime = false;
    this.selectedResponseTime = time
  }
  isSelectedResponseTime(time){
    if(!time) return false
    return this.selectedResponseTime === time
  }

  // ---------------------------------------
  // ACTION 7. HTTP REQUEST
  httpRequestAdvancedToggle(action) {
    action['isShowAdvanced'] = !action['isShowAdvanced'];
  }
  addHttpRequestHeader(action) {
    if (!action['headersCustom']) action['headersCustom'] = [];
    action['headersCustom'].push({key:'',value:'',})
  }
  removeHttpRequestHeader(action, hIdx) {
    if (!action['headersCustom']) action['headersCustom'] = [];
    action['headersCustom'].splice(hIdx, 1)
  }
  addHttpRequest($event, idx){
    $event.stopPropagation();
    const action = new HttpRequest({
      method: 'GET',
      url: '',
      name: '',

      headers : null,
      headersCustom: [],

      auth_type : 1,
      auth_header_key : null,
      auth_header_val: null,
      contentType: 'application/json', // only FE default
      contentTypeCustom: '',

      body : null,
      username : null,
      password : null,
      bearerToken: null,
      });

    // only for FE default
    action.isValidHttpUrl = false // only FE default
    action.isHeaderMaxBytes = false
    action.headerBytes = 0
    action.isBodyMaxBytes = false
    action.bodyBytes = 0
    this.newProcedureList[idx].http_requests.push(action)
  }
  deleteHttpRequest(actions, actionIdx) {
    actions?.splice(actionIdx, 1);
    if(actions?.length === 0) {
      actions.push({
        method: 'GET',
        url: '',
        name: '',

        headers : '',
        headersCustom: [],

        auth_type : '',
        auth_header_key: '',
        auth_header_val: '',
        
        contentType: 'application/json', 
        contentTypeCustom: '',

        body : null,
        username : '',
        password : '',
        bearerToken: '',
        isValidHttpUrl: false,
      });
    }
  }
  async testHttpRequest(event, action){
    event?.stopPropagation();
    const bridge = this.findSupportedBridge()
    const body = this.createProxyBody(bridge, action);
    const dealerId = this.dealerId;
    const siteId = this.site.site_id;
    const deviceId = bridge.device_id;

    try {
      this.isLoading = true;
      await this.devicesService.proxy(dealerId, siteId, deviceId, body, 10, null, 'v1.1', 'text').toPromise()
      action.httpActionTestingResult = true
      this.commonService.showSuccessToast('TEST Http Action', 'Success');
      this.isLoading = false;
    } catch(err) {
      console.debug(err)
      action.httpActionTestingResult = false
      this.commonService.showErrorToast(err, 'TEST Http Action', 'Failed');
      this.isLoading = false;
      if(err?.error?.error) {
        return action.httpActionTestingErr = err?.error?.error
      } if(err?.error) {
        return action.httpActionTestingErr = err?.error
      } else {
        return action.httpActionTestingErr = err?.message || 'Failed'
      }
    }
  }

  findSupportedBridge(){
    return this.siteBridges.find(bridge => {
      try {
        return ims.tool.firmware_version_greater_than_or_equal_to(bridge, ' 4.4.0.12')
      } catch(err) {
        return false
      }
    })
  }
  createProxyBody(device, action) {
    const topic = this.devicesService.makeid();
    const headersObj = {}
    action?.contentType !== 'custom'
      ? headersObj['Content-Type'] = action?.contentType
      : headersObj['Content-Type'] = action?.contentTypeCustom

    if(action?.auth_header_key) headersObj[action.auth_header_key] = action.auth_header_val
    if(action?.headersCustom?.length) action.headersCustom.forEach(header => headersObj[header.key] = header.value) 
      
    const body = {
      headers: {
        method: 'post',
        path: `ckbapiv2/actions/proxyhttp`,
        mqttResponseTopic: `devices/${device.mac}/0/ckbapiv2/res/${topic}`
      },
      body: {
        method: action.method,
        url: action.url,
        authentication: {
          type: action.auth_type,
          username: action.username,
          password: action.password,
        },
        headers: headersObj,
        body: action.body, // 이미 내부에서 JSON.stringify()를 하기 때문에 그냥 보내기
        timeout: 30,
        retry: 3,
      }
    };
    return body;
  }
  disabledTestingButton(action){
    if(!action.url) return true
    if(!action['isValidHttpUrl']) return true
    if(!action.method) return true
    if(action.method === 'POST' && !action.body) return true
    if(action.method === 'PUT' && !action.body) return true
    if(action.method === 'PATCH' && !action.body) return true
    if(action.contentType === 'custom' && !action.contentTypeCustom) return true
    if(action.isHeaderMaxBytes) return true
    if((action.auth_type === 1 || action.auth_type === 2) && !action.username) return true
    if((action.auth_type === 1 || action.auth_type === 2) && !action.password) return true
    if(action.auth_type === 3 && !action['apiKey']) return true
    if(action.auth_type === 3 && !action['apiVal']) return true
    if(action.auth_type === 4 && !action['bearerToken']) return true
    if(action['bodyBytes'] >= 4000) return true
    if(action['headerBytes'] >= 2000) return true
  }

  removeInvalidChar(str) {
    let result = '';
  
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
  
      // ASCII 제어 문자 (0x00 ~ 0x1F, 0x7F) 및 C1 제어 문자 (0x80 ~ 0x9F) 가 아닌 경우만 추가
      if (!((charCode >= 0x00 && charCode <= 0x1F) || charCode === 0x7F || (charCode >= 0x80 && charCode <= 0x9F))) {
        result += str.charAt(i);
      }
    }

    return result;
  };
  httpDataChanged(action, type) {
    // URL /////
    if (type === 'url') {
      // init
      var httpRegex = /https?:\/\//g
      
      // remove spaces
      action.url = action.url.trim();
      
      // must include http:// or https://
      let isFormatHttp = httpRegex.test(action.url);

      // remove invalid character.. like 제어문자
      action.url = this.removeInvalidChar(action.url)

      action.isValidHttpUrl = isFormatHttp
    }

    // Auth /////
    if (type === 'auth') {
      // No Auth
      if (action.auth_type === 0) {
        action.isShowHeaderAuth = false;
      }
      // Basic
      else if (action.auth_type === 1) {
        action.isShowHeaderAuth = true;
        let token = btoa(`${action.username ?? ''}:${action.password ?? ''}`);
        action.auth_header_key = 'Authorization';
        action.auth_header_val = `Basic ${token}`;
      }
      // Digest
      else if (action.auth_type === 2) {
        action.isShowHeaderAuth = false;
      }
      // API Key
      else if (action.auth_type === 3) {
        if (action.apiAddTo === 'header') {
          action.isShowHeaderAuth = true;
          action.auth_header_key = action?.apiKey;
          action.auth_header_val = action?.apiVal;
        }
        else if (action.apiAddTo === 'query') {
          action.isShowHeaderAuth = false;
        }
      }
      // Bearer Token
      else if (action.auth_type === 4) {
        action.isShowHeaderAuth = true;
        action.auth_header_key = 'Authorization';
        action.auth_header_val = `Bearer ${action.bearerToken ?? ''}`;
      }
    }

    // Check Bytes - Body /////
    if (type === 'body') {
      const bodyMax = 4000 // BE max is 4096
      if (action.body) action.bodyBytes = new Blob([action.body]).size;
      else action.bodyBytes = 0

      if (action.bodyBytes >= bodyMax) {
        action.isBodyMaxBytes = true
      }
      else action.isBodyMaxBytes = false
    }

    // Check Bytes - Headers /////
    if (type === 'headers' || type === 'auth' || type === 'content') {
      let headers = {}
      const headerMax = 2000 // BE max is 2048

      if (action.auth_type === 1) {
        if (action.auth_header_key) headers[action.auth_header_key] = action?.auth_header_val
      }
      else if (action.auth_type === 3) {
        if (action.apiAddTo === 'header') {
          if (action.auth_header_key) headers[action.auth_header_key] = action?.auth_header_val
        }
      }
      else if (action.auth_type === 4) {
        if (action.auth_header_key) headers[action.auth_header_key] = action?.auth_header_val
      }

      // Add Content-Type
      if (action.contentType === 'custom') headers['Content-Type'] = action?.contentTypeCustom;
      else headers['Content-Type'] = action?.contentType;

      // Add headers custom to headers
      for (let i = 0; i < action.headersCustom?.length; i++) {
        const header = action?.headersCustom[i];
        headers[header.key] = header.value
      }

      let headerStringify = JSON.stringify(headers)

      action.headerBytes = new Blob([headerStringify]).size;
      if (action.headerBytes >= headerMax) action.isHeaderMaxBytes = true
      else action.isHeaderMaxBytes = false
    }
  }

  // ----------------------------------------------------------------------
  // STEPS
  addAction() {
    this.newProcedureList.push(new newProcedureList());
  }

  selectAction(e, actionType: string, idx: string | number){
    e?.stopPropagation();

    if(this.currentCamera?.is_privacy ) {
      if(actionType === 'response_contacts' || actionType === 'download_contacts') {
        return this.openWarningDialog('Privacy Mode', 'Privacy Mode is enabled. You cannot use this action.')
      }
    }

    this.newProcedureList[idx] = { actionType };
    this.newProcedureList[idx]['isUnfoldActionList'] = false;

    if(actionType === 'audio') {
      const newAction = this.newProcedureList[idx]
      newAction.audio = new Audio();
      this.selectedSpeakers = [this.allSpeakers]
      this.selectedTts = null
    }
    if(actionType === 'relays') {
      const newAction = this.newProcedureList[idx]
      newAction.relays = [new Relay()];
    }
    if(actionType === 'playback_cameras') {
      const newAction = this.newProcedureList[idx]
      newAction.playback_cameras = [];
      this.selectedCameras = []
    }
    if(actionType === 'verification') {
      const newAction = this.newProcedureList[idx]
      newAction.verification = true;
    }
    if(actionType === 'response_contacts') {
      this.selectedResponseContacts = []
      this.setManualContactsForm([])
    }
    if(actionType === 'download_contacts') {
      this.selectedDownloadContacts = []
      this.setDownloadContactsForm([])
    }
    if(actionType === 'http_requests') {
      const newAction = this.newProcedureList[idx]
      const data = new HttpRequest({
        name: '',
        url: '',
        method: 'GET',
        headersCustom: [],

        headers: null,
        auth_type: 1,
        auth_header_key: null,
        auth_header_val: null,

        body: null,
        username: null,
        password: null,
        bearerToken: null,
        contentType: 'application/json', // only FE default
        contentTypeCustom: ''
      })

      // only FE default
      data.isValidHttpUrl = false 
      data.isHeaderMaxBytes = false
      data.headerBytes = 0
      newAction.http_requests = [data];
    }
    this.parseFilteredActions()
  }
  parseFilteredActions() {
    const usedActionTypeList = this.newProcedureList.map(action => action.actionType)
    this.actions.forEach(action => {
      usedActionTypeList.includes(action.type)
      ? action.isUsed = true
      : action.isUsed = false
    })
    this.filteredActions = this.actions.filter(action => !action.isUsed) 
    this.isSendVerification = this.newProcedureList.find(action => action.actionType === 'verification')?.verification
  }

  deleteAction(actionIdx: number) {
    this.newProcedureList.splice(actionIdx, 1);
    this.parseFilteredActions()
  }

  validPassStep(step: { idx: number; }){
    const isPassStep = this.curStep.idx === step.idx || this.isEditMode;
    return isPassStep;
  }

  goToStep(step: { name: string; idx: any; description: string; }){
    if (this.curStep.idx === step.idx) return;
    this.curStep = step;
  }

  goToPrevStep() {
    const curStep = this.curStep;
    const prevStep = this.steps.filter(s=>s.idx === curStep.idx -1)[0];
    if (prevStep) {
      this.goToStep(prevStep)
    }
  }

  goToNextStep() {
    const curStep = this.curStep;
    const nextStep = this.steps.filter(s=>s.idx === curStep.idx + 1)[0];
    if (nextStep) {
      this.goToStep(nextStep);
    }
  }

  checkStep() {
    const curStep = this.curStep;
    if (curStep.idx === 1) {
      this.onClickApply();
    } else {
      this.goToNextStep();
    }
  }


  isDisabledApply(){
    if(this.curStep.idx === 0) return false
    const notHasActionType = this.newProcedureList.some(action => !action.actionType)
    const notHasAudio = this.newProcedureList.some(action => {
      if(action.actionType === 'audio') {
        if(this.selectedSpeakers.length === 0) return true
        if(!this.selectedTts?.site_tts_id) return true
      }
      return false
    })
    const notHasRelay = this.newProcedureList.some(action => {
      if(action.actionType === 'relays'){
        if(action.relays?.length === 0) return true
        return action.relays?.some(relay => !relay.device_do_id)
      }
      return false
    })
    const notHasCamera = this.newProcedureList.some(action => {
      if(action.actionType === 'playback_cameras'){
        if(this.selectedCameras?.length === 0) return true
      }
      return false
    })
    
    const isNotValidManualContacts = this.contacts.controls.some(contact => {
      if(contact.get('name')?.hasError('required')) return true
      if(contact.get('email')?.hasError('email')) return true
      if(contact.get('phone')?.hasError('phone')) return true
      if( // 둘 다 없어도 문제
        contact.get('email')?.hasError('required') &&
        contact.get('phone')?.hasError('required')
      ) return true
      return false
    })
    const notHasResponseContact = this.newProcedureList.some(action => {
      if(action.actionType === 'response_contacts'){
        if(
          this.selectedResponseContacts?.length === 0 &&
          this.manualResponseContactForm.value.contacts.length === 0
        ) return true
        if(
          this.manualResponseContactForm.value.contacts.length &&
          isNotValidManualContacts
        ) return true
      }
      return false
    })

    const isNotValidDownloadContacts = this.downloadContacts.controls.some(contact => {
      if(contact.get('name').hasError('required')) return true
      if(contact.get('email').hasError('email')) return true
      if(contact.get('email').hasError('required')) return true // 여기서는 필수
      return false
    })
    const notHasDownloadContact = this.newProcedureList.some(action => {
      if(action.actionType === 'download_contacts'){
        if(
          this.selectedDownloadContacts?.length === 0 &&
          this.manualDownloadContactForm.value.downloadContacts.length === 0
        ) return true
        if(
          this.manualDownloadContactForm.value.downloadContacts.length &&
          isNotValidDownloadContacts
        ) return true
      }
      return false
    })
    const notHasHttp = this.newProcedureList.some(action => {
      if(action.actionType === 'http_requests'){
        if(action.http_requests?.length === 0) return true
        return action.http_requests?.some(http => {
          if(!http.name) return true
          if(!http.url) return true
          if(!http['isValidHttpUrl']) return true
          if(!http.method) return true
          if(http.method === 'POST' && !http.body) return true
          if(http.method === 'PUT' && !http.body) return true
          if(http.method === 'PATCH' && !http.body) return true
          if(http.contentType === 'custom' && !http.contentTypeCustom) return true
          if(http.isHeaderMaxBytes) return true
          if((http.auth_type === 1 || http.auth_type === 2) && !http.username) return true
          if((http.auth_type === 1 || http.auth_type === 2) && !http.password) return true
          if(http.auth_type === 3 && !http['apiKey']) return true
          if(http.auth_type === 3 && !http['apiVal']) return true
          if(http.auth_type === 4 && !http['bearerToken']) return true
          if(http['bodyBytes'] >= 4000) return true
          if(http['headerBytes'] >= 2000) return true
          return false
        })
      }
    })

    if(notHasActionType) return true
    if(notHasAudio) return true
    if(notHasRelay) return true
    if(notHasCamera) return true
    if(notHasResponseContact) return true
    if(notHasDownloadContact) return true
    if(notHasHttp) return true
    if(!this.isDiff()) return true

    return false
  }

  isDiff() {
    const procedureData = this.procedureData;
    const newProcedureList = this.newProcedureList;
    if (!procedureData || !newProcedureList.length) return false;
    if (procedureData.name !== this.procedureName) return true;
    if (procedureData.level !== this.procedureActionLevel?.value) return true;

    const hasDifferentAudio = this.hasDifferentAudio(procedureData, newProcedureList);
    const hasDifferentRelays = this.hasDifferentRelays(procedureData, newProcedureList);
    const hasDifferentCameras = this.hasDifferentCameras(procedureData);
    const hasDifferentVerification = this.hasDifferentVerification(procedureData, newProcedureList);
    const hasDifferentResponseContacts = this.hasDifferentResponseContacts(procedureData);
    const hasDifferentDownloadContacts = this.hasDifferentDownloadContacts(procedureData);
    const hasDifferentHttpRequest = this.hasDifferentHttpRequest(procedureData, newProcedureList);

    return hasDifferentAudio || hasDifferentRelays 
      || hasDifferentCameras || hasDifferentVerification 
      || hasDifferentResponseContacts || hasDifferentDownloadContacts
      || hasDifferentHttpRequest;
  }

  hasDifferentAudio(procedureData: Procedure, newProcedureList: newProcedureList[]): string | boolean {
    const originHasTts: number | null = procedureData.audio?.site_tts_id;
    const audioAction = newProcedureList.find((v: any) => v.actionType === 'audio');
    const isHasTts: string | null = audioAction && this.selectedTts?.site_tts_id;

    const originSpeakers = procedureData.audio?.speakers || [];
    const newSpeakers = this.selectedSpeakers;
    const newSpeakersIds = newSpeakers?.map(speaker => speaker?.device_audio_id) || [];
    const isDifferentSpeakers = 
      originSpeakers?.length != newSpeakers?.length ||
      originSpeakers.some(speaker => !newSpeakersIds?.includes(speaker?.device_audio_id));
    
    return (originHasTts && !isHasTts) || 
      (!originHasTts && isHasTts) || 
      (originHasTts && isHasTts && procedureData.audio?.site_tts_id !== this.selectedTts?.site_tts_id) ||
      isDifferentSpeakers
  }

  hasDifferentRelays(procedureData: Procedure, newProcedureList: newProcedureList[]) {
    const originHasRelays = procedureData.relays?.length;
    const relayAction = newProcedureList.find(v => v.actionType === 'relays');
    const isHasRelays = relayAction && relayAction.relays?.length;

    if (originHasRelays !== isHasRelays) return true;
    if (originHasRelays && isHasRelays) {
      return relayAction.relays.some((relay, idx: string | number) => {
        const originRelay = procedureData.relays[idx];

        return (
          originRelay?.device_do_id !== relay.device_do_id ||
          originRelay?.mode !== relay.mode ||
          originRelay?.mode_duration !== relay.mode_duration
        );
      });
    }

    return false;
  }

  hasDifferentCameras(procedureData: Procedure): string | boolean {
    const originCameras = procedureData.playback_cameras || [];
    const newCameras = this.selectedCameras;
    const newCameraIds = newCameras?.map(camera => camera?.device_id) || [];
    const isDifferentCameras = 
      originCameras?.length != newCameras?.length ||
      originCameras.some(camera => !newCameraIds?.includes(camera?.device_id));
    
    return isDifferentCameras
  }

  hasDifferentVerification(procedureData: Procedure, newProcedureList: newProcedureList[]): string | boolean{
    // const originHasVerification = Object.keys(procedureData).includes('verification');
    // const newHasVerification = newProcedureList.find((v: any) => v.actionType === 'verification') ? true : false;

    const originVerification = procedureData.verification;
    const newVerification = this.isSendVerification;

    return (originVerification && !newVerification) || (!originVerification && newVerification)
  }

  hasDifferentResponseContacts(procedureData: Procedure): string | boolean{
    const originResponseContacts = procedureData.response_contacts || [];
    const tempList = ims._.cloneDeep(this.manualResponseContactForm.value.contacts);
    tempList.forEach(contact => {
      contact.phone = (contact.phone instanceof Object) ? contact.phone?.e164Number : contact.phone
    })
    const newManualResponseContacts = tempList;
    const newResponseContacts = this.selectedResponseContacts?.concat(newManualResponseContacts);

    if(originResponseContacts?.length != newResponseContacts?.length) return true
    if(this.originSelectedResponseTime != this.selectedResponseTime) return true
    for (let i = 0; i < originResponseContacts.length; i++) {
      const originItem = originResponseContacts[i];
      const newItem = newResponseContacts[i];
  
      const keys = Object.keys(originItem);
  
      for (const key of keys) {
        if(key === 'expire_time') continue // 이것 앞에서 확인했음.
        if (originItem[key] !== newItem[key]) {
          return true; // 하나라도 다르면 변경된 것으로 간주
        }
      }
    }
    return false;
  }

  hasDifferentDownloadContacts(procedureData: Procedure): string | boolean{
    const originDownloadContacts = procedureData.download_contacts || [];
    const newManualDownloadContacts = this.manualDownloadContactForm.value.downloadContacts;
    const newDownloadContacts = this.selectedDownloadContacts?.concat(newManualDownloadContacts);

    if(originDownloadContacts?.length != newDownloadContacts?.length) return true
    for (let i = 0; i < originDownloadContacts.length; i++) {
      const originItem = originDownloadContacts[i];
      const newItem = newDownloadContacts[i];
  
      const keys = Object.keys(originItem);
  
      for (const key of keys) {
        if (originItem[key] !== newItem[key]) {
          return true; // 하나라도 다르면 변경된 것으로 간주
        }
      }
    }
    return false;
  }

  hasDifferentHttpRequest(procedureData: Procedure, newProcedureList: newProcedureList[]) {
    const originHttpRequests = procedureData.http_requests || [];
    const newHttpRequests = newProcedureList.find(v => v.actionType === 'http_requests')?.http_requests || [];
    
    if(originHttpRequests?.length != newHttpRequests?.length) return true
    for (let i = 0; i < originHttpRequests.length; i++) {
      const originItem = originHttpRequests[i];
      const newItem = newHttpRequests[i];
  
      const keys = Object.keys(originItem);
  
      for (const key of keys) {
        if (originItem[key] !== newItem[key]) {
          if(key === 'headerBytes') continue
          if(key === 'isHeaderMaxBytes') continue
          return true; // 하나라도 다르면 변경된 것으로 간주
        }
      }
    }
    return false;
  }

  // ---------------------------------------
  onClickCancel(){
    this.dialogRef.close(false)
  }
  closeModal(){
    this.data.isPasteMode
      ? this.dialogRef.close({procedure: this.monitoringProcedures, mpDirectionId : this.mpDirectionId})
      : this.dialogRef.close({procedure: null, mpDirectionId: this.mpDirectionId})
  }
  async onClickApply(){
    if(this.data.isPasteMode) {
      const obj = this.parseProcedureCreatingBody()
      const idx = this.data.idx
      this.monitoringProcedures[idx] = obj
      return this.goToNextStep()
    }
    
    if(this.procedureId){
      const obj = this.parseProcedureEditingBody()
      await this.editProcedure(obj)
    } else {
      const obj = this.parseProcedureCreatingBody()
      await this.createProcedure(obj)
    }
    this.goToNextStep();
  }
  parseProcedureEditingBody(){
    try {
      const isHasAudioAction = this.newProcedureList.find(action => action.actionType === 'audio')
      const audioAction = {
        site_tts_id: this.selectedTts?.site_tts_id,
        tts_text: this.selectedTts?.tts_text,
        speakers: this.parseSelectedSpeakers(),
        bridge_speakers: this.parseSelectedBridgeSpeakers(),
      }

      const isHasRelays  = this.newProcedureList.find(action => action.actionType === 'relays')?.relays
      const isHasPlaybackAction = this.newProcedureList.find(action => action.actionType === 'playback_cameras')
      const isHasResponseContactAction = this.newProcedureList.find(action => action.actionType === 'response_contacts')
      const isHasDownloadContactAction = this.newProcedureList.find(action => action.actionType === 'download_contacts')
      const isHasHttpRequestAction = this.newProcedureList.find(action => action.actionType === 'http_requests')

      // 필수
      const obj = new Procedure()
      obj.name = this.makeProcedureName()
      obj.level = this.procedureActionLevel?.value
      if(this.isSendVerification) obj.verification = this.isSendVerification

      // 변경된 것만 넣기
      if (isHasAudioAction) obj.audio = audioAction
      if (isHasRelays) obj.relays = this.makeRelay(false)
      if (isHasPlaybackAction) obj.playback_cameras = this.makePlaybackCamera()
      if (isHasResponseContactAction || isHasDownloadContactAction) obj.contacts = this.makeContacts(isHasResponseContactAction, isHasDownloadContactAction, false)
      if (isHasHttpRequestAction) obj.http_requests = this.makeHttpAction()
  
      return obj
    } catch(err) {
      console.debug('parseProcedureEditingBody:>',err)
      this.applyErr = `Error Message: ${err}`
    }
  }
  parseProcedureCreatingBody(){
    try {
      const isHasAudioAction = this.newProcedureList.find(action => action.actionType === 'audio')
      const audioAction = {
        site_tts_id: this.selectedTts?.site_tts_id,
        tts_text: this.selectedTts?.tts_text,
        speakers: this.parseSelectedSpeakers(),
        bridge_speakers: this.parseSelectedBridgeSpeakers(),
      }
      const isHasPlaybackAction = this.newProcedureList.find(action => action.actionType === 'playback_cameras')
      const isHasResponseContactAction = this.newProcedureList.find(action => action.actionType === 'response_contacts')
      const isHasDownloadContactAction = this.newProcedureList.find(action => action.actionType === 'download_contacts')
      const isHasHttpRequestAction = this.newProcedureList.find(action => action.actionType === 'http_requests')
  
      // create일 때는 모든 key가 있어야 함.
      const obj = new Procedure()
      obj.name = this.makeProcedureName()
      obj.level = this.procedureActionLevel?.value
      obj.audio = isHasAudioAction ? audioAction : null, 
      obj.relays = this.makeRelay(true)
      obj.playback_cameras = isHasPlaybackAction ? this.makePlaybackCamera() : null
      if(this.isSendVerification) obj.verification = this.isSendVerification
      obj.contacts = this.makeContacts(isHasResponseContactAction, isHasDownloadContactAction, true)
      obj.http_requests = isHasHttpRequestAction ? this.makeHttpAction() : null
  
      return obj
    } catch(err) {
      console.debug('parseProcedureCreatingBody:>',err)
      this.applyErr = `Error Message: ${err}`
    }
  }

  makeProcedureName(){
    if(this.procedureName) return this.procedureName
    if(typeof this.data.idx === 'number') return `Procedure ${this.data.idx + 1}`
    
    // create
    return `Procedure ${this.monitoringProcedures.length + 1}`
  }
  parseSelectedSpeakers(): Speaker[] | null{
    // All Speakers
    if(this.selectedSpeakers.length && this.selectedSpeakers[0].device_audio_id === -1) {
      return null
    }
    // Selected Speakers
    const list = this.selectedSpeakers.filter(v => !v.device_id)
    if(!list.length) return null

    list.forEach(speaker => {
      const keys = Object.keys(speaker)
      if(!speaker.device_name) speaker.device_name = 'No Speaker Name'

      delete speaker.device_id
      if(keys.includes('id')) delete speaker?.id
      if(keys.includes('site_procedure_talkdown_id')) delete speaker?.site_procedure_talkdown_id
      if(keys.includes('created_at')) delete speaker?.created_at
      if(keys.includes('name')) delete speaker?.name
    })
    return list
  }
  parseSelectedBridgeSpeakers(): Speaker[] | null{
    // All Speakers
    if(this.selectedSpeakers.length && this.selectedSpeakers[0].device_audio_id === -1) {
      return null
    }
    // Selected Speakers
    const list = this.selectedSpeakers.filter(v => v.device_id)
    if(!list.length) return null

    list.forEach(speaker => {
      speaker.device_name?.replace(' (Bridge Speaker)', '');
      if(!speaker.device_name) speaker.device_name = 'No Speaker Name'

      const keys = Object.keys(speaker)
      delete speaker.device_audio_id
      if(keys.includes('id')) delete speaker?.id
      if(keys.includes('site_procedure_talkdown_id')) delete speaker?.site_procedure_talkdown_id
      if(keys.includes('created_at')) delete speaker?.created_at
      if(keys.includes('name')) delete speaker?.name
    })
    return list
  }
  makeRelay(isCreateMode){
    let result = []
    const relays = this.newProcedureList.find(action => action.actionType === 'relays')?.relays
    if(!relays || !relays.length) {
      return isCreateMode ? null : []
    }
    relays.forEach(relay => {
      const obj = {
        device_do_id: relay.device_do_id,
        device_name: relay.device_name || 'No Relay Name',
        mode: relay.mode,
        mode_duration: relay.mode_duration ?? 1
      }
      result.push(obj)
    })

    return isCreateMode
      ? result?.length ? result : null
      : result // edit default : []
  }
  makePlaybackCamera(){
    let result = []
    this.selectedCameras.forEach(camera => {
      result.push(new PlaybackCamera({device_id: camera.device_id, device_name: camera.name}))
    })
    return result
  }
  makeContacts(isHasResponseContactAction, isHasDownloadContactAction, isCreateMode) {
    let result = []
    if(isHasResponseContactAction) this.makeResponseContact(result)
    if(isHasDownloadContactAction) this.makeDownloadContact(result)
    
    return isCreateMode
      ? result?.length ? result : null
      : result // edit default : []
  }
  makeResponseContact(result){
    this.selectedResponseContacts.forEach(contact => {
      const isBoth = this.selectedDownloadContacts.find(v => v.site_contact_id === contact.site_contact_id)
      const contact_type = isBoth ? 'both' : 'response'
      result.push(new ResponseContact({site_contact_id: contact.site_contact_id, name: contact.name, title: contact.title, contact_type, expire_time: this.selectedResponseTime}))
    })
    this.manualResponseContactForm.value.contacts.forEach(contact => {
      const phone = contact.phone?.e164Number ? contact.phone?.e164Number : null
      result.push(new ResponseContact({name: contact.name, phone: phone, email: contact.email, title: contact.title, contact_type: 'response', expire_time: this.selectedResponseTime}))
    })
  }
  makeDownloadContact(result){
    this.selectedDownloadContacts.forEach(contact => {
      const isBoth = this.selectedResponseContacts.find(v => v.site_contact_id === contact.site_contact_id)
      if(isBoth) return // 중복되면 여기에는 포함하지 않음. 앞에서 미리 넣었기 때문
      result.push(new DownloadLink({site_contact_id: contact.site_contact_id, name: contact.name, title: contact.title, contact_type: 'download', expire_time: this.selectedResponseTime}))
    })
    this.manualDownloadContactForm.value.downloadContacts.forEach(contact => {
      result.push(new DownloadLink({name: contact.name, email: contact.email, title: contact.title, contact_type: 'download',}))
    })
  }
  makeHttpAction(){
    let result = []
    const httpRequestActions = this.newProcedureList.find(action => action.actionType === 'http_requests')?.http_requests ?? []
    httpRequestActions.forEach(action => {
      const headersObj = {}
      action.contentType !== 'custom'
        ? headersObj['Content-Type'] = action.contentType
        : headersObj['Content-Type'] = action.contentTypeCustom

      if(action?.auth_header_key) headersObj[action.auth_header_key] = action.auth_header_val
      if(action?.headersCustom?.length) action.headersCustom.forEach(header => headersObj[header.key] = header.value) 

      const value = new HttpRequest({
        method : action.method.toLowerCase(),
        url : action.url,
        name : action.name,

        headers : JSON.stringify(headersObj),
        auth_header_key : action.auth_header_key, // 이건 auth_type 3일때만 사용
        body : action.body,
        auth_type : action.auth_type,
        username : action.username,
        password : action.password
      })
      delete value['isShowAdvanced']
      delete value['isValidHttpUrl']
      delete value['headerBytes']
      delete value['isHeaderMaxBytes']
      delete value['bodyBytes']
      delete value['isBodyMaxBytes']
      
      delete value['contentType']
      delete value['contentTypeCustom']
      delete value['headersCustom']
      delete value['auth_header_val']
      delete value['bearerToken']

      result.push(value)
    })
    return result
  }

  // ---------------------------------------
  async createProcedure(data){
    try {
      this.isLoading = true;
      if(this.deviceId) data['device_id'] = this.deviceId
      // 사실 여기 내용은 변경될 것이 없음. 단순히 백업 위함.
      if(this.mpDirectionStatus === 2) await this.updateFinalizedDirection()

      const { id } = await this.helper.note_directions.create_procedure(this.site.site_id, this.mpDirectionId, data)
      this.procedureId = id
      await this.editMonitoringNoteStatus() // to draft
      this.commonService.showSuccessToast('Success','Monitoring note created successfully.')
      this.isLoading = false;
      return true
    } catch(err) {
      console.debug('onClickEdit:>',err)
      err?.error?.debug
        ? this.applyErr = `Error Message: ${err?.error?.debug}`
        : this.applyErr = `Error Message: ${err?.message}`
      this.isEditMode = false;
      this.isLoading = false;
      return null
    }
  }

  // EDIT
  async editMonitoringNoteStatus(): Promise<boolean>{
    try {
      const status = 0
      const data = { status }
      if(!this.mpDirectionId) return false
      await this.helper.note_directions.update_monitoring_direction_status(this.site.site_id, this.mpDirectionId, data)
      return true
    } catch(err) {
      this.isLoading = false;
      console.debug('editMonitoringNoteStatus:>',err)
      return false
    }
  }

  async editProcedure(data){
    if(!this.procedureId) return console.debug('no procedureId')
    
    try {
      this.isLoading = true;
      // 사실 여기 내용은 변경될 것이 없음. 단순히 백업 위함.
      if(this.mpDirectionStatus === 2) await this.updateFinalizedDirection()
      // 이게 맞아...? BE에서 할 수 있는 방법이 없는지 확인해보아야할 것 같음
      const newProcedureList = await this.helper.note_directions.get_all_procedures(this.site.site_id, this.mpDirectionId)
      const newProcedureId = newProcedureList[this.data.idx].id

      await this.helper.note_directions.update_procedure(this.site.site_id, newProcedureId, data)
      
      await this.editMonitoringNoteStatus() // to draft
      this.isLoading = false;
      this.commonService.showSuccessToast('Success','Monitoring note updated successfully.')
      return true
    } catch(err) {
      console.debug('onClickEdit:>',err)
      err?.error?.debug
        ? this.applyErr = `Error Message: ${err?.error?.debug}`
        : this.applyErr = `Error Message: ${err?.message}`
      this.isEditMode = false;
      this.isLoading = false;
      return false
    }
  }
  async updateFinalizedDirection(){
    const copiedMPDirection = { direction: JSON.stringify(this.monitoringNoteContents) }
    // 기존 내용을 copy해 두기 : MP에서 사용하기 위함
    const copyId = await this.helper.note_directions.copy_monitoring_direction_status(this.site.site_id, this.mpDirectionId, null)
      
    // 업데이트할 내용을 copy한 direction에 반영 -> 앞으로 수정은 이걸로 할거야.
    this.mpDirectionId = copyId
    await this.helper.note_directions.update_monitoring_direction(this.site.site_id, this.mpDirectionId, copiedMPDirection)
  }

  // ---------------------------------------
  openWarningDialog(header, contents) {
    this.c_components.dialog.open('warning', {
      header,
      contents,
      submit_btn: 'OK',
      submit_class: ["button-primary"],
      icon: 'warning',
      isConfirm: true,
      color: 'orange',
      submit_func: () => {},
    });
  }
}