import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'c_side_modal_direction_procedures_component',
  templateUrl: './c_side_modal_direction_procedures.component.pug',
  styleUrls: ['../../common.scss','../../c_side_direction_modal.component.scss'],
})
export class c_side_modal_direction_procedures_component {
  @Output() showPosition = new EventEmitter();
  @Output() hidePosition = new EventEmitter();
  
  constructor() { }

  mouseEnterShowPosition(e, type) {
    e?.stopPropagation();
    this.showPosition.emit(type);
  }

  mouseLeaveHidePosition(e) {
    e?.stopPropagation();
    this.hidePosition.emit()
  }
}
