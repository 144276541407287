import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class DealerPlanService {
  constructor(private api: ApiService) { }
  dealerPlanType$s: BehaviorSubject<any> = new BehaviorSubject<any>(1);
  dealerPlanType$w = this.dealerPlanType$s.asObservable()

  dealerPlanCloudAIUsage$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dealerPlanCloudAIUsage$w = this.dealerPlanCloudAIUsage$s.asObservable()

  public planDescription = {
    1 : { title : 'Standard', description: 'Current Plan for CHeKT dealers', price: 'per site fee'},
    2 : {
      title : 'Professional',
      description : 'A plan that scales with your rapidly growing business',
    },
    3 : {
      title: 'Premium',
      description: 'Premium services for large-scale site management',
    },
    4 : {
      title: 'Reseller',
      description: 'CHeKT Monitoring Center Reseller plan providing wholesale rates for CHeKT services and monitored accounts.',
    }
  }

  public mainDealerPlanPricingListForStandard: any
  public mainDealerPlanPricingListForReseller: any
  public subDealerPlanPricingListForStandard: any
  public subDealerPlanPricingListForProfessional: any
  public subDealerPlanPricingListForPremium: any
  public dealerPlanPricingListForStandard: any
  public dealerPlanPricingListForProfessional: any
  public dealerPlanPricingListForPremium: any

  public mainDealerPlanPriceReseller: string
  public subDealerPlanPriceProfessional: string
  public subDealerPlanPricePremium: string
  public dealerPlanPriceProfessional: string
  public dealerPlanPricePremium: string

  getDealerPlan(dealerId, type='dealer'): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/service_plans`).map(res =>{
      if(type === 'dealer') this.dealerPlanType$s.next(res);
      return res
    })
  }

  updateDealerPlan(dealerId, data): Observable<any>{
    this.dealerPlanType$s.next(0);
    return this.api.put(`/dealers/${dealerId}/service_plans`, data);
  }

  getDealerPlanCloudAIUsage(dealerId, year, month): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/service_plans?year=${year}&month=${month}&includes=videoai`).map(res =>{
      this.dealerPlanCloudAIUsage$s.next(res);
      return res
    })
  }

  getDealerServicePlanPricing(dealerId, dealerType){
    // dealer types - 0: si, 1: cs, 2: wcs.m, 3:wcs.s  에요.
    const url = `/dealers/${dealerId}/service_plans?output=object`
    return this.api.get(url, 'json', 'v3').pipe(
      map(res => {
        this.groupByDealerTyeAndDealerPlan(res['dealer_types'], dealerType)
        return res['dealer_types']
      }));
  }

  groupByDealerTyeAndDealerPlan(dealerPricing, dealerType){
    this.findMainDealerPlanPricingListForStandard(dealerPricing)
    this.findMainDealerPlanPricingListForReseller(dealerPricing)
    this.finSubDealerPlanPricingListForStandard(dealerPricing)
    this.finSubDealerPlanPricingListForProfessional(dealerPricing)
    this.findSubDealerPlanPricingListForPremium(dealerPricing)
    this.findDealerPlanPricingListForStandard(dealerPricing, dealerType)
    this.findDealerPlanPricingListForProfessional(dealerPricing, dealerType)
    this.findDealerPlanPricingListForPremium(dealerPricing, dealerType)
  }

  // for main dealer
  findMainDealerPlanPricingListForStandard(dealerPricing){
    const dealerType = 2
    const planType = 1
    this.mainDealerPlanPricingListForStandard = dealerPricing?.[dealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}
  }
  findMainDealerPlanPricingListForReseller(dealerPricing){
    const dealerType = 2
    const planType = 4
    this.mainDealerPlanPricingListForReseller = dealerPricing?.[dealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}

    const planId = 1
    this.mainDealerPlanPriceReseller = this.formatNumbersWithComma(this.mainDealerPlanPricingListForReseller[planId]?.price)
  }

  // for sub dealer
  finSubDealerPlanPricingListForStandard(dealerPricing){
    const dealerType = 3
    const planType = 1
    this.subDealerPlanPricingListForStandard = dealerPricing?.[dealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}
  }
  finSubDealerPlanPricingListForProfessional(dealerPricing){
    const dealerType = 3
    const planType = 2
    this.subDealerPlanPricingListForProfessional = dealerPricing?.[dealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}

    const planId = 1
    this.subDealerPlanPriceProfessional = this.formatNumbersWithComma(this.subDealerPlanPricingListForProfessional[planId]?.price)
  }
  findSubDealerPlanPricingListForPremium(dealerPricing){
    const dealerType = 3
    const planType = 3
    this.subDealerPlanPricingListForPremium = dealerPricing?.[dealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}

    const planId = 1
    this.subDealerPlanPricePremium = this.formatNumbersWithComma(this.subDealerPlanPricingListForPremium[planId]?.price)
  }

  // for cs/si dealer
  findDealerPlanPricingListForStandard(dealerPricing, dealerType){
    // dealer type: 1 : si dealer, 2: main dealer, 3: sub dealer, 4: cs dealer
    // service dealer types - 0: si, 1: cs, 2: wcs.m, 3:wcs.s 

    const dealerTypeToServiceDealerType = dealerType === 1 ? 0 : 1
    const planType = 1
    this.dealerPlanPricingListForStandard = dealerPricing?.[dealerTypeToServiceDealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}
  }
  findDealerPlanPricingListForProfessional(dealerPricing, dealerType){
    // dealer type: 1 : si dealer, 2: main dealer, 3: sub dealer, 4: cs dealer
    // service dealer types - 0: si, 1: cs, 2: wcs.m, 3:wcs.s 

    const dealerTypeToServiceDealerType = dealerType === 1 ? 0 : 1
    const planType = 2
    this.dealerPlanPricingListForProfessional = dealerPricing?.[dealerTypeToServiceDealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}

    const planId = 1
    this.dealerPlanPriceProfessional = this.formatNumbersWithComma(this.dealerPlanPricingListForProfessional[planId]?.price)
  }
  findDealerPlanPricingListForPremium(dealerPricing, dealerType){
    // dealer type: 1 : si dealer, 2: main dealer, 3: sub dealer, 4: cs dealer
    // service dealer types - 0: si, 1: cs, 2: wcs.m, 3:wcs.s 

    const dealerTypeToServiceDealerType = dealerType === 1 ? 0 : 1
    const planType = 3
    this.dealerPlanPricingListForPremium = dealerPricing?.[dealerTypeToServiceDealerType]?.['service_plan_types']?.[planType]?.['service_items'] ?? {}

    const planId = 1
    this.dealerPlanPricePremium = this.formatNumbersWithComma(this.dealerPlanPricingListForPremium[planId]?.price)
  }


  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }
}
