import { Component, OnInit, Input } from '@angular/core';
import { animeBackground } from '../../../../app/pages/animation';
import { UsersService } from '@app/services/users.service';
import { CommonService } from '@app/services/common.service';
import { DealerPlanService } from '@app/services/service-plan.service';
import { Helper } from '../../../../4services/2helper';

@Component({
  selector: 'c_card_site_plan_component',
  templateUrl: './c_card_site_plan.component.pug',
  styleUrls: ['../common.scss','./c_card_site_plan.component.scss'],
  animations: [animeBackground]
})
export class c_card_site_plan_component implements OnInit {
  @Input('planType')planType;

  me: any;
  servicePricing = {
    sitePlanFee: 0,
    videoVaultPlanFee: 0,
    videoVaultPlusPlanFee: 0,
    videoEvent: {
      free_package_amount: {
        event: 0
      },
      paid_package_amount: {
        price: 0,
        event: 0
      },
    }, 
    sum: 0,
  }

  isLoading = false;
  currentMonthPlan: number
  constructor(
    public usersService: UsersService,
    private commonService: CommonService,
    private dealerPlanService: DealerPlanService,
    private helper: Helper
  ) { }

  async ngOnInit() {
    await this.fetchData();
  }

  //////////////////////////////

  /////////////////////////////
  // Plan Case
  isVideoEventChargePlan(planId){
    planId = parseInt(planId)
    return planId === 9 ? true : false
  }
  isAlarmBasedSitePlan(planId){
    planId = parseInt(planId)
    return planId === 15 ? true : false
  }
  isVideoVaultPlan(planId){
    planId = parseInt(planId)
    return planId === 16 ? true : false
  }
  isVideoVaultPlusPlan(planId){
    planId = parseInt(planId)
    return planId === 17 ? true : false
  }
  //////////////////////////////

  async fetchData() {
    this.isLoading = true;
    this.me = await this.helper.me.get_me();
    await this.getDealerPlanPricing()
    await this.fetchDealerPlan()
    this.isLoading = false
  }
  
  async fetchDealerPlan(){
    try {
      const dealerId = this.me.dealer_id
      const res = await this.dealerPlanService.getDealerPlan(dealerId).toPromise()
      const currentMonthPlan = res.current_month_service_plan_type
      this.currentMonthPlan = currentMonthPlan
    } catch(err) {
      this.isLoading = false
      console.debug('fetchDealerPlan:> ',err)
    }
  }

  async getDealerPlanPricing(){
    const dealerId = this.me.dealer_id
    const dealerType = this.me.type
    try {
      await this.dealerPlanService.getDealerServicePlanPricing(dealerId, dealerType).toPromise()

      const dealerPlanPricingListForStandard = this.dealerPlanService.dealerPlanPricingListForStandard
      const dealerPlanPricingListForProfessional = this.dealerPlanService.dealerPlanPricingListForProfessional
      const dealerPlanPricingListForPremium = this.dealerPlanService.dealerPlanPricingListForPremium
      const mainDealerPlanPricingListForStandard = this.dealerPlanService.mainDealerPlanPricingListForStandard
      const mainDealerPlanPricingListForReseller = this.dealerPlanService.mainDealerPlanPricingListForReseller
      let target = null
      if(this.me.type === 2) {
        this.currentMonthPlan === 0
          ? target = mainDealerPlanPricingListForStandard
          : target = mainDealerPlanPricingListForReseller
      } else {
        if(this.currentMonthPlan === 0) target = dealerPlanPricingListForStandard
        if(this.currentMonthPlan === 1) target = dealerPlanPricingListForProfessional
        if(this.currentMonthPlan === 2) target = dealerPlanPricingListForPremium
      }
      this.parseServicePricing(target)
    } catch(err) {
      this.isLoading = false;
      console.debug(err)
      let title = 'Request Dealer Plan Info';
      this.commonService.showErrorToast(err, title);
    }
  }

  parseServicePricing(pricingList){
    for(const planId in pricingList) {
      const plan = pricingList[planId]
      if(this.isAlarmBasedSitePlan(planId)) {
        this.assignPlanPriceByPlan('sitePlanFee', plan)
      }
      if(this.isVideoVaultPlan(planId)) {
        this.assignPlanPriceByPlan('videoVaultPlanFee', plan)
      }
      if(this.isVideoVaultPlusPlan(planId)) {
        this.assignPlanPriceByPlan('videoVaultPlusPlanFee', plan)
      }
      if(this.isVideoEventChargePlan(planId)) {
        this.assignPlanPriceByPlan('videoEvent', plan)
      }
    }
  }


  assignPlanPriceByPlan(planName, data){
    if(!planName || !data) return

    const targetServicePricing = this.servicePricing

    const conditionalPriceData = data?.conditional_price
    if(conditionalPriceData instanceof Object) {
      targetServicePricing[planName] = {
        free_package_amount: {
          event: this.formatNumbersWithComma(conditionalPriceData?.free_package_amount)
        },
        paid_package_amount: {
          price: this.formatNumbersWithComma(conditionalPriceData?.fee_per_paid_package_amount ),
          event: this.formatNumbersWithComma(conditionalPriceData?.paid_package_amount)
        }
      }
    } else {
      targetServicePricing[planName] = this.formatNumbersWithComma(data?.price)

      const price = data?.price ?? 0
      this.checkUnlimitedOrIncludedPlan('price', price, targetServicePricing, planName)
    }
  }

  ///////////////////////////////////
  // TOOL
  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }
  checkUnlimitedOrIncludedPlan(pricingType, pricingData, targetData, planName){
    if(pricingType === 'conditional') {
      const paidPackageDataIsZero = !pricingData?.fee_per_paid_package_amount && !pricingData?.paid_package_amount
      if(!paidPackageDataIsZero) return

      if(this.isUnlimitedInAllDealerPlan('conditional', planName)) {
        targetData[planName].priceLabel = 'Included'
      } else {
        targetData[planName].priceLabel = 'Unlimited'
      }
    }
    if(pricingType === 'price') {
      if(!pricingData?.price) return

      if(this.isUnlimitedInAllDealerPlan('price', planName)) {
        targetData[planName] = {
          priceLabel : 'Included',
          price : pricingData?.price
        }
      } else {
        targetData[planName] = {
          priceLabel : 'Unlimited',
          price : pricingData?.price
        }
      }
    }
  }

  isUnlimitedInAllDealerPlan(pricingType, planName){
    const standardServicePricing = this.servicePricing

    if(pricingType === 'conditional') {
      const standardPaidPackageDataIsZero = !standardServicePricing?.[planName]?.fee_per_paid_package_amount && !standardServicePricing?.[planName]?.paid_package_amount
      return standardPaidPackageDataIsZero ? true : false
    }
    if(pricingType === 'price'){
      const standardPaidPackageDataIsZero = !standardServicePricing?.[planName]?.price && !standardServicePricing?.[planName]?.price
      return standardPaidPackageDataIsZero ? true : false
    }
  }

  parseCurrentStorageDuration(){
    if(this.currentMonthPlan === 1) return '30 Days'
    if(this.currentMonthPlan === 2) return '90 Days'
    if(this.currentMonthPlan === 3) return '1 year'
    if(this.currentMonthPlan === 4) return '1 year'
  }
}
