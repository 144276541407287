import { Component, Input, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import ims from '../../../../../imports'

@Component({
  selector: 'log_box_relay',
  templateUrl: './relay.component.pug',
  styleUrls: ['../../../../common.scss','../../c_card_event_viewer_box.component.scss']
})
export class log_box_relay_component {
  @Input() log;
  @Input() timezone;
  @Input() toolbar: TemplateRef<any>;

  isBuildReportMode = false;

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService,
    private cdr: ChangeDetectorRef
  ) {}

  private isClearSelectedLogs$w: Subscription;
  private isBuildReportMode$w: Subscription;
  watch() {
    this.isClearSelectedLogs$w = this.eventViewerService.isClearSelectedLogs$w.subscribe((v) => this.unselectLog(v));
    this.isBuildReportMode$w = this.eventViewerService.isBuildReportMode$w.subscribe((v) => this.isBuildReportMode = v);
  }
  unwatch() {
    this.isClearSelectedLogs$w?.unsubscribe();
    this.isBuildReportMode$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  onClickLog(e){
    e.stopPropagation();
    this.isBuildReportMode ? this.selectLog() : this.openLogDetail()
  }
  unselectLog(value){
    if(value === null) return
    if(!value) return
    this.log.isSelected = false
    this.cdr.detectChanges();  // 변경감지 수행
  }
  selectLog() {
    this.log.isSelected = !this.log.isSelected
    this.log.isSelected
      ? this.eventViewerService.addSelectedLogs(this.log)
      : this.eventViewerService.deleteSelectedLogs(this.log)
  }
  openLogDetail(){
    this.eventViewerService.openDetailModal({status: true, log: this.log})
  }

  // -------------------------------------------------------
  // COMMON

  showToolbar = ims._.throttle(this.showToolbarThrottle.bind(this), 200);
  onHoverLog(e, id){
    e.stopPropagation();
    this.showToolbar(id)
  }
  showToolbarThrottle(id){
    const toolbox = document.getElementById('log-box-toolbox'+id)
    if(toolbox) toolbox.style.visibility = 'visible';
  }

  hiddenToolbar = ims._.throttle(this.hiddenToolbarThrottle.bind(this), 200);
  onMouseLeaveLog(e, log){
    e.stopPropagation();
    this.hiddenToolbar(log)
  }
  hiddenToolbarThrottle(log){
    log.isShowMenu = false
    const toolbox = document.getElementById('log-box-toolbox'+log.id)
    if(toolbox) toolbox.style.visibility = 'hidden';
  }

  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  isAutomationRule(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule || this.log.isAutomation
  }

  // --------------------------------------------------------------
  // STYLE
  relayIcon(meta){
    if(!meta || typeof meta != 'string') return 'relay'
    const metaData = JSON.parse(meta)
    const iconName = metaData?.info?.relay?.icon ?? 'relay'
    return iconName
  }
  capitalizeFirstLetter(string: string) {
    if(!string || typeof string != 'string') return
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
