import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/of";

@Injectable()
export class InvoicesService {

  invoiceVersion = 2;

  version1 = 'v1.1';
  version2 = 'v1.2';
  version3 = 'v3';

  version = this.version1;

  constructor(private api: ApiService) {
    switch (this.invoiceVersion) {
      case 1 : this.version = this.version1; break;
      case 2 : this.version = this.version2; break;
      case 3 : this.version = this.version3; break;
      default: this.version = 'v1.1'; break;
    }
  }

  get(dealer_id: number, invoice_id?: number, filter?, version=this.version): Observable<any> {
    let url = `/dealers/${dealer_id}/invoices?sort=invoice.end_period&dir=desc`
    if (invoice_id) {
      url = `/dealers/${dealer_id}/invoices/${invoice_id}`;
    }
    if (filter) {
      url += filter;
    }
    return this.api.get(url,'json',version);
  }

  getLimitInvoices(dealer_id: number, limit, filter?, version=this.version): Observable<any> {
    let url = `/dealers/${dealer_id}/invoices/stime/0/etime/0?sort=invoice.end_period&dir=desc&offset=0&limit=${limit}`;
    if (filter) {
      url += filter;
    }
    return this.api.get(url,'json',version);
  }

  getFilter(dealer_id: number, stime, etime, filter?, version=this.version): Observable<any> {
    let url = `/dealers/${dealer_id}/invoices/stime/${stime}/etime/${etime}?sort=invoice.end_period&dir=desc`;
    if (filter) {
      url += filter;
    }
    return this.api.get(url,'json',version);
  }
  getFilterV3(dealer_id: number, stime, etime, version='v3'): Observable<any> {
    let url = `/dealers/${dealer_id}/invoices?stime=${stime}&etime=${etime}`;
    return this.api.get(url,'json',version);
  }

  getInvoiceStatistics(dealer_id: number, stime, etime, filter?, version=this.version): Observable<any> {
    let url = `/dealers/${dealer_id}/invoices/statistics/events/stime/${stime}/etime/${etime}`;
    if (filter) {
      url += filter;
    }
    return this.api.get(url,'json',version);
  }

  getInvoiceStatisticsDetail(dealer_id: number, stime, etime, filter?, version=this.version): Observable<any> {
    let url = `/dealers/${dealer_id}/sites/invoices/statistics/events/stime/${stime}/etime/${etime}`;
    if (filter) {
      url += filter;
    }
    return this.api.get(url,'json',version);
  }

  getReceipt(dealer_id: number, paymentId?: number, version=this.version): Observable<any> {
    if(version === 'v3') {
      return this.api.get(`/dealers/${dealer_id}/payments/${paymentId}/receipt`,'json', version);
    } else {
      return this.api.get(`/dealers/${dealer_id}/payments/${paymentId}/receipts`,'json', version);
    }
  }

  getInvoiceRecurring(dealerId: number, data, version=this.version): Observable<any> {
    return this.api.post(
      `/dealers/${dealerId}/invoices/current/generate`,
      data,
      version
    );
  }
  getInvoiceRecurringV3(dealerId: number): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/real-time-fee-invoices`, null,'v3');
  }

  amountFloor(includeTaxAmount, amount) {
    let taxAmount = includeTaxAmount - amount;
    taxAmount = Math.floor(taxAmount * 100) / 100;
    return taxAmount;
  }

  //Site plan
  getInvoicePlan(dealerId: number, version=this.version): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/invoices/plans`, "json", version);
  }

  updateSitePlan(dealerId, siteId, planId, data, version=this.version): Observable<any> {
    return this.api.put(
      `/dealers/${dealerId}/sites/${siteId}/invoices/plans/${planId}`,
      data,
      60 * 1000,
      version
    );
  }

  updateSitePlanV2(dealerId, siteId, data, version=this.version): Observable<any> {
    return this.api.put(
      `/dealers/${dealerId}/sites/${siteId}/invoices/plans/`,
      data,
      60 * 1000,
      version
    );
  }

  //retail
  getRetailInvoices(dealerId, retailId): Observable<any> {
    return this.api.delete(
      `/dealers/${dealerId}/retails/${retailId}/invoices/`
    );
  }

  getRetailInvoice(dealerId, retailId, invoiceId): Observable<any> {
    return this.api.delete(
      `/dealers/${dealerId}/retails/${retailId}/invoices/${invoiceId}`
    );
  }

  getRetailRealtimeFee(dealerId, retailId): Observable<any> {
    return this.api.delete(
      `/dealers/${dealerId}/retails/${retailId}/invoices/current/generate`
    );
  }

  //site realtime
  getSiteRealtimeFee(dealerId, siteId, data, version=this.version): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/invoices/current/generate`, data, version);
  }
  getSiteRealTimeFeeV3(dealerId, siteId): Observable<any>{
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/invoice`, null, 'v3')
  }

  // get CSV
  getInvoiceCSV(dealerId, invoiceId, version='v1.2'): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/invoices/${invoiceId}/csv`, 'json', version);
  }
  getInvoiceCSVv3(dealerId, invoiceId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/invoices/${invoiceId}/csv`, 'json', 'v3');
  }

  /* Invoice Agreement */
  getLastTos(dealerId): Observable<any> {
    let apiPath = `/dealers/${dealerId}/invoices/toss/last/text`
    return this.api.get(apiPath, 'json', 'v1.2');
  }
  getTosAccepted(dealerId, filter?): Observable<any> {
    let apiPath = `/dealers/${dealerId}/invoices/toss`;
    if (filter) {
      apiPath += '?' + filter; // ex)filter=tos_id,eq,11
    }
    return this.api.get(apiPath, 'json', 'v1.2');
  }
  acceptTos(dealerId, tosId): Observable<any> {
    let apiPath = `/dealers/${dealerId}/invoices/toss/${tosId}/accept`
    return this.api.post(apiPath, {}, 'v1.2');
  }
}
