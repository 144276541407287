import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NoticeComponent } from './notice.component';

import { MarkdownModule } from 'ngx-markdown';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MarkdownModule.forChild(),
    MatCheckboxModule
  ],
  declarations: [NoticeComponent],
  exports: [
    NoticeComponent
  ]
})
export class NoticeModule { }
