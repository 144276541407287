import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class IncidentReportsService {
  incidentReports$s: BehaviorSubject<any> = new BehaviorSubject<any[]>([]); 
  incidentReport$s: BehaviorSubject<any> = new BehaviorSubject<any>(null); 
  sharedLog$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  incidentReports$w = this.incidentReports$s.asObservable();
  incidentReport$w = this.incidentReport$s.asObservable();
  sharedLog$w = this.sharedLog$s.asObservable();

  constructor(private api: ApiService, private http: HttpClient) { }

  getSiteIncidentReports(dealerId, siteId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/incident_reports`).map(res => {
      this.incidentReports$s.next(res)
      return res
    })
  }

  getSiteIncidentReport(dealerId, siteId, incidentReportId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/incident_reports/${incidentReportId}/logs`).map(res => {
      this.incidentReport$s.next(res)
      return res
    })
  }

  getSiteIncidentReportSharedLog(dealerId, siteId, incidentReportId){
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/incident_reports/${incidentReportId}/shared_logs`).map(res => {
      this.sharedLog$s.next(res)
      return res
    });
  }

  shareSiteIncidentReport(dealerId, siteId, incidentReportId, data){
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/incident_reports/${incidentReportId}/share`, data);
  }

  createSiteIncidentReport(dealerId, siteId, data) {
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/incident_reports`, data);
  }

  updateSiteIncidentReport(dealerId, siteId, incidentReportId, data) {
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/incident_reports/${incidentReportId}`, data);
  }

  deleteSiteIncidentReport(dealerId, siteId, incidentReportId) {
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/incident_reports/${incidentReportId}`);
  }

  uploadSiteIncidentReportPDF(signedUrl: string, blobPDF: string | Blob){
    const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });

    this.http.put(signedUrl, blobPDF, { headers, responseType: 'text' })
      .subscribe({
        next: (response) => console.log("File uploaded successfully"),
        error: (error) => console.error("Error uploading file: ", error)
      });
  }

  async downloadSiteIncidentReportPDF(dealerId, siteId, incidentReportId): Promise<Blob>{
    const { download_url } = await this.api.get(`/dealers/${dealerId}/sites/${siteId}/incident_reports/${incidentReportId}/download_url`).toPromise();
    const blob = await this.http.get(download_url, { responseType: 'blob' }).toPromise();
    return blob
  }
}
