
<ng-template [ngIf]="iconPosition === 'left' && columnName">
    <svg width="12" height="8px" viewBox="0 0 200 200" *ngIf="sortDirection === 'asc'" >
        <g fill="#5A6574"><g id="arrow-drop-down"><polygon points="0,120 100,200 200,120"/></g></g>
    </svg>
    <svg width="12" height="8px" viewBox="0 0 200 200" *ngIf="sortDirection === ''" >
        <g>
            <g id="arrow-drop-down" fill="#5A6574"><polygon points="0,80 100,0 200,80"/></g>
            <g id="arrow-drop-down" fill="#5A6574"><polygon points="0,120 100,200 200,120"/></g>
        </g>
    </svg>
    <svg width="12" height="8px" viewBox="0 0 200 200" *ngIf="sortDirection === 'desc'" >
        <g fill="#5A6574"><g id="arrow-drop-down"><polygon points="0,80 100,0 200,80"/></g></g>
    </svg>
</ng-template>

<ng-content></ng-content>

<ng-template [ngIf]="iconPosition === 'right' && columnName">
    <svg width="12" height="8px" viewBox="0 0 200 200" *ngIf="sortDirection === 'asc'" >
        <g fill="#5A6574"><g id="arrow-drop-down"><polygon points="0,120 100,200 200,120"/></g></g>
    </svg>
    <svg width="12" height="8px" viewBox="0 0 200 200" *ngIf="sortDirection === ''" >
        <g>
            <g id="arrow-drop-down" fill="#5A6574"><polygon points="0,80 100,0 200,80"/></g>
            <g id="arrow-drop-down" fill="#5A6574"><polygon points="0,120 100,200 200,120"/></g>
        </g>
    </svg>
    <svg width="12" height="8px" viewBox="0 0 200 200" *ngIf="sortDirection === 'desc'" >
        <g fill="#5A6574"><g id="arrow-drop-down"><polygon points="0,80 100,0 200,80"/></g></g>
    </svg>
</ng-template>


