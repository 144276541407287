import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit{

  @Input() time: number = 3000;
  contents: any[] = [];
  removed: any[] = [];

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.toastService.getSubscription().subscribe(c => {
      if(c.length == 0)
        return;
      
      this.contents.unshift(this.toastService.pop());
      setTimeout(_=>{
        this.removed.unshift(this.contents.pop());
        this.removed[0].opacity = 0.8;
        setTimeout(_=>{
          this.removed[0].opacity = 0;
          setTimeout(_=> this.removed.pop(), 200);
        });
      }, this.time)
    });
  }

  getTopPosition(content, index) {
    content.top = index * 76;
    return content.top;
  }

  click(content) {
    if(content.func) {
      content.func();
    }
  }
}
