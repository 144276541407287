import { NgModule } from "@angular/core";
import { c_components } from "./index";

import { c_dialog_module } from "./dialog/c_dialog_module";
import { c_dtable_module } from "./dtable/c_dtable_module";
import { c_card_module } from "./card/c_card_module";
import { c_filter_module } from "./filter/c_filter_module";
import { c_side_modal_module } from "./side-modal/c_side_modal.module";
import { c_side_direction_modal_module } from "./side-direction-modal/c_side_direction_modal.module";

@NgModule({
  providers: [c_components],

  exports: [c_dialog_module, c_dtable_module, c_card_module, c_filter_module, c_side_modal_module, c_side_direction_modal_module],
})
export class c_components_module {}
