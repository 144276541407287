import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DivisionService {
  divisions$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  my_divisions$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  division_members$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  divisions$w = this.divisions$s.asObservable();
  my_divisions$w = this.my_divisions$s.asObservable();
  divisions_members$w = this.division_members$s.asObservable();

  constructor(private api: ApiService) { }

  getAllDivisions(dealer_id){
    return this.api.get(`/dealers/${dealer_id}/divisions`).map(res => {
      this.divisions$s.next(res)
      return res
    })
    /**
     * [
     *  {
          "id": 64,
          "dealer_id": 2,
          "name": "aa",
          "description": "bb",
          "updated_at": "2023-07-20T13:21:54.000Z",
          "created_at": "2023-07-20T13:15:51.000Z"
        },
     * ]
     */
  }
  getMyDivisions(dealer_id){
    return this.api.get(`/dealers/${dealer_id}/divisions/me`).map(res => {
      this.my_divisions$s.next(res)
      return res
    })
  }

  deleteAllDivisions(dealer_id){
    return this.api.delete(`/dealers/${dealer_id}/divisions`)
  }

  createDivision(dealer_id, data){
    return this.api.post(`/dealers/${dealer_id}/divisions`, data)
  }
  updateDivision(dealer_id, division_id, data){
    return this.api.put(`/dealers/${dealer_id}/divisions/${division_id}`, data)
  }
  deleteDivision(dealer_id, division_id){
    return this.api.delete(`/dealers/${dealer_id}/divisions/${division_id}`)
  }

  // SITES - 기존 site api들을 활용함
  // get site list : 백엔드에서 처리함
  // add a site in the division : update a site (createSite) -> 기존의 site update api 사용하기
  // delete a site in the division : update a site - null 값 넣기 (customUpdate)
  // 

  addSitesForDivision(dealer_id, division_id, data){
    return this.api.post(`/dealers/${dealer_id}/divisions/${division_id}/sites`, data)
  }

  deleteSiteForDivision(dealer_id, site_id){
    const data = { division_id: null }
    return this.api.put(`/dealers/${dealer_id}/sites/${site_id}`, data)
  }

  // USERS
  getAllMemberForDivision(dealer_id, division_id){
    return this.api.get(`/dealers/${dealer_id}/divisions/${division_id}/members`).map(res => {
      this.division_members$s.next(res)
      return res
    })
    /**
     * [
        {
          "division_id": 64,
          "user_id": 549,
          "created_at": "2023-07-24T13:04:07.000Z",
          "email": "ldsung07@hanmail.net",
          "firstname": "DS",
          "lastname": "Lee",
          "provider_picture": "https://s.gravatar.com/avatar/29d6fa803dcb229292037265412da35a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fdl.png"
        },
      ]
     */
  }

  createMemberForDivision(dealer_id, division_id, user_ids){
    const data = { user_ids }
    return this.api.post(`/dealers/${dealer_id}/divisions/${division_id}/members`, data)
    /**
     * {
        "division_id": 64,
        "user_id": 549
      }
     */
  }
  deleteMemberForDivision(dealer_id, division_id, user_id){
    return this.api.delete(`/dealers/${dealer_id}/divisions/${division_id}/members/${user_id}`)
  }
}
