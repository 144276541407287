import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer_cloud_ai {
  constructor(private ims: Imports) {}

  public cloud_ai_enabled(){
    return this.ims.videoAIService.cloudAIEnabled$s.getValue()
  }

  public cloud_ai_providers(){
    return this.ims.videoAIService.cloudAIProviders$s.getValue()
  }

  public dealer_CSDealers_cloudAI_events(){
    return this.ims.videoAIService.dealerCSDealersCloudAIEvents$s.getValue()
  }

  public dealer_sites_cloudAI_events(){
    return this.ims.videoAIService.dealerSitesCloudAIEvents$s.getValue()
  }

  public dealer_devices_cloudAI_events(){
    return this.ims.videoAIService.dealerDevicesCloudAIEvents$s.getValue()
  }

  public cloud_ai_providers$w(): Observable<any> {
    return this.ims.videoAIService.cloudAIProviders$w;
  }

  public dealer_CSDealers_cloudAI_events$w(): Observable<any> {
    return this.ims.videoAIService.dealerCSDealersCloudAIEvents$w;
  }

  public dealer_sites_cloudAI_events$w(): Observable<any> {
    return this.ims.videoAIService.dealerSitesCloudAIEvents$w;
  }

  public dealer_devices_cloudAI_events$w(): Observable<any> {
    return this.ims.videoAIService.dealerDevicesCloudAIEvents$w;
  }

  public async get_cloud_ai_enabled(): Promise<any> | undefined {
    if(this.cloud_ai_enabled() === null) await this.load_cloud_ai_enabled();
    return this.ims.videoAIService.cloudAIEnabled$s.getValue();
  }

  public async get_cloud_ai_providers(): Promise<any> | undefined {
    if(this.cloud_ai_providers()) return this.cloud_ai_providers()
    await this.load_cloud_ai_providers();
    return this.ims.videoAIService.cloudAIProviders$s.getValue();
  }

  public async get_dealer_CSDealers_cloudAI_events(time): Promise<any> | undefined {
    await this.load_dealer_CSDealers_cloudAI_events(time);
    return this.ims.videoAIService.dealerCSDealersCloudAIEvents$s.getValue();
  }


  public async get_dealer_sites_cloudAI_events(time): Promise<any> | undefined {
    await this.load_dealer_sites_cloudAI_events(time);
    return this.ims.videoAIService.dealerSitesCloudAIEvents$s.getValue();
  }

  public async get_dealer_devices_cloudAI_events(time): Promise<any> | undefined {
    await this.load_dealer_devices_cloudAI_events(time);
    return this.ims.videoAIService.dealerDevicesCloudAIEvents$s.getValue();
  }

  public async load_cloud_ai_enabled(): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.videoAIService.getDealerCloudAIConfig(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Cloud AI stat for Analytics" });
      return false;
    }
  }
  public async load_cloud_ai_providers(): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.videoAIService.getAllProvider(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Cloud AI stat for Analytics" });
      return false;
    }
  }
  public async load_dealer_CSDealers_cloudAI_events(time): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.videoAIService.getDealerCSDealersCloudAIEventStat(dealer_id, time).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Cloud AI stat for Analytics - CS Dealer" });
      return false;
    }
  }

  public async load_dealer_sites_cloudAI_events(time): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");
      const dealer_type = this.get_my_dealer_type()
      const isSubDealer = dealer_type === 3

      await this.ims.videoAIService.getDealerSitesCloudAIEventStat(dealer_id, isSubDealer, time).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Cloud AI stat for Analytics - Sites" });
      return false;
    }
  }

  public async load_dealer_devices_cloudAI_events(time): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");
      const dealer_type = this.get_my_dealer_type()
      const isSubDealer = dealer_type === 3

      await this.ims.videoAIService.getDealerDevicesCloudAIEventStat(dealer_id, isSubDealer, time).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Cloud AI stat for Analytics - Devices" });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private get_my_dealer_type(): Number {
    return this.ims.usersService.me?.type;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
