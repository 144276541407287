import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class activity_log {
  constructor(private ims: Imports) {}

  public async load_site_activity_log(site_id, filter?): Promise<any> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.logsService.getAllSiteActivityLog(dealer_id, site_id, filter).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get Activity Logs` });
    }
  }

  public async load_site_activity_log_time_order(site_id, isAsc, filter?): Promise<any> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.logsService.getAllSiteActivityLogByTimeOrder(dealer_id, site_id, isAsc, filter).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get Activity Logs by time order` });
    }
  }

  public async load_site_activity_log_category(filter?): Promise<any> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.logsService.getActivityLogCategorys(dealer_id, filter).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get Activity Log Category` });
    }
  }

  public async load_site_activity_log_detail(event_id?): Promise<any> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.logsService.getActivityLogDetail(dealer_id, event_id).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get Activity Log Category` });
    }
  }

  public async load_site_tts_url(site_id, activityLog_id): Promise<any> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.logsService.getSiteTtsUrl(dealer_id, site_id, activityLog_id).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get TTS URL in activity log` });
    }
  }


  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
