import { Injectable } from '@angular/core';

import {
  MqttService,
} from 'ngx-mqtt';

import config from "../../1config"

@Injectable()
export class WebsocketService {

  client: any;
  subscribe_list: any[] = [];
  function_list: any[] = [];
  is_connected: boolean = false;

  clientOption: any = {};

  constructor(private _mqttService: MqttService) { }

  mqttInit(token: string) {
    let client_id = "operation-client-" + Math.random().toString(16).substr(2, 8);
    this.clientOption = {
      protocol: 'wss',
      hostname: config.command_center_url,
      port: 443,
      path: '/mqtt',
      clientId: client_id,
      username: 'app',
      password: token
    };

    this.client = this._mqttService.connect(this.clientOption);



    this._mqttService.onConnect.subscribe(connect => {
    })
    this._mqttService.onError.subscribe(e => {
    })
    this._mqttService.onClose.subscribe(() => {
      const client_id = "operation-client-" + Math.random().toString(16).substr(2, 8);
      this.clientOption = {
        protocol: 'wss',
        hostname: config.command_center_url,
        port: 443,
        path: '/mqtt',
        clientId: client_id,
        username: 'app',
        password: token
      };
    })
    this._mqttService.onReconnect.subscribe(() => {
    })
    this._mqttService.onEnd.subscribe(() => {
    })

    this._mqttService.onMessage.subscribe(msg => {
    })
  }

  mqttRelease() {
    if (this.client)
      this.client.disconnect();
  }

  registerFunc(subject, func) {
    this.function_list.push({subject: subject, func: func});
  }

}

