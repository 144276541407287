import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import tool from "../../0lib/0tool";

console.debug("✅ 7subscribers.api_errors loaded");

@Component({
  selector: "c_subscriber_api_errors_component",
  template: "",
})
export class c_subscriber_api_errors_component {
  // ------------------------------------------------------------------------

  constructor(private toaster: ToastrService) {}

  ngOnInit() {
    tool.add_event_listener("api-failure", (data) => {
      this.show_error_toast(data?.err, data?.title);
    });
  }

  // ------------------------------------------------------------------------

  show_error_toast(err: any, title: string, msg = "failed", force = false) {
    if (err.error && !force) {
      if (err.error.permission_name) {
        title = err.error.permission_name;
      }
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    this.toaster.error(msg, title, { closeButton: true, timeOut: 5000, progressBar: true, enableHtml: true });
  }
}
