import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Modal } from '../../model/modal';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UsersService } from '../../services/users.service';
import { CommonService } from '../../services/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import auth0_auth from '../../../2loaders/auth0_auth';

@Component({
  selector: 'dealer-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../common.scss', './profile.component.css']
})
export class ProfileComponent implements OnInit {

  @Input() modal: Modal;
  @Output() result: EventEmitter<boolean> = new EventEmitter();

  me: any;

  constructor(
    private apiService: ApiService,
    private usersService: UsersService,
    private commonService: CommonService,
    public deviceService: DeviceDetectorService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const url = window.location.href;
    const params = this.commonService.getQueryParams(url.split('?')[1]);
    let dealer_id = null;
    if (params['dealer_id']) {
      dealer_id = params['dealer_id'];
    }
    this.usersService.getMe(dealer_id).subscribe(res => {
      this.me = res;
    });
  }

  openAccountPage() {
    this.apiService.openAccountPage();
  }

  logout() {
    auth0_auth.logout();
  }

  cancel() {
    if(this.result)
      this.result.next(false);

    this.modal.close();
  }
}
