// ACTION 1 : SPEAKER
export class Speaker {
  device_audio_id?: number;
  device_id?: number; // bridge speaker인 경우만 있음
  device_name: string = 'No Speaker Name';

  constructor(){
    this.device_name = 'No Speaker Name';
  }
}

export class Audio {
  site_tts_id: number;
  tts_text: string;
  type?: number;
  playing_time?: number
  speakers: Speaker[] | null; // null : All speakers
  bridge_speakers?: Speaker[] | null; // null : All speakers

  constructor(){
    this.tts_text = '';
    this.type = 0;
    this.playing_time = 0;
    this.speakers = [];
  }
}


// ACTION 2: RELAY
export class Relay {
  device_do_id: number; // relay id
  device_id: string; // bridge id
  device_name: string = '';
  channel: number;
  normal_icon: string;
  energized_icon: string;
  mode_duration: number = 1;
  mode: string; // 0: latch, 1: momentary (주의! number가 아님)
  
  isShowRelaySelect?: boolean = false;
}

// ACTION 3: RESPONSE CONTACT
export class ResponseContact {
  site_contact_id?: number;
  name: string;
  phone?: string;
  email?: string | null;
  title?: string | null;
  contact_type?: string;
  expire_time: number;

  constructor(data){
    this.update(data)
  }

  update(data: any) {
    if(data.site_contact_id) this.site_contact_id = data.site_contact_id;
    this.name = data.name;
    if(data.phone) this.phone = data.phone;
    if(data.email) this.email = data.email;
    if(data.title) this.title = data.title
    this.contact_type = data.contact_type || 'response';
    this.expire_time = data.expire_time || 1;
  }
}

// ACTION 4: DOWNLOAD LINK
export class DownloadLink {
  site_contact_id?: number;
  name: string;
  email: string;
  title?: string | null;
  contact_type?: string;
  constructor(data){
    this.update(data)
  }

  update(data: any) {
    if(data.site_contact_id) this.site_contact_id = data.site_contact_id;
    this.name = data.name;
    this.email = data.email;
    this.title = data.title || null;
    this.contact_type = data.contact_type || 'download';
  }
}

// CERATE NEW CONTACT 
export class Contact {
  site_contact_id?: number 
  name: string
  title?: string
  email?: string
  phone?: number
  contact_type: string
  expire_time? : number

  constructor(data){
    this.update(data)
  }

  update(data: any) {
    this.site_contact_id = data.site_contact_id
    this.name = data.name
    this.title = data.title
    this.email = data.email
    this.phone = data.phone
    this.contact_type = data.contact_type
    this.expire_time = data.expire_time
  }
}

// ACTION 5: PLAY BACK CAMERA
export class PlaybackCamera {
  device_id: string;
  device_name: string = 'No Camera Name';
  zone_number?: string = '';
  constructor(data){
    this.update(data)
  }

  update(data: any) {
    this.device_id = data.device_id;
    this.device_name = data.device_name || 'No Camera Name';
    this.zone_number = data.zone_number;
  }
}

// ACTION 6: HTTP REQUEST
export class HttpRequest {
  name: string = 'No Name';
  url: string;
  method: string;

  headers: any;
  headersCustom: any[];

  contentType: string;
  contentTypeCustom: string;

  auth_header_key: string; 
  auth_header_val: string;

  auth_type: number;
  username: string;
  password: string;
  bearerToken: string;

  body: any;
  timeout: number;
  retry: number;

  isValidHttpUrl: boolean = false;
  headerBytes: number = 0;
  isHeaderMaxBytes: boolean = false;
  bodyBytes: number = 0;
  isBodyMaxBytes: boolean = false;

  constructor(data){
    this.update(data)
  }

  update(data: any) {
    this.method = data.method;
    this.url = data.url;
    this.name = data.name;

    this.headers = data.headers;
    this.headersCustom = data.headersCustom;
    this.contentType = data.contentType || 'application/json';
    this.contentTypeCustom = data.contentTypeCustom || '';

    this.body = data.body;
    this.auth_header_key = data.auth_header_key; 
    this.auth_header_val = data.auth_header_val; 
    this.auth_type = data.auth_type;
    this.username = data.username;
    this.password = data.password;
    this.bearerToken = data.bearerToken;
    this.isValidHttpUrl = data.isValidHttpUrl;

    this.timeout = 30;
    this.retry = 2;
  }
}


// MAIN PROCEDURE
export class Procedure {
  // id: number;
  id: number;
  name: string;
  isShowMenu?: boolean;
  
  level: number;
  audio: Audio;
  relays: Relay[];
  verification: boolean | number;
  response_contacts: ResponseContact[]; // only for parsing / viewing
  download_contacts: DownloadLink[]; // only for parsing / viewing
  contacts: Contact[]; // only for creating / editing
  playback_cameras: PlaybackCamera[];
  http_requests: HttpRequest[];

  constructor(){
    this.name = '';
    this.verification = false;
    this.level = null;
  }
}

export class newProcedureList {
  actionType: string = null; // audio, relays, response_contacts, download_contacts, playback_cameras, http_requests
  isUnfoldActionList: boolean = false;
  
  audio?: Audio; 
  relays?: Relay[];
  verification?: boolean;
  response_contacts: ResponseContact[];
  download_contacts: DownloadLink[];
  playback_cameras?: PlaybackCamera[];
  http_requests?: HttpRequest[];
}