import { Component, Input, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';

import ims from '../../../../../imports'

@Component({
  selector: 'log_box_default_template',
  templateUrl: './default_template.component.pug',
  styleUrls: ['../../../../common.scss','../../c_card_event_viewer_box.component.scss'],
})
export class log_box_default_template_component {
  @Input() log;
  @Input() timezone;
  @Input() toolbar: TemplateRef<any>;

  logIcon = { type: '', name: ''}
  isBuildReportMode = false;

  snapshotPermission = false;
  isBrokenImage = false;
  isShowPlayBtn = false;
  isShowVideo = false;
  isLoadingVideo = false;

  procedureName = ''

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService, 
    private cdr: ChangeDetectorRef
  ) {}

  private isClearSelectedLogs$w: Subscription;
  private snapshotPermission$w: Subscription;
  private isBuildReportMode$w: Subscription;
  watch() {
    this.isClearSelectedLogs$w = this.eventViewerService.isClearSelectedLogs$w.subscribe((v) => this.unselectLog(v));
    this.snapshotPermission$w = this.eventViewerService.snapshotPermission$w.subscribe((v) => this.snapshotPermission = v);
    this.isBuildReportMode$w = this.eventViewerService.isBuildReportMode$w.subscribe((v) => this.isBuildReportMode = v);
  }
  unwatch() {
    this.isClearSelectedLogs$w?.unsubscribe();
    this.snapshotPermission$w?.unsubscribe();
    this.isBuildReportMode$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.setIconName()
    this.setProcedureName()
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  unselectLog(value){
    if(value === null) return
    if(!value) return
    this.log.isSelected = false
    this.cdr.detectChanges();  // 변경감지 수행
  }

  onClickLog(e){
    e.stopPropagation();
    this.isBuildReportMode ? this.selectLog() : this.openLogDetail()
  }

  selectLog() {
    this.log.isSelected = !this.log.isSelected
    this.log.isSelected
      ? this.eventViewerService.addSelectedLogs(this.log)
      : this.eventViewerService.deleteSelectedLogs(this.log)
  }

  openLogDetail(){
    this.eventViewerService.openDetailModal({status: true, log: this.log})
  }

  // -------------------------------------------------------
  // COMMON

  showToolbar = ims._.throttle(this.showToolbarThrottle.bind(this), 200);
  onHoverLog(e, id){
    e.stopPropagation();
    this.showToolbar(id)
  }
  showToolbarThrottle(id){
    const toolbox = document.getElementById('log-box-toolbox'+id)
    if(toolbox) toolbox.style.visibility = 'visible';
  }

  hiddenToolbar = ims._.throttle(this.hiddenToolbarThrottle.bind(this), 200);
  onMouseLeaveLog(e, log){
    e.stopPropagation();
    this.hiddenToolbar(log)
  }
  hiddenToolbarThrottle(log){
    log.isShowMenu = false
    const toolbox = document.getElementById('log-box-toolbox'+log.id)
    if(toolbox) toolbox.style.visibility = 'hidden';
  }
  

  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  isAutomationRule(){
    const description = this.log.description.toLowerCase()
    const isAutomationRule = description.includes('automation') || this.log.isAutomation
    return isAutomationRule
  }

  // -------------------------------------------------------
  // STYLE
  computedIconColor(){
    if(this.isAutomationRule()) return '#FFA07A'
    return '#8B8B8B'
  }
  computedIconSize(){
    if(this.logIcon.name === 'metabolism') return '40'
    if(this.logIcon.name === 'http') return '40'
    return '30'
  }

  setIconName(){
    this.setSensorIcon()
    if(!this.logIcon.name) this.setBridgeIcon()
    if(!this.logIcon.name) this.setOtherCategory()
  }
  setOtherCategory(){
    const category = this.log.category.toLowerCase()
    switch(category){
      case('alarm'): return this.logIcon = { type: 'material', name: 'notifications_active'}
      // case('end user live view'): return this.logIcon = { type: 'material', name: 'remember_me'}
      case('guard tour'): return this.logIcon = { type: 'comp', name: 'metabolism'}
      case('live video'): return this.logIcon = { type: 'material', name: 'smart_display'}
      case('monitoring portal'): return this.logIcon = { type: 'material', name: 'live_tv'}
      case('boot'): return this.logIcon = { type: 'material', name: 'power_settings_new'}
      // case('periodic test'): return this.logIcon = { type: 'material', name: 'manage_history'}
      // case('playback event'): return this.logIcon = { type: 'material', name: 'replay'}
      case('privacy mode access'): return this.logIcon = { type: 'material', name: 'passkey'}
      case('share download link'): return this.logIcon = { type: 'material', name: 'download'}
      case('share response link'): return this.logIcon = { type: 'material', name: 'reply'}
      case('site procedure action'): return this.logIcon = { type: 'material', name: 'interests'}
      case('site procedure http request'): return this.logIcon = { type: 'material', name: 'http'}
      case('trouble'): return this.logIcon = { type: 'material', name: 'warning'}
      // case('timelapse event'): return this.logIcon = { type: 'material', name: 'timelapse'}
      // case('walk test'): return this.logIcon = { type: 'material', name: 'directions_walk'}
      default : return this.logIcon = { type: '', name: ''}
    }
  }

  setSensorIcon(){
    const category = this.log.category.toLowerCase()
    const trigger = this.log.trigger.toLowerCase()

    if(category != 'sensor') return { type: '', name: ''}
    if(trigger.includes('di')) return this.logIcon = { type: 'material', name: 'settings_input_component'}
    if(trigger.includes('oe')) return this.logIcon = { type: 'material', name: 'psychology'}
    if(trigger.includes('le')) return this.logIcon = { type: 'comp', name: 'bridge'}
    if(trigger.includes('local')) return this.logIcon = { type: 'comp', name: 'bridge'}
    if(trigger.includes('camera')) return this.logIcon = { type: 'comp', name: 'bypass'}
  }
  setBridgeIcon(){
    const category = this.log.category.toLowerCase()
    const description = this.log.description.toLowerCase()

    if(category != 'bridge') return { type: '', name: ''}
    if(description.includes('automation')) return this.logIcon = { type: 'comp', name: 'autopay'}
    return this.logIcon = { type: 'comp', name: 'bridge'}
  }

  setProcedureName(){
    if(this.log.trigger.toLowerCase() != 'procedure') return
    const meta = JSON.parse(this.log.meta)
    const additionalInfo = meta?.additionalInfo
    
    additionalInfo
      ? this.procedureName = additionalInfo?.procedure_name
      : this.procedureName = meta?.info?.procedure_name
  }
}
