<div class="disable-full-screen" [style.opacity]="modal.is_active ? 100 : 0" [style.visibility]="modal.is_active ? 'visible' : 'hidden'">
  <div class="background" *ngIf="isBlockedClickBackground"></div>
  <div class="background" *ngIf="!isBlockedClickBackground" [@background] (click)="closeModal(false)"></div>
  <div class="container-center">
    <div class="modal notice-modal">
      <div class="header notice-header">
        <div class="title">{{modal.data['title']}}</div>
        <div class="button" *ngIf="!isBlockedClickBackground" (click)="closeModal(false)">
          <i class="material-icons">close</i>
        </div>
      </div>
      <div #container class="body notice-body">
        <div class="description" style="word-break: break-word;">
          <div class="markdown"></div>
        </div>
      </div>

      <div class="tail notice-tail">
        <div style="padding:16px 0px;width:100%;">
          <mat-checkbox
            class="example-margin" 
            [(ngModel)]="isEnable"
            [labelPosition]="labelPosition">
            I acknowledge that I have read and agree to the above Terms and Agreement.
          </mat-checkbox>
        </div>

        <div class="btn-area">
          <div class="button button-primary full-button" (click)="confirm()" [class.disabled]="!isEnable">
            <div class="btn-inner" *ngIf="!isLoading">SUBMIT</div>
            <div class="btn-inner loader" *ngIf="isLoading">
              <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

