
import {throwError as observableThrowError,  Observable ,  Subscription, timer } from 'rxjs';

import {timeout, catchError, map} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import config from "../../1config"
import auth0_auth from "../../2loaders/auth0_auth"

@Injectable()
export class ApiService implements OnDestroy {

  // private headers: Headers;
  // private options: RequestOptions;
  private headers: HttpHeaders;

  private apiVersion = 'v1.1';

  private apiEndpoint: string = config.api.prefix + "/api/" + this.apiVersion;

  public account_url: string = 'https://account' + this.getCurrentDomain();
  private dealer_url: string = 'https://dealer' + this.getCurrentDomain();

  public googleApiKey = config.google_api_key;

  constructor( // private http: Http,
     private http: HttpClient) {
  }

  ngOnDestroy() {
  }

  private options() {
    this.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + auth0_auth.idToken,
      // 'Connection': 'Keep-Alive'
    });
    return { headers: this.headers, withCredentials: true }
  }

  public getCookie(cname) {
    const name = encodeURIComponent(cname) + '=';
    const cookie = document.cookie;
    const ca = cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return decodeURIComponent(c.substring(name.length, c.length));
      }
    }
    return '';
  }

  private getCurrentDomain() {
    const temp = window.location.hostname.split('.');
    if (temp.length > 1) {
      return '.' + temp[+temp.length - 2] + '.' + temp[+temp.length - 1];
    } else {
      return config.env === 'dev' ? '.chektdev.com' : '.chekt.com'
    }
  }

  public openAccountPage(isSelf=false) {
    if (isSelf) {
      window.open(this.account_url, '_self');
    } else {
      window.open(this.account_url, '_blank');
    }

  }

  public openDealerPage(dealerId, path) {
    if (String(window.location.hostname).includes("localhost")) return
    let url = `${this.dealer_url}${path}`;
    if (dealerId) {
      url += `?dealer_id=${dealerId}`;
    }
    window.open(url, '_self');
  }

  public get(url: string, type='json', apiVersion = 'v1.1'): Observable<any> {
    let fullUrl = this.apiEndpoint + url;
    if (apiVersion !== 'v1.1') {
      const endPoint = config.api.prefix + "/api/" + apiVersion;
      fullUrl = endPoint + url;
    }
    if (apiVersion === 'v3') {
      const endPoint = config.api.prefix + "/invoice/" + apiVersion;
      fullUrl = endPoint + url;
    }

    return this.http.get(fullUrl, this.options()).pipe(
      map(res => {
        if (type && type === 'text') {
          return JSON.stringify(res);
        }
        return res;
      }),
      catchError(err => observableThrowError(err)),);
  }

  public post(url: string, data: any, apiVersion = 'v1.1'): Observable<any> {
    let fullUrl = this.apiEndpoint + url;
    if (apiVersion !== 'v1.1') {
      const endPoint = config.api.prefix + "/api/" + apiVersion;
      fullUrl = endPoint + url;
    }
    if (apiVersion === 'v3') {
      const endPoint = config.api.prefix + "/invoice/" + apiVersion;
      fullUrl = endPoint + url;
    }
    return this.http.post(fullUrl, data, this.options()).pipe(
      map(res => {
         return res;
      }),
      catchError(err => observableThrowError(err)),);
  }

  public put(url: string, data: any, timeoutSec= 60 * 1000, apiVersion = 'v1.1'): Observable<any> {
    let fullUrl = this.apiEndpoint + url;
    if (apiVersion !== 'v1.1') {
      const endPoint = config.api.prefix + "/api/" + apiVersion;
      fullUrl = endPoint + url;
    }
    if (apiVersion === 'v3') {
      const endPoint = config.api.prefix + "/invoice/" + apiVersion;
      fullUrl = endPoint + url;
    }
    return this.http.put(fullUrl, data, this.options()).pipe(
      timeout(timeoutSec),
      map(res => {
        return res;
      }),
      catchError(err =>
        observableThrowError(err)
      ),);
  }

  public patch(url: string, data: any, apiVersion = 'v1.1'): Observable<any> {
    let fullUrl = this.apiEndpoint + url;
    if (apiVersion !== 'v1.1') {
      const endPoint = config.api.prefix + "/api/" + apiVersion;
      fullUrl = endPoint + url;
    }
    return this.http.patch(fullUrl, data, this.options()).pipe(
      map(res => {
         return res;
      }),
      catchError(err => observableThrowError(err)),);
  }

  public proxyPut(url: string, data: any, timeoutSec= 60 * 1000, apiVersion = 'v1.1', type = 'json'): Observable<any> {
    let fullUrl = this.apiEndpoint + url;
    if (apiVersion !== 'v1.1') {
      const endPoint = config.api.prefix + "/api/" + apiVersion;
      fullUrl = endPoint + url;
    }
    this.options();
    let options = {}
    if (type === 'text') {
      options = { headers: this.headers, withCredentials: true, responseType: type as 'json' };
    } else {
      options = { headers: this.headers, withCredentials: true };
    }

    return this.http.put(fullUrl, data, options).pipe(
      timeout(timeoutSec),
      map(res => {
        return res;
      }),
      catchError(err =>
        observableThrowError(err)
      ),);
  }

  public delete(url: string, apiVersion = 'v1.1'): Observable<any> {
    let fullUrl = this.apiEndpoint + url;
    if (apiVersion !== 'v1.1') {
      const endPoint = config.api.prefix + "/api/" + apiVersion;
      fullUrl = endPoint + url;
    }
    if (apiVersion === 'v3') {
      const endPoint = config.api.prefix + "/invoice/" + apiVersion;
      fullUrl = endPoint + url;
    }
    return this.http.delete(fullUrl, this.options()).pipe(
      map(res => {
        return res;
      }),
      catchError(err => observableThrowError(err)),);
  }

  public get_custom(url: string, options: {}): Observable<any> {
    return this.http.get(this.apiEndpoint + url, options);
  }
  public getUrl(url: string, options: {}): Observable<any> {
    return this.http.get(url, options);
  }

  public getSnasphot(url: string): Observable<any> {
    const fullUrl = url + '&duration=4&interval=1'
    const headers = new HttpHeaders({
      'Authorization' : 'Bearer ' + auth0_auth.idToken
    });
    const options = { headers: headers};
    return this.http.get(fullUrl, options).pipe(
      map(res => {
        return res;
      }),
      catchError(err => observableThrowError(err)),);
  }

  public get_custom_fullpath(url: string, options: {}): Observable<any> {
    return this.http.get(url, options);
  }

  public put_custom(url: string, data: any, options: {}): Observable<any> {
    return this.http.put(url, data, options);
  }

  put_url(url: string, data: any, timeoutSec= 60 * 1000): Observable<any> {
    const corsHeaders = new HttpHeaders({
      'Authorization' : 'Bearer ' + auth0_auth.idToken,
      'Content-Type' : 'application/json',
      // 'Access-Control-Allow-Origin' : '*',
      // 'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
      // 'Access-Control-Allow-Methods': 'PUT'
    });
    const corsOptions = { headers: corsHeaders};
    return this.http.put(url, data, corsOptions).pipe(
      timeout(timeoutSec),
      map(res => {
        return res;
      }),
      catchError(err => observableThrowError(err)),);
  }

  put_with_cloud_url(url: string, data: any, mimeType: any): Observable<any> {
    let timeoutSec = 60 * 1000
    let headers = new HttpHeaders({
      'Content-Type' : mimeType,
    });
    let options = { headers: headers };
    return this.http.put(url, data, options).pipe(
      timeout(timeoutSec),
      map(res => {
         return res;
      }),
      catchError(err => observableThrowError(err))
    )
  }

  // use when test https request
  // public getTestAutomationRuleHttpRequest(url: string, options: {}): Observable<any> {
  //   return this.http.get(url, options);
  // }
}