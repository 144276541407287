import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/of";

@Injectable()
export class NotificationsService {
  version1 = 'v1.1';
  version2 = 'v1.2';

  version = this.version1;

  constructor(private api: ApiService) {}

  getNotifications(dealerId: number): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/notifications`);
  }

  createNotification(dealerId: number, data): Observable<any> {
    return this.api.post(`/dealers/${dealerId}/notifications`, data);
  }

  updateNotification(dealerId: number, notificationId, data): Observable<any> {
    return this.api.put(
      `/dealers/${dealerId}/notifications/${notificationId}`,
      data
    );
  }

  deleteNotifications(dealerId: number, notificationId): Observable<any> {
    return this.api.delete(
      `/dealers/${dealerId}/notifications/${notificationId}`
    );
  }
}
