import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  template: `
    <h2 mat-dialog-title>Basic Dialog</h2>
    <mat-dialog-content>
      <p>{{ data.message }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button (click)="cancel()">Cancel</button>
      <button (click)="confirm()">OK</button>
    </mat-dialog-actions>
  `,
})
export class c_dialog_basic_component {
  constructor(
    public dialogRef: MatDialogRef<c_dialog_basic_component>,
    @Inject(MAT_DIALOG_DATA) public data: c_dialog_basic_inputs
  ) {}

  cancel(): void {
    this.dialogRef.close("cancel");
  }

  confirm(): void {
    this.dialogRef.close("ok");
  }
}

interface c_dialog_basic_inputs {
  message: string;
}
