<div class="title-area">
  <div class="description" style="font-size: 20px; margin-bottom: 8px">What’s new in your CHeKT Dealer Portal?</div>
  <div class="title" *ngIf="feature === 'video-storage-and-cloud-api'">Available Now!</div>
  <div class="title" *ngIf="feature === 'automation-rules'">Site Based Automation Rules!</div>
  <div class="title" *ngIf="feature === 'cloud-ai'">Cloud based AI Filtering!</div>
</div>

<ng-container *ngIf="isLoading">
  <div class="loader absolute">
    <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" style="stroke: white" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" />
    </svg>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="feature === 'video-storage-and-cloud-api'">
    <div class="description-container-center">
      <div class="description-box">
        <div class="description-item">
          <div class="description-title">🎥 Extend video retention</div>
          <div class="description">Extend video events from 30 days to 90 or 1 year on the Professional and Premium plans, respectively.</div>
        </div>
        <div class="description-item">
          <div class="description-title">💫 CHeKT Cloud API</div>
          <div class="path">Try to apply: <span (click)="goToCloudApi()" class="link">🚀 Settings > API Keys</span></div>
          <div class="description">Integration of 3rd party software systems and automate CheKT functions using new cloud-based API options.</div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="feature === 'automation-rules'">
    <div class="description-container">
      <div class="left">
        <div *ngFor="let description of descriptionBox; let i = index" [ngClass]="description.className" (click)="onClickSwitchVideo(i)">
          <div class="description-title">
            <div class="notice-icon center">
              <i *ngIf="description.iconType === 'material'" class="material-icons" style="color: white; font-size: 20px;">{{description.titleIcon}}</i>
              <comp-icon *ngIf="description.iconType === 'component'" [icon]="description.titleIcon" [size]="'20'" [color]="'white'"></comp-icon>
            </div>
            {{description.title}}
          </div>
          <div class="description">{{description.contents}}</div>
        </div>
      </div> 
      <div class="right">
        <div class="loader absolute" *ngIf="isLoadingVideo">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" style="stroke: white" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" />
          </svg>
        </div>
        <iframe 
          *ngIf="seletctedDescription?.className.includes('selected')" (load)="videoUploadDone()" width="420" height="230"
          [src]="seletctedDescription.src | safe" 
          title="YouTube video player" frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen
        ></iframe>
        <div style="margin-top: 24px; color: #55647B;">Please ensure your sound is on for the video. </div>
        <div style="margin: 8px 0; color: #55647B">
          Please refer to
          <a href="https://support.chekt.com/portal/en/kb/articles/automation-rules" target="_blank" style="color: #1E88E5; text-decoration: underline;">
            this document
          </a>
          for a detailed explanation
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="feature === 'cloud-ai'">
    <div class="description-container-center">
      <div class="description-box">
        <div class="description-item">
          <div class="description-title flex v-center">
            <img src="/assets/logo/cloud-ai-logo.svg" style="width: 20px; margin-right: 8px"/>
            Cloud AI Filtering 
          </div>
          <div class="description">
            CHeKT Cloud AI Filtering provides adds a layer of AI filtering for Humans and Vehicles for video alarm signals sent to your alarm center, increasing operator efficiency.
            <br/><br/>
            - Reduction in false alarm events created by none human activity.<br/>
            - Increased operational efficiently and focus on true alarm activity.<br/>
            <br/>
            Upgrade your Dealer Plan to begin.
          </div>
        </div>
      </div>

      <div class="flex" style="justify-items: center;">
        <iframe 
        (load)="videoUploadDone()" width="420" height="230"
        [src]="cloudAIDescriptionVideo[cloudAIDescriptionVideoRegion] | safe"
        title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen
      ></iframe>
      </div>
    </div>
  </ng-container>
  
  <div class="modal-bottom">
    <div style="font-size: 18px; color: #1E88E5; margin-bottom: 12px; font-weight: 500">
      The possibilities are endless.
    </div>
    Receive updates by subscribing to CHeKT SNS.<br/>
  
    <div class="logo-section">
      <div *ngFor="let logo of snsLogoList" class="tooltip-content" [id]="logo.name + '-logo-box'" (mouseenter)="setTooltipPosition(logo.name + '-logo-box')">
        <div class="logo tooltip-icon">
          <a [href]="logo.src" target="_blank" style="display: flex;">
            <!-- IMG Loading -->
            <div class="loader absolute" *ngIf="isImgLoading">
              <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" style="stroke: white" cx="50" cy="50" r="10" fill="none" stroke-width="4" stroke-miterlimit="10" />
              </svg>
            </div>
            <!-- IMG Loading -->
            <img [src]="logo.img" (load)="imageUploadDone()" style="height: 16px" [alt]="logo.name + 'link'"/>
          </a>
        </div>
        <div class='tooltip' [id]="logo.name + '-logo'">
          <div>{{logo.name}}</div>
        </div>
      </div>  
    </div>
  </div>
</ng-container>

