import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class EventStoragesService {
  constructor(
    private api: ApiService,
  ) { }

  eventStorages$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  eventStorages$w = this.eventStorages$s.asObservable()

  /////////////////////////////
  getAllEventStorages(dealerId): Observable<any> {
    return this.api.get(`/dealers/${dealerId}/event_storages`).map(res =>{
      this.eventStorages$s.next(res)
      return res
    })
  }
  // only for new sites
  updateDealerEventStorages(dealerId, event_storage_id): Observable<any> {
    const data = { event_storage_id }
    return this.api.put(`/dealers/${dealerId}/event_storages`, data)
  }

  updateDealerAllExistingSitesEventStorages(dealerId, event_storage_id): Observable<any> {
    const data = { event_storage_id }
    return this.api.put(`/dealers/${dealerId}/sites/event_storages`, data)
  }

  updateSiteEventStorages(dealerId, site_id, event_storage_id): Observable<any> {
    const data = { event_storage_id }
    return this.api.put(`/dealers/${dealerId}/sites/${site_id}/event_storages`, data)
  }
}
