const dataURItoBlob = function(dataURI) {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
}

const resize = function(image, maxWidth, maxHeight){
  const canvas = document.createElement('canvas');
  const fileName = image.name
  const fileType = fileName.slice(fileName.indexOf("."))
  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;
  canvas.getContext("2d").drawImage(image, 0, 0, width, height);
  const dataUrl = canvas.toDataURL(`image/${fileType}`);
  return dataURItoBlob(dataUrl);
};

export default {
  resizeImage: function(imageSource, maxWidth, maxHeight) {
    const reader = new FileReader();
    const image = new Image();

    return new Promise((ok) => {
      reader.readAsDataURL(imageSource);
      reader.onload = (readerEvent) => {
        image.onload = () => {
          return ok(resize(image, maxWidth, maxHeight));
        };
        image.src = (readerEvent.target.result).toString();
      };
    });
  }
}
