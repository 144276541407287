import { Component, Input } from "@angular/core";
import { Subscription } from "rxjs";

import { c_components } from "../../../../3ui/2components";
import { Helper } from "../../../../4services/2helper";
import { isString } from "lodash";

@Component({
  selector: "c_dtable_permission_groups_component",
  templateUrl: "./c_dtable_permission_groups_component.pug",
  styleUrls: ["../common.scss", "./c_dtable_permission_groups_component.scss"],
})
export class c_dtable_permission_groups_component {
  // ------------------------------------------------------------------------

  @Input() public search_text: string;
  @Input() public tab: string;

  ngOnChanges(changes: any) {
    if (changes.search_text && changes.search_text.currentValue !== changes.search_text.previousValue) {
      this.on_search_text_change();
    }
  }

  am_i_division_user: boolean
  users: any[] = [];
  globalRoles: any[] = [];
  divisionRoles: any[] = [];
  filteredGlobalRoles: any[] = [];
  filteredDivisionRoles: any[] = [];

  hasReadMemberPermission = false;
  hasUpdateMemberPermission = false;
  hasDeleteMemberPermission = false;

  hasReadRolePermission = false;
  hasUpdateRolePermission = false;
  hasDeleteRolePermission = false;

  constructor(private helper: Helper, private c_components: c_components) {}

  members$w: Subscription;
  roles$w: Subscription;
  watch() {
    this.members$w = this.helper.dealer_member.members$w().subscribe((v) => this.on_users_change(v));
    this.roles$w = this.helper.dealer_role.roles$w().subscribe((v) => this.on_roles_change(v));
  }
  unwatch() {
    this.members$w?.unsubscribe();
    this.roles$w?.unsubscribe();
  }

  // ------------------------------------------------------------------------

  async ngOnInit() {
    this.watch();
    this.checkPermission()
    await this.load_data();
  }
  ngOnDestroy() {
    this.unwatch();
  }

  // ------------------------------------------------------------------------
  checkPermission(){
    this.hasReadMemberPermission = this.helper.permission.has("dealer_member_read")
    this.hasUpdateMemberPermission = this.helper.permission.has("dealer_member_update")
    this.hasDeleteMemberPermission = this.helper.permission.has("dealer_member_delete")

    this.hasReadRolePermission = this.helper.permission.has("dealer_role_read")
    this.hasUpdateRolePermission = this.helper.permission.has("dealer_role_update")
    this.hasDeleteRolePermission = this.helper.permission.has("dealer_role_delete")
  }

  // ------------------------------------------------------------------------

  on_search_text_change() {
    this.tab === 'global'
      ? this.update_filtered_global_roles()
      : this.update_filtered_division_roles()
  }

  on_users_change(value: any) {
    if(!value) return
    this.users = value;
  }

  on_roles_change(value: any) {
    if(!value) return
    value?.forEach((role) => {
      role.users = this.users?.filter((user) => {
        return user.role_id == role.role_id;
      });
      role.user_cnt = role.users?.length;
    });
    this.globalRoles = value.filter(v => !v.is_division_role) ?? []
    this.divisionRoles = value.filter(v => v.is_division_role) ?? []
    
    this.update_filtered_global_roles()
    this.update_filtered_division_roles()
  }

  // ------------------------------------------------------------------------

  async load_data() {
    await this.helper.dealer_member.load_members();
    await this.helper.dealer_role.load_roles();
    const amI = await this.helper.me.get_me()
    this.am_i_division_user = !!amI.is_division_user
  }

  update_filtered_global_roles() {
    if (!isString(this.search_text)) return (this.filteredGlobalRoles = this.globalRoles);

    const search_text = this.search_text.toLowerCase();
    if (search_text.length < 2) return (this.filteredGlobalRoles = this.globalRoles);

    this.filteredGlobalRoles = this.globalRoles.filter((role) =>
      [role.name, role.description].join("").toLowerCase().includes(search_text)
    );
  }

  update_filtered_division_roles(){
    if (!isString(this.search_text)) return (this.filteredDivisionRoles = this.divisionRoles);

    const search_text = this.search_text.toLowerCase();
    if (search_text.length < 2) return (this.filteredDivisionRoles = this.divisionRoles);

    this.filteredDivisionRoles = this.divisionRoles.filter((role) =>
      [role.name, role.description].join("").toLowerCase().includes(search_text)
    );
  }

  // ------------------------------------------------------------------------

  gotoDetail(role: any) {
    if(this.am_i_division_user) return
    this.helper.router.navigate_to(`/settings/permission-groups/${role?.role_id}`);
  }

  // ------------------------------------------------------------------------

  openEditDialog(role: any) {
    if(!this.hasUpdateMemberPermission) {
      this.c_components.dialog.open("warning", {
        header: "You do not have permission to edit the permission.",
        contents: `
          <p>
          Please contact your administrator to change the permission.
        </p>
        `,
        isConfirm: true,
        submit_btn: "OK",
        submit_class: ["button-primary"],
        submit_func: () => {},
      })
      return
    }
    this.c_components.dialog.open("dealer_role", { role_id: role?.role_id });
  }

  openDeleteDialog(role: any) {
    if(role.user_cnt) return this.showWarningForPermissionDeletionWithIncludedUsers()

    this.c_components.dialog.open("warning", {
      header: "Delete a Role",
      contents: `
        <p>Are you sure that you want to Delete a Role <b>${role?.name}</b></p>
      `,
      submit_btn: "Delete",
      submit_class: ["button-danger"],
      isInput: "Please type in the name of Permission group",
      confirmInput: role?.name,
      submit_func: () => {
        this.deleteRole(role?.role_id);
      },
    });
  }

  showWarningForPermissionDeletionWithIncludedUsers(){
    this.c_components.dialog.open("warning", {
      header: "You cannot delete the permission.",
      contents: `
        <p>
        There are users included in the permission.<br/>
        To delete the permission, please change it to a different permission.
      </p>
      `,
      isConfirm: true,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      submit_func: () => {},
    });
  }

  async deleteRole(role_id: string) {
    if (!(await this.helper.dealer_role.delete_role(role_id))) return;
    setTimeout(() => {
      this.openConfirmDialog("Delete a Role", "Delete a Role - success", "done", "green", true);
    }, 300);
  }

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green", doAction = false) {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (doAction) {
          this.load_data();
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}
