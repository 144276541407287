import { Injectable } from '@angular/core';

console.debug('✅ 4services.2helper loaded');

import { me } from './me';

import { dealer } from './dealer';
import { partner } from './partner';
import { dealer_member } from './dealer_member';
import { dealer_role } from './dealer_role';
import { dealer_access } from './dealer_access';
import { dealer_plan } from './dealer_plan'
import { dealer_division } from './dealer_division';
import { dealer_payment } from './dealer-payment';
import { dealer_api_keys } from './dealer_api_keys';
import { dealer_cloud_ai } from './dealer_cloud_ai';
import { sites } from './sites'
import { event_storages } from './event_storages'
import { activity_log } from './activity_log';
import { virtual_guard_tour } from './virtual_guard_tour';
import { incident_reports } from './incident-reports';
import { note_directions } from './note-directions';
import { mp_settings } from './mp_settings';

import { devices } from './devices'

import { Router } from './router';
import { Permission } from './permission';

@Injectable()
export class Helper {
  constructor(
    public me: me,
    public dealer: dealer,
    public partner: partner,
    public dealer_member: dealer_member,
    public dealer_role: dealer_role,
    public dealer_access: dealer_access,
    public dealer_plan: dealer_plan,
    public dealer_division: dealer_division,
    public dealer_payment: dealer_payment,
    public dealer_api_keys: dealer_api_keys,
    public dealer_cloud_ai: dealer_cloud_ai,
    public sites: sites,
    public event_storages: event_storages,
    public activity_log: activity_log,
    public virtual_guard_tour: virtual_guard_tour,
    public incident_reports: incident_reports,
    public note_directions: note_directions,
    public mp_settings: mp_settings,
    public devices: devices,
    public router: Router,
    public permission: Permission,
  ) {}
}
