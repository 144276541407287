import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToastService {

  contents: any[] = [];
  subscription: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor() { }

  getSubscription(): BehaviorSubject<any[]> {
    return this.subscription;
  }

  push(content) {
    this.contents.push(content);
    this.subscription.next(this.contents);
  }

  pop() {
    return this.contents.pop();
  }
}
