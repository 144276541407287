import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class partner {
  constructor(private ims: Imports) {}

  public partners$w(): Observable<any> {
    return this.ims.partnerService.partners$w;
  }

  public async get_partners(refresh?): Promise<Array<any>> | undefined {
    if(!this.ims.partnerService.partners$s.getValue()?.length || refresh) await this.load_partners(refresh);
    return this.ims.partnerService.partners$s.getValue();
  }

  public async load_partners(refresh?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const dealer_type = await this.get_my_dealer_type();
      if(dealer_type != 2) return; // WCS.M에 대해서만 실행가능함.

      await this.ims.partnerService.getAllPartners(dealer_id, refresh).toPromise();
      return true;
    } catch (err) {
      console.log(err)
      tool.dispatch_event("api-failure", { err, title: "Get Dealer All Partners" });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private async get_my_dealer_type(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.type;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
