import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningModule } from '../../modals/warning/warning.module'
import { SharedModule } from '../../directives/shared.module'
import { NewFeatureNotification } from './new-feature-notification.component'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SafePipeModule } from '../safe-pipe/safe.pipe.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    MatCheckboxModule,
    SafePipeModule
  ],
  declarations: [
    NewFeatureNotification
  ],
  exports: [
    NewFeatureNotification
  ]
})
export class NewFeatureNotificationModule { }
