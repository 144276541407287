import { Component, HostListener, Input, ViewChildren, QueryList } from '@angular/core';
import { PagerService } from '@app/services/pager.service';

import ims from '../../../imports'
import { Helper } from '../../../../../../4services/2helper'
import { c_components } from 'src/3ui/2components';
import { CommonService } from '@app/services/common.service';

@Component({
  selector: 'c_dtable_service_note',
  templateUrl: './c_dtable_service_note.component.pug',
  styleUrls: ['../../../common.scss', '../site-note-manager.scss']
})
export class c_dtable_service_note_component {
  @Input() pagedItemIsReady: boolean;
  @Input() serviceNotePagedItems: any;
  @Input() filterList: any;
  @ViewChildren('serviceNoteActionMenus') serviceNoteActionMenus: QueryList<any>
  //-----------------------------------------------------------------------------

  isLoading = false;
  hasReadSiteNotesPermission = false;
  hasCreateSiteNotesPermission = false;
  hasUpdateSiteNotesPermission = false;

  generalTypeCount = 0
  installationTypeCount = 0
  accountingTypeCount = 0
  serviceTypeCount = 0
  salesTypeCount = 0
  noContentTypeCount = 0

  // contents
  serviceTableHeader = [
    { name: 'SITE ID', value: 'site_id', width: "7%" },
    { name: 'SITE NAME', value: 'site_name', width: "22%" },
    { name: 'SERVICE TITLE', value: 'note_title', width: "31%" },
    { name: 'TYPE', value: 'type_label', width: "5%" },
    { name: 'DATE CREATED', value: 'created_at', width: "10%" },
    { name: 'DATE UPDATED', value: 'updated_at', width: "10%" },
    { name: '', value: null, width: "5%" },
  ]
  pagedItems = [];

  // Pager
  pager: any = {};
  curPage = 1;
  totalItem = 0;
  pageOffset = 50;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;


  constructor(
    public pagerService : PagerService,
    public commonService: CommonService,
    public c_components: c_components,
    public helper: Helper  
  ) { }

  ngOnChanges(changes): void {
    this.applyItemList(changes)
    this.applyFilter(changes)
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.serviceNoteActionMenus) {
      const serviceNoteActionMenus = this.serviceNoteActionMenus.toArray();
      serviceNoteActionMenus.forEach(actionMenu => {
        this.pagedItems.forEach(serviceNote=> {
          if (`service-note-${serviceNote.id}` === actionMenu.nativeElement.id) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              serviceNote.isShowMenu = false;
            }
          }
        })
      })
    }
  }

  applyItemList(changes){
    if(!changes?.pagedItemIsReady) return
    if(changes?.pagedItemIsReady?.currentValue === changes?.pagedItemIsReady?.previousValue) return
    if(!changes?.pagedItemIsReady?.currentValue) return

    this.isLoading = true
    this.checkPermission()
    this.setPage(1)
    this.isLoading = false
  }

  checkPermission(){
    // 여긴 이미 준비되어 있음. change에서 실행되니까
    this.hasReadSiteNotesPermission = this.helper.permission.has("site_note_read")
    this.hasCreateSiteNotesPermission = this.helper.permission.has("site_note_create")
    this.hasUpdateSiteNotesPermission = this.helper.permission.has("site_note_update")
  }

  applyFilter(changes) {
    if(!changes?.filterList) return
    if(changes?.filterList?.currentValue === changes?.filterList?.previousValue) return
    if(!changes?.filterList?.currentValue) return

    this.isLoading = true
    this.setPage(1)
    this.isLoading = false
  }

  // --------------------------------------------------------------------------------
  goToServiceNoteDetail(event, note){
    event.stopPropagation()

    const noteId = note.id;
    const url = `/customers/devices/note/${noteId}/note-detail`
    this.commonService.emitSiteTab([{targetNoteId: noteId, targetUrl: url, name: 'Notes', link: 'note'}, 'note'])
    this.helper.router.navigate_to(`/customers/devices/note/${noteId}/note-detail`, {id : note.site_id});
  }

  goToAddServiceNote(event, note){
    event.stopPropagation()
    if(!this.hasCreateSiteNotesPermission) return this.openWarningDialog('add')

    const siteId = note.id; // site id 
    const url = `/customers/devices/note/note-detail/add`
    this.commonService.emitSiteTab([{targetNoteId: siteId, targetUrl: url, name: 'Notes', link: 'note'}, 'note'])
    this.helper.router.navigate_to(`/customers/devices/note/note-detail/add`, {id : note.site_id});
  }

  setMorePosition(id) {
    const docElem = document.documentElement
    let elem = document.getElementById('service-note-'+id);
    let more = document.getElementById('service-note-more-'+id);
    let rect = elem?.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right - 10;
    const posY = rect.bottom + 7;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(device){
    const targetMenu = device.isShowMenu;
    device.isShowMenu = !targetMenu;
  }

  checkTotalCountInServiceNote(target){
    this.generalTypeCount = 0
    this.installationTypeCount = 0
    this.accountingTypeCount = 0
    this.serviceTypeCount = 0
    this.salesTypeCount = 0
    this.noContentTypeCount = 0

    target.forEach(note => {
      if(note?.type_label?.toLowerCase() === 'general') this.generalTypeCount++
      if(note?.type_label?.toLowerCase() === 'installation') this.installationTypeCount++
      if(note?.type_label?.toLowerCase() === 'accounting') this.accountingTypeCount++
      if(note?.type_label?.toLowerCase() === 'service') this.serviceTypeCount++
      if(note?.type_label?.toLowerCase() === 'sales') this.salesTypeCount++
      if(!note?.type_label) this.noContentTypeCount++
    })
  }

  determineElementToShowInServiceNoteTable(){
    if(this.isLoading) return false
    if(!this.hasReadSiteNotesPermission) return 'no-permission'
    if(!this.pagedItems?.length) return 'no-data'
    return 'normal'
  }

  // -----------------------------------------------------------------------------
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortTableByColumnInServiceNote(sortTarget) {
    if (!sortTarget.sortColumn || sortTarget.sortDirection === '') {
      return;
    }
    let data = this.serviceNotePagedItems;

    const tmpData = data.sort((a, b) => {
      const isAsc = sortTarget.sortDirection === 'asc';
      switch (sortTarget.sortColumn) {
        case 'site_id': 
          return this.compare(a.site_id, b.site_id, isAsc);
        case 'site_name': 
          return this.compare(a.site_name?.toLowerCase(), b.site_name?.toLowerCase(), isAsc);
        case 'note_title': 
          return this.compare(a.title?.toLowerCase(), b.title?.toLowerCase(), isAsc);
        case 'type_label': 
          return this.compare(a.type_label, b.type_label, isAsc);
        case 'created_at': 
          return this.compare(a.created_at, b.created_at, isAsc);
        case 'updated_at': 
          return this.compare(a.updated_at, b.updated_at, isAsc);
        default: return 0;
      }
    });
    
    this.serviceNotePagedItems = tmpData;
    this.setPage(1)
  }

  setPage(page: number) {
    this.pagedItems = []

    const target = this.filterItems()
    this.checkTotalCountInServiceNote(target)
    if(!target){
      console.debug('⚠️ list is lost', target)
      return this.isLoading = false
    }
    
    // get pager object from service
    this.pager = this.pagerService.getPager(target?.length, page, this.pageOffset);
    this.totalItem = target?.length;
    
    // get current page of items
    this.pagedItems = target.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalItem) {
      this.pageOffsetEnd =  this.totalItem;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
  }

  filterItems() {
    if(!this.filterList) return
    // 1. 선택된 site
    const selectedSite = this.filterList?.selectedSite

    // 2. 선택된 status 리스트
    const selectedStatuses = this.filterList?.noteTypeList

    // 필터링된 아이템
    const target = ims._.cloneDeep(this.serviceNotePagedItems);
    return target.filter(item => {
      // site 필터
      const siteMatch = !selectedSite || selectedSite.site_id === item.site_id

      // status 필터
      const statusMatch = selectedStatuses.length === 0 || selectedStatuses.includes(item.type_label) || (selectedStatuses.includes('None : No Content') && !item.type_label);

      // createdAt 필터
      const createdAtMatch = (!this.filterList?.createdAtRange.start || new Date(item.created_at) >= new Date(this.filterList?.createdAtRange.start)) &&
                             (!this.filterList?.createdAtRange.end || new Date(item.created_at) <= new Date(this.filterList?.createdAtRange.end));
  
      // updatedAt 필터
      const updatedAtMatch = (!this.filterList?.updatedAtRange.start || new Date(item.updated_at) >= new Date(this.filterList?.updatedAtRange.start)) &&
                             (!this.filterList?.updatedAtRange.end || new Date(item.updated_at) <= new Date(this.filterList?.updatedAtRange.end));
  
      return siteMatch && statusMatch && createdAtMatch && updatedAtMatch;
    });
  }

  // -----------------------------------------------------------------------------
  openWarningDialog(permission: string){
    let contents = ''
    if(permission === 'add') {
      contents = `<p>You do not have add permissions to access this.</b></p>`
    } else if(permission === 'edit') {
      contents = `<p>You do not have edit permissions to access this.</b></p>`
    }

    this.c_components.dialog.open("warning", {
      header: 'Access Denied',
      contents: contents,
      submit_btn: "OK",
      submit_class: ["button-danger"],
      icon: 'do_not_disturb_on',
      isConfirm: true,
      color: 'orange',
      submit_func: () => {},
    });
  }
}