export default {
  set_cookie: function (key: string, value: string, date: string = "") {
    const domain = this.get_current_domain_without_subdomain()
    if (!date) {
      const now = new Date()
      now.setFullYear(now.getFullYear() + 5)
      date = now.toUTCString()
    }
    document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)};expires=${date};domain=${domain};path=/`
  },
  delete_cookie: function (key: string) {
    this.set_cookie(key, 'meaning_less_string', 'Thu, 01 Jan 1970 00:00:00 UTC')
  },
  get_cookie: function (key: string) {
    let name = encodeURIComponent(key) + '='
    let cookie = document.cookie
    let ca = cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        let cookieToReturn = decodeURIComponent(c.substring(name.length, c.length))
        if (cookieToReturn === 'undefined') return ''
        else if (cookieToReturn === 'null') return ''
        else if (cookieToReturn === 'NaN') return ''
        else return cookieToReturn
      }
    }
    return ''
  },
  get_current_domain_without_subdomain: function () {
    let temp = window.location.hostname.split('.')
    if (temp.length > 1) {
      return '.' + temp[+temp.length - 2] + '.' + temp[+temp.length - 1]
    } else {
      return temp[0]
    }
  },
};
