import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class NoteDirectionsService {
  dealerSiteNotes$s: BehaviorSubject<any> = new BehaviorSubject<any[]>([]); 
  siteNotes$s: BehaviorSubject<any> = new BehaviorSubject<any[]>([]); 
  siteNote$s: BehaviorSubject<any> = new BehaviorSubject<any>(null); 
  dealerMonitoringDirections$s: BehaviorSubject<any> = new BehaviorSubject<any[]>([]); 
  monitoringDirections$s: BehaviorSubject<any> = new BehaviorSubject<any[]>([]); 
  monitoringDirection$s: BehaviorSubject<any> = new BehaviorSubject<any>(null); 
  procedureList$s: BehaviorSubject<any> = new BehaviorSubject<any>([]); 

  dealerMonitoringDirections$w = this.dealerMonitoringDirections$s.asObservable();
  monitoringDirections$w = this.monitoringDirections$s.asObservable();
  monitoringDirection$w = this.monitoringDirection$s.asObservable();
  procedureList$w = this.procedureList$s.asObservable();
  dealerSiteNotes$w = this.dealerSiteNotes$s.asObservable();
  siteNotes$w = this.siteNotes$s.asObservable();
  siteNote$w = this.siteNote$s.asObservable();

  constructor(private api: ApiService) { }

  // ----------------- Site Note -----------------
  getDealerSiteNotes(dealerId) {
    return this.api.get(`/dealers/${dealerId}/service_notes`).map(res => {
      this.dealerSiteNotes$s.next(res)
      return res
    })
  }

  getSiteNotes(dealerId, siteId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/service_notes`).map(res => {
      this.siteNotes$s.next(res)
      return res
    })
  }

  getSiteNote(dealerId, siteId, note_id) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/service_notes/${note_id}`).map(res => {
      if(res[0]) this.siteNote$s.next(res[0])
      else this.siteNote$s.next([])
      return res[0]
    })
  }

  createSiteNote(dealerId, siteId, data){
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/service_notes`, data);
  }

  updateSiteNote(dealerId, siteId, note_id, data){
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/service_notes/${note_id}`, data);
  }

  deleteSiteNote(dealerId, siteId, note_id){
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/service_notes/${note_id}`);
  }

  // ----------------- Monitoring Directions -----------------
  getDealerMonitoringDirections(dealerId) {
    return this.api.get(`/dealers/${dealerId}/mp_directions`).map(res => {
      this.dealerMonitoringDirections$s.next(res)
      return res
    })
  }

  getSiteMonitoringDirections(dealerId, siteId) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/mp_directions`).map(res => {
      this.monitoringDirections$s.next(res)
      return res
    })
  }

  getSiteMonitoringDirection(dealerId, siteId, note_id) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/mp_directions/${note_id}`).map(res => {
      if(res[0]) this.monitoringDirection$s.next(res[0])
      else this.monitoringDirection$s.next([])
      return res[0]
    })
  }

  createSiteMonitoringDirection(dealerId, siteId, data){
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/mp_directions`, data);
  }

  updateSiteMonitoringDirection(dealerId, siteId, note_id, data){
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/mp_directions/${note_id}`, data);
  }

  deleteSiteMonitoringDirection(dealerId, siteId, note_id){
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/mp_directions/${note_id}`);
  }

  updateSiteMonitoringDirectionStatus(dealerId, siteId, note_id, data){
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/mp_directions/${note_id}/status`, data);
  }

  copySiteMonitoringDirection(dealerId, siteId, note_id, data){
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/mp_directions/${note_id}/copy`, data);
  }

  // ----------------- Procedure -----------------
  getAllProcedures(dealerId, siteId, mp_direction_id) {
    return this.api.get(`/dealers/${dealerId}/sites/${siteId}/mp_directions/${mp_direction_id}/procedures`).map(res => {
      this.procedureList$s.next(res)
      return res
    })
  }

  createProcedure(dealerId, siteId, mp_direction_id, data){
    return this.api.post(`/dealers/${dealerId}/sites/${siteId}/mp_directions/${mp_direction_id}/procedures`, data);
  }

  updateProcedure(dealerId, siteId, procedure_id, data){
    return this.api.put(`/dealers/${dealerId}/sites/${siteId}/procedures/${procedure_id}`, data);
  }

  deleteProcedure(dealerId, siteId, procedure_id){
    return this.api.delete(`/dealers/${dealerId}/sites/${siteId}/procedures/${procedure_id}`);
  }
}
