<div class="disable-full-screen" [style.opacity]="modal.is_active ? 100 : 0" [style.visibility]="modal.is_active ? 'visible' : 'hidden'"
  style="z-index: 101">
  <div class="background" (click)="cancel()"></div>
  <div class="container-center">
    <div class="modal" style="width: 400px;height: 410;" [class.mobile-size]="deviceService.isMobile()">
      <div class="header" style="height: 64px;">
        <div class="title"> </div>
        <div class="button" (click)="cancel()">
          <i class="material-icons">close</i>
        </div>
      </div>
      <div class="body-center" *ngIf="me" style="position: relative; top: -56px; padding-bottom: 0px;">
        <img class="face" *ngIf="me.provider_picture" [src]="me.provider_picture" (error)="me.provider_picture=null" style="width: 96px; height: 96px; border: 4px solid white" />
        <div *ngIf="!me.provider_picture" class="no-face" style="width: 96px; height: 96px; border: 4px solid white;">
          <div style="padding-top:16px;">{{me.email.slice(0,1)}}</div>
        </div>
        <div style="margin: 24px 0 0 0; font-size: 20px;">{{commonService.getFullName(me)}}</div>
        <div style="margin: 8px; font-size: 14px; color: #777;">{{me.email}}</div>

      </div>
      <div class="tail">
        <div class="button button-danger" (click)="logout()">Sign Out</div>
        <div class="space"></div>
        <div class="button" (click)="openAccountPage()">Account Page</div>
      </div>
    </div>
  </div>
</div>