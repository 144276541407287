import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { c_dtable_permission_groups_component } from "./c_dtable_permission_groups_component";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [c_dtable_permission_groups_component],
  exports: [c_dtable_permission_groups_component],
})
export class c_dtable_permission_groups_module {}
