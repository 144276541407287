import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { c_dialog_dealer_division_component } from "./c_dialog_dealer_division_component";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [c_dialog_dealer_division_component],
})
export class c_dialog_dealer_division_module {}
