export default {
  getEnv: function () {
    const currentDomain = String(window.location.hostname);
    let env = localStorage.getItem('chekt::env') || 'dev';
    // filter - PROD
    ['chekt.com'].forEach((v) => {
      if (!currentDomain.includes(v)) return;
      env = 'prod';
    });
    return env;
  },
  logLevel: {
    dev: 'debug',
    prod: 'info',
  },
  api: {
    dev: {
      prefix: 'https://api.chektdev.com',
    },
    prod: {
      prefix: 'https://api.chekt.com',
    },
  },
  command_center_url: {
    dev: 'commandcenter.chektdev.com',
    prod: 'commandcenter.chekt.com',
  },
  auth0_auth: {
    dev: {
      domain: 'auth.chektdev.com',
      client_id: 'yeJIohNks41mYAiaID29JoxsZESOpEQ8',
    },
    prod: {
      domain: 'auth.chekt.com',
      client_id: 'bHzjpwmWpjwdOukz2h7FM2u2ND5BPKFa',
    },
  },
  token_key: {
    dev: 'chekt::id_token_dev',
    prod: 'chekt::id_token',
  },
  google_api_key: {
    dev: 'AIzaSyD33CRqwF-fO7Z9NioI9z98h0eDZ1la3cw',
    prod: 'AIzaSyD33CRqwF-fO7Z9NioI9z98h0eDZ1la3cw',
  },
  updateEnvironmentalVariablesGlobally: function () {
    // GET - env
    const env = this.getEnv();

    // UPDATE - only insensitive information
    localStorage.setItem('chekt::log_level', this.logLevel[env])
    localStorage.setItem('chekt::app_start_time', new Date().toISOString())

    // RETURN - env - for convenience
    return env;
  },
};
