const events_listener_proxy = {};

export default {
  dispatch_event: function (event_name: string, data?: any): boolean {
    if (!events_listener_proxy[event_name]) return;
    return window.dispatchEvent(new CustomEvent(event_name, { detail: data }));
  },
  add_event_listener: function (event_name: string, listener: (data: any) => any): void {
    this.remove_event_listener();
    const event_listener_proxy = this._get_event_listener_proxy_by_creating_one_if_none(event_name, listener);
    return window.addEventListener(event_name, event_listener_proxy);
  },
  remove_event_listener: function (event_name: string): void {
    if (!events_listener_proxy[event_name]) return;
    return window.removeEventListener(event_name, events_listener_proxy[event_name]);
  },

  _get_event_listener_proxy_by_creating_one_if_none: function (
    event_name: string,
    listener: (data: any) => any
  ): Function {
    this._create_event_listener_proxy_if_none(event_name, listener);
    return events_listener_proxy[event_name];
  },
  _create_event_listener_proxy_if_none: function (event_name: string, listener: (data: any) => any): void {
    if (events_listener_proxy[event_name]) return;
    events_listener_proxy[event_name] = (event: CustomEvent) => {
      listener(event?.detail);
    };
  },
  _remove_event_listener_proxy_if_any: function (event_name: string): void {
    if (!events_listener_proxy[event_name]) return;
    delete events_listener_proxy[event_name];
  },
};
