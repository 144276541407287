import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../../../app/directives/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { c_dialog_mp_settings_bulk_update_component } from './c_dialog_mp_settings_bulk_update_component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatCheckboxModule,
    MatSlideToggleModule,
  ],
  declarations: [c_dialog_mp_settings_bulk_update_component],
  exports: [
    c_dialog_mp_settings_bulk_update_component
  ]
})
export class c_dialog_mp_settings_bulk_update_module { }
