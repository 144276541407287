import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Helper } from "../../../../4services/2helper";
import { CommonService } from '../../../../app/services/common.service'

import ims from '../../imports'

@Component({
  templateUrl: './c_dialog_event_storage_region_component.pug',
  styleUrls: ['../common.scss', './c_dialog_event_storage_region_component.scss'],
})
export class c_dialog_event_storage_region_component {
  isAllSitesIncludesNewSites = this.data.isAllSitesIncludesNewSites

  isShowEventRegionOptions = false;
  doubleCheckText:string = ''

  constructor(
    public dialogRef: MatDialogRef<c_dialog_event_storage_region_component>,
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private helper: Helper,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }

  async onClickApply(){
    try {
      await this.changeDealerAllExistingSitesEventRegions()
      if(this.isAllSitesIncludesNewSites) await this.changeDealerNewSitesEventRegions()
      this.commonService.showSuccessToast('Success', 'Update All Site Event Storage')
      ims.tool.sleep(100)
      this.close_dialog(true)
    } catch(err){
      console.debug(err)
    }
  }

  async changeDealerAllExistingSitesEventRegions() {
    try {
      const data = this.data.selectedEventRegionOption.id
      await this.helper.event_storages.update_dealer_all_existing_sites_event_storages(data)
    } catch(err){
      console.debug('changeDealerAllSitesEventRegions:>',err)
      this.commonService.errorDialog(err, 'Failed update - Existing Sites')
    }
  }
  async changeDealerNewSitesEventRegions() {
    try {
      const data = this.data.selectedEventRegionOption.id
      await this.helper.event_storages.update_dealer_event_storages(data)
    } catch(err){
      console.debug('changeDealerAllSitesEventRegions:>',err)
      this.commonService.errorDialog(err, 'Failed update - New Sites')
    }
  }

  // ------------------------------------------------------------------------
  disabledSaveButton(){
    if(!this.doubleCheckText) return true;
    if(this.doubleCheckText != this.data.selectedEventRegionOption.region_name) return true;
    return false
  }

  public close_dialog(result: boolean = false): void {
    this.dialogRef.close(result);
    // if(result) return this.closeAllModal.emit('click')
  }
}
