import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { Observable } from 'rxjs';





@Injectable()
export class WebworkerService {

  me: any;

  constructor(private api: ApiService) { }

  public getAll(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/webworkers`);
  }

  public get(dealer_id: number, webworkerId: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}`);
  }

  public getSource(dealer_id: number, webworkerId: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}/source`);
  }

  public getLog(dealer_id: number, webworkerId: number, offset=0, error?): Observable<any> {
    if (error) {
      return this.api.get(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}/logs?error=${error}&sort=id&dir=desc&offset=${offset * 50}&limit=50`);
    } else {
      return this.api.get(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}/logs?sort=id&dir=desc&offset=${offset * 50}&limit=50`);
    }
  }

  public getRuntime(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/event_adapters/webworker/runtimes`);
  }

  public create(dealer_id: number, data: any): Observable<any> {
    let post_data = {
      name: data['name'],
      enable: data['enable'],
      description: data['description'],
      is_default: data['password'],
    };

    return this.api.post(`/dealers/${dealer_id}/event_adapters/webworkers`, post_data);
  }

  public update(dealer_id: number, webworkerId: number, data: any): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}`, data);
  }

  public updateSource(dealer_id: number, webworkerId: number, source: any): Observable<any> {
    return this.api.put(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}/source`, source);
  }

  public delete(dealer_id: number, webworkerId: number): Observable<any> {
    return this.api.delete(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}`);
  }

  public test(dealer_id: number, webworkerId: number, data): Observable<any> {
    return this.api.post(`/dealers/${dealer_id}/event_adapters/webworkers/${webworkerId}/test`, data);
  }
}