import { Component, Inject } from "@angular/core";
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Helper } from "../../../../../4services/2helper";

import { CommonService } from "../../../../../app/services/common.service";

@Component({
  templateUrl: "./c_dialog_dealer_division_clear_component.pug",
  styleUrls: ["../../common.scss", "./c_dialog_dealer_division_clear_component.scss"],
})
export class c_dialog_dealer_division_clear_component {
  isLoading = false;

  divisions: any;
  allSiteNumber: number = 0;
  allUserNumber: number = 0;
  isValid = false;

  validText = '';
  placeholder = "Delete all divisions information";


  constructor(
    public commonService: CommonService,
    public dialogRef: MatDialogRef<c_dialog_dealer_division_clear_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: number,
    private helper: Helper
  ) {}


  private divisions$w: Subscription;
  private my_divisions$w: Subscription;
  private members$w: Subscription;
  watch() {
    this.divisions$w = this.helper.dealer_division.divisions$w().subscribe((v) => this.on_division_change(v));
    this.my_divisions$w = this.helper.dealer_division.my_divisions$w().subscribe((v) => this.on_division_change(v));
    this.members$w = this.helper.dealer_member.members$w().subscribe((v) => this.on_member_change(v));
  }
  unwatch() {
    this.divisions$w?.unsubscribe();
    this.my_divisions$w?.unsubscribe();
    this.members$w?.unsubscribe();
  }

  ngOnInit() {
    this.isLoading = true
    this.load_division()
    this.load_member()
    this.watch();
    this.isLoading = false
  }

  ngOnDestroy() {
    this.unwatch();
  }

  // -----------------------------------------------------------------------

  on_division_change(value){
    if(!value) return
    this.divisions = value
    this.calculateDivisionStats()
  }

  on_member_change(value){
    if(!value) return
    const allDivisionUsers = value.filter(member => member.provider !== 'ip' && member.is_division_user) ?? []
    this.allUserNumber = allDivisionUsers.length
  }

  async load_division(){
    await this.helper.dealer_division.get_divisions()
  }

  async load_my_division(){
    await this.helper.dealer_division.get_my_divisions()
  }

  async load_member(){
    await this.helper.dealer_member.get_members()
  }

  calculateDivisionStats(){
    this.allSiteNumber = 0
    this.divisions.forEach(division => this.allSiteNumber += division.site_count)
  }

  // ------------------------------------------------------------------------

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  async submit_delete() {
    this.isLoading = true;
    await this.clear_division()
    await Promise.all([
      this.load_division(),
      this.load_my_division()
    ])
    this.commonService.showSuccessToast('Clear Division Data', 'Success')
    this.isLoading = false;
    this.close_dialog(true)
  }

  async clear_division(){
    await this.helper.dealer_division.delete_all_division()
  }
}