import { Injectable } from "@angular/core";
import { Imports } from "../imports";

@Injectable()
export class me {
  constructor(private ims: Imports) {}

  public async isMainDealer(){
    await this.get_me()
    return this.ims.usersService.me?.type === 2
  }
  public async isSubDealer(){
    await this.get_me()
    return this.ims.usersService.me?.type === 3
  }
  public async isOtherDealer(){
    await this.get_me()
    return this.ims.usersService.me?.type === 1 || this.ims.usersService.me?.type === 0
  }

  public async get_me(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me;
  }

  public async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  public load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  public my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
