import { NgModule } from '@angular/core';
import { Helper } from './index';
import { Imports } from './imports';

import { me } from './me';

import { dealer } from './dealer';
import { partner } from './partner';
import { dealer_member } from './dealer_member';
import { dealer_role } from './dealer_role';
import { dealer_access } from './dealer_access';
import { dealer_plan } from './dealer_plan'
import { dealer_division } from './dealer_division'
import { dealer_payment } from './dealer-payment';
import { dealer_api_keys } from './dealer_api_keys';
import { dealer_cloud_ai } from './dealer_cloud_ai';
import { sites } from './sites'
import { event_storages } from './event_storages'
import { activity_log } from './activity_log';
import { virtual_guard_tour } from './virtual_guard_tour';
import { incident_reports } from './incident-reports';
import { note_directions } from './note-directions';
import { mp_settings } from './mp_settings';
import { devices } from './devices'

import { Router } from './router';
import { Permission } from './permission';

@NgModule({
  providers: [
    Helper,
    Imports,

    me,
    dealer,
    partner,
    dealer_member,
    dealer_role,
    dealer_access,
    dealer_plan,
    dealer_division,
    dealer_payment,
    dealer_api_keys,
    dealer_cloud_ai,
    sites,
    event_storages,
    activity_log,
    virtual_guard_tour,
    incident_reports,
    note_directions,
    mp_settings,
    devices,
    
    Router,
    Permission,
  ]
})
export class HelperModule {}
