import { Component, Input, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { Helper } from 'src/4services/2helper';
import ims from '../../../../../imports'

@Component({
  selector: 'log_box_verification',
  templateUrl: './verification.component.pug',
  styleUrls: ['../../../../common.scss','../../c_card_event_viewer_box.component.scss'],
})
export class log_box_verification_component {
  @Input() log;
  @Input() timezone;
  @Input() toolbar: TemplateRef<any>;

  isBuildReportMode = false;

  snapshotPermission = false
  isBrokenImage = false

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService,
    private helper: Helper,
    private cdr: ChangeDetectorRef
  ) {}

  private isClearSelectedLogs$w: Subscription;
  private snapshotPermission$w: Subscription;
  private isBuildReportMode$w: Subscription;
  watch() {
    this.isClearSelectedLogs$w = this.eventViewerService.isClearSelectedLogs$w.subscribe((v) => this.unselectLog(v));
    this.snapshotPermission$w = this.eventViewerService.snapshotPermission$w.subscribe((v) => this.snapshotPermission = v);
    this.isBuildReportMode$w = this.eventViewerService.isBuildReportMode$w.subscribe((v) => this.isBuildReportMode = v);
  }
  unwatch() {
    this.isClearSelectedLogs$w?.unsubscribe();
    this.snapshotPermission$w?.unsubscribe();
    this.isBuildReportMode$w?.unsubscribe();
  }

  ngOnInit(): void {
    this.watch()
  }
  ngOnDestroy(){
    this.unwatch()
  }

  onClickLog(e){
    e.stopPropagation();
    this.isBuildReportMode ? this.selectLog() : this.openLogDetail()
  }
  unselectLog(value){
    if(value === null) return
    if(!value) return
    this.log.isSelected = false
    this.cdr.detectChanges();  // 변경감지 수행
  }
  selectLog() {
    this.log.isSelected = !this.log.isSelected
    this.log.isSelected
      ? this.eventViewerService.addSelectedLogs(this.log)
      : this.eventViewerService.deleteSelectedLogs(this.log)
  }
  openLogDetail(){
    this.eventViewerService.openDetailModal({status: true, log: this.log})
  }

  // -------------------------------------------------------
  // COMMON

  showToolbar = ims._.throttle(this.showToolbarThrottle.bind(this), 200);
  onHoverLog(e, id){
    e.stopPropagation();
    this.showToolbar(id)
  }
  showToolbarThrottle(id){
    const toolbox = document.getElementById('log-box-toolbox'+id)
    if(toolbox) toolbox.style.visibility = 'visible';
  }

  hiddenToolbar = ims._.throttle(this.hiddenToolbarThrottle.bind(this), 200);
  onMouseLeaveLog(e, log){
    e.stopPropagation();
    this.hiddenToolbar(log)
  }
  hiddenToolbarThrottle(log){
    log.isShowMenu = false
    const toolbox = document.getElementById('log-box-toolbox'+log.id)
    if(toolbox) toolbox.style.visibility = 'hidden';
  }

  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  isAutomationRule(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule
  }

  computedBackground(){
    if(this.log.isLoading) return 'loading-background'
    if(!this.snapshotPermission) return 'no-permission'

    if(this.canIShowImageOrVideo() === 'need to upgrade plan') return 'no-permission'
    if(this.canIShowImageOrVideo() === 'expired') return 'no-permission'
    if(this.canIShowImageOrVideo() === 'privacy mode') return 'no-permission'
    if(this.canIShowImageOrVideo() === 'broken image') return 'no-permission'
    if(this.canIShowImageOrVideo() === 'image') return 'full-height'
    if(this.canIShowImageOrVideo() === 'broken video') return 'no-permission'
    if(this.canIShowImageOrVideo() === 'video') return 'full-height'
    if(this.canIShowImageOrVideo() === 'default') return 'verification'
  }
  
  canIShowImageOrVideo(){
    const highestPlan = this.helper.dealer_plan.isPremiumPlan() || this.helper.dealer_plan.isResellerPlan()
    if(!this.snapshotPermission) return 'no permission'
    if(highestPlan && this.log.is_expired) return 'expired'
    if(!highestPlan && this.log.is_expired) return 'need to upgrade plan'
    if(this.log.isPrivacy) return 'privacy mode'
    if(this.isBrokenImage) return 'broken image'
    if(this.log.imageUrl) return 'image'
    if(this.log.videoUrl && !this.log.safetyVideoUrl) return 'broken video'
    if(this.log.safetyVideoUrl) return 'video'
    return 'default'
  } 

  // --------------------------------------------------------------
  // STYLE
  videoAIResult(statusText){
    if(!statusText) return 'Failed'
    switch(statusText){
      case('alarm'): return 'True'
      case('normal'): return 'False'
      case('skipped'): return 'Skipped'
      default: return 'Failed'
    }
  }
  parseVideoAIResult(meta){
    if(!meta || typeof meta != 'string') return 'Failed'
    const metaData = JSON.parse(meta)
    const body = metaData?.req?.body
    const entityIds = body?.summary_entity_ids
    if(!entityIds) return 'Failed'

    let cloudResult = ''
    const data = JSON.parse(entityIds)
    const result = Object.values(data)

    let person = ''
    let vehicle = ''
    const isAllPerson = result.every((entity: any[]) => entity?.includes('person'))
    const isAllVehicle = result.every((entity: any[]) => entity?.includes('vehicle'))

    if(data.static?.length) {
      if(data.static.includes('person')) person = 'Stationary Person'
      if(data.static.includes('vehicle')) vehicle = 'Stationary Vehicle'
    }
    if(data.motion?.length) {
      if(data.motion.includes('person')) person = 'Moving Person'
      if(data.motion.includes('vehicle')) vehicle = 'Moving Vehicle'
    }

    if(isAllPerson) person = 'Person'
    if(isAllVehicle) vehicle = 'Vehicle'

    if(person && vehicle) {
      cloudResult = `${person}, ${vehicle}`
    } else if(person || vehicle) {
      cloudResult = `${person || vehicle}`
    } else if(!person && !vehicle) {
      cloudResult = 'Nothing Detected'
    }
    return cloudResult
  }
}
