import { Component, Inject } from "@angular/core";
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { c_components } from "../../index";
import { Helper } from "../../../../4services/2helper";

@Component({
  templateUrl: "./c_dialog_dealer_role_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_dealer_role_component.scss"],
})
export class c_dialog_dealer_role_component {
  // ------------------------------------------------------------------------

  public role_id: string;
  public role_name: string;
  public is_division_role: boolean;
  public role_description: string;
  public hasPremiumOrResellerPlan: boolean;
  public isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<c_dialog_dealer_role_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: c_dialog_dealer_role_inputs,
    private c_components: c_components,
    private helper: Helper
  ) {}

  private dealer_plan$w: Subscription;
  watch() {
    this.dealer_plan$w = this.helper.dealer_plan.dealer_plan$w().subscribe((v) => this.on_dealer_plan_change(v));
  }
  unwatch() {
    this.dealer_plan$w?.unsubscribe();
  }

  ngOnInit() {
    this.role_id = this.data?.role_id;
    this.update_role_data_if_role_id_provided();
  }

  ngOnDestroy(){
    this.unwatch();
  }

  public close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  public async submit_changes() {
    this.isLoading = true;

    this.trim_spaces_data();
    if (this.role_id) await this.edit_role();
    else await this.create_role();

    this.isLoading = false;
  }

  // ------------------------------------------------------------------------

  on_dealer_plan_change(value: any){
    if(!value) return
    this.hasPremiumOrResellerPlan = value?.current_month_service_plan_type > 2
  }

  private async update_role_data_if_role_id_provided() {
    if (!this.role_id) return;

    const role = await this.helper.dealer_role.get_role(this.role_id);
    this.role_name = role?.name;
    this.role_description = role?.description;
    this.is_division_role = role?.is_division_role;
  }

  private trim_spaces_data() {
    this.role_name = this.role_name?.trim();
    this.role_description = this.role_description?.trim();
  }

  private async create_role() {
    const title = `Create a Role`;
    const msg = `${title} ${this.role_name} - Success.`;
    try {
      await this.helper.dealer_role.create_role({
        name: this.role_name,
        description: this.role_description,
        is_division_role: this.is_division_role
      });
      this.openConfirmDialog(title, msg);
      this.helper.dealer_role.load_roles();
    } catch (err) {
      this.openErrorDialog(err, title);
    }
  }

  private async edit_role() {
    const title = `Edit a Role`;
    const msg = `${title} ${this.role_name} - Success.`;
    try {
      await this.helper.dealer_role.update_role(this.role_id, {
        name: this.role_name,
        description: this.role_description,
        is_division_role: this.is_division_role
      });
      this.openConfirmDialog(title, msg);
      this.helper.dealer_role.load_roles();
    } catch (err) {
      this.openErrorDialog(err, title);
    }
  }

  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}

interface c_dialog_dealer_role_inputs {
  role_id?: string;
}
