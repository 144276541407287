import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { SitesService } from '@app/services/sites.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { CommonService } from '@app/services/common.service';
import { Helper } from 'src/4services/2helper';
import { Subscription } from 'rxjs';

import moment from 'moment';
import 'moment-timezone';

interface CalendarDate {
  day: number | null;
  month: number;
  year: number;
  isSelected: boolean;
  eventNumber: number;
  highlightColorByEvent: string; // 이벤트가 있는 날짜를 표시하기 위한 플래그
  disabled: boolean;
}
@Component({
  selector: 'c_card_calendar_component',
  templateUrl: './c_card_calendar.component.pug',
  styleUrls: ['../../common.scss','./c_card_calendar.component.scss'],
})
export class c_card_calendar_component {
  @Input() siteTimezone;
  @Output() onSelectDate: EventEmitter<any> = new EventEmitter();
  @ViewChild('monthSelectDropdown') monthSelectDropdown: ElementRef;
  @ViewChild('yearSelectDropdown') yearSelectDropdown: ElementRef;
  
  changeSite: any;
  isLoading: boolean = false
  selectedDate: string;

  currentMonth: Date;
  selectedCalendarDate: String | any;
  dates: CalendarDate[] = [];
  weekdays: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  isUnfoldMonthSelectDropdown = false
  monthOptions: any[] = []
  selectedMonth: any
  monthList = [
    { label: 'Jan', value: 0 }, 
    { label: 'Feb', value: 1 }, 
    { label: 'Mar', value: 2 }, 
    { label: 'Apr', value: 3 }, 
    { label: 'May', value: 4 }, 
    { label: 'Jun', value: 5 }, 
    { label: 'Jul', value: 6 }, 
    { label: 'Aug', value: 7 }, 
    { label: 'Sep', value: 8 }, 
    { label: 'Oct', value: 9 }, 
    { label: 'Nov', value: 10 }, 
    { label: 'Dec', value: 11 }
  ]

  isUnfoldYearSelectDropdown = false
  yearOptions: number[] = []
  selectedYear: number

  constructor(
    private sitesService: SitesService,
    public eventViewerService: EventViewerService,
    public commonService: CommonService,
    private helper: Helper,
  ) {}

  selectedDate$w: Subscription
  watch(){
    this.selectedDate$w = this.eventViewerService.selectedDate$w.subscribe(v=>{
      this.selectedDate = v
      this.selectedCalendarDate = v
      this.onChangeSelectedDate(); 
    })
  }
  unwatch(){
    this.selectedDate$w?.unsubscribe()
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.monthSelectDropdown) {
      if (!this.monthSelectDropdown.nativeElement.contains(event.target)) {
        event?.stopPropagation()
        this.isUnfoldMonthSelectDropdown = false
      }
    }
    if (this.yearSelectDropdown) {
      if (!this.yearSelectDropdown.nativeElement.contains(event.target)) {
        event?.stopPropagation()
        this.isUnfoldYearSelectDropdown = false
      }
    }
  }
  async ngOnInit() {
    this.changeSite = this.sitesService.site.subscribe(async (res)=>{
      await this.initData();
      this.commonService.emitPageLoading(false);
    })
    await this.initData();
  }

  ngAfterViewInit() {
    this.commonService.emitPageLoading(false);
  }
  ngOnDestroy() {
    this.unwatch()
    this.changeSite.unsubscribe();
  }

  async initData() {
    this.isLoading = true
    const localTime = this.makeLocalTimeCurrentTime()
    this.watch()

    if(this.selectedDate) {
      const dateValue = moment(this.selectedDate, 'MMM / DD / YYYY').valueOf()
      this.currentMonth = new Date(dateValue)
      this.selectedCalendarDate = this.selectedDate
      this.onChangeSelectedDate(); 
    } else {
      this.currentMonth = localTime
      this.selectedCalendarDate = moment.tz(this.siteTimezone).format('MMM / DD / YYYY')
    }
    
    await this.eventViewerService.fetchEventCounts()
    this.generateCalendar();
    this.generateOptions();
  }

  makeLocalTimeCurrentTime(): Date{
    // Moment-timezone 객체 생성
    let momentTimezone = moment.tz(this.siteTimezone)
    
    // UTC로 변환
    const utcTime = momentTimezone.utc();

    // Date 객체는 UTC 시간을 나타내지만, 설정한 타임존의 해당 시간임.
    let dateObject = new Date(utcTime.format());
    return dateObject
  }

  generateCalendar(): void {
    const dates: CalendarDate[] = [];
    const startOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1);
    const endOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0);

    for (let date = startOfMonth; date <= endOfMonth; date.setDate(date.getDate() + 1)) {
      for (let day = 0; day <= this.weekdays.length; day++) {
        if (date.getDay() != day && date.getDate() === 1){
          dates.push({
            day: null,
            month: date.getMonth(),
            year: date.getFullYear(),
            isSelected: false,
            eventNumber: 0,
            highlightColorByEvent: '',
            disabled: true,
          });
          continue
        } 
        break
      }

      const dateNumber = date.getDate()
      dates.push({
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        isSelected: this.isSelected(date),
        eventNumber: this.eventViewerService.eventListByDate[dateNumber],
        highlightColorByEvent: this.highlightColorByEvent(date),
        disabled: this.checkSelectedDateIsPast(date),
      });
    }

    this.dates = dates;
    this.isLoading = false;
  }

  generateOptions(){
    this.generateMonthOptions()
    this.generateYearOptions()
  }
  generateMonthOptions(){
    this.monthOptions = [] //init
    const selectedDate = moment(this.selectedCalendarDate , 'MMM / DD / YYYY').tz(this.siteTimezone)
    const selectedDateMonth = selectedDate.month()  // 0부터 시작함

    this.monthOptions = this.monthList.slice()
    this.selectedMonth = this.monthOptions.find(v => v.value === selectedDateMonth)
  }
  generateYearOptions(){
    const currentYear = this.currentMonth.getFullYear()
    this.selectedYear = currentYear
    // Log는 최대 1년만 보관되므로 그 이하의 년도는 보여줄 필요 없음.
    for(let i = currentYear; i >=currentYear-1; i--){
      this.yearOptions.push(i)
    }
  }

  // ---------------------------------

  async moveToPreviousMonth(e?) {
    e?.stopPropagation()
    
    this.currentMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() - 1, 1);
    this.selectedMonth = this.monthList.find(v => v.value === this.currentMonth.getMonth())
    this.selectedYear = this.currentMonth.getFullYear()

    this.isLoading = true
    await this.eventViewerService.fetchEventCounts(this.currentMonth)
    this.generateCalendar();
    this.generateMonthOptions();
  }

  async moveToNextMonth(e?) {
    e?.stopPropagation()
    this.currentMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 1);
    this.selectedYear = this.currentMonth.getFullYear()

    this.isLoading = true
    await this.eventViewerService.fetchEventCounts(this.currentMonth)
    this.generateCalendar();
    this.generateMonthOptions();
  }

  toggleMonthOptions(e){
    e?.stopPropagation()
    this.isUnfoldMonthSelectDropdown = !this.isUnfoldMonthSelectDropdown
  }

  toggleYearOptions(e){
    e?.stopPropagation()
    this.isUnfoldYearSelectDropdown = !this.isUnfoldYearSelectDropdown
  }

  // ---------------------------------
  // CLICK EVENT
  selectMonth(e, month){
    e?.stopPropagation()
    this.selectedMonth = month
    this.isUnfoldMonthSelectDropdown = false

    const currentDate = moment(this.selectedCalendarDate, 'MMM / DD / YYYY').tz(this.siteTimezone)
    const selectedMonthCurrentDate = currentDate.clone().set('month', month.value).startOf('month').format()
    const date = new Date(selectedMonthCurrentDate)

    this.currentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dateNumber = date.getDate()
    const calendarDate = {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      isSelected: this.isSelected(date),
      eventNumber: this.eventViewerService.eventListByDate[dateNumber],
      highlightColorByEvent: this.highlightColorByEvent(date),
      disabled: this.checkSelectedDateIsPast(date),
    }

    this.selectDate(calendarDate)
    this.generateCalendar()
  }

  selectYear(e, year){
    e?.stopPropagation()
    this.selectedYear = year
    this.isUnfoldYearSelectDropdown = false

    const currentMonth = moment(this.selectedCalendarDate, 'MMM / DD / YYYY').tz(this.siteTimezone)
    const selectedYearCurrentMonth = currentMonth.clone().set('year', year).startOf('month').format()
    const date = new Date(selectedYearCurrentMonth)

    this.currentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dateNumber = date.getDate()
    const calendarDate = {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      isSelected: this.isSelected(date),
      eventNumber: this.eventViewerService.eventListByDate[dateNumber],
      highlightColorByEvent: this.highlightColorByEvent(date),
      disabled: this.checkSelectedDateIsPast(date),
    }

    this.selectDate(calendarDate)
    this.generateCalendar()
    this.generateMonthOptions()
  }

  selectDate(date: CalendarDate, e?): void {
    e?.stopPropagation()
    const selectedDate = moment.tz(this.siteTimezone).year(date.year).month(date.month).date(date.day)
    const formattedDate = selectedDate.tz(this.siteTimezone).format('MMM / DD / YYYY')
    const selectedDateIsPast = this.checkSelectedDateIsPast(formattedDate)

    if(selectedDateIsPast) return 
    this.dates.forEach(v => v === date ? v.isSelected = true : v.isSelected = false)

    // 이건 유저가 자신의 timezone 기준으로 클릭한 날짜
    this.selectedCalendarDate = formattedDate
    this.currentMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1);
    this.selectedMonth = this.monthList.find(v => v.value === this.currentMonth.getMonth())
    this.selectedYear = this.currentMonth.getFullYear()
    this.onSelectDate.emit({date: this.selectedCalendarDate})
  }
  selectToday(e?){
    e?.stopPropagation()
    const date = new Date(this.eventViewerService.today)
    const dateNumber = date.getDate()
    const today = {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      isSelected: this.isSelected(date),
      eventNumber: this.eventViewerService.eventListByDate[dateNumber],
      highlightColorByEvent: this.highlightColorByEvent(date),
      disabled: this.checkSelectedDateIsPast(date),
    }
    this.selectDate(today)

    this.currentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    this.selectedMonth = this.monthList.find(v => v.value === this.currentMonth.getMonth())
    this.selectedYear = this.currentMonth.getFullYear()
    this.generateMonthOptions()
    this.generateCalendar();
  }

  checkSelectedDateIsPast(date: Date | string){
    if(typeof date != 'string') date = moment(date).tz(this.siteTimezone).format('MMM / DD / YYYY')
    if(date === this.eventViewerService.today) return false

    const selectedDate = moment(date, 'MMM / DD / YYYY')
    const selectedDateIsPast = moment().isBefore(selectedDate) // 선택된 날짜가 이미 오늘을 지났어?
    return selectedDateIsPast
  }

  isSelected(date: Date): boolean {
    const newDate = new Date(this.selectedCalendarDate) 
    return date.getDate() === newDate.getDate() &&
           date.getMonth() === newDate.getMonth() &&
           date.getFullYear() === newDate.getFullYear();
  }

  // ---------------------------------
  // STYLE
  highlightColorByEvent(date: Date): string {
    const dateNumber = date.getDate()
    const eventNumber = this.eventViewerService.eventListByDate[dateNumber]

    if(eventNumber === 0) return ''
    if(eventNumber > 0 && eventNumber <= 50) return 'event-low'
    if(eventNumber > 50 && eventNumber <= 100) return 'event-middle'
    if(eventNumber > 100) return 'event-high'
    return ''
  }

  isOtherYear(){
    const localCurrentTime: Date = this.makeLocalTimeCurrentTime()
    return localCurrentTime.getFullYear() != this.currentMonth.getFullYear()
  }

  // ---------------------------------
  // LIFE CYCLE
  async onChangeSelectedDate(){
    this.isLoading = true
    if(this.selectedCalendarDate === 'Today') {
      this.selectedCalendarDate = moment.tz(this.siteTimezone).format('MMM / DD / YYYY')
      this.isLoading = true
      await this.eventViewerService.fetchEventCounts()
      this.generateCalendar()
      return 
    }

    const momentDate = moment(this.selectedCalendarDate, 'MMM / DD / YYYY')
    const momentDateOffset = momentDate.startOf('day') // timezone에 영향을 받지 않기 위해

    if (this.currentMonth.getMonth() < momentDateOffset.month()) return await this.moveToNextMonth()
    if (this.currentMonth.getMonth() > momentDateOffset.month()) return await this.moveToPreviousMonth()

    this.isLoading = true
    await this.eventViewerService.fetchEventCounts(this.currentMonth)
    this.generateCalendar()
    return
  }

}
