import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { c_card_heat_map_component } from './c_card_heat_map.component';
import { SharedModule } from '../../../../../app/directives/shared.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [c_card_heat_map_component],
  exports: [c_card_heat_map_component],
})
export class c_card_heat_map_module { }
