import { trigger, state, style, transition, animate, AnimationTriggerMetadata} from '@angular/animations';

export const showPage: AnimationTriggerMetadata =
  trigger('page', [
    state('*',
      style({
        opacity: 1,
        transform: 'translateX(0)',
      })
    ),
    transition(':leave', [
      animate('0.1s 0.1s', style({
        opacity: 0,
        // transform: 'translateX(-100%)'
      }),)
    ]),
    transition(':enter', [
      style({ 
        opacity: 0, 
        display: 'none',
        position: 'absolute', 
        top:0, 
        left: 0, 
        width: '100%',
        // transform: 'translateX(50%)'
      }),
      animate('0.1s 100ms', style({
        position: 'absolute',
        opacity: 1,
        transform: 'translateX(0)'
      }))
    ])
  ]);

  export const animeDialog: AnimationTriggerMetadata =
  trigger('dialog', [
    state('*',
      style({
        transform: 'translateX(0)'
      })
    ),
    transition(':enter', [
      style({
        transform: 'translateY(+50%)',
        opacity: 0.5
      }),
      animate('0.2s ease-in')
    ]),
    transition(':leave', [
      animate('0.2s ease-out', style({
        transform: 'translateY(+50%)',
        opacity: 0.5
      }))
    ])
  ]);

export const animeBackground: AnimationTriggerMetadata =
  trigger('background', [
    state('*',
      style({
        opacity: 1
      })
    ),
    transition(':enter', [
      style({
        opacity: 0
      }),
      animate('0.1s ease-in')
    ]),
    transition(':leave', [
      animate('0.1s ease-out', style({
        opacity: 0
      }))
    ])
  ]);

export const animeSubmodal: AnimationTriggerMetadata =
  trigger('subModal', [
    transition(':enter', [
      style({
        transform: 'translateX(+100%)',
        // opacity: 0.5
      }),
      animate('0.2s ease-in')
    ]),
    transition(':leave', [
      animate('0.2s ease-in', style({
        transform: 'translateX(-100%)',
        // opacity: 0.5
      }))
    ])
  ]);

export const animeImgShow: AnimationTriggerMetadata =
  trigger('imgShow', [
    state('*',
      style({
        opacity: 1
      })
    ),
    transition(':enter', [
      style({
        opacity: 0
      }),
      animate('0.2s ease-in')
    ]),
    transition(':leave', [
      animate('0.2s ease-out', style({
        opacity: 0
      }))
    ])
  ]);

