import { ErrorHandler, Injectable,Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
// import { LogsService } from './logs.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error) {
    // const logService = this.injector.get(LogsService);
    const message = error.message ? error.message : error.toString();
    const stack = error.stack ? error.stack : error.toString();
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    // log on the server
    // console.log(message);
    // console.log(stack);
    // console.log(url);

    // logService.err(message, { stack: stack, url: url });
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }
  onNewAppDetected(error: any): void{
    // Loading chunk 5657 failed. (missing: https://dealer.chekt.com/5657.6f2a2b8405392dc34b2b.js) 
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const chunkFileName = this.extractChunkFileName(error.message)
    
    if (chunkFailedMessage.test(error.message)) {
      let data = sessionStorage.getItem(chunkFileName);
      if(data) return
      
      sessionStorage.setItem(chunkFileName, "updated");
      window.location.reload();
    }
  }
  extractChunkFileName(text) {
    const regex = /https:\/\/[^\/]+\/(\d+)\.(.+)\.js/g;
    const matches = regex.exec(text);
    if (matches && matches.length >= 3) {
      const fileName = `${matches[1]}.${matches[2]}.js`;
      return fileName;
    }
    return '';
  }
}