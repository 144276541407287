import { Injectable } from '@angular/core';

import { c_dialog } from './dialog/c_dialog';

@Injectable()
export class c_components {
  constructor(
    public dialog: c_dialog,
  ) {}
}
