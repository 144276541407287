import { Component, OnInit, Input, Output, NgZone, EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from '../animations';

import { Modal } from '../../model/modal';
import { UsersService } from '../../services/users.service';
import { CommonService } from '../../services/common.service';
import { TosService } from '../../services/tos.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss', '../common.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})
export class NoticeComponent implements OnInit {
  @ViewChild('container') private myScrollContainer: ElementRef;
  @Input() modal: Modal = new Modal();
  @Input() tos: any;
  @Output() event: EventEmitter<any> = new EventEmitter();

  me: any;
  isLoading = false;

  isScrollEnd = false;
  isEnable = false;

  title = '';
  labelPosition = 'after';
  isBlockedClickBackground = false

  constructor(
    private usersService: UsersService,
    private commonService: CommonService,
    private tosService: TosService,
  ) { }

  ngOnInit() {
    const url = window.location.href;
    this.isLoading = false;
    const params = this.commonService.getQueryParams(url.split('?')[1]);
    let dealer_id = null;
    if (params['dealer_id']) {
      dealer_id = params['dealer_id'];
    }

    this.usersService.getMe(dealer_id).subscribe(res => {
      this.me = res;

      window.location.pathname.includes('cloud-ai')
        ? this.isBlockedClickBackground = false
        : this.isBlockedClickBackground = true
    });
  }

  ngOnChanges(changes: any) {
    if (changes['tos'] && this.tos) {
      const doc = document.querySelector('.markdown')
      doc.innerHTML = `
        <textarea readonly style="width: 665px; height: 460px; border: 2px solid transparent; resize: none; font-size: 14px; line-height: 20px;">
        ${this.tos['text']}
        </textarea>
      `
      this.title = this.modal.data['title'];
      this.modal.is_active = true;
    }
  }

  onScroll(event){
    let element = this.myScrollContainer.nativeElement;
    let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    if ((element.scrollHeight - element.scrollTop) -element.clientHeight < 0.5) {
      atBottom = true;
      this.isEnable = true;
    }
    if (atBottom) {
        this.isScrollEnd = true;
    } else {
        this.isScrollEnd = false;
    }
  }

  closeModal(result) {
    this.event.emit(result);
    this.isLoading = false;
    this.modal.is_active = false;
    this.modal.close();
  }

  confirm() {
    this.isLoading = true;
    const data = this.isBlockedClickBackground ? { type : 0 } : { type : 2 }
    this.tosService.accept(this.me.dealer_id, this.tos.id, data).subscribe(res => {
      this.usersService.me.is_accepted_tos = 1;
      setTimeout(() => {
        this.isLoading = false;
        this.closeModal(true);
      }, 1500);
    }, err => {
      this.isLoading = false;
      this.closeModal(false);
    })
  }
}
