import { Component, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'c_card_heat_map_component',
  templateUrl: './c_card_heat_map.component.pug',
  styleUrls: ['../../common.scss','./c_card_heat_map.component.scss']
})
export class c_card_heat_map_component {
  @Input() siteTimezone;
  @Input() logs;
  @Output() onSelectDate: EventEmitter<any> = new EventEmitter();

  selectedHour = null;
  currentMonth: Date;
  days: Date[];
  hours: any[];

  constructor() {
  }

  ngOnInit(): void {
    const localTime: Date = this.makeLocalTimeCurrentTime()
    this.currentMonth = localTime;
    this.hours = Array.from({ length: 24 }, (_, i) => this.mapFunction(i)); // 0부터 23까지의 시간
    this.generateDays();
  }

  makeLocalTimeCurrentTime(): Date{
    // Moment-timezone 객체 생성
    const momentTimezone = moment.tz(this.siteTimezone);

    // UTC로 변환
    const utcTime = momentTimezone.utc();

    // Date 객체는 UTC 시간을 나타내지만, 설정한 타임존의 해당 시간임.
    let dateObject = new Date(utcTime.format());
    return dateObject
  }

  mapFunction(x) {
    return { hour: x, isSelected: false}
 }

  generateDays(): void {
    // 현재 월의 모든 날짜 생성
    const startOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1);
    const endOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0);
    const days = [];

    for (let day = new Date(startOfMonth); day <= endOfMonth; day.setDate(day.getDate() + 1)) {
      days.push(new Date(day));
    }

    this.days = days;
  }

  // ---------------------------------

  moveToPreviousMonth(): void {
    this.currentMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() - 1, 1);
    this.generateDays();
  }

  moveToNextMonth(): void {
    this.currentMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 1);
    this.generateDays();
  }

  // ---------------------------------
  // CELL EVENT
  selectHour(date: Date, hour: number){
    this.selectedHour = { date, hour }
    
    const momentData = moment(date).tz(this.siteTimezone)
    const formatDate = momentData.format('MMM / DD / YYYY')
    this.onSelectDate.emit({ date: formatDate, hour })
  }

  hoverHour(event, dayIdx: number, i: number){
    let regex = /event-[^ ]*/gi; // 'event-' 뒤의 문자열까지 포함하여 교체
    event.target.className = event.target.className.replace(regex, '')
    this.setTooltipPosition(dayIdx, i)
  }
  hoverOutHour(event, day: Date, hour: number){
    let className = this.computedEventLevel(day, hour);
    if (event.target.classList.contains(className)) return
    event.target.className += ` ${className}`;
  }

  // ---------------------------------
  // STYLE
  setTooltipPosition(dayIdx, i) {
    let tooltipBox = document.getElementById('cell-' + dayIdx + i + '-box');
    let tooltip = document.getElementById('cell-' + dayIdx + i);
    let rect = tooltipBox.getBoundingClientRect();
    let tooltipRect = tooltip.getBoundingClientRect();

    const posX = rect.left + rect.width - tooltipRect.width/2;
    const posY = rect.top - tooltipRect.height - 10;
    tooltip.style.left = posX + 'px';
    tooltip.style.top = posY + 'px'
  }

  tooltipCellDate(day: Date, i: number){
    let date = moment(day).format('MMM / DD / YYYY')
    let time = `${i} - ${i + 1}`
    return { date, time }
  }

  computedEventLevel(day: Date, hour: number): string {
    // 이것은 임시로 만드는 것입니다.
    // none, event-low, event-middle, event-high
    // 짝수 일 중에서 시간이
    // 1, 14, 21 은 low
    // 3, 18, 24 은 middle
    // 9, 13, 20 은 high
    let className = ''
    const date = day.getDate()
    className = this.isSelectedHour(day, hour)
    if (className) return className

    if( date % 2 === 0) {
      if(hour === 1 || hour === 14 || hour === 21) className = 'event-low'
      if(hour === 3 || hour === 18 || hour === 24) className = 'event-middle'
      if(hour === 9 || hour === 13 || hour === 20) className = 'event-high'
    } else {
      className = ''
    }

    return className
  }

  isSelectedHour(day: Date, hour: number): string{
    if(!this.selectedHour) return ''
    if(this.selectedHour.date === day && this.selectedHour.hour === hour) return 'selected'
  }

  isOtherYear(){
    const localCurrentTime: Date = this.makeLocalTimeCurrentTime()
    return localCurrentTime.getFullYear() != this.currentMonth.getFullYear()
  }
}
