import { Component, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'c_side_modal_approval_process_component',
  templateUrl: './c_side_modal_approval_process.component.pug',
  styleUrls: ['../../common.scss','../../c_side_direction_modal.component.scss'],
})
export class c_side_modal_approval_process_component {

  constructor() { }

}
