import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { Helper } from "../../4services/2helper";

console.debug("✅ 7subscribers.state_changes loaded");

@Component({
  selector: "c_subscriber_state_changes_component",
  template: "",
})
export class c_subscriber_state_changes_component {
  // ------------------------------------------------------------------------

  constructor(private toaster: ToastrService, private helper: Helper) {}

  ngOnInit() {
    this.watch();
  }
  ngOnDestroy() {
    this.unwatch();
  }

  // ------------------------------------------------------------------------

  private members$w: Subscription;
  private roles$w: Subscription;
  watch() {
    // this.members$w = this.helper.dealer_member.members$w().subscribe((v) => this.on_members_change(v));
    // this.roles$w = this.helper.dealer_role.roles$w().subscribe((v) => this.on_roles_change(v));
  }
  unwatch() {
    // this.members$w.unsubscribe();
    // this.roles$w.unsubscribe();
  }

  // ------------------------------------------------------------------------

  private on_members_change(value: any) {
    if (!value) return;
    const title = "Members loaded"
    const message = `Dealer members(${value.length} items) data have been loaded.`
    this.toaster.info(message, title, { closeButton: true, timeOut: 500});
  }

  private on_roles_change(value: any) {
    if (!value) return;
    const title = "Roles loaded"
    const message = `Dealer roles(${value.length} items) data have been loaded.`
    this.toaster.info(message, title, { closeButton: true, timeOut: 500});
  }
}
