import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Helper } from "../../../../4services/2helper";
import { CommonService } from '../../../../app/services/common.service'
import { UsersService } from '../../../../app/services/users.service'

import ims from '../../imports'

@Component({
  templateUrl: './c_dialog_mp_settings_bulk_update_confirm_component.pug',
  styleUrls: ['../common.scss', './c_dialog_mp_settings_bulk_update_confirm_component.scss'],
})
export class c_dialog_mp_settings_bulk_update_confirm_component {
  doubleCheckText:string = ''
  me: any;

  constructor(
    public dialogRef: MatDialogRef<c_dialog_mp_settings_bulk_update_confirm_component>,
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private helper: Helper,
    private commonService: CommonService,
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.usersService.getMe().subscribe(res => {
      this.me = res;
    });
  }

  objectEntries(obj: any) {
    return Object.entries(obj);
  }

  async onClickApply(){
    try {
      for (const key in this.data.dealerMpSettings) {
        if (this.data.dealerMpSettings.hasOwnProperty(key)) {
          this.data.dealerMpSettings[key] = this.data.dealerMpSettings[key] ? 1 : 0
        }
      }
      this.data.dealerMpSettings.sites = null
      let data = this.data.dealerMpSettings

      await this.updateBulkDealerMpSettings(data)
      this.commonService.showSuccessToast('Success', 'Update All Site Montoring Settings')
      await ims.tool.sleep(100)
      this.close_dialog(true)
    } catch(err){
      console.debug(err)
      this.commonService.showErrorToast('Failed', 'Update All Site Montoring Settings')
    }
  }

  async updateBulkDealerMpSettings(data) {
    await this.helper.mp_settings.update_dealer_mp_settings_sync(data)
  }

  // ------------------------------------------------------------------------
  disabledSaveButton(){
    if(!this.doubleCheckText) return true;
    if(this.doubleCheckText != this.me?.company_name) return true;
    return false
  }

  public close_dialog(result: boolean = false): void {
    this.dialogRef.close(result);
  }
}
