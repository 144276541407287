import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class devices {
  constructor(private ims: Imports) {}

  public init_devices(): void {
    this.ims.devicesService.devices$s.next(null);
    return
  }

  public devices_loaded(): Array<any> {
    return this.ims.devicesService.devices;
  }

  public devices$w(): Observable<any> {
    return this.ims.devicesService.devices$w;
  }

  public async get_devices(filter, isForce= false): Promise<Array<any>> | undefined {
    await this.load_devices(filter, isForce);
    return this.ims.devicesService.devices$s.getValue();
  }

  public async get_device(site_id: number, device_id: string): Promise<Array<any>> | undefined {
    await this.load_device(site_id, device_id);
    return this.ims.devicesService.device$s.getValue();
  }

  public async load_devices(filter?,isForce= false): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.devicesService.getDevices(dealer_id, filter, isForce).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Devices" });
      return false;
    }
  }

  public async load_device(site_id: number, device_id: string): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.devicesService.getDevice(dealer_id, site_id, device_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Device" });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
