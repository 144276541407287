import { BrowserModule } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule  } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import * as Sentry from "@sentry/angular-ivy";

import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';

import { ApiService } from './services/api.service';
import { UsersService } from './services/users.service';
import { CommonService } from './services/common.service';
import { DealerService } from './services/dealer.service';
import { RolesService } from './services/roles.service';
import { AccessesService } from './services/accesses.service';
import { SitesService } from './services/sites.service';
import { DevicesService } from './services/devices.service';
import { GlobalErrorHandler } from './services/errorHandler.service';
import { HttpErrorInterceptor } from './services/httpIntercepter.service';
import { PagerService } from './services/pager.service';
import { TosService } from './services/tos.service';
import { ToastService } from './services/toast.service';
import { LogsService } from './services/logs.service';
import { InvoicesService } from './services/invoices.service';
import { WebsocketService } from './services/websocket.service';
import { IntegrationService } from './services/integration.service';
import { PermissionsService } from './services/permissions.service';
import { WebworkerService } from './services/webworker.service';
import { PaymentService } from './services/payment.service';
import { MastermindsService } from './services/masterminds.service';
import { PartnerService } from './services/partner.service';
import { EventsService } from './services/events.service';
import { AudioService } from './services/audio.service';
import { NotificationsService } from './services/notifications.service';
import { ThirdPartyService } from './services/thirdparty.service';
import { ScriptService } from './services/script.service';
import { StatisticsService } from './services/statistics.service';
import { DealerPlanService } from './services/service-plan.service';
import { VerificationService } from './services/verification.service';
import { VideoAIService } from './services/cloud-ai.service';
import { DivisionService } from './services/divisions.service'
import { VirtualGuardTourService } from './services/virtual-guard-tour.service';
import { EventStoragesService } from './services/event-storages.service';
import { EventViewerService } from './services/event-viewer.service';
import { IncidentReportsService } from './services/incident-reports.service';
import { NoteDirectionsService } from './services/note-directions.service';
import { MpSettingsService } from './services/mp_settings.service';

import { c_components_module } from '../3ui/2components/index.module';
import { HelperModule } from '../4services/2helper/index.module';
import { subscribers_module } from '../7subscribers/subscribers_module';
import { ProfileModule } from './modals/profile/profile.module';
import { DealerPlanModalModule } from './modals/dealer-plan/dealer-plan-modal.module';
import { DealerPlanForStandardModule } from './modals/dealer-plan-for-standard/dealer-plan-for-standard-modal.module';
import { ToastModule } from './modals/toast/toast.module';
import { NoticeModule } from './modals/notice/notice.module';
import { MarkdownModule } from 'ngx-markdown';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MqttModule, IMqttServiceOptions } from "ngx-mqtt";
import { SharedModule } from './directives/shared.module';
import { ToastrModule } from 'ngx-toastr';

import config from "../1config"
let client_id = "operation-client-" + Math.random().toString(16).substr(2, 8);

import { SortService } from './services/sort.service';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  protocol:'wss',
  hostname: config.command_center_url,
  port: 443,
  path: '/mqtt',
  clientId: client_id,
  username: 'app',
  keepalive: 50
}

@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    SideNavComponent,
  ],
  imports: [
    c_components_module,
    HelperModule,
    subscribers_module,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRouting,
    ScrollingModule,
    ProfileModule,
    DealerPlanModalModule,
    DealerPlanForStandardModule,
    ToastModule,
    NoticeModule,
    MarkdownModule,
    MarkdownModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    NgxDaterangepickerMd.forRoot(),
    SharedModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      closeButton: true,
      progressBar: true,
    })
  ],
  providers: [
    ApiService,
    UsersService,
    AccessesService,
    TosService,
    LogsService,
    InvoicesService,
    WebsocketService,
    CommonService,
    DealerService,
    PagerService,
    SitesService,
    DevicesService,
    RolesService,
    ToastService,
    IntegrationService,
    PermissionsService,
    WebworkerService,
    MastermindsService,
    PaymentService,
    PartnerService,
    EventsService,
    AudioService,
    NotificationsService,
    SortService,
    ThirdPartyService,
    StatisticsService,
    ScriptService,
    DealerPlanService,
    VerificationService,
    VideoAIService,
    DivisionService,
    VirtualGuardTourService,
    EventStoragesService,
    EventViewerService,
    IncidentReportsService,
    NoteDirectionsService,
    MpSettingsService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(trace: Sentry.TraceService) {}
}
