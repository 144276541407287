<div class="disable-full-screen" [style.opacity]="modal.is_active ? 100 : 0" [style.visibility]="modal.is_active ? 'visible' : 'hidden'">
  <div class="background" (click)="cancel()"></div>
  <div class="container-center">
    <div class="modal" style="width: initial;min-width: 300px;" [style.max-width]="width+'px'">
      <div style="display:flex;height: 50px;justify-content: space-between;background-color: #F7FAFC;border-radius: 5px;">
        <div></div>
        <div style="position:relative">
          <div class="title-close">
            <i class="material-icons" (click)="cancel()">close</i>
          </div>
        </div>
        
      </div>
      <div class="body">
        <div class="icon" *ngIf="modal?.data.icon != 'no-icon'"><i class="material-icons" [style.color]="modal.data.color ? modal.data.color : 'orange'">{{modal.data.icon ? modal.data.icon : 'warning'}}</i></div>
        <div class="title" [innerHTML]="modal.data.header"></div>
        <div *ngIf="modal.data['snapshot']">
          <div style="margin-top: 24px;">
            <img [src]="'data:image/jpg;base64,' + modal.data['snapshot']" style="max-width: 100%;"/>
          </div>
        </div>
        <div class="desc target-msg" [innerHTML]="modal.data.target" *ngIf="modal.data.target" 
        [class.align-left]="modal.data.align==='left'" [class.align-right]="modal.data.align==='right'">
        </div>
        <div class="desc" [innerHTML]="modal.data.contents" *ngIf="modal.data.contents" 
        [class.align-left]="modal.data.align==='left'" [class.align-right]="modal.data.align==='right'">
        </div>
        <div class="desc redText" [innerHTML]="modal.data.warningMessage" *ngIf="modal.data.warningMessage" 
        [class.align-left]="modal.data.align==='left'" [class.align-right]="modal.data.align==='right'">
        </div>
        <div *ngIf="modal.data['isInput']">
          <div style="margin-top: 0px;"></div>
          <div class="text-area">{{modal.data['isInput']}} ({{modal.data['confirmInput']}})</div>
          <input autocomplete="off" type="text" class="input" [(ngModel)]="inputText"/>
          <div style="height: 14px;margin-top:4px;margin-bottom:30px;">
              <div class="err" *ngIf="isErr">text do not match</div>
          </div>
        </div>
        <div class="no-desc" *ngIf="!modal.data.contents"></div>
      </div>
      <div class="tail-btn-area" [class.between]="modal.data['betweenBtn']">
        <div class="btn" *ngIf="!modal.data.btns" [ngClass]="modal.data.submit_class" [class.disabled]="modal.data.isDisabled" 
        style="margin: 0px 0px 0px 7px;"
        (click)="submit(null, modal.data.isOpen)">
          <div class="btn-inner">{{modal.data.submit_btn}}</div>
        </div>
        <!-- <div class="full-btn primary" *ngIf="!modal.data.btns" [ngClass]="modal.data.submit_class" (click)="submit()"></div> --> 
        <div class="flex">
          <div class="btn" *ngFor="let btn of rightBtns"
          [class.noneView]="!modal.data.btns" [class.disabled]="btn.isDisabled" [ngClass]="btn.btnColor ? btn.btnColor : 'btn-primary'" 
          (click)="submit(btn.submit, btn.doOpen)">
            <div class="btn-inner">{{btn.text}}</div>
          </div>
        </div>
        <div class="flex">
          <div class="btn" *ngFor="let btn of leftBtns"
          [class.noneView]="!modal.data.btns" [class.disabled]="btn.isDisabled" [ngClass]="btn.btnColor ? btn.btnColor : 'btn-primary'" 
          (click)="submit(btn.submit, btn.doOpen)">
            <div class="btn-inner">{{btn.text}}</div>
          </div>
        </div>
        
        <!-- <div class="full-btn primary" *ngFor="let btn of modal.data.btns"
        [class.noneView]="!modal.data.btns" [class.disabled]="btn.isDisabled" [ngClass]="modal.data.submit_class" 
        (click)="submit(btn.submit)" style="margin-right:4px;">{{btn.text}}</div> -->
        <div class="btn-space" *ngIf="!modal.data.btns"></div>
        <div class="btn button-white" (click)="cancel()" *ngIf="!modal.data.isConfirm">
          <div class="btn-inner">{{modal.data['cancel_btn']?modal.data['cancel_btn']:'Cancel'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>