import { NgModule } from "@angular/core";

import { c_dtable_permission_groups_module } from "./permission_groups/c_dtable_permission_groups_module";
import { c_dtable_division_list_module } from "./divisions/division_list/c_dtable_division_list_module"
import { c_dtable_division_detail_module } from "./divisions/division_detail/c_dtable_division_detail_module"
import { c_dtable_cloud_ai_stat_device_module } from "./analtics/video-ai-stat/device/c_dtable_cloud_ai_stat_device.module"
import { c_dtable_cloud_ai_stat_site_module } from "./analtics/video-ai-stat/site/c_dtable_cloud_ai_stat_site.module"
import { c_dtable_cloud_ai_stat_dealer_module } from "./analtics/video-ai-stat/dealer/c_dtable_cloud_ai_stat_dealer.module"
import { c_dtable_site_list_module } from "./analtics/site-note-manager/site-list/c_dtable_site_list.module"; 
import { c_dtable_service_note_module } from "./analtics/site-note-manager/service-note/c_dtable_service_note.module";
import { c_dtable_monitoring_portal_direction_module } from "./analtics/site-note-manager/monitoring-portal-direction/c_dtable_monitoring_portal_direction.module";
import { c_dtable_cloud_vms_manager_for_device_module } from "./cloud_vms_manager/device-table/c_dtable_cloud_vms_manager_for_device.module";
import { c_dtable_cloud_vms_manager_for_site_module } from "./cloud_vms_manager/site-table/c_dtable_cloud_vms_manager_for_site.module";

@NgModule({
  exports: [
    c_dtable_permission_groups_module,
    c_dtable_division_list_module,
    c_dtable_division_detail_module,
    c_dtable_cloud_ai_stat_device_module,
    c_dtable_cloud_ai_stat_site_module,
    c_dtable_cloud_ai_stat_dealer_module,
    c_dtable_site_list_module,
    c_dtable_service_note_module,
    c_dtable_monitoring_portal_direction_module,
    c_dtable_cloud_vms_manager_for_device_module,
    c_dtable_cloud_vms_manager_for_site_module
  ],
})
export class c_dtable_module {}
