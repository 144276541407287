import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { c_dialog_mp_direction_procedure_component } from './c_dialog_mp_direction_procedure_component';
import { WarningModule } from '../../../../app/modals/warning/warning.module';
import { SharedModule } from '../../../../app/directives/shared.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WarningModule,
    SharedModule,
    NgxIntlTelInputModule,
    CodemirrorModule,
    ReactiveFormsModule
  ],
  declarations: [c_dialog_mp_direction_procedure_component],
})
export class c_dialog_mp_direction_procedure_module { }
