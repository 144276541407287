import { 
  Directive, 
  Input, 
  ViewContainerRef, 
  ComponentFactoryResolver, 
  OnInit,
} from '@angular/core';

import { log_box_alarm_video_component } from './log-box/alarm-video/alarm_video.component'
import { log_box_arming_component } from './log-box/arming/arming.component'
import { log_box_audio_component } from './log-box/audio/audio.component'
import { log_box_default_template_component } from './log-box/default_template/default_template.component'
import { log_box_playback_component } from './log-box/playback/playback.component'
import { log_box_relay_component } from './log-box/relay/relay.component'
import { log_box_timelapse_component } from './log-box/timelapse/timelapse.component'
import { log_box_verification_component } from './log-box/verification/verification.component'
// import { log_box_trouble_component } from './log-box/trouble/trouble.component'

const componentMapping = {
  'video event': log_box_alarm_video_component,
  'arming': log_box_arming_component,
  'audio': log_box_audio_component,
  'default': log_box_default_template_component,
  'playback event': log_box_playback_component,
  'relay': log_box_relay_component,
  'timelapse event': log_box_timelapse_component,
  'verification': log_box_verification_component, // 로직상 존재는 할 수 있음.
  // 'sensor': log_box_default_template_component,
  // 'trouble': log_box_trouble_component,
};

@Directive({
  selector: '[appLogRenderer]'
})
export class LogRendererDirective implements OnInit {
  @Input() appLogRenderer: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponentForLog(this.appLogRenderer.log));
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance['log'] = this.appLogRenderer.log; 
  }

  getComponentForLog(log){
    let category = this.computedCategory(log)
    const componentList = Object.keys(componentMapping)
    const componentName = componentList.includes(category) ? category : 'default'
    return componentMapping[componentName]
  }

  computedCategory(log){
    if(!log?.category || typeof log.category != 'string') return 'default'
    const category = log.category
    return category.toLowerCase()
  }

}
