import { Component, HostListener, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Subscription } from "rxjs";
import { DealerPlanService } from '../../../../../app/services/service-plan.service'
import { UsersService } from '../../../../../app/services/users.service'
import { VideoAIService } from '../../../../../app/services/cloud-ai.service'
import { CommonService } from '../../../../../app/services/common.service'
import { Helper } from '../../../../../4services/2helper'

const moment = require('moment-timezone');

@Component({
  selector: 'c_card_cloud_ai_stat_component',
  templateUrl: './c_card_cloud_ai_stat.component.pug',
  styleUrls: ['../../common.scss','./c_card_cloud_ai_stat.component.scss']
})
export class c_card_cloud_ai_stat_component {
  @Input() amIStandardPlan;
  @ViewChild('monthSelectDropdown') monthSelectDropdown: ElementRef;
  @Output() selectMonthForTable: EventEmitter<any> = new EventEmitter();
  @Output() setInitSelectedMonth: EventEmitter<any> = new EventEmitter();
  //-----------------------------------------------------------------------------
  isLoading = false;
  isSubDealer = false;
  dealers = []

  // select month
  isUnfoldMonthList = false;
  monthList = [
    { name: '2023-06', value: '2023-06' }
  ]
  selectedMonth = this.monthList[0]


  // cloud AI Events
  // usage
  reservedEvents = {
    freeEvent : null,
    eventCostPerEventBoundary: null,
    eventEventBoundary: null,
    totalUsedEvents: null,
    totalUsedEventsRatio: null
  }
  availableAmount = null
  excessAmount = null


  // true | false alarm
  statisticsData = {
    totalVideoEvents: null,
    trueSignals: null,
    falseSignals: null,
    trueSignalRatio: null,
    falseSignalRatio: null,
  }


  constructor(
    private dealerPlanService: DealerPlanService,
    public usersService: UsersService,
    private videoAIService: VideoAIService,
    private commonService: CommonService, 
    public helper: Helper  
  ) { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if(this.monthSelectDropdown) {
      if (!this.monthSelectDropdown.nativeElement.contains(event.target)) this.isUnfoldMonthList = false;
    }
  }

  private dealer_partners$w: Subscription;
  watch() {
    this.dealer_partners$w = this.helper.partner.partners$w().subscribe((v) => this.on_partners_change(v));
  }
  unwatch() {
    this.dealer_partners$w?.unsubscribe();
  }

  async ngOnInit(){
    this.isLoading = true
    if(this.amIStandardPlan) return this.isLoading = false

    this.init()
    this.watch()
    this.setMonthList()
    await this.load_data()
    this.isLoading = false
  }

  ngOnDestroy(){
    this.unwatch()
  }

  init(){
    this.reservedEvents = {
      freeEvent : '0',
      eventCostPerEventBoundary: '0',
      eventEventBoundary: '0',
      totalUsedEvents: '0',
      totalUsedEventsRatio: '0'
    }
    this.availableAmount = '0'
    this.excessAmount = '0'

    this.statisticsData = {
      totalVideoEvents: '0',
      trueSignals: '0',
      falseSignals: '0',
      trueSignalRatio: '50',
      falseSignalRatio: '50',
    }
  }

  setMonthList(){
    const currentDate = new Date();
    const startDate = new Date(2023, 5); // 2023년 6월
    const monthList = [];

    while (startDate <= currentDate) {
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, '0'); // 월을 2자리 숫자로 포맷
      const formattedDate = `${year}-${month}`;
      const obj = { name: formattedDate, value: formattedDate }
      monthList.unshift(obj);

      // 다음 달로 이동
      startDate.setMonth(startDate.getMonth() + 1);
    }

    this.selectedMonth = monthList[0]
    this.setInitSelectedMonth.emit(this.selectedMonth)
    this.monthList = monthList;
  }

  // month
  calcCurrentMonth(month?){
    const now = month ? new Date(month) : new Date()
    const time = moment(now).utc().startOf('month').format() 
    return time
  }

  async selectMonth(month){
    this.isLoading = true
    this.isUnfoldMonthList = false
    this.selectedMonth = month
    this.selectMonthForTable.emit(month)
    const time = this.calcCurrentMonth(month.value)
    await this.getDealerStatisticByRank(time)
    this.isLoading = false
  }

  // -----------------------------------------------------------------------
  
  async load_data(){
    await this.setDealerInfo()
    await this.load_partners()
    const time = this.calcCurrentMonth(this.selectedMonth.value)
    await this.getDealerStatisticByRank(time)
  }

  async setDealerInfo(){
    const res = await this.helper.dealer.get_dealer_info()
    this.isSubDealer = res[0].type === 3
  }

  async load_partners() {
    const amIMainDealer = await this.helper.me.isMainDealer()
    if(!amIMainDealer) return
    await this.helper.partner.load_partners(true)
  }

  on_partners_change(value){
    if(!Array.isArray(value) || !value?.length) return 

    this.dealers = value;
  }

  //------------------------------------------------------------------------
  // dashboard
  // FOR Main dealer + Sub dealer(모두 처리됨. shared는 제외)
  async getDealerStatisticByRank(time){
    try {
      const dealerId = await this.helper.me.get_my_dealer_id()
      const rank_tag = 'ai_filter_matched'
      const isSubDealer = await this.helper.me.isSubDealer()
      const { data } = await this.videoAIService.getDealerStatisticByRank(dealerId, isSubDealer, time, null, null, "month", rank_tag).toPromise()
      console.debug('getDealerStatisticByRank:>>', data)
      await Promise.all([
        this.calcCloudAIUsage(data),
        this.calcCloudAITrueFalseAlarmChart(data)
      ])
    } catch(err) {
      this.isLoading = false
      console.debug('Get Statistic :>>',err)
      return []
    }
  }

  async calcCloudAIUsage(data){
    if(!data || !(data instanceof Object) || !Object.keys(data)?.length || this.amIStandardPlan) data = { true: 0, false: 0 }
    if(!data?.true) data.true = 0
    if(!data?.false) data.false = 0
    const year = this.selectedMonth.name.split('-')[0]
    const month =  this.selectedMonth.name.split('-')[1]
    try {
      const dealerPlanServiceList = await this.helper.dealer_plan.get_dealer_plan_cloudAI_usage(year, month)
      const videoAIEventsPlan = dealerPlanServiceList?.videoai ?? 0
      
      const allCloudAIEvents = (data?.true + data?.false) ?? 0
      console.log('allCloudAIEvents:>',allCloudAIEvents)
      
      const conditionalPriceData = videoAIEventsPlan?.conditional_price ?? 0
      const freeCloudAIEventCount = conditionalPriceData?.free_package_amount ?? 0
      const calcFreeCloudAIEventsNumber = this.calcFreeCloudAIEventsNumber(freeCloudAIEventCount)
      console.debug(dealerPlanServiceList, videoAIEventsPlan)
      this.reservedEvents = {
        freeEvent: this.formatNumberToK(calcFreeCloudAIEventsNumber),
        eventCostPerEventBoundary: this.formatNumbersWithComma(conditionalPriceData?.fee_per_paid_package_amount )?? 0,
        eventEventBoundary: this.formatNumbersWithComma(conditionalPriceData?.paid_package_amount) ?? 0,
        totalUsedEvents: this.formatNumbersWithComma(allCloudAIEvents) ?? 0, 
        totalUsedEventsRatio: calcFreeCloudAIEventsNumber ? ((allCloudAIEvents / calcFreeCloudAIEventsNumber)* 100).toFixed(1) : 0
      }
      this.setAvailableCloudAIEvent(allCloudAIEvents, calcFreeCloudAIEventsNumber)
      this.setExcessCloudAIEvent(allCloudAIEvents, calcFreeCloudAIEventsNumber)
    } catch(err) {
      this.isLoading = false;
      console.debug(err)
    }
  }

  async calcCloudAITrueFalseAlarmChart(data){
    if(!data || !(data instanceof Object) || !Object.keys(data)?.length || this.amIStandardPlan) return data = { true: 0, false: 0 }
    try {
      const totalVideoEvents = (data?.true + data?.false) ?? 0

      const trueEvents = data.true
      const trueEventsRatio = totalVideoEvents ? ((trueEvents / totalVideoEvents) * 100).toFixed(1) : 0
      const falseEvents = data.false
      const falseEventsRatio = totalVideoEvents ? ((falseEvents / totalVideoEvents) * 100).toFixed(1) : 0

      this.statisticsData = {
        totalVideoEvents: this.formatNumbersWithComma(totalVideoEvents),
        trueSignals: this.formatNumbersWithComma(trueEvents),
        falseSignals: this.formatNumbersWithComma(falseEvents),
        trueSignalRatio: trueEventsRatio,
        falseSignalRatio: falseEventsRatio,
      }

    } catch(err) {
      this.isLoading = false;
      console.debug(err)
      let title = 'Request Dealer Plan Info';
      this.commonService.showErrorToast(err, title);
    }
  }

  // ---------------------------------------------

  calcFreeCloudAIEventsNumber(freeCloudAIEventCount){
    if(!this.helper.me.isMainDealer()) return freeCloudAIEventCount

    // FOR main dealer
    const professionalPartner = this.dealers.filter(v => v.current_month_service_plan_type === 2) 
    const premiumPartner = this.dealers.filter(v => v.current_month_service_plan_type === 3) 

    const professionalSubDealerVideoAIPlan = this.findVideoAIEventsPlan(this.dealerPlanService.subDealerPlanPricingListForProfessional)
    const premiumSubDealerVideoAIPlan = this.findVideoAIEventsPlan(this.dealerPlanService.subDealerPlanPricingListForPremium)

    const mainFreeAmount = freeCloudAIEventCount
    const professionalSubDealerFreeAmount = professionalSubDealerVideoAIPlan?.conditional_price?.free_package_amount
    const premiumSubDealerFreeAmount = premiumSubDealerVideoAIPlan?.conditional_price?.free_package_amount

    const result = 
      mainFreeAmount 
      + (professionalPartner.length * professionalSubDealerFreeAmount) 
      + (premiumPartner.length * premiumSubDealerFreeAmount)
    return result
  }
  findVideoAIEventsPlan(pricingList){
    for(const planId in pricingList) {
      const plan = pricingList[planId]
      if(this.isVideoAIEventsPlan(planId)) return plan
    }
  }
  setAvailableCloudAIEvent(allCloudAIEvents, freeCloudAIEventCount){
    this.availableAmount = 
      freeCloudAIEventCount > allCloudAIEvents
      ? this.formatNumbersWithComma(freeCloudAIEventCount - allCloudAIEvents) 
      : 0
  }
  setExcessCloudAIEvent(allCloudAIEvents, freeCloudAIEventCount){
    this.excessAmount = 
        allCloudAIEvents > freeCloudAIEventCount 
        ? this.formatNumbersWithComma(allCloudAIEvents - freeCloudAIEventCount) 
        : 0
  }

  isVideoAIEventsPlan(planId){
    planId = parseInt(planId)
    return planId === 13 ? true : false
  }

  // ---------------------------------------------------------------

  onClickGoToDealerPlan() {
    this.helper.router.navigate_to_service_plan();
  }

  formatNumbersWithComma(value){
    if(typeof value === 'string' && value.includes(',')) return
    if(value === undefined || value === null) value = 0
    let number = parseFloat(value)
    return number.toLocaleString('en-US')
  }

  formatNumberWithCommaToNumber(value){
    return parseInt(value.replace(/,/g, ""))
  }

  formatNumberToK(input) {
    if (isNaN(input) || input < 1000) {
      return input;
    }
  
    const formattedNumber = (input / 1000).toFixed(1);
    if (formattedNumber.endsWith('.0')) {
      return Math.round(input / 1000) + 'K';
    }
    return formattedNumber.replace(/\.0$/, '') + 'K'; // 소수점 이하 0 제거 후 K 붙이기
  }

  isShowTrueFalseAlarmChart(){
    if(!this.reservedEvents?.totalUsedEvents) return false
    const totalUsedEvents = parseInt(this.reservedEvents?.totalUsedEvents)
    return totalUsedEvents > 0 ? true : false
  }

  comptedUsageChartColor(){
    const target = parseFloat(this.reservedEvents.totalUsedEventsRatio)
    if(target >= 50 && target < 100) return 'warning'
    if(target >= 100) return 'danger'
    return 'primary'
  }
}
