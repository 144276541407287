import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningComponent } from './warning.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [WarningComponent],
  exports: [ WarningComponent ]
})
export class WarningModule { }
