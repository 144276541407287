import { Component, Directive, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from "../../animations";
import { finalize } from 'rxjs/operators';
import { Modal } from '../../../model/modal';

import { UsersService } from '../../../services/users.service';
import { DealerService } from '../../../services/dealer.service';
import { PaymentService } from '../../../services/payment.service';
import { DealerPlanService } from '../../../services/service-plan.service';

import { Helper } from '../../../../4services/2helper';

import moment from "moment-timezone";

@Component({
  selector: 'setting-plan-payment',
  templateUrl: './plan-payment.component.pug',
  styleUrls: ['../../common.scss', './plan-payment.component.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})

export class PlanPaymentComponent implements OnInit {
  @Input() active: boolean;
  @Input() modal: Modal;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  me: any;

  currentPlanType = 1;
  selectedPlanTitle = 'Standard'
  selectedPlan = 1;
  selectedPlanFee;

  m_warning = new Modal();
  m_payment_information = new Modal();
  isValidPaymentInfo = false
  isLoading = false;

  ///////////////
  isNewDealer = false;
  hasStripeCustomerAccount = false;

  cardAccounts = []
  bankAccounts = []
  sepaAccounts = []

  paymentMethod = 'Credit or Debit card';
  paymentInfo: any = {};
  companyName = ''
  firstname = ''
  lastname = ''
  customerEmail = ''

  constructor(
    private usersService: UsersService,
    private dealerService: DealerService,
    private paymentService: PaymentService,
    public dealerPlanService: DealerPlanService,
    private helper: Helper,
  ) {}

  ngOnInit() {
    this.parseSelectedPlan()
    this.usersService.getMe().subscribe(res => {
      this.isLoading = true
      this.me = res;
      const today = moment.utc()
      const created = moment.utc(this.me.created)
      const diff = today.diff(created, 'days')
      diff <= 30 ? this.isNewDealer = true : this.isNewDealer = false
      this.fetchData()
      this.setCustomerData()
    });
  }
  /////////////////////////////

  closeModal(res = null) {
    this.onClose.next(res);
    this.modal.close();
  }

  parseSelectedPlan(){
    this.selectedPlanTitle = this.dealerPlanService.planDescription[this.modal.data].title
    this.selectedPlan = this.modal.data
    this.convertSelectedPlanIdToPlanName(this.selectedPlan)
  }

  convertSelectedPlanIdToPlanName(selectedPlanId){
    switch(selectedPlanId){
      case(1) :
        this.selectedPlanFee = 'per site fee'
        break
      case(2) :
        this.selectedPlanFee = `${this.usersService.me.currency_symbol} ${this.dealerPlanService.dealerPlanPriceProfessional}`
        break
      case(3) :
        this.selectedPlanFee = `${this.usersService.me.currency_symbol} ${this.dealerPlanService.dealerPlanPricePremium}`
        break
      case(4) :
        this.selectedPlanFee = `${this.usersService.me.currency_symbol} ${this.dealerPlanService.mainDealerPlanPriceReseller}`
        break
      default : 
        this.selectedPlanFee = 'per site fee'
        break
    }
  }

  async fetchData() {
    const dealerId = this.me.dealer_id
    const dealerType = this.me.type
    await Promise.all([
      this.getDealerServicePlanPricing(dealerId, dealerType),
      this.getStripe(dealerId),
      this.getSEPAAccount(dealerId)
    ])
    this.getCurrentDealerPlan()
  }
  
  async getDealerServicePlanPricing(dealerId, dealerType){
    await this.dealerPlanService.getDealerServicePlanPricing(dealerId, dealerType).toPromise()
  }
  async getStripe(dealerId){
    try {
      const customers = await this.paymentService.getStripe(dealerId).toPromise()
      customers.length === 0 ? this.hasStripeCustomerAccount = false : this.hasStripeCustomerAccount = true
      this.setStripePayment(customers)
    } catch(err){
      this.hasStripeCustomerAccount = false;
      this.isLoading = false;
    }
  }
  async getSEPAAccount(dealerId){
    try {
      this.sepaAccounts = await this.paymentService.getSEPA(dealerId).toPromise()
    } catch(err){
      this.hasStripeCustomerAccount = false;
      this.isLoading = false;
    }
  }
  setStripePayment(customers){
    if (customers[0]['meta'].deleted) {
      this.isLoading = false
      // 무언가 처리?
      return;
    }

    const paymentMethodList = customers[0].meta.sources.data; // array
    let tmpStripeCards = [];
    let tmpBankAccounts = [];
    tmpStripeCards = paymentMethodList.filter(data => data.object === 'card');
    tmpBankAccounts = paymentMethodList.filter(data => data.object === 'bank_account');

    this.cardAccounts = tmpStripeCards;
    this.bankAccounts = tmpBankAccounts;
  }

  getCurrentDealerPlan(){
    this.dealerPlanService.getDealerPlan(this.me.dealer_id).subscribe(plan => {
      this.currentPlanType = plan?.current_month_service_plan_type ?? 1
    });
  }

  onClickUpgradePlan(){
    this.isLoading = true

    const method = this.paymentMethod
    switch(method){
      case('Credit or Debit card') :
        if(this.cardAccounts.length){
          this.paymentInfo?.type ? this.addCard() : this.upgradePlanByDefaultPayment()
        } else {
          this.addCard()
        }
        break
      case('ACH Bank Accounts') :
        this.bankAccounts.length ? this.upgradePlanByDefaultPayment() : this.addAchAccount()
        break
        case('SEPA') :
        this.sepaAccounts.length ? this.upgradePlanByDefaultPayment() : this.addSEPAAccount()
        break
      case('Others(Check By Mail, etc.)')
        : this.updateDealerPlan(this.selectedPlan)
        break
      default :
        this.paymentInfo?.cardNumber
          ? this.addCard()
          : this.errorDialog('Please close the window and try again', 'Payment method is not set')
        break
    }
  }
  upgradePlanByDefaultPayment(){
    this.setDefaultPayment(this.paymentInfo?.token)
    this.updateDealerPlan(this.selectedPlan)
  }

  setCustomerData() {
    this.dealerService.getDealerInfo(this.me.dealer_id).subscribe(res => {
      this.companyName = res[0].company_name;
      this.firstname = this.me.firstname;
      this.lastname = this.me.lastname;
      this.customerEmail = this.me.email;
      this.isLoading = false;
    }, err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  onSelectPaymentMethod(data){
    const type = data[0]
    const token = data[1]
    if(!token) {
      this.paymentMethod = type
      type.includes('Others') ? this.isValidPaymentInfo = true : this.isValidPaymentInfo = false
      this.paymentInfo = null
      return
    }
    this.paymentMethod = type
    this.isValidPaymentInfo = true
    type.includes('card') ? this.paymentInfo = {token}: this.paymentInfo = {token}
  }
  parseNoCustomerPaymentInfo(e){
    const info = e[0]
    const isValidPaymentInfo = e[1]
    if(!this.paymentInfo) this.paymentInfo = {}
    if(Object.keys(e[0]).length) {
      for(const key in info){
        this.paymentInfo[key] = info[key]
      }
    }
    this.isValidPaymentInfo = isValidPaymentInfo
  }
  parseCustomerPaymentInfo(payment){
    if(!payment) return
    if(!this.paymentInfo) this.paymentInfo = {}
    const token = payment
    this.paymentInfo.token = token
    this.isValidPaymentInfo = true
  }

  ////////////////////////////
  addCard() {
    this.m_warning.data = {
        header: 'Add a card',
        contents: `
          <p>
            Do you want to add this card?<br/>Please confirm the card information.<br/>
          </p>
          <div><b>Card Number</b>: ${this.paymentInfo.cardNumber}</div>
          <div><b>Expired Date</b>: ${this.paymentInfo.exp}</div>
          <div><b>Nickname</b>: ${this.paymentInfo.nickname || 'No nickname'}</div>
        `,
        submit_btn: 'OK',
        submit_class: ['button-primary'],
        icon: 'credit_card',
        color: '#2383e2'
    };
    this.m_warning.data['submit_func'] = () => {
      this.paymentService.getStripe(this.me.dealer_id)
        .subscribe(customers => {
          customers.length === 0 ? this.hasStripeCustomerAccount = false : this.hasStripeCustomerAccount = true;
          this.createCustomer();
        }, err => {
          this.hasStripeCustomerAccount = false;
          this.createCustomer();
        });
    };
    this.m_warning.data['cancel_func'] = () => this.isLoading = false
    this.m_warning.open();
  }

  createCustomer() {
    this.isLoading = true;
    const dealerId = this.me.dealer_id;
    const customerId = this.me.dealer_id;
    const cardNumber = this.paymentInfo.cardNumber.replaceAll(' ', '');
    const exp = this.paymentInfo.exp;
    const expList = exp.split('/');
    const expMonth = parseInt(expList[0]);
    const expYear = parseInt(20 + expList[1]);
    const CVC = parseInt(this.paymentInfo.CVC);
    const nickname = this.paymentInfo.nickname ?? null;

    const country = this.paymentInfo.country?.name ?? "United States";
    const address1 = this.paymentInfo.address1 ?? null;
    const address2 = this.paymentInfo.address2 ?? null;
    const city = this.paymentInfo.city ?? null;
    const state = this.paymentInfo.state ?? null;
    const zipcode = this.paymentInfo.zipcode ?? null;

    const customerEmail = this.customerEmail; // exe
    const metadata = { nickname : nickname};

    const data = {
        customer_id : customerId,
        card_number : cardNumber,
        exp_month : expMonth,
        exp_year : expYear,
        cvc : CVC,
        customer_email : customerEmail,
        customer_name : this.companyName,
        customer_firstname : this.firstname,
        customer_lastname : this.lastname,
        customer_country: country,
        customer_address1 : address1,
        customer_address2 : address2,
        customer_city: city,
        customer_state: state,
        customer_zipcode : zipcode,
        metadata : metadata
    };
    if (!this.hasStripeCustomerAccount) {
      this.paymentService.createStripe(dealerId, data).subscribe(res => {
        this.updateDealerPlan(this.selectedPlan)
        this.setDefaultPayment(res.card_id);
      }, err => {
        this.errorDialog(err, 'Create Customer');
        this.isLoading = false;
      });
    } else {
      this.createCard(customerId, data);
    }
  }

  createCard(customerId, data) {
    this.paymentService.addStripe(customerId, data).subscribe(res => {
      this.setDefaultPayment(res.card_id);
      this.updateDealerPlan(this.selectedPlan)
    }, err => {
      this.errorDialog(err, 'Create Card');
      this.isLoading = false;
    });
  }

  setDefaultPayment(token) {
    const dealerId = this.me.dealer_id;
    const updateData = { payment_method_id : token };
    this.paymentService.updatePaymentByStripe(dealerId, updateData)
    .subscribe(res => {
      console.log('successfully set as the default payment.')
    }, err => {
      this.errorDialog(err, 'Delete Payment');
      this.isLoading = false;
    });
  }

  updateDealerPlan(plan){
    const dealerId = this.me.dealer_id;
    const data = {service_plan_type : plan}
    this.dealerPlanService.updateDealerPlan(dealerId, data)
      .subscribe(res => {
        console.log('successfully set as the plan.')
        this.closeModal(this.selectedPlan)
        this.isLoading = false;
      }, err => {
        this.errorDialog(err, 'Set Dealer Plan');
        this.isLoading = false;
      });
  }

  goToUserPaymentPreferences(){
    this.helper.router.navigate_to('/settings/payment-preferences');
  }

  // bank
  addAchAccount() {
    const dealerId = this.me.dealer_id;
    const title = `Add an ACH debit bank account`;
    const data = {
      country: this.paymentInfo.country,
      currency: this.paymentInfo.currency,
      routing_number: this.paymentInfo.routing_number,
      account_number: this.paymentInfo.account_number,
      account_holder_type: this.paymentInfo.account_holder_type,
      account_holder_name: this.paymentInfo.account_holder_name
    }
    this.isLoading = true;
    this.paymentService.createACH(dealerId, data).pipe(
      finalize(()=>{
        this.updateDealerPlan(this.selectedPlan)
        this.isLoading = false;
      }))
      .subscribe(res => {
        const token = res.bank_account_id;
        this.confirmDialog(title, `Successful`);
        this.setDefaultPayment(token);
      }, err => {
        this.errorDialog(err, title)
      })
  }

  // sepa
  addSEPAAccount(){
    const dealerId = this.me.dealer_id;
    const title = `Add SEPA account`;
    const data = {
      account_holder_name: this.paymentInfo.account_holder_name,
      account_holder_email: this.paymentInfo.account_holder_email,
      iban: this.paymentInfo.iban,
    }
    this.isLoading = true;
    this.paymentService.createSEPA(dealerId, data).pipe(
      finalize(()=>{
        this.updateDealerPlan(this.selectedPlan)
        this.isLoading = false;
      }))
      .subscribe(res => {
        const token = res.payment_method_id;
        this.confirmDialog(title, `Successful`);
        this.setDefaultPayment(token);
      }, err => {
        this.errorDialog(err, title)
      })
  }


  /////////////////////////////
  confirmDialog(header= '', msg= '', icon= 'done',color='green', func = null) {
    this.m_warning.data = {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: 'OK',
      submit_class: ['button-primary'],
      icon : icon,
      isConfirm : true,
      color: color

    };

    this.m_warning.data['submit_func'] = () => func;

    this.m_warning.open();
  }

  errorDialog(err , title) {
    let msg = 'failed.';
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.confirmDialog(title, msg, 'warning', 'orange');
    }, 200);
  }
}

@Directive({
  selector: '[tooltip]'
})
export class optOutTooltipComponent {
  @Output() tooltipReadyEvent: EventEmitter<string> = new EventEmitter();

  ngOnInit(){
    //then finally,
    this.tooltipReadyEvent.emit();
  }
}
