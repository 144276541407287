import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { RouteGuard } from './accessOnlyPaymentPage.guard'

export const routes: Routes = [
  {
    path: '',
    // component: AppComponent,
    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full',
      },
      {
        path: 'dashboard',
        // loadChildren: () => import('@app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        loadChildren: () => import('./pages/dashboard-v2/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'wcs-sub-dealers',
        loadChildren: () => import('./pages/wcs/wcs.module').then(m => m.WcssModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [RouteGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
        canActivate: [RouteGuard]
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      }
    ]
  }
];

export const AppRouting = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
