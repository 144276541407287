import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Permission } from '../permission'
import { dealer_plan } from '../dealer_plan'
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class virtual_guard_tour {
  constructor(private ims: Imports, private dealer_plan: dealer_plan, private permission: Permission) {}

  public virtualGuardTour$w(): Observable<any> {
    return this.ims.virtualGuardTourService.virtualGuardTour$w;
  }

  public async get_virtual_gurad_tour_schedules(site_id): Promise<Array<any>> | undefined {
    await this.load_virtual_gurad_tour_schedules(site_id);
    return this.ims.virtualGuardTourService.virtualGuardTour$s.getValue();
  }
  

  public async create_virtual_gurad_tour_schedules(site_id, data): Promise<boolean> | undefined {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const dealerPlanType = await this.dealer_plan.get_dealer_plan()
      if(dealerPlanType?.current_month_service_plan_type < 3) return

      await this.ims.virtualGuardTourService.createVirtualGuardTourSchedules(dealer_id, site_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Create Virtula guard tour schedule" });
      return false;
    }
  }

  public async update_virtual_gurad_tour_schedules(site_id, data): Promise<boolean> | undefined {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const dealerPlanType = await this.dealer_plan.get_dealer_plan()
      if(dealerPlanType?.current_month_service_plan_type < 3) return

      await this.ims.virtualGuardTourService.updateVirtualGuardTourSchedules(dealer_id, site_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Virtula guard tour schedule" });
      return false;
    }
  }

  public async delete_virtual_gurad_tour_schedules(site_id): Promise<boolean> | undefined {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const dealerPlanType = await this.dealer_plan.get_dealer_plan()
      if(dealerPlanType?.current_month_service_plan_type < 3) return

      await this.ims.virtualGuardTourService.deleteVirtualGuardTourSchedules(dealer_id, site_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Virtula guard tour schedule" });
      return false;
    }
  }

  public async load_virtual_gurad_tour_schedules(site_id): Promise<boolean> {
    try {
      
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const dealerPlanType = await this.dealer_plan.get_dealer_plan()
      if(dealerPlanType?.current_month_service_plan_type < 3) return

      await this.ims.virtualGuardTourService.getVirtualGuardTourSchedules(dealer_id, site_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Virtual guard tour sschedule" });
      return false;
    }
  }


  // --------------------------------
  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
