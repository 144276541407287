import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../../app/directives/shared.module'

import { c_map_component } from './c_map.component'; 

@NgModule({
  imports: [ CommonModule, FormsModule, SharedModule ],
  declarations: [ c_map_component],
  exports: [c_map_component],
})
export class c_map_module { }
