
import {throwError as observableThrowError,  BehaviorSubject ,  Subscription ,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from './api.service';




@Injectable()
export class EventsService {

  devices: any;
  curDeviceArmState = '';

  constructor(private api: ApiService, private sanitizer: DomSanitizer) { }

  makeid() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getSiteDeviceEventRecords(dealerId, siteId, deviceId, eventId): Observable<any> {
    return this.api.get(
      `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/events/${eventId}/records`
    );
  }

  getSiteDeviceEventAnnotation(dealerId, siteId, eventId): Observable<any> {
    return this.api.get(
      `/dealers/${dealerId}/sites/${siteId}/events/${eventId}/annotation`
    );
  }

  getEventSnapshotClassification(dealerId, siteId, eventId, recordId) {
    let apiDetailPath = '/dealers/' + dealerId + '/sites/' + siteId + '/events/' + eventId + '/records/' + recordId + '/classifications'
    return this.api.get(apiDetailPath)
  }

  getEventsReportsInfoSnapshots(dealerId: number, siteId: number, eventIds, recordingNumbers?){
    if(!eventIds.length) return
    let url = `/dealers/${dealerId}/sites/${siteId}/events_recorded_infos?event_ids=${eventIds}`
    if(recordingNumbers) url += `&recording_numbers=${recordingNumbers}`
    return this.api.get(url)
  }

  getSnapshotFromUrl(url): Observable<any> {
    let headers = new Headers();
    let options = { headers: headers, responseType: 'blob'};

    return this.api.getUrl(url, options).pipe(
      catchError(err => observableThrowError(err)));
  }

  getDealerEvents(dealerId, filter) {
    let apiDetailPath = `/dealers/${dealerId}/events?sort=event_id&dir=desc`
    if (filter) {
      apiDetailPath += '&' + filter
    }
    return this.api.get(apiDetailPath)
  }

  createSiteDeviceEventShareLink(dealerId, siteId, deviceId, eventId, timestamp, shareType, setDownloadCount) {
    let apiDetailPath = '/dealers/' + dealerId + '/sites/' + siteId + '/devices/' + deviceId + '/events/' + eventId + '/psaps'
    const data = {
      timestamp: timestamp
    }
    if (shareType) {
      data['share_type'] = shareType;
    }
    if (setDownloadCount) {
      data['set_download_count'] = setDownloadCount;
    }
    return this.api.post(apiDetailPath, data);
  }

  sendPsapEmail(dealerId, siteId, deviceId, eventId, from, to, link, shareType, timestamp) {
    let apiDetailPath = `/dealers/${dealerId}/sites/${siteId}/psapsendemail`
    let data = {
      device_id: deviceId,
      event_id: eventId,
      from: from, // user email
      to: to, // user email
      link: link, // share link
      share_type: shareType,
      timestamp: timestamp
    }
    return this.api.post(apiDetailPath, data)
  }

}
