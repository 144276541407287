import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animeDialog, animeBackground, animeSubmodal, animeImgShow } from "../animations";
import { Modal } from '../../model/modal';

import { UsersService } from '../../services/users.service';
import { DealerService } from '../../services/dealer.service';

@Component({
  selector: 'dealer-plan-for-standard-modal',
  templateUrl: './dealer-plan-for-standard-modal.component.pug',
  styleUrls: ['../common.scss', './dealer-plan-for-standard-modal.component.scss'],
  animations: [animeDialog, animeBackground, animeSubmodal, animeImgShow]
})

export class DealerPlanForStandardModalComponent implements OnInit {
  @Input() modal: Modal;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  me: any;

  isLoading = false;
  isHiding = false;
  clickedFeature = 'cloud-ai'

  isLoadingVideo = true
  cloudAIDescriptionVideoRegion = null
  cloudAIDescriptionVideo = {}

  automationRuleDescriptionVideo = {}
  automationRuleDescriptionVideoRegion = null

  constructor(
    private usersService: UsersService,
    private dealerService: DealerService,
  ) {}

  ngOnInit() {
    this.usersService.getMe().subscribe(res => {
      this.me = res;
      this.clickedFeature = 'cloud-ai'
      this.checkRegionAndSetDescriptionVideo()
      this.fetchVideo()
    });
  }
  /////////////////////////////

  closeModal() {
    this.onClose.next(this.isHiding);
    this.modal.close();
  }

  onClickChangeFeature(feature){
    let page = 0
    if(feature === 'cloud-ai') {
      page = 0
    } else if(feature === 'automation-rule') {
      page = 1
    } else if(feature === 'new-feature') {
      page = 2
    } else if(feature === 'other-feature') {
      page = 3
    }

    this.changeFeaturePictures(page)
    this.clickedFeature = feature
  }

  onClickUpgrade(){
    this.closeModal()
    this.onUpdate.next()
  }

  changeFeaturePictures (page){
    this.isLoadingVideo = true
    const carousalSlide = document.getElementById('carousal-slide')
    const descriptionCarousalContainer = document.getElementById('description-carousal-container')
    const descriptionCarousalSlideList = document.querySelectorAll('.description-carousal-slide-el')
    const descriptionCarousalSlide = document.getElementById('description-carousal-slide')

    carousalSlide.style.transform = `translateX(${-700 * page}px)`;
    carousalSlide.style.transition = `transform 0.4s ease-in-out`;

    const descriptionWidth = descriptionCarousalSlideList[0].clientWidth
    descriptionCarousalSlide.style.transform = `translateX(${-descriptionWidth * page}px)`;
    descriptionCarousalSlide.style.transition = `transform 0.4s ease-in-out`;

    page === 2 ? descriptionCarousalContainer.style.height = '548px' : descriptionCarousalContainer.style.height = '280px'

    this.checkRegionAndSetDescriptionVideo()
    this.stopVideoPlaybackByTab()
  }

  // style
  computedIconStyleClass(feature){
    return feature === this.clickedFeature ? 'material-icons clicked-icon' : 'material-icons icon'
  }

  //////////////////////////
  // video
  videoUploadDone(){
    if(this.clickedFeature != 'cloud-ai' && this.clickedFeature != 'automation-rule') return 

    let id = this.clickedFeature
    let containerElement = document.getElementById(id);
    let iframeTag = containerElement?.querySelector('iframe');

    if(iframeTag && iframeTag?.src) {
      this.isLoadingVideo = false
    }
  }
  fetchVideo(){
    this.fetchDescriptionVideoForAutomationRule()
    this.fetchDescriptionVideoForCloudAI()
  }

  checkRegionAndSetDescriptionVideo(){
    this.dealerService.getDealerInfo(this.me.dealer_id).subscribe(dealer => {
      const region = dealer[0]?.region_id
      switch(region){
        case(4):
          this.cloudAIDescriptionVideoRegion = 'british'
          this.automationRuleDescriptionVideoRegion = 'british'
          break
        case(8):
          this.cloudAIDescriptionVideoRegion = 'french'
          this.automationRuleDescriptionVideoRegion = 'french'
          break
        default:
          this.cloudAIDescriptionVideoRegion = 'english'
          this.automationRuleDescriptionVideoRegion = 'english'
          break
        }
        this.isLoading = false
    })
  }

  fetchDescriptionVideoForCloudAI(){
    this.cloudAIDescriptionVideo = {
      english: 'https://www.youtube.com/embed/T6hsk8ypido?autoplay=1&rel=0&mute=1',
      french: 'https://www.youtube.com/embed/biMtAGHXaYY?autoplay=1&rel=0&mute=1',
      british: 'https://www.youtube.com/embed/Og0DOCd-D-E?autoplay=1&rel=0&mute=1'
    }
  }

  fetchDescriptionVideoForAutomationRule(){
    // for passing 'exposes your application to XSS security risks'
    // ref: https://blog.stories.pe.kr/218
    this.automationRuleDescriptionVideo = {
      english: 'https://www.youtube.com/embed/sDqK_6JXjrQ?autoplay=1&rel=0&mute=1',
      french: 'https://www.youtube.com/embed/ul9zHD_V8os?autoplay=1&rel=0&mute=1',
      british: 'https://www.youtube.com/embed/HyZd5F-pk5o?autoplay=1&rel=0&mute=1'
    }
  }

  stopVideoPlaybackByTab(){
    if(this.clickedFeature === 'cloud-ai') {
      return this.videoStopper('automation-rule')
    } 
    if(this.clickedFeature === 'automation-rule') {
      return this.videoStopper('cloud-ai')
    }
    this.videoStopper('automation-rule')
    this.videoStopper('cloud-ai')
  }

  videoStopper(id) {
    let containerElement = document.getElementById(id);
    let iframeTag = containerElement.querySelector('iframe');
    let videoTag = containerElement.querySelector('video' );
    if (iframeTag) {
        let iframeSrc = iframeTag.src;
        iframeTag.src = iframeSrc; 
    }
    if (videoTag) {
        videoTag.pause();
    }
  }
}