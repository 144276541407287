import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class ThirdPartyService {

  me: any;
  apiKeys$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  apiKeys$w = this.apiKeys$s.asObservable();

  apiKey$s: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  apiKey$w = this.apiKey$s.asObservable();

  constructor(private api: ApiService) { }

  public createAPIKey(dealerId, data):Observable<any> {
    return this.api.post(`/dealers/${dealerId}/apikeys/`, data);
  }

  public getAPIKeys(dealer_id: number): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/apikeys`).pipe(
      map(res => {
        this.apiKeys$s.next(res);
        return res;
      })
    );
  }

  public getAPIKey(dealer_id: number, apiKeyId): Observable<any> {
    return this.api.get(`/dealers/${dealer_id}/apikeys/${apiKeyId}`).pipe(
      map(res => {
        this.apiKey$s.next(res);
        return res;
      })
    );
  }

  public updateAPIKey(dealerId, apiKeyId, data):Observable<any> {
    return this.api.put(`/dealers/${dealerId}/apikeys/${apiKeyId}`, data);
  }

  public deleteAPIKey(dealerId, apiKeyId, data?):Observable<any> {
    return this.api.delete(`/dealers/${dealerId}/apikeys/${apiKeyId}`, data);
  }
}