import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from '../../../../app/directives/shared.module'

import { c_dialog_arming_mode_after_schedule_component } from "./c_dialog_arming_mode_after_schedule_component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
  declarations: [c_dialog_arming_mode_after_schedule_component],
})
export class c_dialog_arming_mode_after_schedule_module {}
