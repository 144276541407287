import cookie from './cookie';
import event from './event';
import delay from './delay';
import firmware from './firmware';
import image from './image';
import jwt from './jwt';

export default {
  ...cookie,
  ...event,
  ...delay,
  ...firmware,
  ...image,
  ...jwt,
};
