import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import { Permission } from '../permission'
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer_member {
  constructor(private ims: Imports, private permission: Permission) {}

  public members$w(): Observable<any> {
    return this.ims.usersService.users$w;
  }

  public async get_members(): Promise<Array<any>> | undefined {
    await this.load_members();
    return this.ims.usersService.users$s.getValue();
  }

  public async getMyIp(): Promise<any> {
    if(!this.ims.usersService.my_ip$s.getValue()) await this.ims.usersService.getMyIp().toPromise();
    return this.ims.usersService.my_ip$s.getValue();
  }

  public async load_members(): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasMemberReadPermission = this.permission.has("dealer_member_read")
      if(!hasMemberReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.usersService.getUsers(dealer_id).toPromise();
      return true;
    } catch (err) {
      if(err.status === 403) return false;
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Members" });
      return false;
    }
  }

  // CREATE
  public async create_member(data: any): Promise<any> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.usersService.createUsers(dealer_id, data).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Dealer Member` });
      return false;
    }
  }

  public async create_ip_member(data: any): Promise<any> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.usersService.createIpUser(dealer_id, data).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Dealer Member` });
      return false;
    }
  }

  public async invite_member(member_id: number): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.usersService.inviteUsers(dealer_id, member_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Dealer Member(${member_id})` });
      return false;
    }
  }

  //

  public async update_member(member_id: number, data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.usersService.update(dealer_id, member_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Dealer Member(${member_id})` });
      return false;
    }
  }

  public async delete_member(member_id: number): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.usersService.deleteUsers(dealer_id, member_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Dealer Member(${member_id})` });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
