import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer_plan {
  constructor(private ims: Imports) {}

  public dealer_plan(){
    return this.ims.dealerPlanService.dealerPlanType$s.getValue()
  }

  isStandardPlan(){
    return this.dealer_plan()?.current_month_service_plan_type === 1
  }
  isProfessionalPlan(){
    return this.dealer_plan()?.current_month_service_plan_type === 2
  }
  isPremiumPlan(){
    return this.dealer_plan()?.current_month_service_plan_type === 3
  }
  isResellerPlan(){
    return this.dealer_plan()?.current_month_service_plan_type === 4
  }

  public dealer_plan_cloudAI_usage(){
    return this.ims.dealerPlanService.dealerPlanCloudAIUsage$s.getValue()
  }

  public dealer_plan$w(): Observable<any> {
    return this.ims.dealerPlanService.dealerPlanType$w;
  }

  public dealer_plan_cloudAI_usage$w(): Observable<any> {
    return this.ims.dealerPlanService.dealerPlanCloudAIUsage$w;
  }

  public async get_dealer_plan(type?): Promise<any> | undefined {
    await this.load_dealer_plan(type);
    return this.ims.dealerPlanService.dealerPlanType$s.getValue();
  }

  public async get_dealer_plan_cloudAI_usage(year, month): Promise<any> | undefined {
    await this.load_dealer_plan_cloudAI_usage(year, month);
    return this.ims.dealerPlanService.dealerPlanCloudAIUsage$s.getValue();
  }

  public async load_dealer_plan(type?): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerPlanService.getDealerPlan(dealer_id, type).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Plan Type for dealer" });
      return false;
    }
  }

  public async load_dealer_plan_cloudAI_usage(year, month): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");
      const dealer_type = await this.get_my_dealer_type();
      if (dealer_type === null) throw new Error("no dealer_type");

      await this.ims.dealerPlanService.getDealerPlanCloudAIUsage(dealer_id, year, month).toPromise();
      await this.ims.dealerPlanService.getDealerServicePlanPricing(dealer_id, dealer_type).toPromise();
      return true;
    } catch (err) {
      console.debug('load_dealer_plan_cloudAI_usage:>',err)
      tool.dispatch_event("api-failure", { err, title: "Get Cloud AI usage" });
      return false;
    }
  }


  public async load_dealer_plan_for_site(site: any): Promise<boolean> {
    try {
      const dealer_id = site.dealer_id
      const dealer_type = site['dealer_type'] === 2 ? null : 'partner'
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerPlanService.getDealerPlan(dealer_id, dealer_type).toPromise();
      return true;
    } catch (err) {
      console.debug('load_dealer_plan_for_site :>',err)
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Plan Type for site" });
      return false;
    }
  }

  public async update_dealer_plan(data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.dealerPlanService.updateDealerPlan(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update Dealer Plan Type` });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private async get_my_dealer_type(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.type;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}
