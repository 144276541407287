import { Directive, ElementRef, Output, EventEmitter, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';

@Directive({
  selector: '[appEventLogObserver]'
})
export class LogObserverDirective implements AfterViewInit, OnDestroy {
  @Output() isVisible: EventEmitter<any> = new EventEmitter();
  @Output() endReached: EventEmitter<string> = new EventEmitter();
  @Output() virtualScroll: EventEmitter<any> = new EventEmitter();

  private observer: IntersectionObserver;
  private observer2: IntersectionObserver;
  private observer3: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.snapshotObserver();
    this.infinityScrollObserver();
    this.virtualScrollObserver()
  }

  ngOnDestroy() {
    this.observer?.disconnect();
    this.observer2?.disconnect();
    this.observer3?.disconnect();
  }

  private snapshotObserver(){
    const observerOptions = {
      root: document.querySelector('.log-widget'),
      threshold: 0.01
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isVisible.emit(entry.target);
        }
      });
    }, observerOptions);

    this.observer.observe(this.el.nativeElement);
  }

  private infinityScrollObserver() {
    const lastElementId = this.getLastElementId();
    if (!lastElementId) return
    
    const targetId = lastElementId.split('-')[1] // ['time', '13']
    const target = document.getElementById(`hour-${targetId}`);

    const observerOptions = {
      root: document.querySelector('.log-widget'),
      // rootMargin: '200px 100px',
      threshold: 0.01
    };

    this.observer2 = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // 여기에 로그 불러오는 API 호출 로직
        this.endReached.emit(targetId)
      }
    }, observerOptions);

    if (target) {
      this.observer2.observe(target);
    }
  }
  private getLastElementId() {
    const items = document.querySelectorAll('.time-list :not(.disabled)');
    const divyArray = Array.from(items);
    const filteredItems = [...divyArray].filter(v => v.id)
    return filteredItems.length > 0 ? filteredItems[filteredItems.length - 1].id : null;
  }

  private virtualScrollObserver() {
    const options = {
      root: document.querySelector('.event-log-container'),
      threshold: 0.01 // Intersection 비율
    };

    this.observer3 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.virtualScroll.emit(entry.target);
        } 
      });
    }, options);

    this.observer3.observe(this.el.nativeElement);
  }
}
