<div class="modal">
  <div class="modal-header">
    <div class="modal-header-txt">{{currentTime}}</div>
    <div style="position:relative">
      <div class="title-close">
        <i class="material-icons" (click)="close(true)">close</i>
      </div>
    </div>
  </div>
    
  <div class="items-area">

    <div *ngIf="areas.length" class="area-filter">
      <mat-slide-toggle [(ngModel)]="showAreaFilter" class="area-filter-toggle">
      </mat-slide-toggle>
      <div class="area-filter-text">
        <div class="area-filter-text-title">Show area filter</div>
        <div class="area-filter-text-desc">If an area filter is set, the score filter is analyzed only within the area defined by the area filter.</div>
      </div>
    </div>
    
    <div>
      <div class="sev-snapshot-area">
        <img [src]="safeSnapshots[curSnapshotIdx] ? safeSnapshots[curSnapshotIdx] : '' " class="sev-snapshot-img-layer"/>

        <div *ngIf="areas.length && showAreaFilter">
          <svg width="100%" height="100%" viewBox="0 0 16 9" preserveAspectRatio="none" *ngFor="let area of areas" class="area">
            <polygon [attr.points]="getPoints(area)" class="area-polygon"></polygon>
          </svg>
        </div>
      </div>
    </div>


    <div class="sev-snapshot-progress-bar">
      <div class="sev-snapshot-progressing" [ngStyle]="{'width': curSnapshotPercentage+'%'}"></div>
    </div>

    <div class="sev-previous-next">
      <div *ngIf="!isFirstEvent" (click)="onClickPreviousBtn()" class="sev-previous sev-previous-next-buttons"><i class="material-icons" style="color: rgb(255 255 255 / 70%);">arrow_back</i></div>
      <div *ngIf="!isLastEvent" (click)="onClickNextBtn()" class="sev-next sev-previous-next-buttons"><i class="material-icons" style="color: rgb(255 255 255 / 70%);">arrow_forward</i></div>
    </div>

  </div>

  <div class="sev-tail-wrapper">
    <div>
      <div class="sev-tail-txt">{{data.label}}: {{currentScore}}</div>
    </div>

    <div class="tail-btn-area">
      <div class="button button-white" [class.disabled]="isLoading"
        (click)="close(true)">
        <div class="btn-inner">
          <span>Close</span>
        </div>
      </div>
    </div>
    
  </div>

  <div class="loader absolute" *ngIf="isLoading">
      <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" />
      </svg>
  </div>
</div>