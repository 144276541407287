import { Component, Input } from '@angular/core';

import { CommonService } from '@app/services/common.service';
import { SitesService } from '../../../../../../app/services/sites.service'

@Component({
  selector: 'log_box_audio',
  templateUrl: './audio.component.pug',
  styleUrls: ['../../../common.scss','../../c_card_event_viewer_time_line.component.scss'],
})
export class log_box_audio_component {
  @Input() log;
  @Input() timezone;

  dealerId: number 
  site: any

  constructor(
    public commonService: CommonService,
    private sitesService: SitesService,
  ) {}


  ngOnInit(): void {
    this.site = this.sitesService.selSite;
  }

}
