import { Component, Output, EventEmitter } from '@angular/core';
import { SitesService } from '@app/services/sites.service';
import { CommonService } from '@app/services/common.service';
import { Helper } from 'src/4services/2helper';
@Component({
  selector: 'c_side_modal_direction_features_component',
  templateUrl: './c_side_modal_direction_features.component.pug',
  styleUrls: ['../../common.scss','../../c_side_modal.component.scss'],
})
export class c_side_modal_direction_features_component {
  @Output() showPosition = new EventEmitter();
  @Output() hidePosition = new EventEmitter();

  site: any;
  currentRoute = null;
  directionList = [];

  constructor(
    private sitesService: SitesService,
    private helper: Helper, 
  ) { }

  async ngOnInit() {
    this.site = this.sitesService.selSite;
    this.directionList = await this.helper.note_directions.get_dealer_monitoring_directions();
  }

  // showPosition(e, type) {
  //   e?.stopPropagation();
  //   const currentUrl = window.location.href
  //   if(!currentUrl.includes('/monitoring-detail')) {
  //     this.goToMPDirectionDetail();
  //   }
  //   this.commonService.setHelpTooltipPosition(type, 'top-center')
  // }

  mouseEnterShowPosition(e, type) {
    e?.stopPropagation();
    this.showPosition.emit(type);
  }

  mouseLeaveHidePosition(e) {
    e?.stopPropagation();
    this.hidePosition.emit()
  }

  goToMPDirectionDetail(){
    // site direction으로 이동
    const siteDirection = this.directionList.filter(direction => !direction.device_id).pop();
    const noteId = siteDirection?.id;
    const targetId = this.site.site_id
    if(noteId) {
      return this.helper.router.navigate_to(`/customers/devices/note/${noteId}/monitoring-detail`, { id : this.site.site_id });
    } else {
      return this.helper.router.navigate_to(`/customers/devices/note/monitoring-detail/add/${targetId}`, { id : this.site.site_id });
    }
  }
}
