import { Component, Input,  ElementRef, ViewChildren, QueryList, HostListener } from "@angular/core";
import { Subscription } from "rxjs";

import { c_components } from "../../..";
import { Helper } from "../../../../../4services/2helper";
import { isString } from "lodash";

@Component({
  selector: "c_dtable_division_list_component",
  templateUrl: "./c_dtable_division_list_component.pug",
  styleUrls: ["../../common.scss", "./c_dtable_division_list_component.scss"],
})
export class c_dtable_division_list_component {
  // ------------------------------------------------------------------------
  @ViewChildren('actionMenus', {read: ElementRef}) actionMenus: QueryList<any>
  @Input() public search_text: string;

  ngOnChanges(changes: any) {
    if (changes.search_text && changes.search_text.currentValue !== changes.search_text.previousValue) {
      this.on_search_text_change();
    }
  }

  isLoading: boolean = false;
  divisions: any[] = [];
  filteredDivisions = [];

  // permission
  hasReadDivisionPermission = false
  hasUpdateDivisionPermission = false
  hasDeleteDivisionPermission = false

  constructor(private helper: Helper, private c_components: c_components) {}

  private divisions$w: Subscription;
  watch() {
    this.divisions$w = this.helper.dealer_division.my_divisions$w().subscribe((v) => this.on_divisions_change(v));
  }
  unwatch() {
    this.divisions$w?.unsubscribe();
  }

  // ------------------------------------------------------------------------

  async ngOnInit() {
    this.watch();
    this.checkPermission();
    await this.load_data();
  }
  ngOnDestroy() {
    this.unwatch();
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.actionMenus) {
      const actionMenus = this.actionMenus.toArray();
      actionMenus.forEach(actionMenu => {
        this.filteredDivisions.forEach(division=> {
          if (('division-'+division?.id) === (actionMenu.nativeElement.id+'')) {
            if (!actionMenu.nativeElement.contains(event.target)) {
              division['isShowMenu'] = false;
            }
          }
        })
      })
    }
  }

  // ------------------------------------------------------------------------

  checkPermission(){
    this.hasReadDivisionPermission = this.helper.permission.has("dealer_division_read")
    this.hasUpdateDivisionPermission = this.helper.permission.has("dealer_division_update")
    this.hasDeleteDivisionPermission = this.helper.permission.has("dealer_division_delete")
  }
  
  on_search_text_change() {
    this.update_filteredDivisions();
  }

  on_divisions_change(value: any) {
    if(!value) return
    this.divisions = value;
    this.update_filteredDivisions();
  }
  
  async load_data() {
    this.isLoading = true
    await this.helper.dealer_division.load_my_divisions();
    this.isLoading = false
  }

  // ------------------------------------------------------------------------

  update_filteredDivisions() {
    if (!isString(this.search_text)) return this.filteredDivisions = this.divisions;

    const search_text = this.search_text.toLowerCase();
    if (search_text.length < 2) return (this.filteredDivisions = this.divisions);

    this.filteredDivisions = this.divisions.filter((role) =>
      [role.name, role.description].join("").toLowerCase().includes(search_text)
    );
  }

  // ------------------------------------------------------------------------

  goToDetail(divisionId){
    this.helper.router.navigate_to(`/settings/divisions/${divisionId}`);
  }

  setDivisionMorePosition(division) {
    const docElem = document.documentElement
    let elem = document.getElementById('division-'+division?.id);
    let more = document.getElementById('division-more-'+division?.id);
    let rect = elem.getBoundingClientRect();
    const posX = docElem.clientWidth - rect.right + 5
    const posY = rect.top - 22;
    more.style.right = posX + 'px';
    more.style.top = posY + 'px';
  }

  toggleMenu(division){
    const targetMenu = division.isShowMenu;
    division.isShowMenu = !targetMenu;
  }

  // ------------------------------------------------------------------------
  editDivision(division){
    this.toggleMenu(division)
    this.c_components.dialog.open('dealer_division', division.id)
  }

  deleteDivision(division){
    this.toggleMenu(division)
    this.c_components.dialog.open("warning", {
      header: `Do you want to delete Division(${division.name})?`,
      contents: `
        <p>There are ${division.site_count} sites and ${division.user_count} user involved.</b></p>
      `,
      submit_btn: "Delete",
      submit_class: ["button-primary"],
      icon: 'warning',
      isConfirm: false,
      color: 'orange',
      submit_func: () => {
        this.delete_division(division)
      },
    });
  }

  async delete_division(division) {
    const title = `Delete a Division`;
    const msg = `${title} ${division.name} - Success.`;
    this.isLoading = true
    try {
      await this.deleteRelatedUsers(division.id)
      await this.helper.dealer_division.delete_division(division.id);
      this.isLoading = false
      this.openConfirmDialog(title, msg);
      this.helper.dealer_division.load_my_divisions();
    } catch (err) {
      this.isLoading = false
      this.openErrorDialog(err, title);
    }
  }

  async deleteRelatedUsers(divisionId){
    await this.helper.dealer_division.load_division_members(divisionId)
    let members
    const members$w = this.helper.dealer_division.divisions_members$w().subscribe(v => members = v)
    const divisionExclusiveUsers = members.filter(member => member?.divisions?.length === 1) ?? []
    const convertAllUser = divisionExclusiveUsers.map(async v => await this.convertToGlobalUser(v?.user_id))
    await Promise.all(convertAllUser);

    members$w.unsubscribe()
  }

  async convertToGlobalUser(userId){
    if(!userId) return
    const data = { is_division_user : 0 }
    await this.helper.dealer_member.update_member(userId, data)
  }

  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green", doAction = false) {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (doAction) {
          this.load_data();
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}
