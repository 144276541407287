import { Component } from "@angular/core";

console.debug("✅ 7subscribers loaded");

@Component({
  selector: "subscribers_component",
  template: `
    <c_subscriber_api_errors_component></c_subscriber_api_errors_component>
    <c_subscriber_state_changes_component></c_subscriber_state_changes_component>
  `,
})
export class subscribers_component {
  constructor() {}
}
