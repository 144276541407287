import { Component, Inject, HostListener, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from '@angular/router'
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import * as _ from 'lodash';
import { c_components } from "../../../index";
import { Helper } from "../../../../../4services/2helper";
import { isString } from "lodash";

import { CommonService } from "../../../../../app/services/common.service";
import { PagerService } from '../../../../../app/services/pager.service';

@Component({
  templateUrl: "./c_dialog_dealer_division_add_users_component.pug",
  styleUrls: ["../../common.scss", "./c_dialog_dealer_division_add_users_component.scss"],
})
export class c_dialog_dealer_division_add_users_component {
  @ViewChild('searcher') searcher: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('securityGroupList') securityGroupList: ElementRef;
  @ViewChild('siteFilterBox') siteFilterBox: ElementRef;
  @ViewChild('permissionSelectDropdown') permissionSelectDropdown: ElementRef;
  // ------------------------------------------------------------------------

  public dealerId: number;
  public divisionId: number;
  public isLoading: boolean = false;
  public alreadyRegisteredDivisionUserIds: any[] = [];

  am_i_division_user = false;
  params: any;
  pager: any = {};
  curPage = 1;
  totalSite = 0;
  pageOffset = 10;
  pageOffsetStart = 1;
  pageOffsetEnd = 1;
  lastPage = 1;


  // filter
  originUsers: any[] = [];
  filteredUsers: any[] = [];
  selectedUsers: any[] = [];

  roles: any[] = [];
  isUnfoldPermissionList = false;
  selectedRole: any

  // paged items
  pagedItems: any[];

  searchText = '';
  isSearching = false;
  isFocusSearch = false;
  isShowFilter = false;
  isSelectListForSecurity = false;
  isExistFilter = false;
  isSecurityGroupFilter = false;
  totalFilter = 1;

  _filterGroup = {
    isSelectListForPermission: false,
    isSelectListForSecurity: false,
    isSecurityGroupFilter: false,
    isExistFilter:  false,
    selSecurityGroups: {}
  }
  filterGroup = _.cloneDeep(this._filterGroup);

  selSecurityGroups:any  = new Object();
  securityGroups = [];

  constructor(
    public commonService: CommonService,
    public pagerService : PagerService,
    private route: ActivatedRoute,

    public dialogRef: MatDialogRef<c_dialog_dealer_division_add_users_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: number,
    private c_components: c_components,
    private helper: Helper
  ) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if(this.searcher) {
      if (!this.searcher?.nativeElement.contains(event.target)) {
        this.isFocusSearch = false;
      }
    }
    if (this.securityGroupList) {
      if (!this.securityGroupList?.nativeElement.contains(event.target)) {
        this.isSelectListForSecurity = false;
      }
    }
    if (this.siteFilterBox) {
      if (!this.siteFilterBox?.nativeElement.contains(event.target)) {
        this.isShowFilter = false;
        this.resetFilter();
      }
    }
    if(this.permissionSelectDropdown){
      if(!this.permissionSelectDropdown?.nativeElement.contains(event.target)){
        this.isUnfoldPermissionList = false
      }
    }
  }

  private members$w: Subscription;
  private roles$w: Subscription;
  private divisions_members$w: Subscription;
  private dealer_access$w: Subscription;
  watch() {
    this.members$w = this.helper.dealer_member.members$w().subscribe((v) => this.on_members_change(v));
    this.roles$w = this.helper.dealer_role.roles$w().subscribe((v) => this.on_roles_change(v));
    this.divisions_members$w = this.helper.dealer_division.divisions_members$w().subscribe((v) => this.on_division_members_change(v));
    this.dealer_access$w = this.helper.dealer_access.accesses$w().subscribe((v) => this.on_dealer_access_change(v));
  }
  unwatch() {
    this.members$w?.unsubscribe();
    this.roles$w?.unsubscribe();
    this.divisions_members$w?.unsubscribe();
    this.dealer_access$w?.unsubscribe();
  }

  async ngOnInit() {
    this.isLoading = true
    this.divisionId = this.data;
    await Promise.all([
      this.get_me(),
      this.load_members(),
      this.load_division_members(),
    ])
    await Promise.all([
      this.load_accesses(),
      this.load_roles(),
    ])
    this.watch();
    this.isLoading = false
  }

  ngOnDestroy() {
    this.unwatch();
  }

  // -----------------------------------------------------------------------
  checkParams(){
    this.route.paramMap.subscribe((params) => {
      this.params = params.get('id')
    })
  }
  
  on_members_change(value: any){
    if(!Array.isArray(value) || !value?.length) return 
    
    const result = this.filter_out_registered_users(value)
    this.originUsers = result;
    this.filteredUsers = result;

    this.doFilterSite();
  }

  on_roles_change(value: any){
    if(!value) return
    console.log(value)
    this.roles = value.filter(v => v.is_division_role)
    this.selectedRole = this.roles[0]
  }

  on_division_members_change(value: any){
    if(!Array.isArray(value) || !value?.length) return 

    this.alreadyRegisteredDivisionUserIds = value?.map(v => v.user_id);
  }

  on_dealer_access_change(value: any){
    if(!value || !value.length) return
    this.securityGroups = value;
    this.selSecurityGroups = value[0];
    this.filterGroup.selSecurityGroups = value[0];
  }

  async get_me(){
    const me = await this.helper.me.get_me()
    this.am_i_division_user = me?.is_division_user ?? false
  }

  async load_members() {
    await this.helper.dealer_member.load_members()
  }

  async load_division_members() {
    await this.helper.dealer_division.load_division_members(this.divisionId)
  }

  async load_accesses() {
    await this.helper.dealer_access.load_accesses()
  }

  async load_roles() {
    await this.helper.dealer_role.load_roles()
  }

  filter_out_registered_users(value){
    if(this.am_i_division_user) {
      return value.filter(v => {
        const notRegisteredThisDivisionUsers = !this.alreadyRegisteredDivisionUserIds.includes(v.user_id)
        const notIpMember = v.provider != 'ip'
        const notGlobalUser = !!v.is_division_user
        return ((notRegisteredThisDivisionUsers && notIpMember) && notGlobalUser) ? true : false
      })
    }

    return value.filter(v => {
      const notRegisteredThisDivisionUsers = !this.alreadyRegisteredDivisionUserIds.includes(v.user_id)
      const notIpMember = v.provider != 'ip'
      return (notRegisteredThisDivisionUsers && notIpMember) ? true : false
    })
  }

  getAccess(access_id: number) {
    let data = this.securityGroups.find(access => {
      return access.access_id == access_id;
    });

    return data?.name ? data.name : 'No Info';
  }

  // ------------------------------------------------------------------------

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  async submit_changes() {
    const userList = this.selectedUsers.map(v => v.user_id)
    if(!userList || !userList.length || userList.length > 100) return

    this.c_components.dialog.open("warning", {
      header: `Add ${userList?.length} users to division?`,
      contents: ``,
      submit_btn: "Add",
      submit_class: ["button-primary"],
      icon: 'warning',
      isConfirm: false,
      color: 'orange',
      submit_func: () => {
        this.add_users_to_division(userList)
      },
    });
  }

  async add_users_to_division(userList){
    this.isLoading = true
    try {
      if(!this.am_i_division_user){
        const convert_all_user = userList.map(async (userId) => await this.convert_to_division_user(userId));
        await Promise.all(convert_all_user);
      }
      await this.helper.dealer_division.add_users_to_division(this.divisionId, userList)
      await this.load_division_members()
      this.commonService.showSuccessToast('Add users to division', 'Success')
      this.isLoading = false;
      this.close_dialog(true)
    } catch(err) {
      console.debug(err)
      this.openErrorDialog(err, 'Failed add users to Division')
    }
  }

  async convert_to_division_user(userId){
    const data = { is_division_user : 1, role_id: this.selectedRole.role_id }
    try {
      await this.helper.dealer_member.update_member(userId, data)
    } catch(err) {
      console.debug(err)
      this.openErrorDialog(err, 'Failed')
    }
  }

  disabledButton(){
    const data = this.selectedUsers.map(v => v.user_id)
    if(!data || !data.length || data.length > 100) return true
    return false
  }

  // ------------------------------------------------------------------------
  setTooltipPosition(target){
    let tooltip = document?.getElementById(target);
    let tooltipRect = tooltip?.getBoundingClientRect();

    const posY = tooltipRect.height + 10;
    tooltip.style.top = posY + 'px';
  }

  checkIfSelectedUsersExceedLimit(){
    const msg = 'You can select up to 100. After that, please register again'
    const header = 'Only 100 at a time can be registered'
    if(this.selectedUsers?.length > 100) this.openConfirmDialog(header, msg, 'warning', 'orange')
  }

  areAllUsersSelected(change){
    change.checked 
      ? this.filteredUsers.forEach(v => { 
        if(this.pagedItems.includes(v)) v.isSelected = true
      })
      : this.filteredUsers.forEach(v => {
        if(this.pagedItems.includes(v)) v.isSelected = false
      })
    this.selectedUsers = this.filteredUsers.filter(v => v.isSelected) ?? []
    this.checkIfSelectedUsersExceedLimit()
  }

  checkIsUsersSelected(){
    this.selectedUsers = this.filteredUsers.filter(v => v.isSelected) ?? []
    this.checkIfSelectedUsersExceedLimit()
  }

  // ------------------------------------------------------------------------
  // FILTER
  toggleShowFilter() {
    this.isShowFilter = !this.isShowFilter;
  }

  changeSecurityGroupFilter(group) {
    const fg = this.filterGroup;
    this.selSecurityGroups = group;
    this.isSelectListForSecurity = false;
    fg.selSecurityGroups = group;
  }

  clearFilter() {
    this.isSecurityGroupFilter = false;
    this.selSecurityGroups = this.securityGroups[0];

    this.filterGroup = _.cloneDeep(this._filterGroup);
    this.filterGroup.isSecurityGroupFilter = false;
    this.filterGroup.selSecurityGroups = this.securityGroups[0];
    this.search();
    this.checkExistFilter();
  }

  doFilter() {
    this.setFilter();
    this.search();
    this.isShowFilter = false;
    this.checkExistFilter();
  }
  
  setFilter() {
    const fg = this.filterGroup;
    console.log(fg);
    this.isSecurityGroupFilter = fg.isSecurityGroupFilter;
    this.selSecurityGroups = fg.selSecurityGroups;
  }

  resetFilter() {
    const fg = this.filterGroup;
    this.isSecurityGroupFilter = fg.isSecurityGroupFilter;
    this.selSecurityGroups = fg.selSecurityGroups;
  }

  checkExistFilter() {
    let res = false;
    let totalFilter = 0;
    if (this.isSecurityGroupFilter) {
      res = true; 
      totalFilter++;
    }
    this.isExistFilter = res;
    this.totalFilter = totalFilter;
  }

  // SEARCH
  setSearchFocus() {
    this.isFocusSearch = true;
  }

  initSearchTxt() {
    this.isFocusSearch = false;
    this.searchText = '';
    this.search();
    if(this.searchInput) this.searchInput?.nativeElement.focus();
  }

  doFilterSite() {
    console.log('doFilterSite');
    // Site Activation Status filter
    if (this.isSecurityGroupFilter) {
      this.filteredUsers = this.filteredUsers.filter(e => this.selSecurityGroups['access_id'] === e.access_id);
    }

    this.setPage(1);
  }

  search() {
    if (!isString(this.searchText)) return this.filteredUsers = this.originUsers;
    this.filteredUsers = [];
    this.pagedItems = [];

    if (this.searchText.length == 0) {
      this.filteredUsers = this.originUsers;
      this.isLoading = false;
      this.doFilterSite();
      return;
    }
    this.isLoading = true;

    const search_text = this.searchText.toLowerCase();
    if (search_text.length < 2) return (this.filteredUsers = this.originUsers);

    this.filteredUsers = this.originUsers.filter((user) =>
      {
        const divisionList = user?.divisions.map(v => v?.name)
        return [user.firstname, user.lastname, user.email, ...divisionList].join("").toLowerCase().includes(search_text)
      }
    );
    this.pagedItems = this.filteredUsers 
    this.isLoading = false
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredUsers?.length, page, this.pageOffset);
    this.totalSite = this.filteredUsers?.length;

    // get current page of items
    this.pagedItems = this.filteredUsers?.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.curPage = this.pager.currentPage;
    let lastIdx = this.pager.totalPages;
    if (this.curPage -1 < 0) {
      this.pageOffsetStart = 0;
    } else if (this.curPage -1 == 0) {
      this.pageOffsetStart = 1;
    } else {
      this.pageOffsetStart =  (this.curPage -1) * this.pageOffset +1;
    }

    if (this.curPage * this.pageOffset > this.totalSite) {
      this.pageOffsetEnd =  this.totalSite;
    } else {
      this.pageOffsetEnd =  this.curPage * this.pageOffset;
    }
    for (let i = 0; i < lastIdx; i ++) {
      let isExist = this.filteredUsers?.slice(i*this.pageOffset, i*this.pageOffset + this.pageOffset).filter(user => user.user_id == this.params);
      if (isExist?.length != 0) {
        if (this.pager.currentPage !== i+1) {
          this.setPage(i+1);
        }
      }
    }
  }

  // permission
  selectRole(role){
    this.selectedRole = role
    this.isUnfoldPermissionList = false
  }

  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}