import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { c_dialog_dealer_role_component } from "./c_dialog_dealer_role_component";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [CommonModule, FormsModule, MatSlideToggleModule],
  declarations: [c_dialog_dealer_role_component],
})
export class c_dialog_dealer_role_module {}
