import system from "./system";
const env = system.updateEnvironmentalVariablesGlobally();

console.debug("✅ 1config loaded: " + env);
export default {
  env,

  logs: {
    level: system.logLevel[env],
  },

  api: system.api[env],
  command_center_url: system.command_center_url[env],
  auth0_auth: system.auth0_auth[env],
  token_key: system.token_key[env],
  token_key_dev: system.token_key.dev,
  token_key_prod: system.token_key.prod,
  google_api_key: system.google_api_key[env],
};
