import config from './1config';
import load_loaders from './2loaders';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment'; // 이것은 여기를 따라야 함.
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import 'hammerjs';

//////////////////////////////////////////////////////
import 'codemirror/lib/codemirror';                 //
import 'codemirror/addon/edit/matchbrackets';       //
import 'codemirror/addon/hint/show-hint';           //
import 'codemirror/mode/javascript/javascript';     //
import 'codemirror/addon/fold/foldgutter';          //
import 'codemirror/addon/fold/brace-fold';          //
import 'codemirror/addon/edit/closebrackets';       //
import 'codemirror/addon/lint/lint';                //
import 'codemirror/addon/lint/json-lint';           //
import 'codemirror/addon/display/placeholder';      //
//////////////////////////////////////////////////////

if (config.env === "prod") {
  enableProdMode();
  load_loaders()
  if(window){
    window.console.log = function(){};
    window.console.group = function(){};
    window.console.groupEnd = function(){};
    window.console.table = function(){};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
