import { Component } from '@angular/core';
import { Helper } from 'src/4services/2helper';

@Component({
  selector: 'c_side_modal_direction_define_component',
  templateUrl: './c_side_modal_direction_define.component.pug',
  styleUrls: ['../../common.scss','../../c_side_modal.component.scss'],
})
export class c_side_modal_direction_define_component {

  constructor(private helper: Helper) { }

  goToSettings(){
    this.helper.router.navigate_to(`/settings/monitoring-portal-version`);
  }
}
