import { NgModule } from "@angular/core";

import { c_card_cloud_ai_stat_module } from "./analtics/video-ai-stat/c_card_cloud_ai_stat.module"
import { c_card_calendar_module } from './event-viewer/calendar/c_card_calendar.module'
import { c_card_heat_map_module } from './event-viewer/heat-map/c_card_heat_map.module'
import { c_card_event_viewer_box_module } from './event-viewer/event-viewer-box/c_card_event_viewer_box.module'
import { c_card_event_viewer_time_line_module } from "./event-viewer-time-line/c_card_event_viewer_time_line.module";
import { c_card_site_plan_module } from './site-plan/c_card_site_plan.module'
import { c_card_site_plan_v2_module } from './site-plan-v2/c_card_site_plan_v2.module'
import { c_map_module } from './google-map/c_map.module'

@NgModule({
  exports: [
    c_card_cloud_ai_stat_module,
    c_card_calendar_module,
    c_card_heat_map_module,
    c_card_event_viewer_box_module,
    c_card_event_viewer_time_line_module,
    c_card_site_plan_module,
    c_card_site_plan_v2_module,
    c_map_module
  ],
})
export class c_card_module {}
