import { trigger, state, style, transition, animate, AnimationTriggerMetadata} from '@angular/animations';

export const animeDialog: AnimationTriggerMetadata =
  trigger('dialog', [
    transition(':enter', [
      style({
        transform: 'translateY(+50%)',
        opacity: 0.5
      }),
      animate('0.2s ease-in')
    ]),
    transition(':leave', [
      animate('0.2s ease-out', style({
        transform: 'translateY(+50%)',
        opacity: 0.5
      }))
    ])
  ]);

export const animeBackground: AnimationTriggerMetadata =
  trigger('background', [
    state('*',
      style({
        opacity: 1
      })
    ),
    transition(':enter', [
      style({
        opacity: 0
      }),
      animate('0.1s ease-in')
    ]),
    transition(':leave', [
      animate('0.1s ease-out', style({
        opacity: 0
      }))
    ])
  ]);

export const animeSubmodal: AnimationTriggerMetadata =
  trigger('subModal', [
    transition(':enter', [
      style({
        transform: 'translateX(+100%)',
        // opacity: 0.5
      }),
      animate('0.2s ease-in')
    ]),
    transition(':leave', [
      animate(200, style({
        transform: 'translateX(100%)',
        opacity: 1
      }))
    ]),
    transition('* => void', [
      animate(200, style({ transform: 'translateX(100%)', opacity: 1 }))
    ])
  ]);

export const animeImgShow: AnimationTriggerMetadata =
  trigger('imgShow', [
    state('*',
      style({
        opacity: 1
      })
    ),
    transition(':enter', [
      style({
        opacity: 0
      }),
      animate('0.2s ease-in')
    ]),
    transition(':leave', [
      animate('0.2s ease-out', style({
        opacity: 0
      }))
    ])
  ]);

export const animeTopShow: AnimationTriggerMetadata =
  trigger('topShow', [
    state('*',
      style({
      })
    ),
    transition(':enter', [
      style({
        transform: 'translateY(-100%)',
        // opacity: 0.5
      }),
      animate('0.2s ease-in')
    ]),
    transition(':leave', [
      animate(200, style({
        transform: 'translateY(-100%)',
        opacity: 0
      }))
    ]),
    transition('* => void', [
      animate(200, style({ transform: 'translateY(-100%)', opacity: 0 }))
    ])
  ]);