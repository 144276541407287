import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { c_components } from "../../index";
import { Helper } from "../../../../4services/2helper";

@Component({
  templateUrl: "./c_dialog_dealer_division_component.pug",
  styleUrls: ["../common.scss", "./c_dialog_dealer_division_component.scss"],
})
export class c_dialog_dealer_division_component {
  // ------------------------------------------------------------------------

  public division_id: number;
  public division_name: string;
  public division_description: string;
  public isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<c_dialog_dealer_division_component>,
    @Inject(MAT_DIALOG_DATA)
    public data: number,
    private c_components: c_components,
    private helper: Helper
  ) {}

  ngOnInit() {
    this.division_id = this.data;
    this.update_division_data_if_division_id_provided();
  }

  close_dialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  async submit_changes() {
    this.isLoading = true;

    this.trim_spaces_data();
    if (this.division_id) await this.edit_division();
    else await this.create_division();

    this.isLoading = false;
  }

  // ------------------------------------------------------------------------

  async update_division_data_if_division_id_provided() {
    if (!this.division_id) return;

    const division = await (await this.helper.dealer_division.get_divisions()).find(v => v.id === this.division_id)
    this.division_name = division?.name;
    this.division_description = division?.description;
  }

  trim_spaces_data() {
    if(this.division_name) this.division_name = this.division_name?.trim();
    if(this.division_description) this.division_description = this.division_description?.trim();
  }

  async create_division() {
    const title = `Create a Division`;
    const msg = `${title} ${this.division_name} - Success.`;
    try {
      await this.helper.dealer_division.create_division({
        name: this.division_name,
        description: this.division_description,
      });
      this.openConfirmDialog(title, msg);
      this.helper.dealer_division.load_my_divisions();
    } catch (err) {
      this.openErrorDialog(err, title);
    }
  }

  async edit_division() {
    const title = `Edit a Division`;
    const msg = `${title} ${this.division_name} - Success.`;
    try {
      await this.helper.dealer_division.update_division(this.division_id, {
        name: this.division_name,
        description: this.division_description,
      });
      this.openConfirmDialog(title, msg);
      this.helper.dealer_division.load_my_divisions();
    } catch (err) {
      this.openErrorDialog(err, title);
    }
  }

  // ------------------------------------------------------------------------

  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {
        if (color === "green") {
          this.close_dialog(true);
        }
      },
    });
  }

  openErrorDialog(err, title) {
    let msg = "failed.";
    if (err.error) {
      if (err.error.message) {
        msg = err.error.message;
      }
    }
    if (err._body) {
      msg = JSON.parse(err._body).message;
    }
    setTimeout(() => {
      this.openConfirmDialog(title, msg, "warning", "orange");
    }, 200);
  }
}

// interface c_dialog_dealer_division_inputs {
//   id?: string;
// }
