import { Injectable } from '@angular/core';
import { Imports } from '../imports';

@Injectable()
export class Router {
  constructor(
    private ims: Imports,
  ) {}

  public navigate_to (path: string, queryParams: object = {}): void {
    this.ims.router.navigate(
      [path],
      {
        queryParams: {
          dealer_id: this.get_dealer_id_if_technical_support_session(),
          ...queryParams,
        }
      },
    )
  }

  public navigate_to_service_plan (): void {
    if (this.is_wcs_main_dealer()) {
      this.navigate_to("/settings/reseller-plan")
      return
    }

    this.navigate_to("/settings/dealer-plan")
  }

  private get_dealer_id_if_technical_support_session (): string {
    return this.ims.usersService.isTemporaryLogin ? this.ims.usersService.me?.dealer_id : null
  }

  private is_wcs_main_dealer (): boolean {
    return this.ims.usersService.me?.type === 2
  }
}
