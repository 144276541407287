import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from '../../../../app/directives/shared.module'

import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { c_dialog_dealer_member_add_user_component } from "./c_dialog_dealer_member_add_user_component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, MatRadioModule, MatSlideToggleModule],
  declarations: [c_dialog_dealer_member_add_user_component],
})
export class c_dialog_dealer_member_add_user_module {}
